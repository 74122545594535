import { JoonUIColor, QuestDifficulty, SPACING } from "@joonapp/web-shared"

import { capitalizeFirstLetter } from "../../../util/util"

type Props = {
  difficulty: QuestDifficulty
  reward: number | null
}

const DifficultyBadge = ({ difficulty, reward }: Props) => {
  const getBadgeColor = (difficulty: QuestDifficulty) => {
    switch (difficulty) {
      case "easy":
        return JoonUIColor.semantic.success
      case "medium":
        return JoonUIColor.semantic.warning
      case "hard":
        return JoonUIColor.semantic.destructive
    }
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        borderRadius: SPACING.space2,
        gap: SPACING.space1,
        color: JoonUIColor.text.inverted,
        whiteSpace: "nowrap",
        height: "fit-content",
        width: "fit-content",
        fontWeight: "600",
        fontSize: "12px",
        padding: `${SPACING.space1} ${SPACING.space2}`,
        background: getBadgeColor(difficulty),
      }}
    >
      <div>{capitalizeFirstLetter(difficulty)}</div>
      <div>{reward}</div>
      <img
        style={{ height: SPACING.space4, width: "auto" }}
        src="/images/icons/coin-icon.png"
        alt=""
      />
    </div>
  )
}

export default DifficultyBadge
