import {
  ChevronRightIcon,
  FlexBox,
  JoonUIColor,
  SPACING,
} from "@joonapp/web-shared"
import { useState } from "react"

import DataTaskCard from "./dataTasks/DataTaskCard"
import useDataTasksModalStore from "./dataTasks/useDataTasksModalStore"
import Card from "../../components/card/Card"
import { completeQuestStatuses } from "../../constants"
import {
  useIncidentTemplatesQuery,
  useParentTaskQuery,
} from "../../networking/queries"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { groupQuestsByTherapist } from "../../util/util"
import ReportIncidentButton from "../me/incidents/ReportIncidentButton"
import ParentTaskCard from "../me/parentTasks/ParentTaskCard"
import { useDisplayParentTaskSection } from "../me/parentTasks/useDisplayParentTasksSection"
import useParentTasksModalStore from "../me/parentTasks/useParentTasksModalStore"

const AcademyActions = () => {
  const { data: incidentTemplates } = useIncidentTemplatesQuery()
  const { data: parentTasks } = useParentTaskQuery()
  const groupedParentTasks = groupQuestsByTherapist(parentTasks)
  const [selectedTherapistIndex] = useState(0)
  const { displayParentTasksSection } = useDisplayParentTaskSection()

  const tasks = groupedParentTasks?.[selectedTherapistIndex]
  const selectedTherapistProfile = tasks?.[0]?.series?.assigner_profile
  const dataTasks = incidentTemplates?.parent_training_templates
  const sortedTasks =
    groupedParentTasks[selectedTherapistIndex]?.sort((task) =>
      completeQuestStatuses.includes(task.status) ? 1 : -1
    ) || []

  const hasRemainingTasks = !sortedTasks?.every((task) =>
    completeQuestStatuses.includes(task.status)
  )

  if (!displayParentTasksSection) return <></>

  return (
    <FlexBox direction="column" gap={SPACING.space2}>
      <div className="tour-step-3" style={{ width: "100%" }}>
        <ReportIncidentButton />
      </div>
      {dataTasks?.length === 1 ? (
        <DataTaskCard dataTask={dataTasks[0]} />
      ) : (
        dataTasks && dataTasks?.length > 1 && <DataTasksCard />
      )}
      {sortedTasks.length === 1 ? (
        <ParentTaskCard task={sortedTasks[0]} />
      ) : (
        hasRemainingTasks &&
        selectedTherapistProfile && (
          <ParentTasksCard therapistId={selectedTherapistProfile?.user_id} />
        )
      )}
    </FlexBox>
  )
}

export default AcademyActions

const DataTasksCard = () => {
  const { onOpen: openDataTasksModal } = useDataTasksModalStore()

  return (
    <Card
      title="Practice tasks"
      buttonComponent={<ArrowCircleRightIcon />}
      onClick={() => {
        openDataTasksModal()
      }}
    />
  )
}

const ParentTasksCard = ({ therapistId }: { therapistId: number }) => {
  const { onOpen: openParentTasksModal } = useParentTasksModalStore()

  return (
    <Card
      title="Quick to-dos"
      buttonComponent={<ArrowCircleRightIcon />}
      onClick={() => {
        openParentTasksModal(therapistId)
        trackAnalyticEvent(ANALYTIC_EVENTS.OPEN_PARENT_TASKS_SHEET)
      }}
    />
  )
}

export const ArrowCircleRightIcon = () => {
  return (
    <FlexBox
      direction="row"
      align="center"
      justify="center"
      gap={SPACING.space2}
      style={{
        background: JoonUIColor.background.lightGray,
        borderRadius: "50%",
        width: SPACING.space8,
        height: SPACING.space8,
        minWidth: SPACING.space8,
        minHeight: SPACING.space8,
        paddingLeft: SPACING.space05,
      }}
    >
      <ChevronRightIcon size={20} color={JoonUIColor.icon.neutral} />
    </FlexBox>
  )
}
