import {
  CloseIcon,
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  Shadow,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"

import useTraining from "./useTraining"
import useTrainingStore from "./useTrainingStore"
import LinearProgress from "../../../components/linearProgress/LinearProgress"

const TrainingTopBar = () => {
  const { onClose } = useTrainingStore()
  const { currentTraining, remainingModules, completionPercentage } =
    useTraining()

  return (
    <FlexBox
      direction="column"
      gap={SPACING.space1}
      style={{
        background: JoonUIColor.background.primaryNeutral,
        padding: SPACING.space4,
        borderBottom: `1px solid ${JoonUIColor.border.default}`,
        boxShadow: Shadow.high,
      }}
    >
      <FlexBox align="center" justify="space-between" wrap={false}>
        <Typography variant="bodyBold">{currentTraining?.title}</Typography>
        <TextButton onClick={onClose} style={{ padding: SPACING.space1 }}>
          <CloseIcon size={24} color={JoonUIColor.icon.neutral} />
        </TextButton>
      </FlexBox>
      <FlexBox align="center" justify="space-between" wrap={false}>
        <LinearProgress
          value={completionPercentage}
          style={{ width: "30%" }}
          displayValue
          colorPrimary={JoonColorExpanded.viridian300}
        />
        <Typography variant="bodySmall" color={JoonUIColor.text.secondary}>
          {remainingModules} items remaining
        </Typography>
      </FlexBox>
    </FlexBox>
  )
}

export default TrainingTopBar
