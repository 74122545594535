import { useMutation } from "@tanstack/react-query"

import { QUERY_KEYS } from "../../../constants"
import { useUserQuery } from "../../../networking/queries"
import { updatePhoneNumber } from "../../../networking/user"
import { queryClient } from "../../../util/queryClient"

export const usePhoneNumberMutation = () => {
  const { user } = useUserQuery()

  return useMutation({
    mutationFn: (phoneNumber: string) =>
      updatePhoneNumber({ userId: user?.id as number, phoneNumber }),
    onSuccess: () => queryClient.invalidateQueries([QUERY_KEYS.USER]),
  })
}
