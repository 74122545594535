import { create } from "zustand"

import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"

interface AddQuestTemplatesModalStore {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

export const useAddQuestTemplatesModalStore =
  create<AddQuestTemplatesModalStore>((set) => ({
    isOpen: false,
    onOpen: () => {
      trackAnalyticEvent(ANALYTIC_EVENTS.ADD_QUEST_INTENT)
      set({ isOpen: true })
    },
    onClose: () => set({ isOpen: false }),
  }))
