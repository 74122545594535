import {
  Button,
  JoonUIColor,
  Modal,
  ModalHeader,
  SPACING,
  SelectInput,
} from "@joonapp/web-shared"
import { useState } from "react"

interface Props {
  modalTitle: string
  time: string | null | undefined
  setTime: (time: string | null) => void
  placeholder?: string
  fullWidth?: boolean
  name?: string
  disabled?: boolean
}

const minuteOptions = Array.from({ length: 60 }, (_, i) => ({
  value: `${i}`,
  label: `${i} min`,
}))
const secondOptions = Array.from({ length: 60 }, (_, i) => ({
  value: `${i}`,
  label: `${i} sec`,
}))

const getDoubleDigitNum = (num: string | null | undefined) => {
  if (num === null || num === undefined) return "00"
  else if (num.length > 1) return num
  else return "0" + num
}

const TimerInput = ({
  modalTitle,
  time,
  setTime,
  placeholder,
  name,
  disabled,
  fullWidth,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false)

  const initialMinute = time
    ? {
        value: time?.split(":")[1],
        label: time?.split(":")[1],
      }
    : null
  const initialSec = time
    ? {
        value: time?.split(":")[2]?.split(" ")[0],
        label: time?.split(":")[2]?.split(" ")[0],
      }
    : null

  const [minute, setMinute] = useState<any>(initialMinute)
  const [seconds, setSeconds] = useState<any>(initialSec)

  const innerIconColor = disabled
    ? JoonUIColor.icon.inverted
    : JoonUIColor.icon.neutral

  const submit = () => {
    if (minute?.value > 0 || seconds?.value > 0) {
      let timeString = `00:${getDoubleDigitNum(minute?.value)}:${getDoubleDigitNum(
        seconds?.value
      )}`
      setTime(timeString)
    }

    setIsOpen(false)
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} animate>
        <ModalHeader title={modalTitle} onClose={() => setIsOpen(false)} />
        <div
          style={{
            width: "min(100vw, 380px)",
            padding: `${SPACING.space2} ${SPACING.space6} ${SPACING.space6} ${SPACING.space6}`,
          }}
        >
          <div style={{ display: "flex", gap: SPACING.space1 }}>
            <SelectInput
              isMulti={false}
              name="minute"
              options={minuteOptions}
              selectedOption={minute}
              setSelectedOption={setMinute}
              placeholder="Minute"
              fullWidth
            />
            <SelectInput
              isMulti={false}
              name="second"
              options={secondOptions}
              selectedOption={seconds}
              setSelectedOption={setSeconds}
              placeholder="Second"
              fullWidth
            />
          </div>

          <Button
            text="Done"
            onClick={submit}
            style={{ marginTop: "24px" }}
            fullWidth
          />
        </div>
      </Modal>

      <button
        className={`jooniverse__text-input ${fullWidth ? "full-width" : ""} `}
        style={{ cursor: "pointer" }}
        onClick={() => !disabled && setIsOpen(true)}
      >
        <svg
          width="21"
          height="20"
          viewBox="0 0 21 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.4994 18.3334C15.1018 18.3334 18.8326 14.6024 18.8326 10C18.8326 5.39765 15.1018 1.66669 10.4994 1.66669C5.89698 1.66669 2.16602 5.39765 2.16602 10C2.16602 14.6024 5.89698 18.3334 10.4994 18.3334Z"
            stroke={innerIconColor}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10.5 5V10L13.8334 11.6667"
            stroke={innerIconColor}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <input
          type="text"
          name={name}
          value={
            time
              ? `${Number(time.split(":")[1])} min, ${Number(
                  time.split(":")[2]
                )} sec`
              : undefined
          }
          placeholder={placeholder}
          disabled={disabled}
          aria-label={name}
          style={{ pointerEvents: "none" }}
        />
      </button>
    </>
  )
}

export default TimerInput
