import {
  QuestInstance,
  getReptitionIntervalFromRrule,
} from "@joonapp/web-shared"
import dayjs from "dayjs"

import {
  capitalizeFirstLetter,
  getDayAbbreviations,
  getRepetitionArrayFromRrule,
} from "../../../util/util"
import Badge from "../Badge"

type Props = {
  questInstance: QuestInstance
  type: "routine" | "reminder" | "repetition"
}

export const ScheduleBadge = ({ questInstance, type }: Props) => {
  const questSeries = questInstance.series
  const frequency = getReptitionIntervalFromRrule(questSeries.recurrence)
  const daysString = getDayAbbreviations(
    getRepetitionArrayFromRrule(questSeries.recurrence)
  )

  const getScheduleBadgeContent = () => {
    switch (type) {
      case "routine":
        return capitalizeFirstLetter(questSeries.routine)
      case "reminder":
        return questSeries.reminder_time
      case "repetition":
        return questSeries.occurrence_limit
          ? dayjs(questSeries.start_date).format("dddd, MMM D, YYYY")
          : `${daysString} every ${frequency > 1 ? frequency : ""} week${frequency > 1 ? "s" : ""}`
    }
  }

  return <Badge>{getScheduleBadgeContent()}</Badge>
}
