import { FlexBox, JoonUIColor, SPACING } from "@joonapp/web-shared"
import { useEffect, useState } from "react"

import { useCatchUpStore } from "./useCatchUpStore"

const CatchUpTabBar = ({
  children,
}: {
  children: React.ReactNode | React.ReactNode[]
}) => {
  const { cardScrollRef } = useCatchUpStore()
  const [displayBoxShadow, setDisplayBoxShadow] = useState(false)

  const cardElement = cardScrollRef?.current

  const updateShadow = () => {
    if (cardElement) {
      const hasScrollbar = cardElement.scrollHeight > cardElement.clientHeight
      const isAtScrollBottom =
        cardElement.scrollTop + 1 >=
        cardElement.scrollHeight - cardElement.clientHeight
      setDisplayBoxShadow(hasScrollbar && !isAtScrollBottom)
    }
  }

  useEffect(() => {
    if (cardElement) {
      cardElement.addEventListener("scroll", updateShadow)
      setTimeout(updateShadow, 0)
    }

    return () => {
      if (cardElement) cardElement.removeEventListener("scroll", updateShadow)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FlexBox
      direction="row"
      wrap={false}
      align="center"
      style={{
        alignSelf: "flex-end",
        width: "min(450px, 100vw)",
        height: "fit-content",
        borderTop: `1px solid ${JoonUIColor.border.default}`,
        borderBottomLeftRadius: SPACING.space4,
        borderBottomRightRadius: SPACING.space4,
        padding: `${SPACING.space2} ${SPACING.space3}`,
        position: "sticky",
        bottom: 0,
        background: "white",
        zIndex: "1002",
        boxShadow: displayBoxShadow
          ? "2px 0px 30px 0px rgba(36, 23, 185, 0.25)"
          : "none",
        transition: "box-shadow 0.2s",
      }}
    >
      {children}
    </FlexBox>
  )
}

export default CatchUpTabBar
