import { Button, TextInput, Typography } from "@joonapp/web-shared"
import { useMutation } from "@tanstack/react-query"
import toast from "react-hot-toast"

import DeleteAccount from "./DeleteAccount"
import BackButton from "../../components/buttons/BackButton"
import PageLoader from "../../components/loading/PageLoader"
import PageContentWrapper from "../../components/pageContentWrapper/PageContentWrapper"
import PageHeader from "../../components/pageHeader/PageHeader"
import PageWrapper from "../../components/pageWrapper/PageWrapper"
import { GRADIENTS } from "../../constants"
import useMobile from "../../hooks/useMobile"
import { resetPassword } from "../../networking/authentication"
import { useUserQuery } from "../../networking/queries"
import { useRouter } from "../../util/router"

const SettingsAccount = () => {
  const { user } = useUserQuery()
  const router = useRouter()
  const isMobile = useMobile()

  const { isLoading, mutate: resetPw } = useMutation({
    mutationFn: resetPassword,
    onSuccess: () => toast.success("Password reset email sent"),
    onError: () => toast.error("Error sending password reset email"),
  })

  if (!user || isLoading) return <PageLoader />

  return (
    <PageWrapper style={{ background: GRADIENTS.gradient100 }}>
      <PageHeader
        title="Account"
        leftComponent={
          isMobile && <BackButton onBack={() => router.push("/settings")} />
        }
      />
      <PageContentWrapper mobileGutter={true}>
        <Typography variant="caption">My Email:</Typography>
        <TextInput
          name="Email"
          onChange={() => {}}
          disabled
          style={{ height: 50, width: "100%" }}
          value={user?.email}
        />
        <Button
          isLoading={isLoading}
          style={{ marginTop: "20px" }}
          disabled={isLoading}
          onClick={() => resetPw(user?.email)}
          text="Change Password"
          buttonType="secondary"
        />
        <DeleteAccount />
      </PageContentWrapper>
    </PageWrapper>
  )
}

export default SettingsAccount
