import { Button, JoonUIColor, Modal } from "@joonapp/web-shared"
import { useQueryClient } from "@tanstack/react-query"
import { useState } from "react"

import { QUERY_KEYS } from "../../constants"
import { useUserQuery } from "../../networking/queries"
import { useAuth } from "../../util/auth"
import { createJoonAPIClient } from "../../util/joon-api"

const DeleteAccount = () => {
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { signout } = useAuth()
  const { user } = useUserQuery()
  const queryClient = useQueryClient()

  const deleteAccount = async () => {
    setIsLoading(true)
    const API = createJoonAPIClient()
    await API.delete(`/api/users/${user?.id}/`)
    setIsLoading(false)
    signout()
    setOpenConfirmModal(false)
    queryClient.invalidateQueries([QUERY_KEYS.USER])
  }

  return (
    <>
      <Modal
        isOpen={openConfirmModal}
        onClose={() => setOpenConfirmModal(false)}
        animate
      >
        <div
          style={{
            width: "min(95vw, 350px)",
            padding: "30px",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          <div className="section-title">
            Are you sure you want to delete your account?
          </div>
          <div>This action cannot be undone!</div>
          <div style={{ display: "flex", gap: "8px", marginTop: "12px" }}>
            <Button
              text="Cancel"
              onClick={() => setOpenConfirmModal(false)}
              fullWidth
            />
            <Button
              buttonType="redPrimary"
              onClick={deleteAccount}
              isLoading={isLoading}
              text="Delete"
              fullWidth
            />
          </div>
        </div>
      </Modal>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <button
          style={{
            marginTop: "64px",
            color: JoonUIColor.semantic.alert,
            fontSize: "16px",
            fontWeight: 600,
          }}
          onClick={() => setOpenConfirmModal(true)}
        >
          Delete Account
        </button>
      </div>
    </>
  )
}

export default DeleteAccount
