import {
  ChevronRightIcon,
  FlexBox,
  JoonUIColor,
  Loader,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { InlineWidget, useCalendlyEventListener } from "react-calendly"
import { isAndroid, isChrome, isMobile } from "react-device-detect"

import ChatBubble from "./ChatBubble"
import TimerBanner from "./coachingFlow/planComponents/TimerBanner"
import SmallCoachCard from "./SmallCoachCard"
import { useCoachingStore } from "./useCoachingStore"
import PageLoader from "../../components/loading/PageLoader"
import { useAddToHomescreenModal } from "../../components/modals/AddToHomescreenModal"
import { useIOSModal } from "../../components/modals/IOSModal"
import { CoachingStep, TrainingStep } from "../../constants"
import useMobile from "../../hooks/useMobile"
import { useUserQuery } from "../../networking/queries"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import {
  cohorts,
  experimentNames,
  useGetExperimentCohort,
} from "../../util/experiments"
import { submitCoachQuestionnaire } from "../../util/questionnaires"
import { useRouter } from "../../util/router"
import { localStorageItems } from "../../util/storage"

const CalendlyComponent: React.FC = () => {
  const { setStep, selectedCoach, planIndex } = useCoachingStore()
  const router = useRouter()
  const { user } = useUserQuery()
  const variant = useGetExperimentCohort(
    experimentNames.webParentVirtualCoachOnboarding
  )

  useCalendlyEventListener({
    onDateAndTimeSelected: () => {
      if (router.query.from === "academy")
        trackAnalyticEvent(ANALYTIC_EVENTS.MARKETING_DATE_TIME_SELECTED)
      else trackAnalyticEvent(ANALYTIC_EVENTS.CALENDLY_DATE_TIME_SELECTED)
    },
    onEventScheduled: () => {
      if (router.query.from === "academy")
        trackAnalyticEvent(ANALYTIC_EVENTS.MARKETING_EVENT_SCHEDULED)
      else trackAnalyticEvent(ANALYTIC_EVENTS.CALENDLY_EVENT_SCHEDULED)
      // submit questionnaires
      const coachQuestionnaire = localStorage.getItem(
        localStorageItems.coachQuestionnaire
      )
      if (coachQuestionnaire) {
        const {
          parentId,
          coachQuestionnaire: questionnaire,
          questionAnswerMapping,
        } = JSON.parse(coachQuestionnaire)
        submitCoachQuestionnaire(parentId, questionnaire, questionAnswerMapping)
      }
      const postSelectionQuestionnaire = localStorage.getItem(
        localStorageItems.postSelectionQuestionnaire
      )
      if (postSelectionQuestionnaire) {
        const {
          parentId,
          postSelectionQuestionnaire: questionnaire,
          questionAnswerMapping,
        } = JSON.parse(postSelectionQuestionnaire)
        submitCoachQuestionnaire(parentId, questionnaire, questionAnswerMapping)
      }
      localStorage.removeItem(localStorageItems.coachQuestionnaire)
      localStorage.removeItem(localStorageItems.postSelectionQuestionnaire)
      localStorage.setItem(localStorageItems.coachOnboardingComplete, "true")
      localStorage.setItem(localStorageItems.showCoachPending, "true")
      if (variant === cohorts.variantA || variant === cohorts.variantD)
        setStep(CoachingStep.SCHEDULED)
      else setStep(TrainingStep.SCHEDULED)
    },
  })

  return (
    <div
      style={{
        position: "relative",
        width: "min(450px, 100%)",
        boxShadow: "1px 1px 8px rgba(0, 0, 0, 0.08)",
      }}
    >
      <InlineWidget
        url={
          variant === cohorts.variantD &&
          selectedCoach?.user.name &&
          typeof planIndex === "number"
            ? `https://calendly.com/joonapp/${selectedCoach?.user.name.toLowerCase()}-first-coach-call-${planIndex + 1}`
            : "https://calendly.com/joonapp/joon-care-navigator-call"
        }
        pageSettings={{
          hideEventTypeDetails: false,
          hideLandingPageDetails: false,
          primaryColor: JoonUIColor.text.primaryAccent,
          textColor: JoonUIColor.text.primary,
        }}
        prefill={{
          email: user?.email,
          name: `${user?.name}${user?.last_name ? ` ${user?.last_name}` : ""}`,
        }}
        styles={{
          height: "70vh",
        }}
        LoadingSpinner={() => (
          <div style={{ position: "absolute", top: "50%", left: "50%" }}>
            <Loader color={JoonUIColor.text.secondary} />
          </div>
        )}
      />
    </div>
  )
}

const CalendlyPage = () => {
  const router = useRouter()
  const { onOpen: openIOSModal } = useIOSModal()
  const { onOpen: openAddToHomescreenModal } = useAddToHomescreenModal()
  const { setStep, selectedCoach } = useCoachingStore()

  const variant = useGetExperimentCohort(
    experimentNames.webParentVirtualCoachOnboarding
  )

  const isOnMobileChrome = isChrome && isMobile && isAndroid

  const isMobileMediaQuery = useMobile()

  const onSkipClick = () => {
    router.push("/me")
    if (router.query.from === "academy") {
      trackAnalyticEvent(ANALYTIC_EVENTS.MARKETING_NEVERMIND_CLICK)
      return
    }
    if (variant === cohorts.variantA)
      trackAnalyticEvent(ANALYTIC_EVENTS.PERSONALIZED_COACH_SKIP_CLICK, {
        step: CoachingStep.CALENDLY - 1,
      })
    else if (variant === cohorts.variantD)
      trackAnalyticEvent(ANALYTIC_EVENTS.PERSONALIZED_COACH_SKIP_CLICK, {
        step: CoachingStep.CALENDLY,
      })
    else
      trackAnalyticEvent(ANALYTIC_EVENTS.VIRTUAL_COACH_SKIP_CLICK, {
        step: TrainingStep.CALENDLY - TrainingStep.LANDING,
      })
    if (isOnMobileChrome) openAddToHomescreenModal()
    else openIOSModal()
  }

  if (!selectedCoach) return <PageLoader />

  return (
    <FlexBox
      justify="flex-start"
      align="center"
      direction="column"
      gap={SPACING.space4}
      style={{
        minHeight: "70vh",
        width: "min(450px, 90vw)",
        marginTop: isMobileMediaQuery ? SPACING.space4 : SPACING.space8,
      }}
    >
      {variant === cohorts.variantA ? (
        <Typography variant="h2" color={JoonUIColor.text.primary}>
          See if {selectedCoach.user.name} is the right fit for you with a
          risk-free 15 minute call
        </Typography>
      ) : variant === cohorts.variantD ? (
        <Typography variant="h2" color={JoonUIColor.text.primary}>
          Schedule your kick off call <br />
          with {selectedCoach.user.name}
        </Typography>
      ) : (
        <Typography variant="h2" color={JoonUIColor.text.primary}>
          Schedule your risk-free <br />
          15 minute initial call
        </Typography>
      )}

      {variant === cohorts.variantA && (
        <SmallCoachCard coachCardContent={selectedCoach} />
      )}

      {variant === cohorts.variantD && (
        <>
          <TimerBanner coachName={selectedCoach.user.name} />
          <FlexBox
            style={{ position: "relative", marginTop: SPACING.space8 }}
            fullWidth
          >
            <ChatBubble text="Let's find a time to meet!" width="fit-content" />
            <SmallCoachCard
              coachCardContent={selectedCoach}
              backgroundColor={JoonUIColor.background.primaryNeutral}
            />
          </FlexBox>
        </>
      )}

      <CalendlyComponent />

      <FlexBox
        direction="column"
        align="center"
        gap={SPACING.space3}
        style={{ marginBottom: "50px" }}
      >
        {variant === cohorts.variantD && (
          <button
            style={{
              width: "min(480px, 100%)",
              padding: 0,
            }}
            onClick={() => setStep(CoachingStep.RECOMMENDED_COACHES)}
          >
            <FlexBox
              direction="row"
              align="center"
              wrap={false}
              style={{
                background: JoonUIColor.background.primaryNeutral,
                borderRadius: SPACING.space2,
                padding: `${SPACING.space3} ${SPACING.space2} ${SPACING.space3} ${SPACING.space4}`,
                height: "72px",
                boxShadow: "4px 4px 6px rgba(0, 0, 0, 0.1)",
                overflow: "hidden",
              }}
            >
              <FlexBox direction="column" wrap={false}>
                <Typography
                  variant="bodySmall"
                  color={JoonUIColor.text.primaryAccent}
                  style={{ lineHeight: "1.4" }}
                >
                  Didn't see a time that works for you?
                </Typography>
                <Typography
                  variant="bodyBold"
                  color={JoonUIColor.text.primaryAccent}
                  textAlign="left"
                  style={{ lineHeight: "1.4" }}
                >
                  Choose another coach
                </Typography>
              </FlexBox>

              <div style={{ minWidth: "36px" }}>
                <ChevronRightIcon color={JoonUIColor.icon.accent} size={28} />
              </div>
            </FlexBox>
          </button>
        )}
        <TextButton onClick={onSkipClick}>
          <Typography variant="bodyBold" color={JoonUIColor.text.primaryAccent}>
            {variant === cohorts.variantD
              ? "Nevermind, I don't want a coach"
              : router.query.from === "academy" || variant === cohorts.variantA
                ? "Nevermind"
                : "Skip, Continue to Joon App"}
          </Typography>
        </TextButton>
      </FlexBox>
    </FlexBox>
  )
}

export default CalendlyPage
