import {
  Button,
  FlexBox,
  JoonUIColor,
  ShortAnswerLearningModuleAssignmentCompletion,
  ShortAnswerModule as ShortAnswerModuleType,
  SPACING,
  TextArea,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { useEffect, useState } from "react"

import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../../util/analytics"
import TrainingProgressionBar from "../TrainingProgressionBar"
import useAssignmentCompletionMutation from "../useAssignmentCompletionMutation"
import useTraining from "../useTraining"
import useTrainingStore from "../useTrainingStore"

const ShortAnswerModule = () => {
  const { currentModule } = useTraining()
  const learningModule = currentModule as ShortAnswerModuleType
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(-1)

  const startingPageIndex = -1
  const summaryPageIndex = learningModule.short_answer_questions.length

  switch (currentQuestionIndex) {
    case startingPageIndex:
      return (
        <ShortAnswerModuleStartingPage
          setCurrentQuestionIndex={setCurrentQuestionIndex}
        />
      )
    case summaryPageIndex:
      return (
        <ShortAnswerSummaryPage
          currentQuestionIndex={currentQuestionIndex}
          setCurrentQuestionIndex={setCurrentQuestionIndex}
        />
      )
    default:
      return (
        <>
          <ShortAnswerQuestion
            currentQuestionIndex={currentQuestionIndex}
            setCurrentQuestionIndex={setCurrentQuestionIndex}
          />
        </>
      )
  }
}

export default ShortAnswerModule

const ShortAnswerQuestion = ({
  currentQuestionIndex,
  setCurrentQuestionIndex,
}: {
  currentQuestionIndex: number
  setCurrentQuestionIndex: (index: number) => void
}) => {
  const { currentModule, currentCompletion } = useTraining()
  const learningModule = currentModule as ShortAnswerModuleType
  const completion =
    currentCompletion as ShortAnswerLearningModuleAssignmentCompletion

  const currentQuestion =
    learningModule.short_answer_questions[currentQuestionIndex]
  const numberOfQuestions = learningModule.short_answer_questions.length
  const userAnswer =
    completion?.short_answer_answers?.[currentQuestionIndex]?.answer

  const assignmentCompletionMutation = useAssignmentCompletionMutation()

  const [answer, setAnswer] = useState(userAnswer ? userAnswer : "")

  useEffect(() => {
    // If going to next module, reset question index
    const completedAnswer = completion?.short_answer_answers?.find(
      (answer) => answer.question_id === currentQuestion.id
    )
    setAnswer(completedAnswer?.answer || "")
  }, [completion, currentQuestion, setCurrentQuestionIndex])

  const onClickNext = () => {
    assignmentCompletionMutation.mutate({
      assignmentCompletionId: completion.id,
      shortAnswerAnswers: [{ question_id: currentQuestion.id, answer }],
    })
    setCurrentQuestionIndex(currentQuestionIndex + 1)
  }

  return (
    <>
      <FlexBox
        direction="column"
        style={{
          padding: SPACING.space6,
          background: JoonUIColor.background.primaryNeutral,
          flex: 1,
        }}
        gap={SPACING.space4}
      >
        <Typography variant="bodySmall" textAlign="center">
          Question {currentQuestionIndex + 1} of {numberOfQuestions}
        </Typography>
        <Typography variant="h2" textAlign="center">
          {currentQuestion?.text}
        </Typography>

        <TextArea
          name="Answer"
          value={answer}
          onChange={(e) => setAnswer(e.target.value)}
          placeholder="Type your answer..."
          fullWidth
        />
      </FlexBox>
      <TrainingProgressionBar>
        <FlexBox direction="row" gap={SPACING.space3} wrap={false}>
          <TextButton
            onClick={() => setCurrentQuestionIndex(currentQuestionIndex - 1)}
          >
            <Typography
              variant="bodyBold"
              color={JoonUIColor.text.primaryAccent}
              style={{ padding: `0 ${SPACING.space8}` }}
            >
              Previous
            </Typography>
          </TextButton>
          <Button
            text="Next"
            onClick={onClickNext}
            fullWidth
            disabled={!answer}
          />
        </FlexBox>
      </TrainingProgressionBar>
    </>
  )
}

const ShortAnswerModuleStartingPage = ({
  setCurrentQuestionIndex,
}: {
  setCurrentQuestionIndex: (index: number) => void
}) => {
  const { currentModule } = useTraining()
  const learningModule = currentModule as ShortAnswerModuleType
  const totalQuestions = learningModule.short_answer_questions.length

  const onClickStart = () => {
    setCurrentQuestionIndex(0)
  }

  return (
    <>
      <FlexBox
        direction="column"
        gap={SPACING.space4}
        style={{ padding: SPACING.space6 }}
      >
        <FlexBox
          direction="column"
          align="center"
          justify="center"
          gap={SPACING.space6}
          style={{
            background: JoonUIColor.background.primaryNeutral,
            borderRadius: SPACING.space2,
            padding: SPACING.space4,
          }}
        >
          <Typography variant="h2" textAlign="center">
            {learningModule.title}
          </Typography>
          <Typography variant="body" textAlign="center">
            {totalQuestions} questions
          </Typography>
        </FlexBox>
      </FlexBox>
      <TrainingProgressionBar>
        <Button text="Start" onClick={onClickStart} fullWidth />
      </TrainingProgressionBar>
    </>
  )
}

const ShortAnswerSummaryPage = ({
  currentQuestionIndex,
  setCurrentQuestionIndex,
}: {
  currentQuestionIndex: number
  setCurrentQuestionIndex: (index: number) => void
}) => {
  const { incrementModuleIndex } = useTrainingStore()
  const { getButtonText, currentModule } = useTraining()
  const learningModule = currentModule as ShortAnswerModuleType
  const totalQuestions = learningModule.short_answer_questions.length

  const buttonText = getButtonText({ isLastPageInModule: true })

  const onClickNext = () => {
    setCurrentQuestionIndex(-1)
    incrementModuleIndex()
    trackAnalyticEvent(
      ANALYTIC_EVENTS.COMPLETE_SHORT_ANSWER_LEARNING_ASSIGNMENT
    )
  }

  return (
    <>
      <FlexBox
        direction="column"
        gap={SPACING.space4}
        style={{ padding: SPACING.space6 }}
      >
        <FlexBox
          direction="column"
          align="center"
          justify="center"
          gap={SPACING.space6}
          style={{
            background: JoonUIColor.background.primaryNeutral,
            borderRadius: SPACING.space2,
            padding: SPACING.space4,
          }}
        >
          <Typography variant="h2" textAlign="center">
            {learningModule.title}
          </Typography>
          <Typography variant="body" textAlign="center">
            {totalQuestions} questions answered!
          </Typography>
        </FlexBox>
      </FlexBox>
      <TrainingProgressionBar>
        <FlexBox direction="row" gap={SPACING.space3} wrap={false}>
          <TextButton
            onClick={() => setCurrentQuestionIndex(currentQuestionIndex - 1)}
          >
            <Typography
              variant="bodyBold"
              color={JoonUIColor.text.primaryAccent}
              style={{ padding: `0 ${SPACING.space8}` }}
            >
              Previous
            </Typography>
          </TextButton>
          <Button
            text={buttonText}
            onClick={onClickNext}
            fullWidth
            style={{ whiteSpace: "nowrap" }}
          />
        </FlexBox>
      </TrainingProgressionBar>
    </>
  )
}
