import {
  ArrowRightIcon,
  FlexBox,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"

import { useCatchUp } from "./useCatchUpStore"
import { useFamilyQuery, useQuestReviewQuery } from "../../networking/queries"
import useQuestReviewStore from "../questBoard/questReview/useQuestReviewStore"

const CatchUpButton = () => {
  const { data: questsToReview } = useQuestReviewQuery()
  const { openCatchUp } = useCatchUp()
  const { isLoading: isLoadingFamily } = useFamilyQuery()
  const { onOpen: openQuestReviewModal } = useQuestReviewStore()

  if (!questsToReview || questsToReview.length === 0 || isLoadingFamily)
    return <></>

  return (
    <div
      style={{
        boxShadow: "4px 6px 6px 0px rgba(47, 30, 53, 0.24)",
        borderRadius: SPACING.space4,
        overflow: "hidden",
      }}
    >
      <button
        style={{ padding: "0", width: "100%" }}
        onClick={() => openCatchUp({ isReviewing: true })}
      >
        <FlexBox
          direction="row"
          wrap={false}
          justify="space-around"
          align="center"
          style={{
            background: "linear-gradient(to right, #F47474, #CB6ED9, #6425ED)",
            padding: `${SPACING.space4}`,
            borderRadius: SPACING.space4,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            boxSizing: "border-box",
            position: "relative",
          }}
        >
          <span
            style={{
              position: "absolute",
              top: "0px",
              left: "0px",
              right: "0px",
              bottom: "0px",
              borderRadius: SPACING.space4,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              border: "3px solid rgba(255, 255, 255, 0.5)",
              pointerEvents: "none",
            }}
          />
          <FlexBox direction="column">
            <Typography variant="bodyXSmall" color={JoonUIColor.text.inverted}>
              Catch up
            </Typography>
            <Typography variant="caption" color={JoonUIColor.text.inverted}>
              Quests: {questsToReview.length} to review
            </Typography>
          </FlexBox>
          <ArrowRightIcon color={JoonUIColor.text.inverted} size={15} />
        </FlexBox>
      </button>
      <button
        style={{
          height: SPACING.space12,
          width: "100%",
          zIndex: 20,
          background: "white",
          padding: 0,
        }}
        onClick={openQuestReviewModal}
      >
        <FlexBox
          direction="row"
          align="center"
          justify="space-between"
          style={{ padding: `${SPACING.space3} ${SPACING.space4}` }}
        >
          <Typography variant="caption">Quick Review in Bulk</Typography>
          <ArrowRightIcon size={15} color={JoonUIColor.icon.neutral} />
        </FlexBox>
      </button>
    </div>
  )
}

export default CatchUpButton
