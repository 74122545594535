import {
  Button,
  FlexBox,
  JoonUIColor,
  Modal,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"

import { useQuestDndListStore } from "./reorderQuests/useQuestDndListStore.ts"
import { useMoreOptionsModalStore } from "./useMoreOptionsModalStore.ts"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics.ts"
import useQuestBoardStore from "../useQuestBoardStore.ts"

const MoreOptionsModal = () => {
  const { isOpen, onClose } = useMoreOptionsModalStore()
  const { onOpen: onOpenReorderQuestsModal } = useQuestDndListStore()
  const { setSelectedRoutine } = useQuestBoardStore()

  const onReorderQuestsClick = () => {
    trackAnalyticEvent(ANALYTIC_EVENTS.REORDER_QUESTS)
    setSelectedRoutine(null)
    onClose()
    onOpenReorderQuestsModal()
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      style={{
        padding: SPACING.space4,
        width: "min(200px, 100vw)",
      }}
      displayCloseIcon
      animate
    >
      <FlexBox
        direction="column"
        align="center"
        gap={SPACING.space2}
        fullWidth
        wrap={false}
      >
        <Typography variant="h3" style={{ paddingBottom: SPACING.space4 }}>
          More Options
        </Typography>
        <Button
          buttonType="primary"
          text="Reorder Quests"
          onClick={onReorderQuestsClick}
          fullWidth
        />
        <TextButton
          onClick={onClose}
          style={{ width: "100%", borderRadius: "100px" }}
        >
          <Typography variant="bodyBold" color={JoonUIColor.text.primaryAccent}>
            Cancel
          </Typography>
        </TextButton>
      </FlexBox>
    </Modal>
  )
}

export default MoreOptionsModal
