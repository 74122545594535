import { SPACING } from "@joonapp/web-shared"
import React, { useRef } from "react"

import useMobile from "../../hooks/useMobile"

type Props = {
  children: React.ReactNode
  isOpen: boolean
  onClose: () => void
  showCard: boolean
  hasHeaderComponent?: boolean
  size: "fit-content" | "default"
}

const SlideUpModalCard = ({
  isOpen,
  onClose,
  showCard,
  size,
  hasHeaderComponent = false,
  children,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null)
  const isMobile = useMobile()

  return (
    <div
      ref={ref}
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100dvh",
        width: "min(450px, 100vw)",
        position: "fixed",
        top: 0,
        pointerEvents: isOpen ? "auto" : "none",
        zIndex: "1000",
        overflow: "hidden",
        scrollbarWidth: "none",
      }}
    >
      {hasHeaderComponent && (
        <div style={{ minHeight: "80px", cursor: "default" }} />
      )}
      <button
        style={{
          height: "100vh",
          width: "100vw",
          position: "absolute",
          cursor: "default",
        }}
        onClick={onClose}
      />
      <div
        style={{
          bottom: 0,
          background: "white",
          marginTop: hasHeaderComponent ? "0px" : "auto",
          position: "relative",
          marginBottom: isOpen && !isMobile ? "auto" : "unset",
          borderRadius: SPACING.space4,
          borderBottomLeftRadius: isMobile ? 0 : SPACING.space4,
          borderBottomRightRadius: isMobile ? 0 : SPACING.space4,
          width: "min(600px, 100%)",
          zIndex: "1000",
          transform: isOpen && showCard ? "translateY(0)" : "translateY(100vh)",
          transition: "transform 0.3s",
          height:
            isMobile && size === "default"
              ? hasHeaderComponent
                ? "calc(100dvh - 80px)"
                : "95vh"
              : "auto",
          overflow: "auto",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        {children}
      </div>
    </div>
  )
}

export default SlideUpModalCard
