import { ParentAvatar } from "@joonapp/web-shared"

import { getParentAvatarImage } from "../../../util/util"

type Props = {
  avatar: ParentAvatar | null
  isSelected: boolean
  setSelectedParentAvatar: () => void
}

const ParentAvatarButton = ({
  avatar,
  isSelected,
  setSelectedParentAvatar,
}: Props) => {
  return (
    <button
      className={`parent-avatar-section ${isSelected ? "selected" : ""}`}
      onClick={setSelectedParentAvatar}
    >
      <img
        src={avatar ? (getParentAvatarImage(avatar) as string) : undefined}
        alt={avatar ? (getParentAvatarImage(avatar) as string) : undefined}
      />
    </button>
  )
}

export default ParentAvatarButton
