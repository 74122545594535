import {
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import React from "react"

type Props = {
  value: number
  style?: React.CSSProperties
  colorPrimary?: JoonColorExpanded
  colorSecondary?: string
  displayValue?: boolean
}

const LinearProgress = ({
  value,
  style,
  colorPrimary = JoonUIColor.background.accent,
  colorSecondary = "#D1DCEC",
  displayValue = false,
}: Props) => {
  return (
    <div
      style={{
        height: SPACING.space4,
        background: colorSecondary,
        width: "100%",
        borderRadius: SPACING.space6,
        overflow: "hidden",
        position: "relative",
        ...style,
      }}
    >
      <div
        style={{
          height: "100%",
          width: `${value}%`,
          background: colorPrimary,
          transition: "width 0.3s",
        }}
      />
      {displayValue && (
        <Typography
          variant="bodyXSmall"
          style={{
            position: "absolute",
            left: SPACING.space2,
            top: 0,
            fontWeight: "600",
          }}
        >
          {value.toFixed()}%
        </Typography>
      )}
    </div>
  )
}

export default LinearProgress
