import { JoonColorExpanded } from "@joonapp/web-shared"
import { CSSProperties } from "react"

import { useFamilyUnreadMailQuery } from "../../networking/queries"

type Props = {
  size: CSSProperties["height"]
}

export default function NotificationDot({ size }: Props) {
  const { data: unreadMail } = useFamilyUnreadMailQuery()
  if (unreadMail === undefined || !unreadMail.length) return <></>
  return (
    <div
      style={{
        height: size,
        width: size,
        borderRadius: "50%",
        background: JoonColorExpanded.red400,
        position: "absolute",
        top: "-4px",
        right: "-4px",
      }}
    />
  )
}
