import { FlexBox, SPACING } from "@joonapp/web-shared"
import { useLocation } from "react-router-dom"

import useDocumentTitle from "../hooks/useDocumentTitle"

function NotFoundPage() {
  useDocumentTitle("404")
  const location = useLocation()

  return (
    <FlexBox
      direction="column"
      gap={SPACING.space2}
      align="center"
      justify="center"
      style={{
        paddingBottom: "100px",
        textAlign: "center",
        height: "100dvh",
      }}
    >
      The page <code>{location.pathname}</code> could not be found
    </FlexBox>
  )
}

export default NotFoundPage
