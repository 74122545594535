import { DropdownOption } from "@joonapp/web-shared"
import { create } from "zustand"

import { periodDropdownOptions } from "../../../constants"

type CoinTransactionsModalStore = {
  isOpen: boolean
  onOpen: (child: any) => void
  onClose: () => void
  child: any
  selectedPeriod: DropdownOption
  setSelectedPeriod: (selectedPeriod: DropdownOption) => void
  startDate: string | null
  endDate: string
}

export const useCoinTransactionsModalStore = create<CoinTransactionsModalStore>(
  (set) => ({
    isOpen: false,
    onOpen: (child) => set({ isOpen: true, child }),
    onClose: () => set({ isOpen: false, child: null }),
    child: null,
    selectedPeriod: periodDropdownOptions[0],
    setSelectedPeriod: (selectedPeriod: DropdownOption) =>
      set({
        selectedPeriod,
        startDate: selectedPeriod.startDate,
        endDate: selectedPeriod.endDate,
      }),
    startDate: periodDropdownOptions[0].startDate,
    endDate: periodDropdownOptions[0].endDate,
  })
)
