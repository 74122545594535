import {
  FlexBox,
  JoonUIColor,
  SPACING,
  TherapistIcon,
  Typography,
} from "@joonapp/web-shared"
import { useState } from "react"

import { useDisplayParentTaskSection } from "./useDisplayParentTasksSection"
import { useParentTaskQuery } from "../../../networking/queries"
import { groupQuestsByTherapist } from "../../../util/util"
import AcademyActions from "../../academy/AcademyActions"

const ParentTasksSection = () => {
  const { data: parentTasks } = useParentTaskQuery()
  const { displayParentTasksSection } = useDisplayParentTaskSection()
  const groupedParentTasks = groupQuestsByTherapist(parentTasks)
  const [selectedTherapistIndex] = useState(0)
  const tasks = groupedParentTasks?.[selectedTherapistIndex]
  const selectedTherapistProfile = tasks?.[0]?.series?.assigner_profile

  if (!displayParentTasksSection) return <></>

  return (
    <FlexBox
      direction="column"
      gap={SPACING.space4}
      align="center"
      style={{
        background:
          "linear-gradient(108deg, rgba(255, 255, 255, 0.60) -6.4%, rgba(255, 255, 255, 0.80) 20.05%, rgba(255, 255, 255, 0.70) 69.45%, rgba(255, 255, 255, 0.60) 111.48%)",
        borderRadius: SPACING.space4,
        padding: SPACING.space4,
      }}
    >
      <FlexBox direction="row" align="center" gap={SPACING.space2}>
        <TherapistIcon size={20} color={JoonUIColor.background.accent} />
        <Typography variant="bodyBold">
          From {selectedTherapistProfile?.nickname}
        </Typography>
      </FlexBox>
      <AcademyActions />
    </FlexBox>
  )
}

export default ParentTasksSection
