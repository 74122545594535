import { create } from "zustand"

interface ManageGameplayModalStore {
  isOpen: boolean
  onOpen: (child?: number) => void
  onClose: () => void
  childId: number | null
}

export const useManageGameplayModalStore = create<ManageGameplayModalStore>(
  (set) => ({
    isOpen: false,
    onOpen: (childId) => set({ isOpen: true, childId }),
    onClose: () => set({ isOpen: false, childId: null }),
    childId: null,
  })
)
