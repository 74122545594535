import {
  Button,
  Checkbox,
  FlexBox,
  SPACING,
  Typography,
  VideoModule as VideoModuleType,
} from "@joonapp/web-shared"
import { useState } from "react"
import ReactPlayer from "react-player"

import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../../util/analytics"
import TrainingProgressionBar from "../TrainingProgressionBar"
import useAssignmentCompletionMutation from "../useAssignmentCompletionMutation"
import useTraining from "../useTraining"
import useTrainingStore from "../useTrainingStore"
import { getModuleTheme } from "../utils"

const VideoModule = () => {
  const { currentCompletion, currentModule, getButtonText } = useTraining()
  const { incrementModuleIndex } = useTrainingStore()
  const learningModule = currentModule as VideoModuleType

  const [hasVerifiedCompletion, setHasVerifiedCompletion] = useState(
    !!currentCompletion && !!currentCompletion.date_completed
  )

  const assignmentCompletionMutation = useAssignmentCompletionMutation()

  const { completionVerificationText } = currentModule
    ? getModuleTheme(currentModule?.type)
    : { completionVerificationText: null }

  const onClickNext = () => {
    if (!currentCompletion) return
    incrementModuleIndex()
    assignmentCompletionMutation.mutate({
      assignmentCompletionId: currentCompletion.id,
    })
    trackAnalyticEvent(ANALYTIC_EVENTS.COMPLETE_BASIC_LEARNING_ASSIGNMENT)
  }

  const buttonText = getButtonText({ isLastPageInModule: true })

  return (
    <>
      <FlexBox
        direction="column"
        gap={SPACING.space6}
        align="center"
        wrap={false}
        style={{ padding: SPACING.space6 }}
      >
        <Typography
          variant="h2"
          textAlign="center"
          style={{ padding: SPACING.space4 }}
        >
          {learningModule?.title}
        </Typography>
        <ReactPlayer
          url={learningModule?.video_url}
          width={"100%"}
          controls
          style={{
            borderRadius: SPACING.space2,
            overflow: "hidden",
          }}
        />
      </FlexBox>
      <TrainingProgressionBar>
        <FlexBox direction="column" gap={SPACING.space2}>
          {!!completionVerificationText && (
            <Checkbox
              name="Completion verification"
              hideBorder
              label={completionVerificationText}
              style={{ background: "transparent" }}
              selected={hasVerifiedCompletion}
              onChange={() => setHasVerifiedCompletion(!hasVerifiedCompletion)}
            />
          )}
          <Button
            text={buttonText}
            onClick={onClickNext}
            fullWidth
            disabled={!!completionVerificationText && !hasVerifiedCompletion}
            style={{ whiteSpace: "nowrap" }}
          />
        </FlexBox>
      </TrainingProgressionBar>
    </>
  )
}

export default VideoModule
