import { UserRole } from "@joonapp/web-shared"
import { useMemo } from "react"

import { useCareTeamQuery } from "../../networking/queries"
import { useRouter } from "../../util/router"

const useCareTeam = () => {
  const { data: careTeam, isLoading } = useCareTeamQuery()
  const router = useRouter()
  const careTeamUserId = Number(router.query.careTeamUserId)

  const { selectedProfile, hasCoach, hasTherapist, sortedCareTeam } =
    useMemo(() => {
      const selected = careTeam?.profiles?.find(
        (profile) => profile.user.id === careTeamUserId
      )
      const coach = !!careTeam?.profiles?.find(
        (profile) => profile?.role === UserRole.COACH
      )
      const therapist = !!careTeam?.profiles?.find(
        (profile) => profile?.role === UserRole.THERAPIST
      )

      const sorted = careTeam
        ? [...careTeam.profiles].sort((profile1, profile2) => {
            if (
              profile1.role === UserRole.COACH &&
              profile2.role === UserRole.COACH
            ) {
              return profile1.user.id < profile2.user.id ? -1 : 1
            } else if (profile1.role === UserRole.COACH) {
              return -1
            } else if (profile2.role === UserRole.COACH) {
              return 1
            } else {
              return profile1.user.id < profile2.user.id ? -1 : 1
            }
          })
        : []

      return {
        selectedProfile: selected,
        hasCoach: coach,
        hasTherapist: therapist,
        sortedCareTeam: sorted,
      }
    }, [careTeam, careTeamUserId])

  return {
    careTeam: sortedCareTeam,
    isLoading,
    hasCoach,
    hasTherapist,
    selectedProfile,
  }
}

export default useCareTeam
