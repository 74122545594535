import { create } from "zustand"

interface ManageProfileModalStore {
  isOpen: boolean
  onOpen: (child?: number) => void
  onClose: () => void
  newNickname: string
  setNewNickname: (newNickname: string) => void
  childId: number | null
}

export const useManageProfileModalStore = create<ManageProfileModalStore>(
  (set) => ({
    isOpen: false,
    onOpen: (childId) => set({ isOpen: true, childId }),
    onClose: () => set({ isOpen: false, childId: null }),
    newNickname: "",
    setNewNickname: (newNickname) => set({ newNickname }),
    childId: null,
  })
)
