import {
  AuthCode,
  FlexBox,
  JoonColorExpanded,
  SPACING,
  Typography,
} from "@joonapp/web-shared"

import { useChildAuthCodeQuery } from "../../networking/queries"

const ChildAuthCode = () => {
  const { data: authCode } = useChildAuthCodeQuery()
  return (
    <FlexBox
      gap={SPACING.space2}
      align="center"
      style={{ paddingBottom: SPACING.space3, width: "fit-content" }}
    >
      <Typography variant="bodyBold" color={JoonColorExpanded.indigo700}>
        Child Login Code:{" "}
      </Typography>
      <AuthCode
        code={authCode || ""}
        style={{ color: JoonColorExpanded.indigo700 }}
      />
    </FlexBox>
  )
}

export default ChildAuthCode
