import {
  DropdownOption,
  FamilyParentProfile,
  FlexBox,
  GearIcon,
  JoonUIColor,
  MailIcon,
  SPACING,
  SegmentedControl2,
  TextButton,
  Typography,
  UsersIcon,
} from "@joonapp/web-shared"
import { useState } from "react"
import toast from "react-hot-toast"

import ChildFamilyItem from "./ChildFamilyItem"
import { useEditFamilyModalStore } from "./EditFamilyModal"
import ParentFamilyItem from "./ParentFamilyItem"
import QuickActionsFamilyItem from "./QuickActionsFamilyItem"
import TherapistsTab from "./therapists/TherapistsTab"
import ChildAuthCode from "../../components/childAuthCode/ChildAuthCode"
import PageContentWrapper from "../../components/pageContentWrapper/PageContentWrapper"
import PageHeader from "../../components/pageHeader/PageHeader"
import PageWrapper from "../../components/pageWrapper/PageWrapper"
import { FamilySkeleton } from "../../components/skeleton/Skeleton"
import { Toasts } from "../../components/toaster/Toaster"
import { GRADIENTS } from "../../constants"
import useDocumentTitle from "../../hooks/useDocumentTitle"
import useMobile from "../../hooks/useMobile"
import {
  useFamilyQuery,
  useTherapistsQuery,
  useUserQuery,
} from "../../networking/queries"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { requireAuth } from "../../util/auth"
import { useRouter } from "../../util/router"
import { separateFamily, noOnboardedChildren } from "../../util/util"

enum FamilyPageTab {
  FAMILY,
  THERAPISTS,
}

const familyPageTabs = [
  {
    label: "Family",
    value: FamilyPageTab.FAMILY,
  },
  {
    label: "My Therapists",
    value: FamilyPageTab.THERAPISTS,
  },
]

const FamilyPage = () => {
  useDocumentTitle("Joon - My Family")
  useTherapistsQuery()

  const isMobile = useMobile()
  const router = useRouter()

  const [familyTab, setFamilyTab] = useState(FamilyPageTab.FAMILY)

  const onClickFamilyTab = (tab: FamilyPageTab) => {
    setFamilyTab(tab)
    if (tab === FamilyPageTab.FAMILY) {
      trackAnalyticEvent(ANALYTIC_EVENTS.FAMILY_TAB_MANAGE_FAMILY)
    } else {
      trackAnalyticEvent(ANALYTIC_EVENTS.FAMILY_TAB_MANAGE_THERAPISTS)
    }
  }

  return (
    <PageWrapper style={{ background: GRADIENTS.gradient100 }}>
      <PageHeader
        title="Family"
        rightComponent={
          isMobile && (
            <TextButton onClick={() => router.push("/settings")}>
              <GearIcon size={20} color={JoonUIColor.icon.neutral} />
            </TextButton>
          )
        }
      />
      <ChildAuthCode />
      <PageContentWrapper mobileGutter={true}>
        <SegmentedControl2
          options={familyPageTabs}
          value={
            familyPageTabs.find(
              (tab) => tab.value === familyTab
            ) as DropdownOption
          }
          setValue={(tab) => onClickFamilyTab(tab.value as FamilyPageTab)}
          fullWidth
          style={{ border: `1px solid ${JoonUIColor.border.default}` }}
        />
        {familyTab === FamilyPageTab.FAMILY ? <FamilyTab /> : <TherapistsTab />}
      </PageContentWrapper>
    </PageWrapper>
  )
}

const FamilyPageWithAuth = requireAuth(FamilyPage)
export default FamilyPageWithAuth

const FamilyTab = () => {
  const { user } = useUserQuery()
  const { data: family, isLoading } = useFamilyQuery()
  const { parent, coparents, children } = separateFamily(user, family)
  const { onOpen: onOpenEditFamilyModal } = useEditFamilyModalStore()
  const router = useRouter()

  if (isLoading)
    return (
      <div style={{ marginTop: SPACING.space3 }}>
        <FamilySkeleton />
      </div>
    )

  const onFamilyMailboxClick = () => {
    if (noOnboardedChildren(children)) {
      toast.dismiss()
      toast(Toasts.noOnboardedChildren, { duration: 3000 })
    } else {
      trackAnalyticEvent(ANALYTIC_EVENTS.OPEN_FAMILY_MAILBOX)
      router.push("/family/mailbox")
    }
  }

  return (
    <FlexBox direction="column" gap={SPACING.space2}>
      <Typography variant="bodyBold" style={{ marginTop: SPACING.space4 }}>
        Quick actions
      </Typography>
      <QuickActionsFamilyItem
        icon={MailIcon}
        title={"Family Mailbox"}
        onClick={onFamilyMailboxClick}
      />
      <QuickActionsFamilyItem
        icon={UsersIcon}
        title={"Edit family information"}
        onClick={onOpenEditFamilyModal}
      />
      <Typography variant="bodyBold" style={{ marginTop: SPACING.space4 }}>
        Parents
      </Typography>
      {parent && <ParentFamilyItem user={parent} />}
      {coparents?.map((coparent: FamilyParentProfile, i: number) => (
        <ParentFamilyItem user={coparent} key={i} />
      ))}
      <Typography variant="bodyBold" style={{ marginTop: SPACING.space4 }}>
        Children
      </Typography>
      {children.map((child, i) => (
        <ChildFamilyItem user={child.user} key={i} />
      ))}
    </FlexBox>
  )
}
