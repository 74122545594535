import {
  Button,
  CalendarCheckIcon,
  ChatIcon,
  FlexBox,
  GearIcon,
  HeartIcon,
  InfoSolidIcon,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { isChrome, isMobile, isAndroid } from "react-device-detect"

import ListCard from "../../../components/coaching/ListCard"
import { useAddToHomescreenModal } from "../../../components/modals/AddToHomescreenModal"
import { useIOSModal } from "../../../components/modals/IOSModal"
import { TrainingStep } from "../../../constants"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"
import { useRouter } from "../../../util/router"
import { useCoachingStore } from "../useCoachingStore"

const TrainingLandingPage = () => {
  const router = useRouter()
  const { setStep } = useCoachingStore()
  const { onOpen: openIOSModal } = useIOSModal()
  const { onOpen: openAddToHomescreenModal } = useAddToHomescreenModal()

  const isOnMobileChrome = isChrome && isMobile && isAndroid

  const onContinueClick = () => {
    trackAnalyticEvent(ANALYTIC_EVENTS.VIRTUAL_COACH_CONTINUE_CLICK, {
      step: TrainingStep.LANDING - TrainingStep.LANDING,
    })
    setStep(TrainingStep.PROGRAM_DETAILS)
  }

  const onSkipClick = () => {
    trackAnalyticEvent(ANALYTIC_EVENTS.VIRTUAL_COACH_SKIP_CLICK, {
      step: TrainingStep.LANDING - TrainingStep.LANDING,
    })
    router.push("/me")
    if (isOnMobileChrome) openAddToHomescreenModal()
    else openIOSModal()
  }

  return (
    <>
      <FlexBox
        justify="flex-start"
        align="center"
        direction="column"
        fullWidth
        gap={SPACING.space2}
      >
        <Typography variant="bodySmall" color={JoonUIColor.text.secondary}>
          Based on your responses...
        </Typography>

        <Typography variant="h2" color={JoonUIColor.text.primary}>
          You may benefit from Virtual Parent Training
        </Typography>
      </FlexBox>

      <FlexBox
        direction="column"
        align="center"
        justify="center"
        gap={SPACING.space3}
        style={{ width: "82%" }}
      >
        <Typography variant="bodyBold" color={JoonUIColor.text.primary}>
          Get personalized strategies <br />
          and 1-on-1 support to:
        </Typography>
        <div>
          <ListCard
            icon={InfoSolidIcon}
            text="Manage challenging child behaviors"
            iconColor={JoonColorExpanded.yellow400}
            backgroundColor={JoonColorExpanded.yellow100}
            gap={SPACING.space2}
          />
          <ListCard
            icon={HeartIcon}
            text="Learn stress management techniques"
            iconColor={JoonColorExpanded.yellow400}
            backgroundColor={JoonColorExpanded.yellow100}
            gap={SPACING.space2}
          />
          <ListCard
            icon={GearIcon}
            text="Create systems to improve behavior"
            iconColor={JoonColorExpanded.yellow400}
            backgroundColor={JoonColorExpanded.yellow100}
            gap={SPACING.space2}
          />
        </div>
      </FlexBox>

      <FlexBox
        direction="column"
        align="center"
        justify="center"
        gap={SPACING.space3}
        style={{ width: "90%" }}
      >
        <Typography variant="bodyBold" color={JoonUIColor.text.primary}>
          Our program is designed <br />
          for parents like you:
        </Typography>
        <div>
          <ListCard
            icon={CalendarCheckIcon}
            text="Regular & flexible virtual appointments, covered by insurance"
            iconColor={JoonUIColor.background.accent}
            backgroundColor={JoonUIColor.background.lightAccent}
            gap={SPACING.space3}
          />
          <ListCard
            icon={ChatIcon}
            text="Unlimited messaging with your clinician"
            iconColor={JoonUIColor.background.accent}
            backgroundColor={JoonUIColor.background.lightAccent}
            gap={SPACING.space3}
          />
        </div>
      </FlexBox>

      <FlexBox
        direction="column"
        align="center"
        gap={SPACING.space3}
        style={{ width: "90%", marginBottom: "70px" }}
      >
        <Button
          fullWidth
          onClick={onContinueClick}
          text="Learn About Virtual Parent Training"
        />
        <TextButton onClick={onSkipClick}>
          <Typography variant="bodyBold" color={JoonUIColor.text.primaryAccent}>
            No Thanks, Continue to Joon App
          </Typography>
        </TextButton>
      </FlexBox>
    </>
  )
}

export default TrainingLandingPage
