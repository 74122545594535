import { Button, TextInput, Typography } from "@joonapp/web-shared"
import { useEffect, useState } from "react"

import useOnboardContext from "../../hooks/useOnboardContext"
import { validateAuthCode } from "../../networking/user"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { localStorageItems } from "../../util/storage"
import BackButton from "../buttons/BackButton"

function OnboardCoparentCodeSection() {
  const { coparentCode, setCoparentCode, nextStep, previousStep } =
    useOnboardContext()
  const codeLength = 8
  const [isLoading, setIsLoading] = useState(false)
  const [codeIsValid, setCodeIsValid] = useState(false)
  const [errorText, setErrorText] = useState("")

  useEffect(() => {
    if (coparentCode.length === codeLength) {
      setIsLoading(true)
      validateAuthCode(coparentCode.toUpperCase())
        .then(() => {
          setCodeIsValid(true)
          setErrorText("")
        })
        .catch(() => {
          setErrorText("Invalid code")
        })
        .finally(() => setIsLoading(false))
    } else {
      setCodeIsValid(false)
      setErrorText("")
    }
  }, [coparentCode])

  const clickNext = () => {
    if (coparentCode.length === codeLength) {
      nextStep()
      trackAnalyticEvent(ANALYTIC_EVENTS.ENTER_COPARENT_CODE)
      localStorage.setItem(localStorageItems.isCoparent, "true")
    } else {
      trackAnalyticEvent(ANALYTIC_EVENTS.INVALID_COPARENT_CODE)
      setErrorText("Invalid code")
    }
  }

  return (
    <>
      <BackButton onBack={previousStep} />
      <div className="onboard-section-centered">
        <Typography variant="h3" textAlign="center">
          Enter Code
        </Typography>
        <Typography
          variant="bodyBold"
          textAlign="center"
          style={{ margin: "16px 0" }}
        >
          Enter the code the existing parent generated! (They can find their
          code in the Family tab → Manage Family → Add Parent)
        </Typography>
        <TextInput
          name="Coparent Code"
          value={coparentCode}
          onChange={(e) => setCoparentCode(e.target.value)}
          maxLength={8}
          placeholder="ABCD1234"
          fullWidth
        />
        {errorText && <div className="error-text mt-6">{errorText}</div>}

        <Button
          style={{ marginTop: "24px" }}
          onClick={clickNext}
          isLoading={isLoading}
          disabled={!codeIsValid}
          fullWidth
          text="Next"
        />
      </div>
    </>
  )
}

export default OnboardCoparentCodeSection
