import {
  BellIcon,
  CalendarDaysIcon,
  ChatIcon,
  JoonColorExpanded,
  JoonUIColor,
  ReceiptIcon,
  SPACING,
  Typography,
  VideoIcon,
  WandMagicSparklesIcon,
} from "@joonapp/web-shared"
import { FlexBox } from "@joonapp/web-shared"

import TextArrowButton from "../../../../components/coaching/TextArrowButton"
import { CoachingStep, COACH_PLANS } from "../../../../constants"
import { ANALYTIC_EVENTS } from "../../../../util/analytics"
import { trackAnalyticEvent } from "../../../../util/analytics"
import { useRouter } from "../../../../util/router"
import { useCoachingStore } from "../../useCoachingStore"
type PlanCarouselCardProps = {
  index: number
}

const PlanCarouselCard = ({ index }: PlanCarouselCardProps) => {
  const { setStep } = useCoachingStore()
  const router = useRouter()
  const { plan, fullPrice, price, frequency } = COACH_PLANS[index]

  const infoCards = [
    {
      Icon: CalendarDaysIcon,
      text: (fullPrice: number) =>
        `$${fullPrice}/month\nafter your\nfirst month`,
    },
    {
      Icon: ReceiptIcon,
      text: () => "Love it or get\na full refund\nwithin 30 days",
    },
    {
      Icon: BellIcon,
      text: () => "We'll email you 5\ndays before your\nfirst billing date",
    },
  ]

  const onContinueClick = () => {
    if (router.query.from === "academy") {
      trackAnalyticEvent(ANALYTIC_EVENTS.MARKETING_PLAN_SELECT, {
        plan_index: index,
      })
      return
    }
    trackAnalyticEvent(ANALYTIC_EVENTS.PERSONALIZED_COACH_PLAN_SELECT, {
      plan_index: index,
    })
  }

  return (
    <FlexBox
      direction="column"
      justify="center"
      align="center"
      gap={SPACING.space0}
      fullWidth
      style={{
        background: "#F9FAFC",
        border: `2px solid ${JoonUIColor.text.primaryAccent}`,
        borderRadius: "16px",
        marginBottom: SPACING.space4,
        boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.10)",
      }}
    >
      <FlexBox
        justify="center"
        align="center"
        gap={SPACING.space1}
        style={{
          padding: SPACING.space4,
        }}
      >
        <FlexBox justify="space-between" align="center" fullWidth>
          <FlexBox justify="center" align="center" gap={SPACING.space1}>
            <Typography variant="h2" color={JoonUIColor.text.primary}>
              JoonCoach
            </Typography>
            <Typography
              variant="h2"
              color={JoonUIColor.text.primaryAccent}
              style={{ fontFamily: "Caveat" }}
            >
              {plan}
            </Typography>
          </FlexBox>
        </FlexBox>

        <FlexBox justify="center" align="center" gap={SPACING.space2}>
          <Typography
            variant="h2"
            color={JoonUIColor.background.disabled}
            style={{ textDecoration: "line-through" }}
          >
            ${fullPrice}
          </Typography>
          <Typography variant="h2" color={JoonUIColor.text.primary}>
            ${price}
          </Typography>
        </FlexBox>

        <Typography variant="bodyBold" color={JoonUIColor.text.secondary}>
          First month. Cancel any time.
        </Typography>
      </FlexBox>

      <FlexBox
        direction="row"
        justify="space-between"
        align="center"
        gap={SPACING.space0}
        wrap={false}
        style={{
          padding: `${SPACING.space4} ${SPACING.space2}`,
          background: JoonUIColor.background.primaryNeutral,
          borderTop: `1px solid ${JoonUIColor.icon.light}`,
          borderBottom: `1px solid ${JoonUIColor.icon.light}`,
        }}
      >
        {infoCards.map(({ Icon, text }) => (
          <FlexBox
            key={Icon.name}
            direction="column"
            justify="center"
            align="center"
            gap={SPACING.space2}
          >
            <Icon size={24} color={JoonUIColor.icon.neutral} />
            <Typography
              variant="bodyXSmall"
              color={JoonUIColor.text.primary}
              style={{ lineHeight: "1.3", whiteSpace: "pre-line" }}
            >
              {text(fullPrice)}
            </Typography>
          </FlexBox>
        ))}
      </FlexBox>

      <FlexBox
        justify="center"
        align="center"
        gap={SPACING.space6}
        style={{
          padding: SPACING.space2,
          paddingBottom: SPACING.space4,
        }}
      >
        <FlexBox direction="column" align="flex-start" gap={SPACING.space0}>
          <FlexBox
            direction="row"
            justify="flex-start"
            align="center"
            gap={SPACING.space1}
            wrap={false}
            style={{ padding: SPACING.space2 }}
          >
            <div style={{ minWidth: "12px", maxWidth: "12px" }}>
              <WandMagicSparklesIcon
                size={12}
                color={JoonUIColor.icon.neutral}
              />
            </div>
            <Typography
              variant="bodyXSmall"
              color={JoonUIColor.text.primary}
              style={{ fontWeight: "700", textAlign: "left" }}
            >
              What you'll get:
            </Typography>
          </FlexBox>

          <FlexBox
            direction="row"
            justify="flex-start"
            align="center"
            gap={SPACING.space1}
            wrap={false}
            style={{ padding: SPACING.space2 }}
          >
            <div style={{ minWidth: "12px", maxWidth: "12px" }}>
              <ChatIcon size={12} color={JoonUIColor.icon.accent} />
            </div>
            <Typography
              variant="bodyXSmall"
              color={JoonUIColor.text.primary}
              style={{ fontWeight: "700", textAlign: "left" }}
            >
              Anytime direct messaging with your coach
            </Typography>
          </FlexBox>

          <FlexBox
            direction="row"
            justify="flex-start"
            align="center"
            gap={SPACING.space1}
            wrap={false}
            style={{
              backgroundColor: JoonColorExpanded.yellow200,
              padding: SPACING.space2,
              paddingRight: SPACING.space0,
              borderRadius: SPACING.space2,
            }}
          >
            <div style={{ minWidth: "12px", maxWidth: "12px" }}>
              <VideoIcon size={12} color={JoonUIColor.icon.accent} />
            </div>
            <Typography
              variant="bodyXSmall"
              color={JoonUIColor.text.primary}
              style={{ fontWeight: "700", textAlign: "left" }}
            >
              {frequency}
            </Typography>
          </FlexBox>
        </FlexBox>

        <div
          style={{ width: "100%" }}
          onPointerDown={(e) => {
            e.currentTarget.dataset.pressed = "true"
            e.currentTarget.dataset.pressX = e.clientX.toString()
            e.currentTarget.dataset.pressY = e.clientY.toString()
          }}
          onPointerUp={(e) => {
            if (e.currentTarget.dataset.pressed === "true") {
              const startX = Number(e.currentTarget.dataset.pressX || 0)
              const startY = Number(e.currentTarget.dataset.pressY || 0)
              const deltaX = Math.abs(e.clientX - startX)
              const deltaY = Math.abs(e.clientY - startY)
              if (deltaX < 10 && deltaY < 10) {
                setStep(CoachingStep.CALENDLY)
              }
              onContinueClick()
            }
            e.currentTarget.dataset.pressed = "false"
          }}
          onPointerLeave={(e) => {
            e.currentTarget.dataset.pressed = "false"
          }}
        >
          <TextArrowButton
            text="Continue with this plan"
            onClick={() => {}}
            style={{
              position: "relative",
              width: "100%",
              cursor: "pointer",
            }}
          />
        </div>
      </FlexBox>
    </FlexBox>
  )
}

export default PlanCarouselCard
