import { JoonColorExpanded, SPACING, Typography } from "@joonapp/web-shared"
import { ReactNode } from "react"

const PageHeaderLeft = ({ children }: { children: string | ReactNode }) => {
  return (
    <Typography
      variant="h1"
      style={{ marginTop: SPACING.space6, width: "min(600px, 100%)" }}
      textAlign="left"
      color={JoonColorExpanded.indigo700}
    >
      {children}
    </Typography>
  )
}

export default PageHeaderLeft
