import { Button, Modal } from "@joonapp/web-shared"
import { create } from "zustand"

import doterGroup from "../../images/doter-group.svg"
import { sessionManager } from "../../util/storage"

interface FreeTrialFirstVerifyModalStore {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

export const useFreeTrialFirstVerifyModalStore =
  create<FreeTrialFirstVerifyModalStore>((set) => ({
    isOpen: false,
    onOpen: () => set({ isOpen: true }),
    onClose: () => {
      sessionManager.clearNeedsToSeeVerifyFreemiumPopup()
      set({ isOpen: false })
    },
  }))

const FreeTrialFirstVerifyModal = () => {
  const { isOpen, onClose } = useFreeTrialFirstVerifyModalStore()

  return (
    <Modal isOpen={isOpen} onClose={onClose} displayCloseIcon animate>
      <div
        style={{
          width: "min(400px, 95vw)",
          display: "flex",
          flexDirection: "column",
          padding: "30px",
        }}
      >
        <img
          src={doterGroup}
          alt=""
          style={{ width: "100%", height: "120px" }}
        />
        <div className="mt-12 mb-24" style={{ textAlign: "center" }}>
          <div className=" mb-12" style={{ fontSize: "26px" }}>
            Try Joon!
          </div>
          <div style={{ fontSize: "18px" }}>
            You can verify up to 7 Quests a day without a subscription!
          </div>
        </div>
        <Button text="Got it!" onClick={onClose} />
      </div>
    </Modal>
  )
}

export default FreeTrialFirstVerifyModal
