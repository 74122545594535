import {
  CalendarCheckIcon,
  CheckIcon,
  ChevronRightIcon,
  FlexBox,
  HandshakeAngleIcon,
  JoonColorExpanded,
  JoonUIColor,
  LocationArrowIcon,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useEffect } from "react"

import CoachPending from "./CoachPending"
import ReadyButton from "./ReadyButton"
import PageWrapper from "../../../components/pageWrapper/PageWrapper"
import { CoachingStep, TrainingStep } from "../../../constants"
import useMobile from "../../../hooks/useMobile"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"
import {
  cohorts,
  experimentNames,
  useGetExperimentCohort,
} from "../../../util/experiments"
import { useRouter } from "../../../util/router"
import { localStorageItems } from "../../../util/storage"
import useCareTeam from "../../care/useCareTeam"
import { useCoachingStore } from "../useCoachingStore"

const CareMarketingPage = () => {
  const { setStep } = useCoachingStore()
  const { careTeam, isLoading, hasCoach } = useCareTeam()
  const coachingVariant = useGetExperimentCohort(
    experimentNames.webParentVirtualCoachOnboarding
  )
  const isExperiment = !careTeam?.length && coachingVariant !== cohorts.control
  const isCoaching =
    coachingVariant === cohorts.variantA ||
    coachingVariant === cohorts.variantD ||
    !isExperiment
  const isMobile = useMobile()
  const router = useRouter()
  const showCoachPending =
    localStorage.getItem(localStorageItems.showCoachPending) === "true"

  const onReadyButtonClick = () => {
    trackAnalyticEvent(ANALYTIC_EVENTS.MARKETING_BUTTON_CLICK)
    isCoaching
      ? setStep(CoachingStep.RECOMMENDED_COACHES)
      : setStep(TrainingStep.CALENDLY)
    router.push("/coaching?from=academy")
  }

  const onAlreadySignedUpClick = () => {
    trackAnalyticEvent(ANALYTIC_EVENTS.MARKETING_ALREADY_SIGNED_UP_CLICK)
    localStorage.setItem(localStorageItems.showCoachPending, "true")
    router.push("/coach")
  }

  useEffect(() => {
    if (router.pathname === "/coach") {
      trackAnalyticEvent(
        showCoachPending
          ? ANALYTIC_EVENTS.MARKETING_COACH_PENDING_VIEWED
          : ANALYTIC_EVENTS.MARKETING_TAB_VIEWED
      )
    }
  }, [router.pathname, showCoachPending])

  const mainHeaderText = () => {
    if (!isExperiment) return "Get a coach who gets you"
    switch (coachingVariant) {
      case cohorts.variantA:
        return "Get a coach who gets you"
      case cohorts.variantB:
        return "Get a therapist who gets your family"
      case cohorts.variantC:
        return "Get a therapist, covered by insurance"
      case cohorts.variantD:
        return "Get a coach who gets you"
      default:
        return ""
    }
  }

  const howThisWorksText = () => {
    if (!isExperiment) return "Get matched with the best coach for your family"
    switch (coachingVariant) {
      case cohorts.variantA:
        return "Get matched with the best coach for your family"
      case cohorts.variantB:
        return "Get matched with the best behavioral therapist for your family"
      case cohorts.variantC:
        return "Get matched with the best behavioral therapist for your family, covered by your insurance"
      case cohorts.variantD:
        return "Get matched with the best coach for your family"
      default:
        return ""
    }
  }

  const weeklySessionsText = () => {
    if (!isExperiment)
      return (
        <Typography
          variant="bodySmall"
          textAlign="left"
          style={{ width: "80%" }}
        >
          Weekly 45 min 1-on-1 sessions with your coach
        </Typography>
      )
    switch (coachingVariant) {
      case cohorts.variantA:
        return (
          <Typography
            variant="bodySmall"
            textAlign="left"
            style={{ width: "80%" }}
          >
            Weekly 45 min 1-on-1 sessions with your coach
          </Typography>
        )
      case cohorts.variantB:
        return (
          <Typography
            variant="bodySmall"
            textAlign="left"
            style={{ width: "80%" }}
          >
            Weekly 1-hour sessions with a therapist
          </Typography>
        )
      case cohorts.variantC:
        return (
          <Typography
            variant="bodySmall"
            textAlign="left"
            style={{ width: "80%" }}
          >
            Weekly 1-hour sessions with a therapist,{" "}
            <Typography variant="bodySmall" style={{ fontWeight: "800" }}>
              covered by insurance
            </Typography>
          </Typography>
        )
      case cohorts.variantD:
        return (
          <Typography
            variant="bodySmall"
            textAlign="left"
            style={{ width: "80%" }}
          >
            Weekly 45 min 1-on-1 sessions with your coach
          </Typography>
        )
      default:
        return ""
    }
  }

  useEffect(() => {
    if (hasCoach) {
      router.push(`/coach/${careTeam[0].user.id}`)
    }
  }, [careTeam, hasCoach, router])

  if (coachingVariant === null || isLoading || hasCoach) return <></>

  if (showCoachPending) return <CoachPending />

  return (
    <PageWrapper
      style={{
        background: JoonColorExpanded.yellow100,
        paddingBottom: "0px",
      }}
    >
      <FlexBox
        direction="column"
        align="center"
        wrap={false}
        style={{
          position: "relative",
          padding: SPACING.space0,
          paddingTop: SPACING.space9,
          width: "min(600px, 100%)",
        }}
        gap={SPACING.space9}
      >
        <FlexBox direction="column" align="center" gap={SPACING.space4}>
          <Typography variant="bodySmall" textAlign="center">
            Finally...
          </Typography>
          <Typography variant="h1" textAlign="center" style={{ width: "75%" }}>
            {mainHeaderText()}
          </Typography>
          <Typography
            variant="body"
            textAlign="center"
            style={{ width: "min(400px, 85%)" }}
          >
            {`Connect 1:1 with a ${isCoaching ? "coach" : "therapist"}, right here in the app and `}
            <Typography variant="bodyBold">
              get support like never before
            </Typography>
          </Typography>
          <img
            src={`/images/guidePhotos/${isCoaching ? "coach" : "care"}_main_headline.svg`}
            style={{ width: "min(380px, 80%)" }}
            alt="<3"
          />
        </FlexBox>

        <img src="/images/squiggle.svg" style={{ width: "70%" }} alt="~~~" />

        <Typography variant="h2" textAlign="center" style={{ width: "75%" }}>
          {`Why you should consider 
          ${isCoaching ? "a coach" : "behavioral therapy for you and your child"}`}
        </Typography>
        <FlexBox
          direction="column"
          align="center"
          gap={SPACING.space12}
          style={{ marginBottom: SPACING.space4 }}
        >
          <FlexBox direction="column" align="center">
            <Typography
              variant="bodyBold"
              textAlign="center"
              style={{ width: "min(400px, 85%)" }}
            >
              Get practical strategies for managing challenging behaviors
            </Typography>
            <img
              src={`/images/guidePhotos/${isCoaching ? "coach" : "care"}_practical_strategy.svg`}
              style={{
                width: "min(400px, 80%)",
                paddingBottom: SPACING.space3,
              }}
              alt="Practical strategies"
            />
            <Typography
              variant="bodySmall"
              textAlign="center"
              style={{ width: "min(400px, 85%)" }}
            >
              {isCoaching
                ? "Message your coach anytime and retain continuity between daily challenges and active sessions."
                : "Stay connected with your family's therapist between sessions. Share daily challenges and get ongoing personalized support when you need it most."}
            </Typography>
          </FlexBox>
          <FlexBox direction="column" align="center">
            <Typography variant="bodyBold" textAlign="center">
              Improve your relationship with your child
            </Typography>
            <img
              src={`/images/guidePhotos/parent_task.svg`}
              style={{ width: "min(400px, 80%)", paddingTop: SPACING.space3 }}
              alt="Practical strategies"
            />
            <Typography
              variant="bodySmall"
              textAlign="center"
              style={{ width: "min(400px, 85%)" }}
            >
              {`Receive guided practice ${isCoaching ? "tasks" : "activities"} to continue improving your relationship with your child`}
            </Typography>
          </FlexBox>
          <FlexBox direction="column" align="center">
            <Typography
              variant="bodyBold"
              textAlign="center"
              style={{ width: "min(400px, 85%)" }}
            >
              {`Virtually meet your ${isCoaching ? "coach" : "family's therapist"}, anytime, anywhere`}
            </Typography>
            <img
              src={`/images/guidePhotos/zoom.svg`}
              style={{
                width: "min(400px, 80%)",
                paddingTop: SPACING.space3,
                paddingBottom: SPACING.space2,
              }}
              alt="Meet Your Coach"
            />
            <Typography
              variant="bodySmall"
              textAlign="center"
              style={{ width: "min(400px, 70%)" }}
            >
              All you need is a little space and an open mind to get started
            </Typography>
          </FlexBox>
          <FlexBox direction="column" align="center">
            <Typography variant="bodyBold" textAlign="center">
              On demand curated learning
            </Typography>
            <img
              src={`/images/guidePhotos/learning_modules.svg`}
              style={{
                width: "min(400px, 80%)",
              }}
              alt="Learning Modules"
            />
            <Typography
              variant="bodySmall"
              textAlign="center"
              style={{ width: "min(400px, 70%)" }}
            >
              {`Continue to learn while on the go with bite-sized modules specifically curated by ${isCoaching ? "your coach" : "a therapist"}`}
            </Typography>
          </FlexBox>
        </FlexBox>

        <FlexBox
          direction="column"
          align="center"
          wrap={false}
          style={{ width: "min(500px, 100%)" }}
        >
          <FlexBox
            direction="column"
            align="center"
            gap={SPACING.space10}
            style={{
              background: JoonColorExpanded.purple600,
              padding: SPACING.space4,
              paddingBottom: SPACING.space12,
              borderTopLeftRadius: SPACING.space4,
              borderTopRightRadius: SPACING.space4,
            }}
          >
            <Typography
              variant="h2"
              textAlign="center"
              color={JoonUIColor.text.inverted}
              style={{ marginTop: SPACING.space4 }}
            >
              How this works
            </Typography>
            <FlexBox direction="column" align="center" gap={SPACING.space2}>
              <HandshakeAngleIcon
                size={48}
                color={JoonColorExpanded.yellow400}
              />
              <Typography
                variant="bodyBold"
                textAlign="center"
                color={JoonUIColor.text.inverted}
                style={{ width: "min(400px, 70%)" }}
              >
                {howThisWorksText()}
              </Typography>
              <Typography
                variant="bodySmall"
                textAlign="center"
                color={JoonUIColor.text.inverted}
                style={{ width: "min(400px, 70%)" }}
              >
                {`Our Care Navigator will provide you with a curated list of best ${isCoaching ? "coaches" : "therapists"} that matches your family's needs`}
              </Typography>
            </FlexBox>
            <FlexBox direction="column" align="center" gap={SPACING.space2}>
              <CalendarCheckIcon
                size={48}
                color={JoonColorExpanded.yellow400}
              />
              <Typography
                variant="bodyBold"
                textAlign="center"
                color={JoonUIColor.text.inverted}
              >
                Always on your own time
              </Typography>
              <Typography
                variant="bodySmall"
                textAlign="center"
                color={JoonUIColor.text.inverted}
              >
                Schedule times that work for you
              </Typography>
            </FlexBox>
            <FlexBox direction="column" align="center" gap={SPACING.space2}>
              <LocationArrowIcon
                size={48}
                color={JoonColorExpanded.yellow400}
              />
              <Typography
                variant="bodyBold"
                textAlign="center"
                color={JoonUIColor.text.inverted}
                style={{ width: "min(400px, 70%)" }}
              >
                Let's set your goals
              </Typography>
              <Typography
                variant="bodySmall"
                textAlign="center"
                color={JoonUIColor.text.inverted}
                style={{ width: "min(400px, 70%)" }}
              >
                Establish parenting goals, set attainable targets, and make
                meaningful progress towards your parenting goals
              </Typography>
            </FlexBox>
          </FlexBox>

          <FlexBox
            direction="column"
            align="center"
            gap={SPACING.space6}
            wrap={false}
            style={{
              height: isMobile ? "590px" : "480px",
              background: JoonColorExpanded.yellow400,
              padding: `${SPACING.space12} ${SPACING.space6}`,
            }}
          >
            <Typography
              variant="h2"
              textAlign="center"
              color={JoonUIColor.text.primary}
              style={{ width: "min(350px, 90%)" }}
            >
              {`What you'll get with your ${isCoaching ? "coaching" : "therapy"} plan`}
            </Typography>

            <FlexBox
              direction="column"
              justify="left"
              gap={SPACING.space4}
              wrap={false}
              style={{
                width: "auto",
                borderRadius: SPACING.space4,
                padding: `${SPACING.space6} ${SPACING.space4}`,
                background: JoonColorExpanded.yellow100,
              }}
            >
              <FlexBox
                direction="row"
                gap={SPACING.space2}
                align="center"
                wrap={false}
              >
                <CheckIcon size={28} color={JoonColorExpanded.viridian400} />
                {weeklySessionsText()}
              </FlexBox>
              <FlexBox
                direction="row"
                gap={SPACING.space2}
                align="center"
                wrap={false}
              >
                <CheckIcon size={28} color={JoonColorExpanded.viridian400} />
                <Typography variant="bodySmall" textAlign="left">
                  Access to personalized resources and strategies for your
                  family
                </Typography>
              </FlexBox>
              <FlexBox
                direction="row"
                gap={SPACING.space2}
                align="center"
                wrap={false}
              >
                <CheckIcon size={28} color={JoonColorExpanded.viridian400} />
                <Typography
                  variant="bodySmall"
                  textAlign="left"
                  style={{ width: "80%" }}
                >
                  Unlimited messaging
                </Typography>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </FlexBox>
        <ReadyButton
          onClick={onReadyButtonClick}
          style={{
            bottom: isMobile ? "136px" : "80px",
          }}
        />
        {isCoaching && (
          <button
            style={{
              position: "fixed",
              bottom: isMobile ? "76px" : "20px",
              width: "min(480px, 96%)",
              padding: 0,
            }}
            onClick={onAlreadySignedUpClick}
          >
            <FlexBox
              direction="row"
              align="center"
              wrap={false}
              style={{
                background: JoonUIColor.background.primaryNeutral,
                borderRadius: SPACING.space2,
                padding: `${SPACING.space3} ${SPACING.space2} ${SPACING.space3} ${SPACING.space4}`,
                height: "54px",
                boxShadow: "4px 4px 6px rgba(0, 0, 0, 0.1)",
                overflow: "hidden",
              }}
            >
              <Typography
                variant="bodyBold"
                color={JoonUIColor.text.primaryAccent}
                textAlign="left"
                style={{ lineHeight: "1.4" }}
              >
                I already signed up
              </Typography>
              <div style={{ minWidth: "36px" }}>
                <ChevronRightIcon color={JoonUIColor.icon.accent} size={28} />
              </div>
            </FlexBox>
          </button>
        )}
      </FlexBox>
    </PageWrapper>
  )
}

export default CareMarketingPage
