import { create } from "zustand"

type DataTaskCompletionModalStore = {
  isOpen: boolean
  dataTaskId: number | null

  duration: string | null // format hh:mm:ss
  reflection: string | null
  numSuccesses: string | null
  numTotal: string | null
  frequency: string | null
  view: DataTaskCompletionView

  onClose: () => void
  onOpen: (dataTaskId?: number) => void
  setView: (view: DataTaskCompletionView) => void
  resetDataTask: () => void

  setDuration: (duration: string | null) => void
  setReflection: (reflection: string | null) => void
  setNumSuccesses: (numSuccesses: string | null) => void
  setNumTotal: (numTotal: string | null) => void
  setFrequency: (frequency: string | null) => void
}

export enum DataTaskCompletionView {
  COMPLETION,
  SUCCESS,
}

const initialState = {
  isOpen: false,
  view: DataTaskCompletionView.COMPLETION,
  dataTaskId: null,
  duration: null,
  reflection: "",
  numSuccesses: null,
  numTotal: null,
  frequency: null,
}

const useDataTaskCompletionModalStore = create<DataTaskCompletionModalStore>(
  (set) => ({
    ...initialState,
    onClose: () => set(initialState),
    onOpen: (dataTaskId) => set({ isOpen: true, dataTaskId }),
    setView: (view) => set({ view }),
    resetDataTask: () => set(initialState),

    setDuration: (duration) => set({ duration }),
    setReflection: (reflection) => set({ reflection }),
    setNumSuccesses: (numSuccesses) => {
      if (numSuccesses === "") set({ numSuccesses: null })
      if (Number.isNaN(Number(numSuccesses))) return
      else set({ numSuccesses })
    },
    setNumTotal: (numTotal) => {
      if (numTotal === "") set({ numTotal: null })
      if (Number.isNaN(Number(numTotal))) return
      else set({ numTotal })
    },
    setFrequency: (frequency) => {
      if (frequency === "") set({ frequency: null })
      if (Number.isNaN(Number(frequency))) return
      else set({ frequency })
    },
  })
)

export default useDataTaskCompletionModalStore
