import { FlexBox, Modal, SPACING } from "@joonapp/web-shared"

import { useChildQuestionnaireModalStore } from "./useChildQuestionnaireModalStore"
import { submitQuestionnaire } from "../../../networking/authentication"
import { useRecommendedQuestionnairesQuery } from "../../../networking/queries"
import { QuestionAnswerMappingInterface } from "../../../types"
import QuestionnaireSection from "../../onboarding/questionnaires/QuestionnaireSection"

const ChildQuestionnaireModal = () => {
  const { isOpen, onClose, childId, onSubmit } =
    useChildQuestionnaireModalStore()
  const { data: childQuestionnaires } = useRecommendedQuestionnairesQuery()

  const onConfirm = async ({
    questionAnswerMapping,
  }: {
    questionAnswerMapping: QuestionAnswerMappingInterface
  }) => {
    if (!childId || !childQuestionnaires) return

    const questionnairePromises = childQuestionnaires?.questionIdMap?.map(
      (questionnaire: any) => {
        const answers = questionnaire.questions.map((question_id: any) => ({
          question_id,
          answer_ids: questionAnswerMapping[question_id],
        }))
        return submitQuestionnaire(questionnaire.id, childId, answers)
      }
    )
    await Promise.all(questionnairePromises)
    onClose()
    onSubmit()
  }

  if (!childQuestionnaires) return null

  return (
    <Modal isOpen={isOpen} onClose={onClose} animate>
      <FlexBox style={{ padding: SPACING.space6 }} justify="center">
        <QuestionnaireSection
          questions={childQuestionnaires?.allQuestions}
          onConfirm={onConfirm}
          previousStep={onClose}
          fixedProgressBar={false}
        />
      </FlexBox>
    </Modal>
  )
}

export default ChildQuestionnaireModal
