import { Button } from "@joonapp/web-shared"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { QUERY_KEYS } from "../../constants"
import { redeemCustomReward } from "../../networking/user"
import { RewardPurchase } from "../../types"

type Props = {
  purchase: RewardPurchase
}

const RewardRedeemButton = ({ purchase }: Props) => {
  const queryClient = useQueryClient()

  const redeemRewardMutation = useMutation({
    mutationFn: redeemCustomReward,
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.PURCHASED_REWARDS])
    },
  })
  return (
    <Button
      buttonType="secondary"
      size="small"
      onClick={() => redeemRewardMutation.mutate(purchase.id)}
      isLoading={redeemRewardMutation.isLoading}
      text="Redeem"
    />
  )
}

export default RewardRedeemButton
