import { FlexBox, JoonUIColor, SPACING, Typography } from "@joonapp/web-shared"

import { useAddQuestTemplatesModalStore } from "./useAddQuestTemplatesModalStore"
import DifficultyBadge from "../../../components/badges/difficultyBadge/DifficultyBadge"
import {
  difficultyRewards,
  QuestRepeatFrequency,
  REPETITIONS,
} from "../../../constants"
import { QuestTemplate } from "../../../types/quests"
import {
  getRepetitionArrayFromRrule,
  getReptitionIntervalFromRrule,
} from "../../../util/util"
import { useAddEditQuestStore } from "../addEditQuestModal/useAddEditQuestStore"

export const QuestTemplateItem = ({
  questTemplate,
}: {
  questTemplate: QuestTemplate
}) => {
  const {
    setQuestName,
    setQuestDifficulty,
    setCoinReward,
    setDescription,
    setReqReview,
    setReqNotes,
    setReqPhoto,
    setRepetition,
    setRepeatsFrequency,
    setCategory,
    setTemplateId,
    setCustomTemplateId,
    setQuestRepeatSelection,
    setTimerLength,
    setReminderTime,
    setMandatory,
    toggleRoutine,
  } = useAddEditQuestStore()
  const { onClose: onCloseAddTemplateModal } = useAddQuestTemplatesModalStore()
  const { onOpen: onOpenAddEditQuestModal } = useAddEditQuestStore()

  const setTemplate = () => {
    onOpenAddEditQuestModal()
    onCloseAddTemplateModal()
    setQuestName(questTemplate.title)
    toggleRoutine(questTemplate.routine)
    setQuestDifficulty(questTemplate.difficulty)
    setReqReview(questTemplate.requires_review)
    setCategory(questTemplate.skill)

    switch (questTemplate.type) {
      case "custom":
        setCustomTemplateId(questTemplate.id)
        setCoinReward(questTemplate.redeemable_reward)
        setReqNotes(questTemplate.requires_completion_notes)
        setReqPhoto(questTemplate.requires_completion_photo)
        setReminderTime(questTemplate.reminder_time)
        setTimerLength(questTemplate.timer_length)
        setMandatory(questTemplate.mandatory)

        if (questTemplate.description !== null) {
          setDescription(questTemplate.description!)
        }

        if (questTemplate.occurrence_limit === 1) {
          setRepetition(REPETITIONS.noDays)
          setQuestRepeatSelection(QuestRepeatFrequency.NO_REPEAT)
        } else {
          setRepetition(getRepetitionArrayFromRrule(questTemplate.recurrence))
          setRepeatsFrequency(
            getReptitionIntervalFromRrule(questTemplate.recurrence)
          )
          setQuestRepeatSelection(QuestRepeatFrequency.REPEAT)
        }
        break
      case "suggested":
        setTemplateId(questTemplate.id)
        setCoinReward(difficultyRewards[questTemplate.difficulty])
        if (questTemplate.repeats_daily) {
          setRepetition(REPETITIONS.daily)
          setQuestRepeatSelection(QuestRepeatFrequency.REPEAT)
        } else {
          setRepetition(REPETITIONS.noDays)
          setQuestRepeatSelection(QuestRepeatFrequency.NO_REPEAT)
        }
        break
    }
  }

  return (
    <FlexBox
      direction="row"
      onClick={setTemplate}
      align="flex-start"
      wrap={false}
      gap={SPACING.space2}
      style={{
        background: JoonUIColor.background.lightGray,
        padding: SPACING.space2,
        borderRadius: SPACING.space2,
        cursor: "pointer",
        width: "100%",
      }}
    >
      <FlexBox direction="column">
        <Typography variant="bodyBold">{questTemplate.title}</Typography>
        {questTemplate.type === "suggested" && (
          <Typography variant="bodySmall" color={JoonUIColor.text.secondary}>
            {questTemplate.additional_text}
          </Typography>
        )}
      </FlexBox>
      <DifficultyBadge
        difficulty={questTemplate.difficulty}
        reward={difficultyRewards[questTemplate.difficulty]}
      />
    </FlexBox>
  )
}
