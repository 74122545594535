interface ParentQuestionnaireAnswers {
  [key: number]: number[]
}

interface ProblemValueProp {
  problem: string
  valueProp: string
}

const problemValuePropMapping: { [key: number]: ProblemValueProp } = {
  0: {
    problem: "Lack of child motivation",
    valueProp: "Effective reinforcement strategies",
  },
  1: {
    problem: "Challenging behaviors",
    valueProp: "Positive behavior techniques",
  },
  2: {
    problem: "Executive function struggles",
    valueProp: "EF skill-building tools",
  },
  3: {
    problem: "Parent-child relationship strain",
    valueProp: "Relationship-strengthening strategies",
  },
  4: {
    problem: "High parental stress",
    valueProp: "Stress management techniques",
  },
  5: {
    problem: "Inconsistent routines",
    valueProp: "Structured daily schedules",
  },
  6: {
    problem: "Frequent prompting conflicts",
    valueProp: "De-escalation techniques",
  },
  7: {
    problem: "Impulse control challenges",
    valueProp: "Emotional regulation skills",
  },
  8: {
    problem: "Frequent tantrums or outbursts",
    valueProp: "Preventative strategies",
  },
  9: {
    problem: "Emotional communication difficulties",
    valueProp: "Socio-emotional development skills",
  },
  10: {
    problem: "Lack of independence in routines",
    valueProp: "Strategies for building self-efficacy",
  },
  11: {
    problem: "N/A IGNORE",
    valueProp: "N/A IGNORE",
  },
}

const answerProblemValuePropMapping: { [key: number]: ProblemValueProp } = {
  // What are you primarily looking for help with? (1)
  1: problemValuePropMapping[0],
  3: problemValuePropMapping[1],
  4: problemValuePropMapping[2],
  5: problemValuePropMapping[3],
  6: problemValuePropMapping[4],
  2: problemValuePropMapping[5],
  // How often does prompting your child lead to conflict or outbursts? (436)
  619: problemValuePropMapping[6],
  620: problemValuePropMapping[6],
  // In the last week, how often did your child struggle with controlling impulsive behaviors when angry or frustrated? (401)
  545: problemValuePropMapping[7],
  546: problemValuePropMapping[7],
  // In the last week, how often did your child have temper tantrums or was easily angered? (410)
  581: problemValuePropMapping[8],
  582: problemValuePropMapping[8],
  // In the last week, how often did your child struggle with understanding and communicating feelings? (403)
  553: problemValuePropMapping[9],
  554: problemValuePropMapping[9],
  // How often does your child independently complete their daily routines? (434)
  613: problemValuePropMapping[10],
  614: problemValuePropMapping[10],
  // What else would you like to focus on? (2)
  8: problemValuePropMapping[0],
  10: problemValuePropMapping[1],
  11: problemValuePropMapping[2],
  12: problemValuePropMapping[3],
  13: problemValuePropMapping[4],
  9: problemValuePropMapping[5],
  67: problemValuePropMapping[11],
}

const defaultValueProps: ProblemValueProp[] = [
  problemValuePropMapping[0],
  problemValuePropMapping[1],
  problemValuePropMapping[2],
]

export function getTopValueProps(
  answers: ParentQuestionnaireAnswers
): ProblemValueProp[] {
  const valueProps: ProblemValueProp[] = []
  const seenProblems = new Set<string>()

  // Helper function to add unique value props
  const addUniqueProp = (prop: ProblemValueProp) => {
    if (!seenProblems.has(prop.problem)) {
      valueProps.push(prop)
      seenProblems.add(prop.problem)
    }
  }

  // Prioritize primary goal (Question 1)
  if (1 in answers) {
    addUniqueProp(answerProblemValuePropMapping[answers[1][0]])
  }

  // Prioritize questions 2-6 with "Every day" answers
  const everyDayQuestions = [410, 436, 401, 434, 403]
  const everyDayAnswers: { [key: number]: number } = {
    410: 581,
    436: 619,
    401: 545,
    434: 614, // inverse logic, "Never"
    403: 553,
  }
  for (const questionId of everyDayQuestions) {
    const relevantAnswer = everyDayAnswers[questionId]
    if (questionId in answers && answers[questionId].includes(relevantAnswer)) {
      addUniqueProp(answerProblemValuePropMapping[relevantAnswer])
    }
  }

  // Prioritize secondary goal (Question 2)
  if (2 in answers) {
    const secondaryGoal = answers[2][0]
    if (secondaryGoal !== 67) {
      // Exclude "Something else"
      addUniqueProp(answerProblemValuePropMapping[secondaryGoal])
    }
  }

  // Prioritize questions 2-6 with "Most days" answers
  const mostDaysQuestions = [410, 436, 401, 434, 403]
  const mostDaysAnswers: { [key: number]: number } = {
    410: 582,
    436: 620,
    401: 546,
    434: 613, // inverse logic, "Some days"
    403: 554,
  }
  for (const questionId of mostDaysQuestions) {
    if (questionId in answers) {
      const relevantAnswer = mostDaysAnswers[questionId]
      if (answers[questionId].includes(relevantAnswer)) {
        addUniqueProp(answerProblemValuePropMapping[relevantAnswer])
      }
    }
  }

  // Fill in with default value props if needed
  while (valueProps.length < 3) {
    const defaultProp = defaultValueProps.find(
      (prop) => !seenProblems.has(prop.problem)
    )
    if (defaultProp) {
      addUniqueProp(defaultProp)
    } else {
      break // No more unique default props to add
    }
  }

  return valueProps.slice(0, 3)
}
