import { FlexBox, JoonUIColor, SPACING, Typography } from "@joonapp/web-shared"

import { useSelectGiftModalStore } from "./useSelectGiftModalStore"
import { useSendMessageModalStore } from "./useSendMessageModalStore"
import { InventoryItem } from "../../../types"
import { getShopItemText } from "../../../util/util"

interface GiftItemProps {
  gift: InventoryItem
}

const GiftItem = ({ gift }: GiftItemProps) => {
  const { onClose } = useSelectGiftModalStore()
  const { setSelectedGift } = useSendMessageModalStore()

  const onClickGift = (gift: InventoryItem) => {
    setSelectedGift(gift)
    onClose()
  }

  return (
    <FlexBox
      key={gift.id}
      direction="row"
      align="center"
      justify="flex-start"
      wrap={false}
      gap={SPACING.space2}
      onClick={() => onClickGift(gift)}
      style={{
        backgroundColor: JoonUIColor.background.primaryNeutral,
        padding: SPACING.space2,
        borderRadius: SPACING.space2,
        cursor: "pointer",
      }}
    >
      <img
        src={gift.shop_item.image_url}
        style={{
          width: "15vw",
          maxWidth: "50px",
          height: "auto",
        }}
        alt="Gift"
      />
      <FlexBox
        direction="column"
        gap={SPACING.space0}
        align="flex-start"
        justify="flex-start"
      >
        <Typography variant="caption" style={{ lineHeight: "1.25rem" }}>
          {gift.shop_item.name}
        </Typography>
        <Typography variant="bodySmall" style={{ lineHeight: "1.25rem" }}>
          {gift.shop_item.description + " "}
          {getShopItemText(gift.shop_item)}
        </Typography>
      </FlexBox>
    </FlexBox>
  )
}

export default GiftItem
