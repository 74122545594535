import {
  CheckIcon,
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useEffect, useMemo, useState } from "react"
import { LoaderIcon } from "react-hot-toast"

import { CoachingStep } from "../../../constants"
import { useCoachingStore } from "../useCoachingStore"

function Matching() {
  const [progress, setProgress] = useState(0)
  const { setStep } = useCoachingStore()

  const timerLength = 9000
  const maxPercent = 100
  const numIntervals = 60

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === maxPercent) return maxPercent
        const step = maxPercent / numIntervals
        return Math.min(oldProgress + step, maxPercent)
      })
    }, timerLength / numIntervals)
    const timer = setTimeout(
      () => setStep(CoachingStep.RECOMMENDED_COACHES),
      timerLength
    )

    return () => {
      clearInterval(interval)
      clearTimeout(timer)
    }
  }, [setStep])

  const LoadingCard = useMemo(() => {
    return ({ text, loading }: { text: string; loading: boolean }) => (
      <FlexBox
        direction="row"
        align="center"
        justify="space-between"
        gap={SPACING.space4}
        style={{
          width: "fit-content",
          backgroundColor: JoonColorExpanded.purple600,
          padding: `${SPACING.space2} ${SPACING.space3}`,
          borderRadius: 100,
        }}
      >
        <Typography variant="body" color={JoonUIColor.text.inverted}>
          {text}
        </Typography>
        {loading ? (
          <LoaderIcon />
        ) : (
          <CheckIcon color={JoonUIColor.icon.inverted} />
        )}
      </FlexBox>
    )
  }, [])

  return (
    <FlexBox
      direction="column"
      align="center"
      justify="center"
      fullWidth
      gap={SPACING.space8}
    >
      <Typography variant="h2" color={JoonUIColor.text.primary}>
        Matching you with the best coach for you...
      </Typography>
      <FlexBox
        direction="column"
        align="center"
        justify="center"
        gap={SPACING.space4}
      >
        {[
          { text: "Analyzing Intake Questions", threshold: 25 },
          { text: "Finding available coaches", threshold: 60 },
          { text: "Preparing your options", threshold: 95 },
        ].map(({ text, threshold }, index) => (
          <div
            key={text}
            style={{
              opacity: progress < (index === 0 ? 1 : threshold - 35) ? 0 : 1,
              transition: "opacity 1s",
            }}
          >
            <LoadingCard text={text} loading={progress < threshold} />
          </div>
        ))}
      </FlexBox>
    </FlexBox>
  )
}

export default Matching
