import {
  FlexBox,
  Modal,
  ModalContent,
  ModalHeader,
  SPACING,
} from "@joonapp/web-shared"
import dayjs from "dayjs"
import { create } from "zustand"

import Card from "../../components/card/Card"
import { usePurchasedRewardsQuery } from "../../networking/queries"
import { RewardPurchase } from "../../types"

interface RedeemedRewardsModalStore {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

export const useRedeemedRewardsModal = create<RedeemedRewardsModalStore>(
  (set) => ({
    isOpen: false,
    onOpen: () => set({ isOpen: true }),
    onClose: () => set({ isOpen: false }),
  })
)

const RedeemedRewards = () => {
  const { isOpen, onClose } = useRedeemedRewardsModal()
  const { data: redeemedRewards } = usePurchasedRewardsQuery({
    hasRedeemed: true,
  })

  return (
    <Modal isOpen={isOpen} onClose={onClose} animate>
      <ModalHeader title="Redeemed Rewards" onClose={onClose} />
      <ModalContent
        style={{
          padding: `${SPACING.space0} ${SPACING.space6} ${SPACING.space6} ${SPACING.space6}`,
          width: "min(500px, 95vw)",
        }}
      >
        <FlexBox direction="column" gap={SPACING.space1}>
          {redeemedRewards?.map((purchase: RewardPurchase, i: number) => (
            <Card
              title={purchase.reward.title}
              subtitle={`Redeemed on ${dayjs(purchase.redeem_date).format("MMM D, YYYY")}`}
              coinAmount={purchase.reward.cost}
              key={i}
              childIds={[purchase.user_id]}
            />
          ))}
        </FlexBox>
      </ModalContent>
    </Modal>
  )
}

export default RedeemedRewards
