import { JoonColorExpanded, Typography } from "@joonapp/web-shared"
import React from "react"
import "./pageHeader.scss"

type PageHeaderProps = {
  title: string
  leftComponent?: React.ReactNode
  rightComponent?: React.ReactNode
}

const PageHeader = ({
  title,
  leftComponent,
  rightComponent,
}: PageHeaderProps) => {
  return (
    <div className={`jooniverse__page-header`}>
      {leftComponent && (
        <div className="jooniverse__page-header-left">{leftComponent}</div>
      )}
      <Typography
        variant="h1"
        textAlign="center"
        color={JoonColorExpanded.indigo700}
      >
        {title}
      </Typography>
      {rightComponent && (
        <div className="jooniverse__page-header-right">{rightComponent}</div>
      )}
    </div>
  )
}

export default PageHeader
