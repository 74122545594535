import {
  Button,
  CalloutBox,
  FlexBox,
  JoonUIColor,
  SPACING,
  TextInput,
  Typography,
} from "@joonapp/web-shared"
import { useState } from "react"

import AppleAuth from "./AppleAuth"
import { getAfterAuthPath } from "./ForgotPassword"
import GoogleAuth2 from "./GoogleAuth"
import { signin } from "../../networking/authentication"
import { AlertInterface } from "../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { useRouter } from "../../util/router"
import { createErrorFromSignupResponse } from "../../util/util"

const SignIn = () => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [formAlert, setFormAlert] = useState<AlertInterface | null>(null)
  const router = useRouter()
  const [pending, setPending] = useState(false)

  const handleSignIn = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    try {
      setPending(true)
      await signin(email, password)
      router.push(getAfterAuthPath(router))
    } catch (err) {
      const errorMessage = createErrorFromSignupResponse(err)
      if (errorMessage) setFormAlert({ type: "error", message: errorMessage })
      trackAnalyticEvent(ANALYTIC_EVENTS.SIGNUP_FAILED, { type: "email" })
    } finally {
      setPending(false)
    }
  }

  return (
    <div style={{ width: "min(100%, 520px)", textAlign: "center" }}>
      <div
        className="onboard-section-centered"
        style={{ gap: "30px", minHeight: "75vh" }}
      >
        <img
          src="/images/logos/logo_white.svg"
          alt="Joon"
          style={{ width: "96px" }}
        />
        <FlexBox justify="center" style={{ marginBottom: SPACING.space2 }}>
          <Typography variant="h1">Welcome Back</Typography>
        </FlexBox>

        {formAlert && (
          <div style={{ margin: `${SPACING.space2} 0` }}>
            <CalloutBox type="error" fullWidth>
              <Typography variant="bodySmall">{formAlert.message}</Typography>
            </CalloutBox>
          </div>
        )}

        <form onSubmit={handleSignIn} style={{ width: "100%" }}>
          <FlexBox direction="column" gap={SPACING.space1}>
            <Typography variant="bodyBold">Email</Typography>
            <TextInput
              type="email"
              icon="email"
              name="email"
              placeholder="user@example.com"
              fullWidth={true}
              onChange={(e: any) => setEmail(e.target.value)}
              value={email}
            />
          </FlexBox>
          <FlexBox
            direction="column"
            gap={SPACING.space1}
            style={{ marginTop: SPACING.space2 }}
          >
            <Typography variant="bodyBold">Password</Typography>
            <TextInput
              icon="password"
              type="password"
              placeholder="Password"
              name="pass"
              fullWidth={true}
              onChange={(e: any) => setPassword(e.target.value)}
              value={password}
            />
          </FlexBox>
          <Button
            isLoading={pending}
            disabled={pending}
            fullWidth={true}
            text="Sign In"
            type="submit"
            style={{ marginTop: SPACING.space3 }}
          />
        </form>
        <FlexBox justify="center" direction="row" align="center">
          <hr
            style={{
              backgroundColor: JoonUIColor.border.accent,
              width: "21%",
            }}
          />
          <Typography
            textAlign="center"
            variant="bodyXSmall"
            style={{ width: "8%" }}
          >
            OR
          </Typography>
          <hr
            style={{
              backgroundColor: JoonUIColor.border.accent,
              width: "21%",
            }}
          />
        </FlexBox>
        <FlexBox gap={SPACING.space2} direction="column">
          <GoogleAuth2 type="signin" setFormAlert={setFormAlert} />
          <AppleAuth type="signin" setFormAlert={setFormAlert} />
        </FlexBox>
        <FlexBox direction="column" align="center" gap={SPACING.space4}>
          <button onClick={() => router.push("/auth/forgotpass")}>
            <Typography variant="bodyBold" color={JoonUIColor.semantic.primary}>
              Forgot Password?
            </Typography>
          </button>
          <Typography
            variant="body"
            color={JoonUIColor.text.secondary}
            textAlign="center"
          >
            <span>Don't have an account? </span>
            <button onClick={() => router.push("/welcome")}>
              <Typography
                variant="bodyBold"
                color={JoonUIColor.semantic.primary}
              >
                Create one here
              </Typography>
            </button>
          </Typography>
        </FlexBox>
      </div>
    </div>
  )
}

export default SignIn
