import { loadStripe, Stripe } from "@stripe/stripe-js"

import { ANALYTIC_EVENTS, trackAnalyticEvent } from "./analytics"
import { apiRequestOld, isTestEnv } from "./util"
import { priceIds } from "../constants"
import { SubscriptionTrialKey } from "../types"

let stripe: Stripe | null
// Load the Stripe script
loadStripe(`${import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY}`, {
  // Pin to specific version of the Stripe API
  apiVersion: "2020-08-27",
}).then((stripeInstance) => {
  // Set stripe so all functions below have it in scope
  stripe = stripeInstance
})

interface StripeRedirectProps {
  priceId: string
  trialPriceId?: string
  trialLengthKey?: SubscriptionTrialKey
  campaign?: string
  subscriberId: number | null
  email: string
  clinician_access?: string
}

export const getStripeRedirectFunction = async ({
  subscriberId,
  email,
  trialLengthKey = SubscriptionTrialKey.SEVEN,
  priceId,
  trialPriceId = undefined,
}: StripeRedirectProps) => {
  // TODO: Use revenuecat to figure out what the default price is if it doesn't exist.
  const checkoutPrice = isTestEnv ? priceIds.test : priceId
  const trialPrice =
    isTestEnv && trialPriceId ? priceIds.testDollarTrial : trialPriceId

  // Default to 7 days if one isn't provided
  const priceIdParams = `&priceId=${checkoutPrice}`
  const trialLengthParams = trialLengthKey ? `&tpk=${trialLengthKey}` : ""
  const redirectQueryParams = `${priceIdParams}${trialLengthParams}`

  // The user will not have a trial if the trialLengthKey doesn't exist or if it is `none`
  const hasTrial = trialLengthKey !== "none"

  // Create a checkout session
  const session = await apiRequestOld(
    "stripe-create-checkout-session",
    "POST",
    {
      priceId: `price_${checkoutPrice}`,
      trialLengthKey: trialLengthKey,
      trialPriceId: trialPrice ? `price_${trialPrice}` : undefined,
      successUrl: `${window.location.origin}/me?paid=true&trial=${hasTrial}${redirectQueryParams}`,
      cancelUrl: `${window.location.origin}/me?cancelledPayment=true${redirectQueryParams}`,
      subscriberId: subscriberId,
      email: email,
    }
  )

  const redirectFunction = () => {
    trackAnalyticEvent(ANALYTIC_EVENTS.SHOW_CHECKOUT, {
      priceId: checkoutPrice,
      tpk: trialLengthKey,
    })

    stripe?.redirectToCheckout({
      sessionId: session.id,
    })
  }

  return redirectFunction
}

export async function redirectToCheckoutWithPriceId({
  priceId = priceIds.default,
  trialLengthKey,
  campaign,
  subscriberId,
  email,
  clinician_access,
}: StripeRedirectProps) {
  // TODO: Use revenuecat to figure out what the default price is if it doesn't exist.
  const checkoutPrice = isTestEnv ? priceIds.test : priceId

  // Default to 7 days if one isn't provided
  trackAnalyticEvent(ANALYTIC_EVENTS.SHOW_CHECKOUT, {
    priceId: checkoutPrice,
    tpk: trialLengthKey,
    campaign: campaign,
    clinician_access,
  })

  const priceIdParams = `&priceId=${checkoutPrice}`
  const trialLengthParams = trialLengthKey ? `&tpk=${trialLengthKey}` : ""
  const campaignParams = campaign ? `&campaign=${campaign}` : ""
  const redirectQueryParams = `${priceIdParams}${trialLengthParams}${campaignParams}`

  // The user will not have a trial if the trialLengthKey doesn't exist or if it is `none`
  const hasTrial = trialLengthKey ? trialLengthKey !== "none" : false

  // Create a checkout session
  const session = await apiRequestOld(
    "stripe-create-checkout-session",
    "POST",
    {
      priceId: `price_${checkoutPrice}`,
      trialLengthKey: trialLengthKey,
      successUrl: `${window.location.origin}/me?paid=true&trial=${hasTrial}${redirectQueryParams}`,
      cancelUrl: `${window.location.origin}/me?cancelledPayment=true${redirectQueryParams}`,
      subscriberId: subscriberId,
      email: email,
    }
  )

  // Ensure if user clicks browser back button from checkout they go to /me
  // instead of this page or they'll redirect right back to checkout.
  window.history.replaceState({}, "", "/me")

  return stripe?.redirectToCheckout({
    sessionId: session.id,
  })
}

export async function redirectToBilling(email: string) {
  // Create a billing session
  const session = await apiRequestOld("stripe-create-billing-session", "POST", {
    returnUrl: `${window.location.origin}/`,
    email: email,
  })

  // Ensure if user clicks browser back button from billing they go to /settings/general
  // instead of this page or they'll redirect right back to billing.
  window.history.replaceState({}, "", "/")
  // window.history.replaceState({}, "", "https://joonapp.io");

  // Redirect to billing session url
  window.location.href = session.url
}
