import { Button, Modal } from "@joonapp/web-shared"
import { CSSProperties, useState } from "react"

import { useAddEditRewardModal } from "./AddEditRewardModal"
import { useBonusRewardModalStore } from "./BonusRewardModal"
import RewardTemplates, { useRewardTemplatesModal } from "./RewardTemplates"
import useMobile from "../../hooks/useMobile"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"

export const AddRewardButton = () => {
  const { onOpen: openAddRewardModal } = useAddEditRewardModal()
  const isMobile = useMobile()
  const [showAddTypeModal, setShowAddTypeModal] = useState(false)
  const { onOpen: openRewardTemplatesModal } = useRewardTemplatesModal()
  const { onOpen: openBonusRewardModal } = useBonusRewardModalStore()

  const styles: CSSProperties = isMobile
    ? {
        right: "12px",
        position: "absolute",
        bottom: "82px",
        width: "56px",
        height: "56px",
        fontSize: "36px",
        borderRadius: "16px",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.25)",
      }
    : {
        width: "120px",
        whiteSpace: "nowrap",
      }

  return (
    <>
      <Button
        onClick={() => {
          trackAnalyticEvent(ANALYTIC_EVENTS.CUSTOM_REWARD_CREATE_CUSTOM)
          setShowAddTypeModal(true)
        }}
        style={styles}
        text={isMobile ? "+" : "Add Reward"}
      />
      <Modal
        isOpen={showAddTypeModal}
        onClose={() => setShowAddTypeModal(false)}
        displayCloseIcon
        animate
      >
        <div className="quest-modal">
          <div className="quest-modal-content x-small">
            <div className="quest-modal-header section-title">
              Create Custom Reward
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                justifyContent: "center",
              }}
            >
              <Button
                text="Create Your Own"
                onClick={() => {
                  setTimeout(() => openAddRewardModal(), 100)
                  setShowAddTypeModal(false)
                }}
              />
              <Button
                text="Use a Template"
                onClick={() => {
                  setTimeout(() => openRewardTemplatesModal(), 100)
                  setShowAddTypeModal(false)
                }}
              />
              <Button
                onClick={() => {
                  trackAnalyticEvent(ANALYTIC_EVENTS.SEND_BONUS_REWARD)
                  setTimeout(() => openBonusRewardModal(), 100)
                  setShowAddTypeModal(false)
                }}
                style={{ height: "50px" }}
                text="Gift Bonus Coin Reward"
              />
            </div>
          </div>
        </div>
      </Modal>
      <RewardTemplates />
    </>
  )
}
