import { FlexBox, JoonUIColor, SPACING, Typography } from "@joonapp/web-shared"

export const TemplateFigure = ({
  text,
  image,
}: {
  text: string
  image: string
}) => {
  return (
    <FlexBox
      align="center"
      direction="column"
      gap={SPACING.space2}
      style={{ maxWidth: "70px" }}
    >
      <FlexBox
        align="center"
        justify="center"
        style={{
          background: JoonUIColor.background.lightGray,
          borderRadius: SPACING.space2,
          height: "60px",
          width: "60px",
        }}
      >
        <img style={{ height: "35px", width: "35px" }} src={image} alt="" />
      </FlexBox>
      <Typography variant="bodyXSmall">{text}</Typography>
    </FlexBox>
  )
}
