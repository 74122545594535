import { FlexBox, SPACING } from "@joonapp/web-shared"
import { useEffect } from "react"

import PhoneNumberPage from "./PhoneNumberPage"
import ProgramDetailsPage from "./ProgramDetailsPage"
import TrainingLandingPage from "./TrainingLandingPage"
import BackButton from "../../../components/buttons/BackButton"
import JoinParentsTextBox from "../../../components/coaching/JoinParentsTextBox"
import TransitionWrapper from "../../../components/coaching/TransitionWrapper"
import { TrainingStep } from "../../../constants"
import useMobile from "../../../hooks/useMobile"
import { useRouter } from "../../../util/router"
import CalendlyPage from "../CalendlyPage"
import ScheduledPage from "../ScheduledPage"
import { useCoachingStore } from "../useCoachingStore"

const TrainingFlow = () => {
  const router = useRouter()
  const { step, setStep } = useCoachingStore()
  const isMobile = useMobile()

  useEffect(() => {
    if (router.query.from === "academy") setStep(TrainingStep.CALENDLY)
    else setStep(TrainingStep.LANDING)
  }, [router.query.from, setStep])

  return (
    <FlexBox
      direction="column"
      align="center"
      justify="space-between"
      gap={SPACING.space2}
      wrap={false}
      style={{
        position: "relative",
        width: "min(450px, 100%)",
        height: "calc(100dvh - 20px)",
        ...(isMobile
          ? { margin: "20px 20px 0px", paddingTop: "20px" }
          : { margin: "30px 0px 0px", paddingTop: "0px" }),
      }}
    >
      <div>
        <div
          style={{
            ...(step > TrainingStep.LANDING && step !== TrainingStep.SCHEDULED
              ? { opacity: 1, pointerEvents: "auto" }
              : { opacity: 0, pointerEvents: "none" }),
            transition: "opacity .2s ease",
            position: "absolute",
            top: "0px",
            left: "0px",
          }}
        >
          {router.query.from !== "academy" && (
            <BackButton onBack={() => setStep(step - 1)} />
          )}
        </div>
        <img
          src={"/images/logos/joon-health-logo.svg"}
          alt=""
          style={{ height: "24px", width: "auto" }}
        />
        <TransitionWrapper showChildren={step === TrainingStep.LANDING}>
          <FlexBox
            justify="flex-start"
            align="center"
            direction="column"
            fullWidth
            gap={SPACING.space4}
            style={{
              minHeight: "70vh",
              padding: `${SPACING.space2} 0 0`,
            }}
          >
            <TrainingLandingPage />
          </FlexBox>
        </TransitionWrapper>
        <TransitionWrapper showChildren={step === TrainingStep.PROGRAM_DETAILS}>
          <ProgramDetailsPage />
        </TransitionWrapper>
        <TransitionWrapper showChildren={step === TrainingStep.PHONE_NUMBER}>
          <PhoneNumberPage />
        </TransitionWrapper>
        <TransitionWrapper showChildren={step === TrainingStep.CALENDLY}>
          <CalendlyPage />
        </TransitionWrapper>
        <TransitionWrapper showChildren={step === TrainingStep.SCHEDULED}>
          <ScheduledPage />
        </TransitionWrapper>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          ...(step < TrainingStep.PHONE_NUMBER
            ? { opacity: 1, pointerEvents: "auto" }
            : { opacity: 0, pointerEvents: "none" }),
          transition: "opacity .2s ease",
        }}
      >
        <JoinParentsTextBox />
      </div>
    </FlexBox>
  )
}

export default TrainingFlow
