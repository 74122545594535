import { Button, Typography, JoonUIColor } from "@joonapp/web-shared"

import useOnboardContext, {
  OnboardingStep,
} from "../../hooks/useOnboardContext"
import DotersGroupedWelcomeImage from "../../images/doter-group-welcome-screen.png"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { useRouter } from "../../util/router"
import { localStorageItems } from "../../util/storage"
import BackButton from "../buttons/BackButton"

const OnboardAddChildOrJoinFamily = () => {
  const { setStep, previousStep, setIsCoparent } = useOnboardContext()
  const router = useRouter()

  const onBack = () => {
    if (!localStorage.getItem(localStorageItems.therapistCode)) previousStep()
    else router.push("/invite")
  }

  return (
    <>
      <BackButton onBack={onBack} />
      <div className="onboard-section-centered" style={{ height: "75vh" }}>
        <img
          src={DotersGroupedWelcomeImage}
          alt="doter group"
          style={{ height: "min(150px, 15vh)" }}
        />
        <div
          style={{
            textAlign: "center",
            width: "100%",
            fontSize: "20px",
            lineHeight: "24px",
            fontWeight: "600",
            margin: "24px 0",
          }}
        >
          <div>Up next:</div>
          <div>Let's Add your kids!</div>
        </div>
        <Button
          fullWidth
          onClick={() => {
            setIsCoparent(false)
            trackAnalyticEvent(ANALYTIC_EVENTS.CONTINUE_ADD_CHILDREN)
            setStep(OnboardingStep.AddChildren)
          }}
          text="Continue"
        />
        <div className="or-separator my-35">
          <div style={{ background: "#F0F1FB" }}>OR</div>
        </div>
        <Button
          buttonType="secondary"
          fullWidth
          onClick={() => {
            setIsCoparent(true)
            trackAnalyticEvent(ANALYTIC_EVENTS.HAS_FAMILY_CODE)
            setStep(OnboardingStep.CoparentCode)
          }}
          text="Enter code to join family"
        />
      </div>
      <button
        onClick={() => {
          router.push("/auth/signin")
          trackAnalyticEvent(ANALYTIC_EVENTS.HAS_ACCOUNT)
        }}
        style={{ width: "100%", textAlign: "center", marginTop: "auto" }}
      >
        <Typography variant="bodyBold" color={JoonUIColor.background.accent}>
          Sign in to an existing account
        </Typography>
      </button>
    </>
  )
}

export default OnboardAddChildOrJoinFamily
