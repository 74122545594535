import { ParentTraining, ParentTrainingStatus } from "@joonapp/web-shared"

import { createJoonAPIClient } from "../util/joon-api"

export async function getTrainings({
  userId,
  status,
}: {
  userId: number
  status: ParentTrainingStatus
}) {
  const API = createJoonAPIClient()
  try {
    return API.get<{ results: ParentTraining[] }>(`api/parent-trainings/`, {
      params: {
        user_id: userId,
        status,
      },
    }).then((res) => res.data.results)
  } catch (error) {
    console.error(error)
  }
}

export const completeAssignment = async ({
  assignmentCompletionId,
  shortAnswerAnswers,
  multipleChoiceAnswers,
}: {
  assignmentCompletionId: number
  shortAnswerAnswers?: {
    question_id: number
    answer: string
  }[]
  multipleChoiceAnswers?: {
    question_id: number
    answer_id: number
    is_correct: boolean
  }[]
}) => {
  const API = createJoonAPIClient()
  return API.post(
    `api/learning-module-assignment-completions/${assignmentCompletionId}/complete/`,
    {
      short_answer_answers: shortAnswerAnswers,
      multiple_choice_answers: multipleChoiceAnswers,
    }
  )
}
