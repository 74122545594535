import { FlexBox, SPACING, Typography } from "@joonapp/web-shared"
import { useEffect } from "react"

import MailItem from "./MailItem"
import { FamilyMailboxSkeleton } from "../../../components/skeleton/Skeleton"
import { QUERY_KEYS } from "../../../constants"
import {
  useFamilyMailQuery,
  useFamilyQuery,
  useUserQuery,
} from "../../../networking/queries"
import { FamilyMail, FamilyMailboxTab } from "../../../types"
import { queryClient } from "../../../util/queryClient"
import { separateFamily } from "../../../util/util"

const ReceivedMail = () => {
  const { user } = useUserQuery()
  const { data: family, isLoading: isLoadingFamily } = useFamilyQuery()
  const { children } = separateFamily(user, family)

  const { data: receivedMail, isLoading } = useFamilyMailQuery(
    FamilyMailboxTab.RECEIVED
  )

  useEffect(() => {
    queryClient.invalidateQueries([QUERY_KEYS.FAMILY_MAIL])
    queryClient.invalidateQueries([QUERY_KEYS.UNREAD_MAIL])
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return isLoading || isLoadingFamily ? (
    <div style={{ marginTop: SPACING.space3 }}>
      <FamilyMailboxSkeleton />
    </div>
  ) : receivedMail && receivedMail.length > 0 ? (
    <FlexBox direction="column" gap={SPACING.space0}>
      <Typography variant="bodyBold" style={{ marginTop: SPACING.space3 }}>
        Mail from your children
      </Typography>
      <FlexBox
        wrap={false}
        direction="column"
        gap={SPACING.space2}
        style={{
          maxHeight: "calc(100vh - 300px)",
          overflowY: "scroll",
        }}
      >
        {receivedMail.map((mail: FamilyMail, i: number) => (
          <MailItem
            mail={mail}
            tab={FamilyMailboxTab.RECEIVED}
            child={
              children.find((child) => child.user.id === mail.sender.id)?.user
            }
            key={i}
          />
        ))}
      </FlexBox>
    </FlexBox>
  ) : (
    <FlexBox
      direction="column"
      justify="center"
      align="center"
      style={{
        position: "absolute",
        top: "40%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
      }}
    >
      <Typography variant="bodyBold">No mail from your child yet!</Typography>
      <Typography variant="body">
        Send mail below to start the conversation!
      </Typography>
    </FlexBox>
  )
}

export default ReceivedMail
