import { useEffect, useState } from "react"

import { usePaywallReferralModalStore } from "./PaywallReferralModal"
import { priceIds } from "../../constants"
import {
  useFamilyQuery,
  useSubscriberInfo,
  useUserQuery,
} from "../../networking/queries"
import { SubscriptionTrialKey } from "../../types"
import { getStripeRedirectFunction } from "../../util/stripe"

export const useCheckoutRedirect = () => {
  const [isLoadingCheckoutFn, setIsLoadingCheckoutFn] = useState(false)
  const [checkoutFn, setCheckoutFn] = useState<Function | null>(null)
  const [isRedirecting, setIsRedirecting] = useState(false)
  const { hasValidCode } = usePaywallReferralModalStore()

  const { user } = useUserQuery()
  const { data: family } = useFamilyQuery()
  const { data: subscriber } = useSubscriberInfo()

  // Set the checkout function
  useEffect(() => {
    if (!user || !family || !subscriber || isLoadingCheckoutFn) return
    setIsLoadingCheckoutFn(true)
    getStripeRedirectFunction({
      subscriberId: family.subscriber_id,
      trialLengthKey: subscriber?.hasPurchasedPlanBefore
        ? SubscriptionTrialKey.NONE
        : undefined,
      email: user.email,
      priceId: hasValidCode ? priceIds.discount : priceIds.default,
    })
      .then((stripeRedirectFn) => setCheckoutFn(() => stripeRedirectFn))
      .finally(() => setIsLoadingCheckoutFn(false))
  }, [hasValidCode, family, user, subscriber]) // eslint-disable-line react-hooks/exhaustive-deps

  // Wait until the checkout function is set before redirecting
  useEffect(() => {
    if (isRedirecting && !isLoadingCheckoutFn && !!checkoutFn) {
      checkoutFn()
      setIsRedirecting(false)
    }
  }, [isRedirecting, isLoadingCheckoutFn, checkoutFn])

  return {
    isLoadingCheckoutFn,
    isRedirecting,
    setIsRedirecting,
  }
}
