import { useMutation } from "@tanstack/react-query"

import { QUERY_KEYS } from "../../../constants"
import { useUserQuery } from "../../../networking/queries"
import { updateNotificationPreferences } from "../../../networking/user"
import { queryClient } from "../../../util/queryClient"

export const useNotificationPreferencesMutation = () => {
  const { user } = useUserQuery()

  return useMutation({
    mutationFn: (receiveSms: boolean) =>
      updateNotificationPreferences({
        userId: user?.id as number,
        receiveSms,
      }),
    onSuccess: () =>
      queryClient.invalidateQueries([QUERY_KEYS.NOTIFICATION_PREFERENCES]),
  })
}
