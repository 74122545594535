import { JoonUIColor, SPACING, Shadow } from "@joonapp/web-shared"
import { useState } from "react"

export const HoverCard = ({ onClick, fullWidth, children, style }: any) => {
  const [isHovered, setIsHovered] = useState(false)
  const [isPressed, setIsPressed] = useState(false)

  return (
    <button
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => {
        setIsPressed(false)
        setIsHovered(false)
      }}
      onMouseDown={() => setIsPressed(true)}
      onMouseUp={() => setIsPressed(false)}
      onClick={onClick}
      style={{
        borderRadius: SPACING.space2,
        boxShadow: isPressed ? "none" : isHovered ? Shadow.high : Shadow.low,
        transition: "box-shadow 0.2s",
        padding: `${SPACING.space6} ${SPACING.space6}`,
        border: `1px solid ${JoonUIColor.background.lightGray}`,
        background: "white",
        cursor: "pointer",
        width: fullWidth ? "100%" : "fit-content",
        transform: isPressed ? "translateY(1px)" : "none",
        ...style,
      }}
    >
      {children}
    </button>
  )
}
