import { Button, JoonUIColor, SPACING } from "@joonapp/web-shared"
import { Typography } from "@joonapp/web-shared"

import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"

interface Props {
  childName: string
  onConfirm: () => void
}

function QuestionnaireDisclaimer({ childName, onConfirm }: Props) {
  return (
    <div className="onboard-section-centered">
      <Typography
        textAlign="center"
        variant="h1"
        style={{ marginBottom: "1rem" }}
      >
        Intake Questionnaire for {childName}
      </Typography>
      <Typography textAlign="center" variant="bodySmall">
        <strong>
          The following questions are used to help provide personalized
          recommendations{" "}
        </strong>
        and guidance for your family. You'll be able to add more children after
        this.
      </Typography>
      <Button
        fullWidth
        text="Continue"
        style={{ marginTop: SPACING.space4, marginBottom: SPACING.space6 }}
        onClick={() => {
          onConfirm()
          trackAnalyticEvent(ANALYTIC_EVENTS.START_CHILD_ONBOARDING_SURVEY)
        }}
      />
      <Typography
        textAlign="center"
        variant="bodySmall"
        style={{ fontSize: "14px" }}
      >
        <span
          style={{ color: JoonUIColor.background.accent, fontWeight: "700" }}
        >
          The answers to the Intake Questionnaire are never shared with anyone
          without your consent.{" "}
        </span>
        If you are uncomfortable answering a personally sensitive question, you
        can always select "Prefer not to answer."
      </Typography>
    </div>
  )
}

export default QuestionnaireDisclaimer
