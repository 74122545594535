import React from "react"
import "./pageContentWrapper.scss"

// WILL BE MOVING THESE TO LIBRARY ONCE STABLE

interface PageContentWrapperProps {
  children: React.ReactNode
  mobileGutter?: boolean
  style?: React.CSSProperties
}

const PageContentWrapper = ({
  mobileGutter,
  children,
  style,
}: PageContentWrapperProps) => {
  return (
    <div
      className={`jooniverse__page-content-wrapper ${
        mobileGutter ? "mobile-gutter" : ""
      }`}
      style={style}
    >
      {children}
    </div>
  )
}

export default PageContentWrapper
