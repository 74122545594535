import { QuestRoutine } from "@joonapp/web-shared"
import { MutableRefObject } from "react"
import { create } from "zustand"

interface QuestBoardStore {
  questBoardRef: MutableRefObject<any> | null
  setQuestBoardRef: (questBoardRef: MutableRefObject<any> | null) => void

  selectedChildId: number | null
  setSelectedChildId: (selectedChildId: number | null) => void

  selectedRoutine: QuestRoutine | null
  setSelectedRoutine: (selectedRoutine: QuestRoutine | null) => void
}

const useQuestBoardStore = create<QuestBoardStore>((set) => ({
  setQuestBoardRef: (questBoardRef: MutableRefObject<any> | null) =>
    set({ questBoardRef }),
  questBoardRef: null,

  selectedChildId: null,
  setSelectedChildId: (selectedChildId: number | null) =>
    set({ selectedChildId }),

  selectedRoutine: null,
  setSelectedRoutine: (selectedRoutine: QuestRoutine | null) =>
    set({ selectedRoutine }),
}))

export default useQuestBoardStore
