import { create } from "zustand"

interface TourStore {
  run: boolean
  setRun: (run: boolean) => void
  showTour: boolean
  setShowTour: (showTour: boolean) => void
  stepIndex: number
  setStepIndex: (stepIndex: number) => void
  trainingId: number | null
  setTrainingId: (trainingId: number) => void
}

const useTourStore = create<TourStore>((set) => ({
  run: true,
  setRun: (run) => set({ run }),
  showTour: false,
  setShowTour: (showTour) => set({ showTour }),
  stepIndex: 0,
  setStepIndex: (stepIndex) => set({ stepIndex }),
  trainingId: null,
  setTrainingId: (trainingId) => set({ trainingId }),
}))

export default useTourStore
