import {
  Badge,
  Family,
  JoonColorExpanded,
  SettingsIcon,
} from "@joonapp/web-shared"

import { useManageTherapistModal } from "./ManageTherapistModal"
import { useFamilyQuery } from "../../../networking/queries"
import { TherapistProfileWithPatients } from "../../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"
import { getUserFromFamily } from "../../../util/util"

const TherapistItem = ({
  therapist,
}: {
  therapist: TherapistProfileWithPatients
}) => {
  const { onOpen: openManageTherapistModal } = useManageTherapistModal()
  const { data: family } = useFamilyQuery()
  const children = therapist.patients.map((patient) =>
    getUserFromFamily(family as Family, Number(patient))
  )

  return (
    <div className="therapist-item">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="font-body">{therapist.user.name}</div>
        <div style={{ display: "flex", gap: "8px" }}>
          <div
            className="font-footnote"
            style={{
              background: JoonColorExpanded.viridian100,
              padding: "4px",
              borderRadius: "4px",
            }}
          >
            Connected!
          </div>
          <button
            onClick={() => {
              trackAnalyticEvent(ANALYTIC_EVENTS.MANAGE_THERAPISTS_BUTTON_CLICK)
              openManageTherapistModal(therapist)
            }}
          >
            <SettingsIcon size={20} color="#C7C7CC" />
          </button>
        </div>
      </div>
      <div style={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
        {children.map((patient) => (
          <Badge key={patient?.id}>
            <div className="font-footnote">{patient?.name}</div>
          </Badge>
        ))}
      </div>
    </div>
  )
}

export default TherapistItem
