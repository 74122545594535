const SlideUpModalBackground = ({
  type,
  isOpen,
  onClose,
}: {
  type: "solid" | "overlay"
  isOpen: boolean
  onClose: () => void
}) => {
  return (
    <button
      onClick={onClose}
      style={{
        height: "100dvh",
        width: "100vw",
        left: "0",
        top: "0",
        display: "flex",
        zIndex: 999,
        alignItems: "center",
        position: "fixed",
        flexDirection: "column",
        background:
          type === "solid"
            ? "linear-gradient(48deg, rgba(162,167,237,1) 0%, rgba(201,182,202,1) 64%, rgba(217,159,207,1) 100%)"
            : "#1B1F25",
        opacity: isOpen ? (type === "solid" ? 1 : 0.85) : 0,
        transition: "opacity 0.3s",
        cursor: "default",
        pointerEvents: isOpen ? "auto" : "none",
      }}
    />
  )
}
export default SlideUpModalBackground
