import {
  FlexBox,
  HandHoldingHeartIcon,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"

import PageContentWrapper from "../../../components/pageContentWrapper/PageContentWrapper"
import PageWrapper from "../../../components/pageWrapper/PageWrapper"
import useMobile from "../../../hooks/useMobile"

const CoachPending = () => {
  const isMobile = useMobile()

  return (
    <PageWrapper
      style={{
        background: JoonColorExpanded.yellow100,
        paddingBottom: "0px",
      }}
    >
      <PageContentWrapper
        mobileGutter
        style={{ alignItems: "center", justifyContent: "space-evenly" }}
      >
        <FlexBox
          direction="column"
          align="center"
          gap={SPACING.space6}
          wrap={false}
          style={{
            position: "relative",
            width: "min(450px, 100%)",
            ...(isMobile
              ? {
                  paddingTop: "20px",
                  paddingBottom: "0px",
                }
              : {
                  paddingTop: "0px",
                  paddingBottom: "120px",
                }),
          }}
        >
          <FlexBox direction="column" gap={SPACING.space4} align="center">
            <Typography variant="h2" textAlign="center">
              Welcome to Joon Coaching!
            </Typography>
            <Typography
              variant="bodyBold"
              textAlign="center"
              color={JoonUIColor.text.secondary}
              style={{ maxWidth: isMobile ? "80%" : "auto" }}
            >
              Yay for putting yourself first and signing up for Joon Coaching.
            </Typography>
          </FlexBox>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: SPACING.space4,
              backgroundColor: JoonColorExpanded.indigo100,
              width: "62px",
              height: "62px",
              transform: "rotate(15deg)",
              paddingBottom: "5px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                transform: "rotate(-15deg)",
              }}
            >
              <HandHoldingHeartIcon
                size={48}
                color={JoonUIColor.semantic.primary}
              />
            </div>
          </div>
          <Typography
            variant="bodyBold"
            textAlign="center"
            color={JoonUIColor.text.secondary}
          >
            We're currently connecting you with your Coach.
            <br />
            This process may take up to 48 hours.
          </Typography>
          <Typography
            variant="bodySmall"
            textAlign="center"
            color={JoonUIColor.text.secondary}
          >
            Check back soon to start chatting with your Coach!
          </Typography>
        </FlexBox>
        <Typography
          variant="bodySmall"
          textAlign="center"
          color={JoonUIColor.text.secondary}
        >
          If you don't see this changing, please contact{" "}
          <a
            href="mailto:brad@joonapp.io"
            style={{ color: JoonUIColor.text.primaryAccent }}
          >
            brad@joonapp.io
          </a>
        </Typography>
      </PageContentWrapper>
    </PageWrapper>
  )
}

export default CoachPending
