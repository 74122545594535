import {
  CheckIcon,
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  Shadow,
  SkipIcon,
  ThumbsDownIcon,
  Typography,
} from "@joonapp/web-shared"
import { useShallow } from "zustand/react/shallow"

import { useCatchUp, useCatchUpStore } from "./useCatchUpStore"

const CatchUpCompleteActionButtons = () => {
  const { onClickCompleteQuests, onClickRejectQuests, onClickSkipQuests } =
    useCatchUp()
  const { selectedQuestIdsForCompletion } = useCatchUpStore(
    useShallow((state) => ({
      selectedQuestIdsForCompletion: state.selectedQuestIdsForCompletion,
    }))
  )

  const buttonsAreEnabled = selectedQuestIdsForCompletion.length > 0

  return (
    <>
      <FlexBox direction="column" gap={SPACING.space2}>
        <Typography variant="bodyBold" textAlign="center">
          {selectedQuestIdsForCompletion.length > 0
            ? `Mark (${selectedQuestIdsForCompletion.length}) selected as:`
            : "Tap a Quest above to check off!"}
        </Typography>
        <FlexBox direction="row" wrap={false} align="center">
          <FlexBox
            direction="column"
            align="center"
            gap={SPACING.space2}
            style={{
              flex: 1,
              cursor: "pointer",
              opacity: buttonsAreEnabled ? 1 : 0.6,
            }}
            onClick={onClickRejectQuests}
          >
            <ThumbsDownIcon color={JoonUIColor.icon.neutral} size={24} />
            <Typography variant="bodyBold" color={JoonUIColor.icon.neutral}>
              Reject
            </Typography>
          </FlexBox>
          <FlexBox
            direction="column"
            align="center"
            gap={SPACING.space2}
            style={{
              flex: 1,
              cursor: buttonsAreEnabled ? "pointer" : "default",
              opacity: buttonsAreEnabled ? 1 : 0.6,
            }}
            onClick={onClickSkipQuests}
          >
            <SkipIcon color={JoonUIColor.icon.neutral} size={24} />
            <Typography variant="bodyBold" color={JoonUIColor.icon.neutral}>
              Skipped
            </Typography>
          </FlexBox>
          <FlexBox
            direction="column"
            align="center"
            gap={SPACING.space2}
            onClick={buttonsAreEnabled ? onClickCompleteQuests : undefined}
            style={{
              padding: `${SPACING.space2} ${SPACING.space4}`,
              borderRadius: SPACING.space2,
              cursor: buttonsAreEnabled ? "pointer" : "default",
              flex: 1,
              background: buttonsAreEnabled
                ? JoonColorExpanded.viridian100
                : "unset",
              boxShadow: buttonsAreEnabled ? Shadow.medium : "unset",
              opacity: buttonsAreEnabled ? 1 : 0.6,
            }}
          >
            <CheckIcon
              color={
                buttonsAreEnabled
                  ? JoonUIColor.semantic.success
                  : JoonUIColor.icon.neutral
              }
              size={24}
            />
            <Typography
              variant="bodyBold"
              color={
                buttonsAreEnabled
                  ? JoonUIColor.semantic.success
                  : JoonUIColor.icon.neutral
              }
            >
              Completed
            </Typography>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </>
  )
}

export default CatchUpCompleteActionButtons
