import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core"
import type { Active, Over, UniqueIdentifier } from "@dnd-kit/core"
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable"
import type { ReactNode } from "react"
import { Fragment, useMemo, useState } from "react"
import { createPortal } from "react-dom"

import { SortableOverlay } from "./SortableOverlay"

interface BaseItem {
  id: UniqueIdentifier
}

interface Props<T extends BaseItem> {
  sections: [string, T[]][]
  onChange(sections: [string, T[]][]): void
  renderItem(item: T): ReactNode
  renderSection(section: [string, T[]]): ReactNode
}

export function MultipleSortableLists<T extends BaseItem>({
  sections,
  onChange,
  renderItem,
  renderSection,
}: Props<T>) {
  const [active, setActive] = useState<Active | null>(null)
  const activeItem = useMemo(() => {
    if (!active) return null
    for (const section of sections) {
      const activeQuest = section[1].find((quest) => quest.id === active.id)
      if (activeQuest) return activeQuest
    }
    return null
  }, [active, sections])

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 100,
        tolerance: 10,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  const handleDragOver = ({
    active,
    over,
  }: {
    active: Active
    over: Over | null
  }) => {
    if (active && over && active.id !== over.id) {
      const activeContainer = active.data.current?.sortable?.containerId
      const overContainer = over.data.current?.sortable?.containerId
      const newSections = sections.map(([routine, quests]) => {
        if (routine === activeContainer && activeContainer === overContainer) {
          const oldIndex = quests.findIndex((quest) => quest.id === active.id)
          const newIndex = quests.findIndex((quest) => quest.id === over.id)
          if (oldIndex === -1 || newIndex === -1) {
            return [routine, quests]
          }
          const newQuests = [...quests]
          const [movedQuest] = newQuests.splice(oldIndex, 1)
          newQuests.splice(newIndex, 0, movedQuest)
          return [routine, newQuests]
        } else if (routine === overContainer) {
          // add the quest to this routine container
          const insertIndex = quests.findIndex((quest) => quest.id === over.id)
          if (insertIndex === -1) {
            return [routine, quests]
          }
          const newQuests = [...quests]
          newQuests.splice(insertIndex, 0, activeItem as T)
          return [routine, newQuests]
        } else if (routine === activeContainer) {
          // remove the quest from this routine container
          const newQuests = quests.filter((quest) => quest.id !== active.id)
          return [routine, newQuests]
        }
        return [routine, quests]
      }) as [string, T[]][]

      // Update order only for affected containers
      const updatedSections = newSections.map(([routine, quests]) => {
        if (routine === activeContainer || routine === overContainer) {
          return [
            routine,
            quests.map((quest, index) => ({
              ...quest,
              order: index,
            })),
          ]
        }
        return [routine, quests]
      })

      onChange(updatedSections as [string, T[]][])
    }
  }

  const handleDragEnd = ({
    active,
    over,
  }: {
    active: Active
    over: Over | null
  }) => {
    if (active && over && active.id !== over.id) {
      let order = 0
      const newItemsOrderIndexed = sections.map(([routine, quests]) => {
        const newQuests = quests.map((quest) => ({
          ...quest,
          order: order++,
        }))
        return [routine, newQuests]
      })
      onChange(newItemsOrderIndexed as [string, T[]][])
    }
    setActive(null)
  }

  return (
    <DndContext
      sensors={sensors}
      onDragStart={({ active }) => setActive(active)}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      onDragCancel={() => {
        setActive(null)
      }}
    >
      {sections.map((section) => (
        <Fragment key={section[0]}>{renderSection(section)}</Fragment>
      ))}
      {createPortal(
        <SortableOverlay>
          {activeItem ? renderItem(activeItem) : null}
        </SortableOverlay>,
        document.body
      )}
    </DndContext>
  )
}
