import { useEffect } from "react"

import ForgotPassword from "../components/auth/ForgotPassword"
import SignIn from "../components/auth/SignIn"
import PageWrapper from "../components/pageWrapper/PageWrapper"
import useDocumentTitle from "../hooks/useDocumentTitle"
import { useRouter } from "../util/router"
import { sessionManager } from "../util/storage"
import { getCheckoutRedirectUrl } from "../util/util"

function AuthPage() {
  useDocumentTitle("Joon - Sign In")
  const router = useRouter()

  const authType = router.query.type

  useEffect(() => {
    if (sessionManager.getRefreshToken()) {
      if (router.query.priceId) router.push(getCheckoutRedirectUrl(router))
      else router.push("/")
    }
  })

  return (
    <PageWrapper style={{ width: "100%", background: "#f0f1fb" }}>
      <div className="onboard-wrapper">
        <div className="onboard-section-centered">
          {authType === "forgotpass" ? <ForgotPassword /> : <SignIn />}
        </div>
      </div>
    </PageWrapper>
  )
}

export default AuthPage
