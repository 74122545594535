import {
  FlexBox,
  IconProps,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"

import NotificationDot from "./NotificationDot"
import Card from "../../components/card/Card"

type Props = {
  icon: React.FC<IconProps>
  title: string
  onClick: () => void
}

const QuickActionsFamilyItem = ({ icon: Icon, title, onClick }: Props) => {
  return (
    <Card
      title={
        <FlexBox
          direction="row"
          align="center"
          justify="flex-start"
          gap={SPACING.space3}
          style={{ flexWrap: "nowrap" }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Icon size={24} color={JoonUIColor.icon.accent} />
          </div>
          <Typography variant="body">{title}</Typography>
        </FlexBox>
      }
      onClick={onClick}
      buttonComponent={
        title === "Family Mailbox" && <NotificationDot size={"12px"} />
      }
      style={{
        height: SPACING.space12,
        pointerEvents: "auto",
        position: "relative",
      }}
    />
  )
}

export default QuickActionsFamilyItem
