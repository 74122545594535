import {
  Button,
  EditIcon,
  FamilyParentProfile,
  FlexBox,
  JoonUIColor,
  Loader,
  Modal,
  SPACING,
  TextButton,
  TextInput,
  TrashIcon,
  Typography,
} from "@joonapp/web-shared"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useState } from "react"

import useEditParentMutation from "./useEditParentMutation"
import { QUERY_KEYS } from "../../constants"
import useDocumentTitle from "../../hooks/useDocumentTitle"
import { useFamilyQuery, useUserQuery } from "../../networking/queries"
import { removeCoParent } from "../../networking/user"

interface Props {
  parent: FamilyParentProfile | null
}

function ParentItem({ parent }: Props) {
  useDocumentTitle("Joon Settings - Family")
  const { user } = useUserQuery()
  const queryClient = useQueryClient()

  const [isOpenEdit, setIsOpenEdit] = useState(false)
  const [isOpenDelete, setIsOpenDelete] = useState(false)
  const [newName, setNewName] = useState(parent?.user?.name || "")
  const [newNickname, setNewNickname] = useState(parent?.nickname || "")
  const { data: family } = useFamilyQuery()

  const isParentUser = user?.id === parent?.user.id

  const editParentMutation = useEditParentMutation()

  const deleteParentMutation = useMutation({
    mutationFn: () => removeCoParent(family?.id!, parent?.user.id!),
    onSuccess: () => {
      setIsOpenDelete(false)
      queryClient.invalidateQueries([QUERY_KEYS.FAMILY])
      queryClient.invalidateQueries([QUERY_KEYS.USER])
    },
    onError: () => alert("Error"),
  })

  const loading = editParentMutation.isLoading || deleteParentMutation.isLoading

  if (!parent || !user) return <></>

  return (
    <>
      <div className="user-item">
        <div className="user-item-initial">{parent.nickname.charAt(0)}</div>
        <div className="user-item-name">{parent.nickname}</div>
        {isParentUser ? (
          <button
            onClick={() => setIsOpenEdit(true)}
            className="user-item-icon"
          >
            {loading ? (
              <Loader
                size={28}
                color={JoonUIColor.background.accent}
                thickness={2}
              />
            ) : (
              <EditIcon color={JoonUIColor.icon.inverted} />
            )}
          </button>
        ) : (
          <button
            onClick={() => setIsOpenDelete(true)}
            className="user-item-icon delete"
          >
            {loading ? (
              <Loader
                size={28}
                color={JoonUIColor.background.accent}
                thickness={2}
              />
            ) : (
              <TrashIcon color={JoonUIColor.icon.inverted} />
            )}
          </button>
        )}
      </div>
      <Modal
        isOpen={isOpenEdit}
        onClose={() => setIsOpenEdit(false)}
        displayCloseIcon
        animate
      >
        <FlexBox
          direction="column"
          style={{ width: "min(350px, 95vw)", padding: "30px" }}
          gap={SPACING.space4}
        >
          <FlexBox>
            <Typography variant="bodyBold">Your First Name</Typography>
            <TextInput
              name="firstName"
              value={newName}
              onChange={(e: any) => setNewName(e.target.value)}
              placeholder="Jennifer"
              maxLength={140}
              fullWidth
            />
          </FlexBox>

          <FlexBox>
            <Typography variant="bodyBold">
              Nickname
              <Typography variant="body"> (what your kids will see)</Typography>
            </Typography>
            <TextInput
              name="nickname"
              value={newNickname}
              onChange={(e: any) => setNewNickname(e.target.value)}
              placeholder="Mom"
              maxLength={140}
              fullWidth
            />
          </FlexBox>
          <Button
            isLoading={loading}
            onClick={async () => {
              await editParentMutation.mutateAsync({
                parentInfo: { name: newName, nickname: newNickname },
                userId: user?.id,
              })
              setIsOpenEdit(false)
            }}
            fullWidth
            text="Save"
          />
        </FlexBox>
      </Modal>
      <Modal
        isOpen={isOpenDelete}
        onClose={() => setIsOpenDelete(false)}
        displayCloseIcon
        animate
      >
        <div style={{ width: "min(400px, 95vw)", padding: SPACING.space6 }}>
          <FlexBox
            direction="column"
            gap={SPACING.space4}
            style={{ marginBottom: SPACING.space4 }}
          >
            <Typography variant="h3">
              Are you sure you want to remove {parent.nickname} from your
              family?
            </Typography>
            <Typography variant="body">
              You can reshare your family code with them to add them back in the
              future.
            </Typography>
          </FlexBox>
          <FlexBox justify="flex-end" gap={SPACING.space4}>
            <TextButton onClick={() => setIsOpenDelete(false)}>
              <Typography
                variant="bodyBold"
                color={JoonUIColor.text.primaryAccent}
              >
                Cancel
              </Typography>
            </TextButton>
            <Button
              text="Remove"
              isLoading={loading}
              onClick={() => deleteParentMutation.mutate()}
              buttonType="redPrimary"
            />
          </FlexBox>
        </div>
      </Modal>
    </>
  )
}

export default ParentItem
