import {
  CoachProfile,
  JoonColorExpanded,
  JoonUIColor,
  Typography,
  UserRole,
} from "@joonapp/web-shared"

import { TherapistProfile } from "../../types"

const CareTeamAvatar = ({
  profile,
  size = "medium",
}: {
  profile: CoachProfile | TherapistProfile
  size?: string
}) => {
  const imageUrl = profile.profile_image_url || ""
  const role = profile.role || ""
  const displayName = profile.nickname
    ? profile.nickname
    : `${profile.user.name} ${profile.user.last_name || ""}`

  const avatarSizeMap: Record<string, string> = {
    small: "24px",
    medium: "48px",
    large: "80px",
  }

  const fontSizeMap: Record<string, string> = {
    small: "1rem",
    medium: "2rem",
    large: "3.5rem",
  }

  return (
    <div
      style={{
        minWidth: avatarSizeMap[size],
        maxWidth: avatarSizeMap[size],
        minHeight: avatarSizeMap[size],
        maxHeight: avatarSizeMap[size],
        borderRadius: "50%",
        background:
          role === UserRole.COACH
            ? JoonColorExpanded.yellow400
            : JoonColorExpanded.purple300,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {imageUrl ? (
        <img
          src={imageUrl}
          style={{ width: "100%", height: "100%", borderRadius: "50%" }}
          alt="Coach Profile"
        />
      ) : (
        <Typography
          variant="h1"
          color={JoonUIColor.text.inverted}
          style={{
            fontSize: fontSizeMap[size],
          }}
        >
          {displayName[0].toUpperCase()}
        </Typography>
      )}
    </div>
  )
}

export default CareTeamAvatar
