import { QuestInstance, QuestStatus } from "@joonapp/web-shared"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import toast from "react-hot-toast"

import { QUERY_KEYS } from "../../constants"
import {
  completeQuests,
  rejectQuest,
  rejectQuests,
  retryQuest,
  skipQuests,
  verifyQuest,
} from "../../networking/quests"

const useQuestActions = () => {
  const queryClient = useQueryClient()

  const onReviewMutate = async (body: any) => {
    await queryClient.cancelQueries([QUERY_KEYS.QUEST_REVIEW])

    // Snapshot the previous value
    const previousQuestsToReview = queryClient.getQueryData<QuestInstance[]>([
      QUERY_KEYS.QUEST_REVIEW,
    ])
    const newQuestsToReview = previousQuestsToReview?.filter(
      (questInstance) => questInstance.id !== body.instanceId
    )

    // Optimistically update to the new value
    queryClient.setQueryData<QuestInstance[]>(
      [QUERY_KEYS.QUEST_REVIEW],
      newQuestsToReview
    )

    // Return a context object with the snapshotted value
    return { previousQuestsToReview }
  }

  const onReviewError = (
    _err: any,
    _variables: any,
    context: { previousQuestsToReview: QuestInstance[] | undefined } | undefined
  ) => {
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    if (context?.previousQuestsToReview) {
      queryClient.setQueryData<QuestInstance[]>(
        [QUERY_KEYS.QUEST_REVIEW],
        context.previousQuestsToReview
      )
    }
  }

  const onReviewSettled = () => {
    // Always refetch after error or success:
    queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.QUEST_REVIEW] })
    queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.QUEST_BOARD] })
    queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.USER] })
  }

  // Complete Quests

  const onCompleteMutate = async (body: {
    questInstanceIds: number[]
    status: QuestStatus.VERIFIED | QuestStatus.SKIPPED | QuestStatus.REJECTED
    questDate: string
  }) => {
    const questBoardQueryKey = [QUERY_KEYS.QUEST_BOARD, body.questDate]
    await queryClient.cancelQueries(questBoardQueryKey)

    // Snapshot the previous value
    const previousQuestBoard =
      queryClient.getQueryData<QuestInstance[]>(questBoardQueryKey)

    const newQuestBoardResults = (previousQuestBoard || []).map(
      (questInstance) => {
        if (body.questInstanceIds.includes(questInstance.id)) {
          return { ...questInstance, temp_status: body.status }
        }
        return questInstance
      }
    )

    // Optimistically update to the new value
    queryClient.setQueryData<QuestInstance[]>(
      questBoardQueryKey,
      newQuestBoardResults
    )

    // Return a context object with the snapshotted value
    return { previousQuestBoard }
  }

  const onCompleteError = (
    _err: any,
    variables: any,
    context: { previousQuestBoard: QuestInstance[] | undefined } | undefined
  ) => {
    const questBoardQueryKey = [QUERY_KEYS.QUEST_BOARD, variables.questDate]
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    if (context?.previousQuestBoard) {
      queryClient.setQueryData<QuestInstance[]>(
        questBoardQueryKey,
        context.previousQuestBoard
      )
    }
    toast.error("Error occurred. Please try again.")
  }
  const onCompleteSettled = (_data: any, _error: any, variables: any) => {
    const questBoardQueryKey = [QUERY_KEYS.QUEST_BOARD, variables.questDate]
    // Always refetch after error or success:
    queryClient.invalidateQueries({ queryKey: questBoardQueryKey })
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.QUEST_INSTANCE_STATS],
    })
    queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.USER] })
  }

  const useVerifyQuestMutation = useMutation({
    mutationFn: verifyQuest,
    onMutate: onReviewMutate,
    onError: onReviewError,
    onSettled: onReviewSettled,
  })

  const useRejectMutation = useMutation({
    mutationFn: rejectQuest,
    onMutate: onReviewMutate,
    onError: onReviewError,
    onSettled: onReviewSettled,
  })

  const useRetryMutation = useMutation({
    mutationFn: retryQuest,
    onMutate: onReviewMutate,
    onError: onReviewError,
    onSettled: onReviewSettled,
  })

  const useCompleteQuestsMutation = useMutation({
    mutationFn: completeQuests,
    onMutate: onCompleteMutate,
    onError: onCompleteError,
    onSettled: onCompleteSettled,
  })

  const useSkipQuestsMutation = useMutation({
    mutationFn: skipQuests,
    onMutate: onCompleteMutate,
    onError: onCompleteError,
    onSettled: onCompleteSettled,
  })

  const useRejectQuestsMutaton = useMutation({
    mutationFn: rejectQuests,
    onMutate: onCompleteMutate,
    onError: onCompleteError,
    onSettled: onCompleteSettled,
  })

  return {
    useVerifyQuestMutation,
    useRejectMutation,
    useRetryMutation,
    useCompleteQuestsMutation,
    useSkipQuestsMutation,
    useRejectQuestsMutaton,
  }
}

export default useQuestActions
