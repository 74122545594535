import dayjs from "dayjs"

import { createJoonAPIClient } from "../util/joon-api"

export const getTodaysBonusRewards = async () => {
  const API = createJoonAPIClient()
  const response = await API.get("api/bonus-rewards", {
    params: { reward_date: dayjs().format("YYYY-MM-DD") },
  })
  return response.data
}

export const sendBonusReward = async (
  childId: number,
  coins: number,
  reason: string
) => {
  const API = createJoonAPIClient()

  return API.post(`api/bonus-rewards/`, { receiver_id: childId, coins, reason })
}
