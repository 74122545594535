import { QuestDifficulty, QuestRoutine, SkillKey } from "@joonapp/web-shared"

interface SharedQuestTemplateFields {
  id: number
  title: string
  difficulty: QuestDifficulty
  routine: QuestRoutine
  requires_review: boolean
  skill: SkillKey | null

  type: "custom" | "suggested"
}

export interface SuggestedQuestTemplate extends SharedQuestTemplateFields {
  repeats_daily: boolean
  additional_text: string | null

  type: "suggested"
}

export interface CustomQuestTemplate extends SharedQuestTemplateFields {
  description: string | null
  redeemable_reward: number
  requires_completion_photo: boolean
  requires_completion_notes: boolean
  mandatory: boolean
  reminder_time: string | null
  timer_length: string | null
  recurrence: string
  occurrence_limit: number | null

  type: "custom"
}

export type QuestTemplate = SuggestedQuestTemplate | CustomQuestTemplate

/* eslint-disable no-unused-vars */
export enum QuestTemplateCategory {
  RECOMMENDED = "recommended",
  TOP = "top",
  HOUSEWORK = "housework",
  MENTAL_HEALTH = "mental_health",
  EDUCATION = "education",
  FITNESS = "fitness",
  FOCUS = "focus",
  RELATIONSHIPS = "relationships",
  CUSTOM = "custom",
}

export interface QuestInstanceStats {
  num_approved: number
  num_rejected: number
  num_to_collect: number
  num_to_complete: number
  num_to_review: number
  num_total_today: number
  num_user_created: number
}
