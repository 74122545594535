import { FlexBox, JoonUIColor, SPACING, Typography } from "@joonapp/web-shared"
import dayjs, { Dayjs } from "dayjs"
import { useEffect, useRef, useState } from "react"
import { create } from "zustand"

import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { calendarDates } from "../../util/util"

interface QuestBoardDatePickerStore {
  questBoardDate: Dayjs
  setQuestBoardDate: (date: Dayjs) => void
}

export const useQBDatePickerStore = create<QuestBoardDatePickerStore>(
  (set) => ({
    questBoardDate: dayjs(),
    setQuestBoardDate: (date: Dayjs) => {
      trackAnalyticEvent(ANALYTIC_EVENTS.CHANGE_QB_DATE)
      set({ questBoardDate: date })
    },
  })
)

const QuestBoardDatePicker = () => {
  const { questBoardDate, setQuestBoardDate } = useQBDatePickerStore()

  // initialize at the center of the scroll container
  const scrollContainerRef = useRef<any>(null)
  useEffect(() => {
    if (scrollContainerRef.current) {
      const centerPosition =
        (scrollContainerRef.current.scrollWidth -
          scrollContainerRef.current.offsetWidth) /
        2
      scrollContainerRef.current.scrollLeft = centerPosition + 20
    }
  }, [])

  const [isMouseDown, setIsMouseDown] = useState(false)
  const mouseCoords = useRef({ startX: 0, scrollLeft: 0 })

  const handleDragStart = (e: any) => {
    if (!scrollContainerRef.current) return
    const slider = scrollContainerRef.current
    const startX =
      (e.type.includes("mouse") ? e.pageX : e.touches[0].pageX) -
      slider.offsetLeft
    const scrollLeft = slider.scrollLeft
    mouseCoords.current = { startX, scrollLeft }
    setIsMouseDown(true)
    document.body.style.cursor = e.type.includes("mouse")
      ? "grabbing"
      : "default"
  }
  const handleDragEnd = () => {
    setIsMouseDown(false)
    if (!scrollContainerRef.current) return
    document.body.style.cursor = "default"
  }

  const handleDrag = (e: any) => {
    if (!isMouseDown || !scrollContainerRef.current) return
    const slider = scrollContainerRef.current
    const x =
      (e.type.includes("mouse") ? e.pageX : e.touches[0].pageX) -
      slider.offsetLeft
    const walkX = e.type.includes("mouse")
      ? (x - mouseCoords.current.startX) * 1.5
      : x - mouseCoords.current.startX
    slider.scrollLeft = mouseCoords.current.scrollLeft - walkX
  }

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      ref={scrollContainerRef}
      onMouseDown={handleDragStart}
      onMouseUp={handleDragEnd}
      onMouseMove={handleDrag}
      onMouseLeave={handleDragEnd}
      onTouchStart={handleDragStart}
      onTouchEnd={handleDragEnd}
      onTouchMove={handleDrag}
      style={{
        position: "relative",
        overflow: "hidden",
        overflowY: "visible",
        display: "flex",
        whiteSpace: "nowrap",
        width: "100%",
        gap: SPACING.space2,
        maxWidth: "600px",
        paddingBottom: SPACING.space2,
        maskImage:
          "linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 20px, rgba(0, 0, 0, 1) calc(100% - 20px), rgba(0, 0, 0, 0))", // Masking gradient
      }}
    >
      {calendarDates.map((date, i) => (
        <DateItem
          date={date}
          key={i}
          setSelectedDate={setQuestBoardDate}
          selectedDate={questBoardDate}
        />
      ))}
    </div>
  )
}

export default QuestBoardDatePicker

const DateItem = ({
  date,
  setSelectedDate,
  selectedDate,
}: {
  date: Dayjs
  setSelectedDate: (date: Dayjs) => void
  selectedDate: Dayjs
}) => {
  const isToday = date.format("YYYY-MM-DD") === dayjs().format("YYYY-MM-DD")
  const isSelected =
    date.format("YYYY-MM-DD") === selectedDate.format("YYYY-MM-DD")

  return (
    <button
      onClick={() => setSelectedDate(date)}
      style={{
        borderRadius: SPACING.space2,
        height: SPACING.space12,
        width: SPACING.space12,
        minHeight: SPACING.space12,
        minWidth: SPACING.space12,
        background: isSelected
          ? "white"
          : isToday
            ? JoonUIColor.background.lightBlue
            : "transparent",
        boxShadow: isSelected ? "4px 6px 6px 0px rgba(0, 0, 0, 0.08)" : "none",
        zIndex: 10,
      }}
    >
      <FlexBox direction="column" align="center" justify="center">
        <Typography variant="bodySmall">{date.format("ddd")}</Typography>
        <Typography
          variant={isSelected ? "caption" : "bodySmall"}
          style={{ lineHeight: "20px" }}
        >
          {date.format("D")}
        </Typography>
      </FlexBox>
    </button>
  )
}
