import {
  ChevronRightIcon,
  GearIcon,
  IconProps,
  JoonUIColor,
  SPACING,
  PenLineIcon,
  CommentDots,
} from "@joonapp/web-shared"

import ChildAuthCode from "../../components/childAuthCode/ChildAuthCode"
import { useAddPhoneNumberModalStore } from "../../components/modals/addPhoneNumberModal/useAddPhoneNumberModalStore"
import PageContentWrapper from "../../components/pageContentWrapper/PageContentWrapper"
import PageHeader from "../../components/pageHeader/PageHeader"
import PageWrapper from "../../components/pageWrapper/PageWrapper"
import LogoutButton from "../../components/sidebar/LogoutButton"
import { GRADIENTS } from "../../constants"
import useDocumentTitle from "../../hooks/useDocumentTitle"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { requireAuth } from "../../util/auth"
import { useRouter } from "../../util/router"

function SettingsPage() {
  useDocumentTitle("Joon - Settings")
  const router = useRouter()

  const { onOpen } = useAddPhoneNumberModalStore()

  const onClickParentResourcesGroup = () => {
    window.open("https://www.facebook.com/groups/joonadhdcommunity/", "_blank")
  }

  const onClickFeedback = () => {
    window.open(
      "https://docs.google.com/forms/d/e/1FAIpQLScol6cyUpFy6QMOCTQsHe5dnchnfAlobyeMF5arwYnnYmmdRg/viewform",
      "_blank"
    )
  }

  const onClickTextMessageSettings = () => {
    trackAnalyticEvent(ANALYTIC_EVENTS.OPEN_TEXT_MESSAGE_SETTINGS)
    onOpen()
  }

  return (
    <PageWrapper style={{ background: GRADIENTS.gradient100 }}>
      <PageHeader title="Settings" />
      <ChildAuthCode />
      <PageContentWrapper mobileGutter={true}>
        <div className="settings-nav" style={{ marginBottom: SPACING.space6 }}>
          <button
            onClick={() => router.push("/settings/account")}
            className="settings-nav-item"
          >
            <GearIcon size={18} color={JoonUIColor.semantic.primary} />
            <span>Account</span>
            <ChevronRightIcon color={JoonUIColor.semantic.primary} size={20} />
          </button>
          <button
            onClick={() => router.push("/settings/subscription")}
            className="settings-nav-item"
          >
            <DollarSignIcon size={18} color={JoonUIColor.semantic.primary} />
            <span>Subscription</span>
            <ChevronRightIcon color={JoonUIColor.semantic.primary} size={20} />
          </button>
          <button
            onClick={onClickTextMessageSettings}
            className="settings-nav-item"
          >
            <CommentDots size={18} color={JoonUIColor.semantic.primary} />
            <span>Text Message Settings</span>
            <ChevronRightIcon color={JoonUIColor.semantic.primary} size={20} />
          </button>
          <button
            onClick={onClickParentResourcesGroup}
            className="settings-nav-item"
          >
            <FaceGrinIcon size={18} color={JoonUIColor.semantic.primary} />
            <span>Joon Parenting Community</span>
            <ChevronRightIcon color={JoonUIColor.semantic.primary} size={20} />
          </button>
          <button onClick={onClickFeedback} className="settings-nav-item">
            <PenLineIcon size={18} color={JoonUIColor.semantic.primary} />
            <span>Give Us Feedback</span>
            <ChevronRightIcon color={JoonUIColor.semantic.primary} size={20} />
          </button>
        </div>
        <LogoutButton />
      </PageContentWrapper>
    </PageWrapper>
  )
}

const SettingsPageWithAuth = requireAuth(SettingsPage)
export default SettingsPageWithAuth

const DollarSignIcon = ({
  color = JoonUIColor.icon.neutral,
  size = 16,
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 17 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.19775 0.849121C9.09803 0.849121 9.82537 1.57646 9.82537 2.47673V4.29254C9.90675 4.30271 9.98304 4.31289 10.0644 4.32815C10.0848 4.33323 10.1 4.33323 10.1204 4.33832L12.5618 4.78591C13.4468 4.94867 14.0317 5.79808 13.869 6.67801C13.7062 7.55794 12.8568 8.14795 11.9769 7.98519L9.56088 7.54268C7.96887 7.30871 6.56505 7.46639 5.57831 7.85803C4.59157 8.24968 4.19484 8.78882 4.10329 9.28728C4.00156 9.83151 4.07786 10.1367 4.16432 10.3249C4.25588 10.5232 4.44407 10.747 4.81537 10.9963C5.64443 11.5405 6.91601 11.8965 8.56397 12.334L8.71147 12.3747C10.1661 12.7612 11.9464 13.2292 13.2688 14.0938C13.991 14.5669 14.6726 15.2077 15.0948 16.1029C15.5271 17.0134 15.6187 18.0306 15.4203 19.114C15.0693 21.0468 13.7367 22.3387 12.0837 23.0152C11.3869 23.3 10.629 23.4831 9.82537 23.5747V25.2633C9.82537 26.1636 9.09803 26.8909 8.19775 26.8909C7.29748 26.8909 6.57014 26.1636 6.57014 25.2633V23.4882C6.54979 23.4831 6.52436 23.4831 6.50402 23.478H6.49384C5.25279 23.2848 3.21319 22.7507 1.83989 22.1403C1.02099 21.7741 0.649695 20.8128 1.01591 19.9939C1.38212 19.175 2.34343 18.8037 3.16232 19.1699C4.22536 19.643 5.97504 20.1109 6.98722 20.2686C8.60974 20.5076 9.94744 20.3703 10.8528 19.999C11.7124 19.6481 12.104 19.1394 12.2159 18.5291C12.3126 17.9899 12.2363 17.6797 12.1498 17.4915C12.0532 17.288 11.865 17.0642 11.4886 16.815C10.6544 16.2708 9.37777 15.9147 7.72473 15.4773L7.58231 15.4417C6.13272 15.0551 4.35252 14.5821 3.03008 13.7174C2.30783 13.2444 1.63135 12.5985 1.20919 11.7033C0.781939 10.7928 0.695472 9.77556 0.898924 8.69218C1.26514 6.74922 2.71982 5.48782 4.37286 4.83169C5.04934 4.56211 5.79194 4.37901 6.57014 4.2722V2.47673C6.57014 1.57646 7.29748 0.849121 8.19775 0.849121Z"
        fill={color}
      />
    </svg>
  )
}

const FaceGrinIcon = ({
  color = JoonUIColor.icon.neutral,
  size = 16,
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0834 26.4251C16.5368 26.4251 19.8487 25.0533 22.2906 22.6114C24.7325 20.1695 26.1043 16.8576 26.1043 13.4042C26.1043 9.95085 24.7325 6.63893 22.2906 4.19704C19.8487 1.75514 16.5368 0.383301 13.0834 0.383301C9.63005 0.383301 6.31813 1.75514 3.87624 4.19704C1.43434 6.63893 0.0625 9.95085 0.0625 13.4042C0.0625 16.8576 1.43434 20.1695 3.87624 22.6114C6.31813 25.0533 9.63005 26.4251 13.0834 26.4251ZM19.8024 16.2932C20.428 16.0999 21.0384 16.6442 20.7841 17.2444C19.5125 20.2504 16.5523 22.3561 13.0987 22.3561C9.64507 22.3561 6.67977 20.2504 5.41328 17.2444C5.15896 16.6442 5.76932 16.0999 6.39493 16.2932C8.41419 16.9137 10.6929 17.2596 13.0987 17.2596C15.5045 17.2596 17.7831 16.9137 19.8024 16.2932ZM7.40711 10.9628C7.40711 10.5311 7.57859 10.1171 7.88382 9.81189C8.18906 9.50666 8.60305 9.33518 9.03472 9.33518C9.46639 9.33518 9.88038 9.50666 10.1856 9.81189C10.4909 10.1171 10.6623 10.5311 10.6623 10.9628C10.6623 11.3945 10.4909 11.8084 10.1856 12.1137C9.88038 12.4189 9.46639 12.5904 9.03472 12.5904C8.60305 12.5904 8.18906 12.4189 7.88382 12.1137C7.57859 11.8084 7.40711 11.3945 7.40711 10.9628ZM17.1728 9.33518C17.6045 9.33518 18.0184 9.50666 18.3237 9.81189C18.6289 10.1171 18.8004 10.5311 18.8004 10.9628C18.8004 11.3945 18.6289 11.8084 18.3237 12.1137C18.0184 12.4189 17.6045 12.5904 17.1728 12.5904C16.7411 12.5904 16.3271 12.4189 16.0219 12.1137C15.7167 11.8084 15.5452 11.3945 15.5452 10.9628C15.5452 10.5311 15.7167 10.1171 16.0219 9.81189C16.3271 9.50666 16.7411 9.33518 17.1728 9.33518Z"
        fill={color}
      />
    </svg>
  )
}
