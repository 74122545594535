import { FlexBox, JoonUIColor, Loader } from "@joonapp/web-shared"

const CreatingAccount = () => {
  return (
    <FlexBox
      direction="column"
      wrap={false}
      justify="center"
      align="center"
      style={{ height: "80dvh" }}
    >
      <img
        style={{ height: "150px" }}
        src={"/images/chibi_polair.svg"}
        alt="polair"
      />
      <div className="page-title mb-24">Creating your Account!</div>
      <Loader color={JoonUIColor.background.accent} size={32} thickness={4} />
    </FlexBox>
  )
}

export default CreatingAccount
