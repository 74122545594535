import { FlexBox, JoonUIColor, SPACING, Typography } from "@joonapp/web-shared"
import dayjs from "dayjs"

import { useRedeemedRewardsModal } from "./RedeemedRewards"
import RewardRedeemButton from "./RewardRedeemButton"
import Card from "../../components/card/Card"
import { usePurchasedRewardsQuery } from "../../networking/queries"
import { RewardPurchase } from "../../types"

const RewardsRedeem = () => {
  const { data: purchasedRewards, status: purchasedRewardsStatus } =
    usePurchasedRewardsQuery({ hasRedeemed: false })
  const { data: redeemedRewards, status: redeemedRewardsStatus } =
    usePurchasedRewardsQuery({ hasRedeemed: true })
  const { onOpen } = useRedeemedRewardsModal()

  const isLoading =
    purchasedRewardsStatus === "loading" || redeemedRewardsStatus === "loading"

  return (
    <div style={{ paddingTop: SPACING.space3 }}>
      {isLoading ? (
        <></>
      ) : purchasedRewards?.length === 0 ? (
        <FlexBox justify="center" style={{ margin: "auto" }}>
          <Typography
            variant="caption"
            textAlign="center"
            style={{
              width: "min(380px, 95vw",
              background: "white",
              padding: SPACING.space6,
              borderRadius: SPACING.space2,
            }}
          >
            Your children have not purchased any rewards yet. They can purchase
            rewards by talking to your avatar in the game!
          </Typography>
        </FlexBox>
      ) : (
        <FlexBox direction="column" gap={SPACING.space2}>
          <Typography variant="bodyXSmall">
            The following items have been purchased by your kids. redeem them
            when you're ready to reward your kids in real life!
          </Typography>
          <FlexBox direction="column" gap={SPACING.space1}>
            {purchasedRewards?.map((purchase: RewardPurchase, i: number) => (
              <Card
                title={`${purchase.reward.emoji} ${purchase.reward.title}`}
                subtitle={`Purchased on ${dayjs(purchase.purchase_date).format("MMM D, YYYY")}`}
                childIds={[purchase.user_id]}
                coinAmount={purchase.reward.cost}
                key={i}
                buttonComponent={<RewardRedeemButton purchase={purchase} />}
              />
            ))}
          </FlexBox>
        </FlexBox>
      )}
      {!isLoading && redeemedRewards && redeemedRewards.length > 0 && (
        <button
          style={{ marginTop: SPACING.space6, width: "100%" }}
          onClick={onOpen}
        >
          <Typography
            variant="bodyBold"
            textAlign="center"
            color={JoonUIColor.semantic.primary}
          >
            View previously redeemed rewards
          </Typography>
        </button>
      )}
    </div>
  )
}

export default RewardsRedeem
