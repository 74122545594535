import {
  FlexBox,
  JoonUIColor,
  SPACING,
  TextArea,
  Typography,
} from "@joonapp/web-shared"

import TextArrowButton from "../../../components/coaching/TextArrowButton"
import PageLoader from "../../../components/loading/PageLoader"
import { CoachingStep } from "../../../constants"
import {
  useCoachPostSelectionQuestionnaireQuery,
  useUserQuery,
} from "../../../networking/queries"
import {
  QuestionAnswerMappingInterface,
  QuestionInterface,
  QuestionnairesInterface,
} from "../../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"
import {
  cohorts,
  experimentNames,
  useGetExperimentCohort,
} from "../../../util/experiments"
import SmallCoachCard from "../SmallCoachCard"
import { useCoachingStore } from "../useCoachingStore"

interface PostSelectionQuestionnaireProps {
  onConfirm: (
    parentId: number,
    coachQuestionnaire: QuestionnairesInterface,
    questionAnswerMapping: QuestionAnswerMappingInterface
  ) => void
}

const PostSelectionQuestionnaire = ({
  onConfirm,
}: PostSelectionQuestionnaireProps) => {
  const {
    selectedCoach,
    setStep,
    postSelectionQuestionAnswerMapping,
    setPostSelectionQuestionAnswerMapping,
  } = useCoachingStore()
  const { user } = useUserQuery()
  const { data: questionnaire, isLoading } =
    useCoachPostSelectionQuestionnaireQuery()
  const variant = useGetExperimentCohort(
    experimentNames.webParentVirtualCoachOnboarding
  )

  const questions: QuestionInterface[] | [] = questionnaire?.questions || []

  const onSubmitFreeformAnswerClick = () => {
    trackAnalyticEvent(ANALYTIC_EVENTS.PERSONALIZED_COACH_POST_SELECTION_SUBMIT)
    trackAnalyticEvent(ANALYTIC_EVENTS.PERSONALIZED_COACH_CONTINUE_CLICK, {
      step: CoachingStep.POST_SELECTION,
    })
    if (user && questionnaire)
      onConfirm(user.id, questionnaire, postSelectionQuestionAnswerMapping)
    if (variant === cohorts.variantD) setStep(CoachingStep.SELECT_PLAN)
    else setStep(CoachingStep.CALENDLY)
  }

  if (isLoading || !selectedCoach) {
    return <PageLoader />
  }

  return (
    <FlexBox
      justify="flex-start"
      align="center"
      direction="column"
      gap={SPACING.space6}
      style={{
        minHeight: "70vh",
        width: "min(450px, 90vw)",
        paddingTop: "20px",
      }}
    >
      <Typography variant="bodyBold" color={JoonUIColor.text.primary}>
        Great choice, you're one step away from meeting your coach.
      </Typography>
      <SmallCoachCard coachCardContent={selectedCoach} />
      <Typography variant="body" color={JoonUIColor.text.primary}>
        To ensure a productive first session, {selectedCoach.user.name} would
        like you to answer the following question:
      </Typography>
      <FlexBox
        key={questions[0].id}
        direction="column"
        align="flex-start"
        gap={SPACING.space3}
        fullWidth
        style={{ marginBottom: SPACING.space4 }}
      >
        <Typography variant="h2" color={JoonUIColor.text.primary}>
          {questions[0].question}
        </Typography>
        <TextArea
          placeholder="Type your answer..."
          value={
            postSelectionQuestionAnswerMapping[questions[0].id]?.toString() ||
            ""
          }
          onChange={(e) =>
            setPostSelectionQuestionAnswerMapping({
              [questions[0].id]: e.target.value,
            })
          }
          name="freeformAnswer"
          maxLength={255}
          showCounter={true}
          fullWidth
        />
      </FlexBox>
      <TextArrowButton
        text="Next"
        onClick={onSubmitFreeformAnswerClick}
        disabled={
          !postSelectionQuestionAnswerMapping[questions[0].id] ||
          postSelectionQuestionAnswerMapping[questions[0].id]?.toString()
            .length === 0
        }
        style={{
          width: "min(450px, calc(98% - 40px))",
          position: "fixed",
          left: "50%",
          transform: "translateX(-50%)",
          bottom: "7dvh",
        }}
      />
    </FlexBox>
  )
}

export default PostSelectionQuestionnaire
