import {
  CalendarSolidIcon,
  GiftIcon,
  HandHoldingHeartIcon,
  JoonUIColor,
  SPACING,
  UserIcon,
  UsersIcon,
} from "@joonapp/web-shared"

import MobileNavLink from "./MobileNavLink"
import { useFamilyQuery } from "../../networking/queries"
import useCareTeam from "../../pages/care/useCareTeam"
import useCoachStore from "../../pages/coach/useCoachStore"
import { FamilyQuerySelect } from "../../types"
import {
  cohorts,
  experimentNames,
  useGetExperimentCohort,
} from "../../util/experiments"

const MobileNav = () => {
  const { isFocused } = useCoachStore()
  const { careTeam, hasTherapist } = useCareTeam()
  const { data: children, isLoading: isLoadingChildren } = useFamilyQuery(
    FamilyQuerySelect.CHILDREN
  )

  const notLinkedOrHasOnboardedChildren =
    !hasTherapist || children?.find((child) => child?.user?.active_doter?.type)

  const variant = useGetExperimentCohort(
    experimentNames.webParentVirtualCoachOnboarding
  )

  const userIsTypingMessageToCoach = isFocused

  if (
    (careTeam?.length && isLoadingChildren) ||
    variant === null ||
    userIsTypingMessageToCoach
  )
    return <></>

  return (
    <div
      style={{
        background: JoonUIColor.background.primaryNeutral,
        position: "fixed",
        bottom: 0,
        width: "100vw",
        padding: `0 ${SPACING.space4}`,
        height: "70px",
        borderTop: `1px solid ${JoonUIColor.border.default}`,
        zIndex: "500",
        display: "flex",
        justifyContent: "space-around",
        boxShadow: "2px 0px 30px 0px rgba(36, 23, 185, 0.25)",
      }}
    >
      {notLinkedOrHasOnboardedChildren && (
        <MobileNavLink to="/me" name="Me" Icon={UserIcon} />
      )}
      {hasTherapist && (
        <MobileNavLink to="/care" name="Care" Icon={HandHoldingHeartIcon} />
      )}
      {!hasTherapist && (careTeam?.length || variant !== cohorts.control) && (
        <MobileNavLink to="/coach" name="Coach" Icon={HandHoldingHeartIcon} />
      )}
      <MobileNavLink to="/quests" name="Quests" Icon={CalendarSolidIcon} />
      {notLinkedOrHasOnboardedChildren && (
        <MobileNavLink to="/rewards" name="Rewards" Icon={GiftIcon} />
      )}
      <MobileNavLink to="/family" name="Family" Icon={UsersIcon} />
    </div>
  )
}

export default MobileNav
