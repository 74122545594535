import { Checkbox, SPACING, Shadow } from "@joonapp/web-shared"
import React from "react"

export interface CheckboxProps {
  id?: string
  name: string
  selected: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  label?: string
  disabled?: boolean
  fullWidth?: boolean
}

const QuestionnaireCheckbox: React.FC<CheckboxProps> = ({
  name,
  selected,
  onChange,
  label,
  disabled = false,
}) => (
  <Checkbox
    id={name}
    name={name}
    selected={selected}
    onChange={onChange}
    disabled={disabled}
    label={label}
    fullWidth
    style={{
      height: SPACING.space10,
      borderRadius: SPACING.space4,
      boxShadow: Shadow.high,
      background: "white",
    }}
  />
)

export default QuestionnaireCheckbox
