import {
  ModuleType,
  MultipleChoiceLearningModuleAssignmentCompletion,
  ParentTrainingStatus,
  ShortAnswerLearningModuleAssignmentCompletion,
} from "@joonapp/web-shared"
import { useCallback, useEffect, useMemo } from "react"

import useTrainingStore from "./useTrainingStore"
import { getModuleTheme } from "./utils"
import { useTrainingsQuery } from "../../../networking/queries"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"

const useTraining = (trainingId?: number) => {
  const { data: trainingsIP } = useTrainingsQuery()
  const { data: trainingsMastered } = useTrainingsQuery(
    ParentTrainingStatus.MASTERED
  )
  const { selectedTrainingId, moduleIndex, onClose } = useTrainingStore()

  const currentTraining =
    trainingsIP?.find(
      (training) => training.id === (selectedTrainingId || trainingId)
    ) ||
    trainingsMastered?.find(
      (training) => training.id === (selectedTrainingId || trainingId)
    )

  const totalModules = currentTraining?.assignments.length
  const currentAssignment = currentTraining?.assignments?.[moduleIndex]
  const currentCompletion = currentAssignment?.assignment_completion

  const currentModule = currentAssignment?.learning_module
  const isLastModule = totalModules && moduleIndex === totalModules - 1
  const nextAssignment = useMemo(() => {
    if (isLastModule) return null
    return currentTraining?.assignments?.[moduleIndex + 1]
  }, [isLastModule, moduleIndex, currentTraining])

  const remainingModules = useMemo(() => {
    return currentTraining?.assignments?.filter((assignment) => {
      if (assignment.learning_module.type === ModuleType.MULTIPLE_CHOICE) {
        const completion =
          assignment.assignment_completion as MultipleChoiceLearningModuleAssignmentCompletion
        const numberOfAnswers = completion.multiple_choice_answers.length
        const numberOfQuestions =
          assignment.learning_module.multiple_choice_questions.length
        if (numberOfAnswers !== numberOfQuestions) return true
      } else if (assignment.learning_module.type === ModuleType.SHORT_ANSWERS) {
        const completion =
          assignment.assignment_completion as ShortAnswerLearningModuleAssignmentCompletion
        const numberOfAnswers = completion.short_answer_answers.length
        const numberOfQuestions =
          assignment.learning_module.short_answer_questions.length
        if (numberOfAnswers !== numberOfQuestions) return true
      }

      return !assignment.assignment_completion.date_completed
    }).length
  }, [currentTraining])

  const completionPercentage = useMemo(() => {
    const totalAssignments = currentTraining?.assignments.length
    if (!totalAssignments) return 0
    if (!currentTraining) return 0
    if (remainingModules === undefined) return 0
    return ((totalAssignments - remainingModules) / totalAssignments) * 100
  }, [currentTraining, remainingModules])

  const getButtonText = useCallback(
    ({ isLastPageInModule }: { isLastPageInModule: boolean }) => {
      if (!isLastPageInModule) return "Next"
      if (isLastModule) return "Finish"
      if (!nextAssignment) throw new Error("Next assignment is not defined")
      const { moduleText } = getModuleTheme(nextAssignment.learning_module.type)
      return `Next: ${moduleText}`
    },
    [isLastModule, nextAssignment]
  )

  useEffect(() => {
    if (moduleIndex === totalModules) {
      onClose()
      trackAnalyticEvent(ANALYTIC_EVENTS.FINISH_CURRENT_LEARNING_MODULES)
    }
  }, [moduleIndex, totalModules, onClose])

  return {
    currentTraining,
    currentAssignment,
    totalModules,
    currentModule,
    currentCompletion,
    isLastModule,
    nextAssignment,
    remainingModules,
    completionPercentage,
    getButtonText,
  }
}

export default useTraining
