import { QuestInstance } from "@joonapp/web-shared"
import { create } from "zustand"

interface ViewQuestModalStore {
  isOpen: boolean
  onOpen: (questInstance: QuestInstance) => void
  onClose: () => void

  questInstance: QuestInstance | null
  setQuestGroup: (quest: QuestInstance) => void
}

export const useViewQuestModalStore = create<ViewQuestModalStore>((set) => ({
  isOpen: false,
  onOpen: (questInstance) => set({ isOpen: true, questInstance }),
  onClose: () => set({ isOpen: false }),

  questInstance: null,
  setQuestGroup: (questInstance: QuestInstance) => set({ questInstance }),
}))
