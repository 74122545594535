import { SPACING, Typography } from "@joonapp/web-shared"
import { useEffect, useState } from "react"

import useOnboardContext from "../../hooks/useOnboardContext"
import LinearProgress from "../linearProgress/LinearProgress"

function CreatingPlan() {
  const [progress, setProgress] = useState(0)
  const { setCreatingPlan } = useOnboardContext()

  const timerLength = 6000
  const maxPercent = 100
  const numIntervals = 60

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === maxPercent) return maxPercent
        const step = maxPercent / numIntervals
        return Math.min(oldProgress + step, maxPercent)
      })
    }, timerLength / numIntervals)
    const timer = setTimeout(() => setCreatingPlan(false), timerLength)

    return () => {
      clearInterval(interval)
      clearTimeout(timer)
    }
  }, [setCreatingPlan])

  return (
    <div className="onboard-section-centered">
      <Typography
        variant="h3"
        textAlign="center"
        style={{ marginBottom: SPACING.space4 }}
      >
        {progress < 30
          ? "Analyzing Your Survey Responses"
          : progress < 65
            ? "Creating a personalized plan for your family"
            : "Finalizing the key focus areas for your child"}
      </Typography>
      <div className="progress-bar">
        <LinearProgress value={progress} />
      </div>
    </div>
  )
}

export default CreatingPlan
