import { FlexBox, SPACING } from "@joonapp/web-shared"
import { useRef, useEffect, useCallback, useState } from "react"

import { useCoachingStore } from "../../useCoachingStore"

const PlanSlider = () => {
  const { planIndex, setPlanIndex } = useCoachingStore()
  const sliderRef = useRef<HTMLDivElement>(null)
  const isDraggingRef = useRef(false)
  const [visualPosition, setVisualPosition] = useState(planIndex)
  const debounceTimerRef = useRef<NodeJS.Timeout>()
  const positions = [0, 1, 2, 3]
  const indexToPlanMap: Record<number, number> = {
    0: 0,
    1: 1,
    2: 2,
    3: 4,
  }

  const calculatePosition = useCallback((clientX: number) => {
    if (!sliderRef.current) return null
    const rect = sliderRef.current.getBoundingClientRect()
    const relativeX = clientX - rect.left
    const width = rect.width
    const sectionWidth = width / 4
    const newIndex = Math.min(
      3,
      Math.max(0, Math.floor(relativeX / sectionWidth))
    )

    return newIndex
  }, [])

  const handleTrackClick = useCallback(
    (
      e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>
    ) => {
      e.preventDefault()
      const clientX =
        "touches" in e
          ? e.touches.length > 0
            ? e.touches[0].clientX
            : e.changedTouches[0].clientX
          : e.clientX
      if (!clientX) return
      const newIndex = calculatePosition(clientX)
      if (newIndex !== null && newIndex !== planIndex) {
        setPlanIndex(newIndex)
      }
    },
    [calculatePosition, setPlanIndex, planIndex]
  )

  const handleDragStart = useCallback(() => {
    isDraggingRef.current = true
  }, [])

  const handleDrag = useCallback(
    (e: MouseEvent | TouchEvent) => {
      if (!isDraggingRef.current) return

      const clientX = "touches" in e ? e.touches[0].clientX : e.clientX
      const newIndex = calculatePosition(clientX)

      if (newIndex !== null) {
        // Update visual position immediately
        setVisualPosition(newIndex)

        // Debounce the actual state update
        if (debounceTimerRef.current) {
          clearTimeout(debounceTimerRef.current)
        }

        debounceTimerRef.current = setTimeout(() => {
          setPlanIndex(newIndex)
        }, 32)
      }
    },
    [calculatePosition, setPlanIndex]
  )

  const handleDragEnd = useCallback(() => {
    isDraggingRef.current = false
    setPlanIndex(planIndex)
  }, [setPlanIndex, planIndex])

  useEffect(() => {
    const handleDragEvents = (e: MouseEvent | TouchEvent) => {
      if (isDraggingRef.current) {
        handleDrag(e)
      }
    }

    window.addEventListener("mousemove", handleDragEvents)
    window.addEventListener("touchmove", handleDragEvents)
    window.addEventListener("mouseup", handleDragEnd)
    window.addEventListener("touchend", handleDragEnd)

    return () => {
      window.removeEventListener("mousemove", handleDragEvents)
      window.removeEventListener("touchmove", handleDragEvents)
      window.removeEventListener("mouseup", handleDragEnd)
      window.removeEventListener("touchend", handleDragEnd)
    }
  }, [handleDrag, handleDragEnd])

  useEffect(() => {
    return () => {
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current)
      }
    }
  }, [])

  useEffect(() => {
    setVisualPosition(planIndex)
  }, [planIndex])

  return (
    <FlexBox
      gap={SPACING.space2}
      style={{
        width: "100%",
        padding: "16px 8px 4px 8px",
        borderRadius: 8,
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        userSelect: "none",
      }}
    >
      <div
        ref={sliderRef}
        role="presentation"
        onClick={handleTrackClick}
        onTouchStart={(e) => e.stopPropagation()}
        onTouchEnd={handleTrackClick}
        style={{
          position: "relative",
          width: "100%",
          display: "flex",
          alignItems: "center",
          cursor: isDraggingRef.current ? "grabbing" : "pointer",
          padding: "0",
          userSelect: "none",
        }}
      >
        <div
          role="slider"
          tabIndex={0}
          aria-valuemin={0}
          aria-valuemax={3}
          aria-valuenow={planIndex}
          onMouseDown={handleDragStart}
          onMouseUp={handleDragEnd}
          onTouchStart={handleDragStart}
          onTouchEnd={handleDragEnd}
          style={{
            position: "absolute",
            left: `calc(${(visualPosition * 100) / 3}% + ${
              visualPosition === 0
                ? 18
                : visualPosition === 1
                  ? 6
                  : visualPosition === 2
                    ? -6
                    : -18
            }px)`,
            transform: "translateX(-50%)",
            transition: isDraggingRef.current
              ? "none"
              : "left 0.2s ease-in-out",
            width: 16,
            height: 16,
            background: "#5E65E9",
            boxShadow: isDraggingRef.current
              ? "3px 3px 4px rgba(0, 0, 0, 0.12)"
              : "2px 2px 2px rgba(0, 0, 0, 0.08)",
            borderRadius: "50%",
            border: "2px white solid",
            cursor: isDraggingRef.current ? "grabbing" : "grab",
            zIndex: 2,
            touchAction: "none",
          }}
        />
        <div
          style={{
            width: "100%",
            height: 8,
            background: "#BBBEF5",
            borderRadius: 16,
          }}
        />
      </div>
      <FlexBox
        direction="row"
        justify="space-between"
        align="center"
        fullWidth
        style={{ padding: "0 12px" }}
      >
        {positions.map((index) => (
          <button
            key={index}
            onClick={() => setPlanIndex(index)}
            onTouchStart={(e) => e.stopPropagation()}
            onTouchEnd={(e) => {
              e.preventDefault()
              setPlanIndex(index)
            }}
            tabIndex={0}
            aria-label={`Set frequency to ${index}x`}
            style={{
              color: planIndex === index ? "#5E65E9" : "black",
              fontSize: 20,
              fontFamily: "Caveat",
              fontWeight: "700",
              cursor: "pointer",
              transition: "color 0.2s ease-in-out",
              background: "none",
              border: "none",
              padding: 0,
            }}
          >
            {indexToPlanMap[index]}x
          </button>
        ))}
      </FlexBox>
    </FlexBox>
  )
}

export default PlanSlider
