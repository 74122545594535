import { FlexBox, JoonColorExpanded, JoonUIColor } from "@joonapp/web-shared"
import { useEffect, useRef } from "react"

import CoachingFlow from "./coachingFlow/CoachingFlow"
import TrainingFlow from "./trainingFlow/TrainingFlow"
import { useCoachingStore } from "./useCoachingStore"
import { CoachingStep } from "../../constants"
import {
  cohorts,
  experimentNames,
  useGetExperimentCohort,
} from "../../util/experiments"

const CoachingPage = () => {
  const variant = useGetExperimentCohort(
    experimentNames.webParentVirtualCoachOnboarding
  )
  const { step, questionnaireStep } = useCoachingStore()
  const pageContainerRef = useRef<HTMLDivElement>(null)

  const shouldUseWhiteBackground =
    variant !== cohorts.variantD ||
    step < CoachingStep.SELECT_PLAN ||
    step === CoachingStep.SCHEDULED

  const coachingFlowVariants =
    variant === cohorts.variantA || variant === cohorts.variantD
  const trainingFlowVariants =
    variant === cohorts.variantB || variant === cohorts.variantC

  useEffect(() => {
    pageContainerRef.current?.scrollTo(0, 0)
  }, [step, questionnaireStep])

  return (
    <FlexBox
      ref={pageContainerRef}
      direction="row"
      justify="center"
      wrap={false}
      style={{
        flexGrow: 1,
        overflowX: "hidden",
        overflowY: "auto",
        minHeight: "100dvh",
        maxHeight: "100dvh",
        textAlign: "center",
        opacity: variant === null ? 0 : 1,
        transition: "opacity 0.2s ease",
        backgroundColor: shouldUseWhiteBackground
          ? JoonUIColor.background.primaryNeutral
          : JoonColorExpanded.yellow100,
      }}
    >
      {coachingFlowVariants && <CoachingFlow />}
      {trainingFlowVariants && <TrainingFlow />}
    </FlexBox>
  )
}

export default CoachingPage
