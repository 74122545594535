import { ParentAvatar } from "@joonapp/web-shared"
import { create } from "zustand"

interface SelectAvatarModalStore {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  selectedAvatar: ParentAvatar | null
  setSelectedAvatar: (avatar: ParentAvatar) => void
}

export const useSelectAvatarModalStore = create<SelectAvatarModalStore>(
  (set) => ({
    isOpen: false,
    onOpen: () => set({ isOpen: true }),
    onClose: () => set({ isOpen: false }),
    selectedAvatar: null,
    setSelectedAvatar: (selectedAvatar) => set({ selectedAvatar }),
  })
)
