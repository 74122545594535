import TextArrowButton from "../../../components/coaching/TextArrowButton"
import { CoachingStep } from "../../../constants"
import { useCoachingStore } from "../useCoachingStore"

const PostAnswerScreen = () => {
  const { setStep, postAnswerScreenInfo } = useCoachingStore()
  const { background_color_hex, background_image_url, button_title } =
    postAnswerScreenInfo

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: "100vw",
        height: "100dvh",
        backgroundColor: background_color_hex,
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img
        src={background_image_url}
        alt="Background"
        style={{
          width: "100%",
          objectFit: "contain",
          objectPosition: "center",
          maxWidth: "100vw",
          maxHeight: "100dvh",
        }}
      />
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <TextArrowButton
          text={button_title}
          onClick={() => setStep(CoachingStep.INTAKE_FORM)}
          disabled={false}
          style={{
            width: "min(450px, calc(98% - 40px))",
            position: "fixed",
            left: "50%",
            transform: "translateX(-50%)",
            bottom: "7dvh",
          }}
        />
      </div>
    </div>
  )
}

export default PostAnswerScreen
