import {
  ChevronRightIcon,
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"

import Shine from "../../components/coaching/Shine"
import useMobile from "../../hooks/useMobile"
import { useRouter } from "../../util/router"

const CareMarketingCard = () => {
  const isMobile = useMobile()
  const router = useRouter()

  const onReadyButtonClick = () => {
    router.push("/coach")
  }

  return (
    <FlexBox
      direction="column"
      align="center"
      wrap={false}
      style={{
        borderRadius: SPACING.space2,
        boxShadow: "4px 6px 6px 0px rgba(0, 0, 0, 0.08)",
        background: JoonUIColor.background.primaryNeutral,
        position: "relative",
        padding: SPACING.space4,
        width: "min(600px, 99%)",
      }}
      gap={SPACING.space4}
    >
      <FlexBox direction="column" align="center" gap={SPACING.space4}>
        <Typography
          variant="bodyBold"
          textAlign="center"
          style={{ width: "75%" }}
        >
          Get a coach who gets you
        </Typography>
        <Typography
          variant="bodySmall"
          textAlign="center"
          style={{ width: "min(400px, 85%)" }}
        >
          Connect 1:1 with a coach, right here in the app and{" "}
          <Typography variant="caption">
            get support like never before
          </Typography>
        </Typography>
        <img
          src={`/images/guidePhotos/coach_main_headline.svg`}
          style={{ width: "min(350px, 80%)" }}
          alt="<3"
        />
      </FlexBox>

      <button
        style={{
          position: "relative",
          width: "min(300px, 90%)",
          padding: 0,
        }}
        onClick={onReadyButtonClick}
      >
        <FlexBox
          direction="row"
          align="center"
          justify="space-between"
          wrap={false}
          style={{
            background: JoonColorExpanded.indigo400,
            borderRadius: SPACING.space2,
            padding: `${SPACING.space2} ${SPACING.space1} ${SPACING.space2} ${SPACING.space3}`,
            marginTop: SPACING.space4,
            boxShadow: "4px 4px 6px rgba(0, 0, 0, 0.1)",
            overflow: "hidden",
          }}
        >
          <Typography
            variant={isMobile ? "caption" : "bodyBold"}
            color={JoonUIColor.text.inverted}
            style={{ lineHeight: "1.4" }}
          >
            Learn more about Joon Coaching
          </Typography>

          <div style={{ minWidth: "30px" }}>
            <ChevronRightIcon color={JoonUIColor.icon.inverted} size={24} />
          </div>
        </FlexBox>
        <Shine />
      </button>
    </FlexBox>
  )
}

export default CareMarketingCard
