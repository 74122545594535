import { QuestStatus, QuestType } from "@joonapp/web-shared"
import toast from "react-hot-toast"

import { useQBDatePickerStore } from "./QuestBoardDatePicker"
import useQuestActions from "./useQuestActions"
import useQuestBoardFilters from "./useQuestBoardFilters"
import useQuestBoardMultiSelectStore from "./useQuestBoardMultiSelectStore"
import { useSubscription } from "../../hooks/useSubscription"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"

const useQuestBoardMultiSelect = () => {
  const { selectedQuestIds, clearSelectedQuestIds } =
    useQuestBoardMultiSelectStore()
  const { filteredQuests } = useQuestBoardFilters()
  const {
    useCompleteQuestsMutation,
    useRejectQuestsMutaton,
    useSkipQuestsMutation,
  } = useQuestActions()
  const { questBoardDate } = useQBDatePickerStore()

  const { checkRemainingFreeQuests } = useSubscription()

  const onClickCompleteQuests = () => {
    if (selectedQuestIds.length === 0) return
    checkRemainingFreeQuests(selectedQuestIds.length)
    useCompleteQuestsMutation.mutateAsync({
      questInstanceIds: selectedQuestIds,
      status: QuestStatus.VERIFIED,
      questDate: questBoardDate.format("YYYY-MM-DD"),
    })
    clearSelectedQuestIds()
    trackAnalyticEvent(ANALYTIC_EVENTS.COMPLETE_MULTIPLE_QUESTS, {
      count: selectedQuestIds.length,
      source: "quest_board",
    })
  }

  const onClickRejectQuests = () => {
    if (selectedQuestIds.length === 0) return
    useRejectQuestsMutaton.mutate({
      questInstanceIds: selectedQuestIds,
      status: QuestStatus.REJECTED,
      questDate: questBoardDate.format("YYYY-MM-DD"),
    })
    clearSelectedQuestIds()
    trackAnalyticEvent(ANALYTIC_EVENTS.REJECT_MULTIPLE_QUESTS, {
      count: selectedQuestIds.length,
      source: "quest_board",
    })
  }

  const onClickSkipQuests = () => {
    if (selectedQuestIds.length === 0) return

    // If one of the quests is a non-repeating quest,
    // Then we can not skip this
    const hasNonRepeatingQuest = filteredQuests.find(
      (quest) =>
        selectedQuestIds.includes(quest.id) &&
        quest.series.type !== QuestType.REPEATING
    )
    if (hasNonRepeatingQuest) {
      toast(
        "You can not skip a one-off Quest. To remove this Quest, mark it as Rejected or delete it!"
      )
      return
    }

    useSkipQuestsMutation.mutate({
      questInstanceIds: selectedQuestIds,
      status: QuestStatus.SKIPPED,
      questDate: questBoardDate.format("YYYY-MM-DD"),
    })
    clearSelectedQuestIds()
    trackAnalyticEvent(ANALYTIC_EVENTS.SKIP_MULTIPLE_QUESTS, {
      count: selectedQuestIds.length,
      source: "quest_board",
    })
  }

  return { onClickCompleteQuests, onClickRejectQuests, onClickSkipQuests }
}

export default useQuestBoardMultiSelect
