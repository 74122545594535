import useIncidentReportStore from "./useIncidentReportStore"
import Card from "../../../components/card/Card"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"
import { ArrowCircleRightIcon } from "../../academy/AcademyActions"

const ReportIncidentButton = () => {
  const { onOpen } = useIncidentReportStore()

  return (
    <Card
      title="Report child behavior incident"
      onClick={(e) => {
        e.stopPropagation()
        onOpen()
        trackAnalyticEvent(ANALYTIC_EVENTS.INITIATE_LOG_INCIDENT)
      }}
      buttonComponent={<ArrowCircleRightIcon />}
    />
  )
}

export default ReportIncidentButton
