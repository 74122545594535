import { ChildUser } from "@joonapp/web-shared"
import { create } from "zustand"

import { FamilyMail, FamilyMailboxTab } from "../../../types"

interface ViewMessageModalStore {
  isOpen: boolean
  onOpen: (mail: FamilyMail) => void
  onClose: () => void
  child: ChildUser | undefined
  setChild: (child: ChildUser) => void
  mail: FamilyMail | undefined
  tab: FamilyMailboxTab
  setTab: (tab: FamilyMailboxTab) => void
}

export const useViewMessageModalStore = create<ViewMessageModalStore>(
  (set) => ({
    isOpen: false,
    onOpen: (mail) => set({ isOpen: true, mail }),
    onClose: () => set({ isOpen: false }),
    child: undefined,
    setChild: (child) => set({ child }),
    mail: undefined,
    tab: FamilyMailboxTab.RECEIVED,
    setTab: (tab) => set({ tab }),
  })
)
