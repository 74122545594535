import { create } from "zustand"

interface QuestReviewModalStore {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void

  selectedQuestIds: number[]
  setSelectedQuestIds: (ids: number[]) => void
}

const useQuestReviewStore = create<QuestReviewModalStore>((set) => ({
  isOpen: false,
  onOpen: () => set({ isOpen: true }),
  onClose: () => set({ isOpen: false }),

  selectedQuestIds: [],
  setSelectedQuestIds: (ids) => set({ selectedQuestIds: ids }),
}))

export default useQuestReviewStore
