import {
  Button,
  Checkbox,
  FlexBox,
  JoonUIColor,
  parseLineBreaks,
  ReadingTextModule,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { useEffect, useState } from "react"

import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../../util/analytics"
import TrainingProgressionBar from "../TrainingProgressionBar"
import useAssignmentCompletionMutation from "../useAssignmentCompletionMutation"
import useTraining from "../useTraining"
import useTrainingStore from "../useTrainingStore"
import { getModuleTheme } from "../utils"

const ReadingModule = () => {
  const { currentCompletion, currentModule, getButtonText } = useTraining()
  const [hasVerifiedCompletion, setHasVerifiedCompletion] = useState(
    !!currentCompletion && !!currentCompletion.date_completed
  )
  const [currentPage, setCurrentPage] = useState(0)
  const { incrementModuleIndex } = useTrainingStore()
  const assignmentCompletionMutation = useAssignmentCompletionMutation()

  useEffect(() => {
    setHasVerifiedCompletion(
      !!currentCompletion && !!currentCompletion.date_completed
    )
    setCurrentPage(0)
  }, [currentCompletion])

  const learningModule = currentModule as ReadingTextModule | undefined
  if (!learningModule) return null

  const { completionVerificationText } = getModuleTheme(learningModule.type)
  const isLastPage = currentPage === learningModule.reading_texts.length - 1

  const buttonText = getButtonText({ isLastPageInModule: isLastPage })

  const onClickNext = () => {
    if (currentPage === learningModule.reading_texts.length - 1) {
      if (!currentCompletion) return
      if (!hasVerifiedCompletion) return
      incrementModuleIndex()
      assignmentCompletionMutation.mutate({
        assignmentCompletionId: currentCompletion.id,
      })
    } else {
      setCurrentPage((prev) => prev + 1)
    }
    trackAnalyticEvent(ANALYTIC_EVENTS.COMPLETE_BASIC_LEARNING_ASSIGNMENT)
  }
  const currentPageContent = learningModule.reading_texts[currentPage]

  return (
    <>
      <FlexBox
        direction="column"
        gap={SPACING.space4}
        style={{
          padding: SPACING.space6,
          background: JoonUIColor.background.primaryNeutral,
          flex: 1,
        }}
      >
        <Typography variant="h3" textAlign="center">
          {currentPageContent.header}
        </Typography>
        <Typography variant="body">
          {parseLineBreaks(currentPageContent.body)}
        </Typography>
      </FlexBox>
      <TrainingProgressionBar>
        <FlexBox direction="column" gap={SPACING.space2}>
          {!!completionVerificationText && isLastPage && (
            <Checkbox
              name="Completion verification"
              hideBorder
              label={completionVerificationText}
              style={{ background: "transparent" }}
              selected={hasVerifiedCompletion}
              onChange={() => setHasVerifiedCompletion(!hasVerifiedCompletion)}
            />
          )}
          <FlexBox align="center" wrap={false} gap={SPACING.space8}>
            {currentPage > 0 && (
              <TextButton onClick={() => setCurrentPage((prev) => prev - 1)}>
                <Typography
                  variant="bodyBold"
                  color={JoonUIColor.text.primaryAccent}
                  style={{ padding: `0 ${SPACING.space8}` }}
                >
                  Previous
                </Typography>
              </TextButton>
            )}
            <Button
              text={buttonText}
              onClick={onClickNext}
              fullWidth
              disabled={isLastPage && !hasVerifiedCompletion}
              style={{ whiteSpace: "nowrap" }}
            />
          </FlexBox>
        </FlexBox>
      </TrainingProgressionBar>
    </>
  )
}

export default ReadingModule
