import { create } from "zustand"

interface CoachStore {
  isFocused: boolean
  setIsFocused: (isFocused: boolean) => void
  isInfoModalOpen: boolean
  onOpenInfoModal: () => void
  onCloseInfoModal: () => void
}

const useCoachStore = create<CoachStore>((set) => ({
  isFocused: false,
  setIsFocused: (isFocused) => set({ isFocused }),
  isInfoModalOpen: false,
  onOpenInfoModal: () => set({ isInfoModalOpen: true }),
  onCloseInfoModal: () => set({ isInfoModalOpen: false }),
}))

export default useCoachStore
