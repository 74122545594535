import { QuestionAnswerMappingInterface } from "../../../types"
import { localStorageItems } from "../../../util/storage"

interface QuestionAnswerMapping {
  [questionId: string]: number[]
}

export function mergeQuestionnaireAnswers(
  newAnswers: QuestionAnswerMappingInterface
): QuestionAnswerMapping {
  const parentQuestionnaireAnswers = JSON.parse(
    localStorage.getItem(localStorageItems.parentQuestionnaireAnswers) || "{}"
  )

  const mergedAnswers: QuestionAnswerMapping = { ...parentQuestionnaireAnswers }

  for (const [questionId, answers] of Object.entries(newAnswers)) {
    const questionIdNum = Number(questionId)
    const everyDayAnswer = getEveryDayAnswer(questionIdNum)
    const mostDaysAnswer = getMostDaysAnswer(questionIdNum)

    if (
      everyDayAnswer !== null &&
      (answers.includes(everyDayAnswer) ||
        mergedAnswers[questionId]?.includes(everyDayAnswer))
    ) {
      mergedAnswers[questionId] = [everyDayAnswer]
    } else if (
      mostDaysAnswer !== null &&
      (answers.includes(mostDaysAnswer) ||
        mergedAnswers[questionId]?.includes(mostDaysAnswer))
    ) {
      mergedAnswers[questionId] = [mostDaysAnswer]
    } else {
      mergedAnswers[questionId] = answers
    }
  }

  return mergedAnswers
}

function getEveryDayAnswer(questionId: number): number | null {
  switch (questionId) {
    case 436:
      return 619
    case 401:
      return 545
    case 410:
      return 581
    case 403:
      return 553
    case 434:
      return 613 // "Never"
    default:
      return null
  }
}

function getMostDaysAnswer(questionId: number): number | null {
  switch (questionId) {
    case 436:
      return 620
    case 401:
      return 546
    case 410:
      return 582
    case 403:
      return 554
    case 434:
      return 614 // "Some days"
    default:
      return null
  }
}
