import { create } from "zustand"

import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"

type DataTasksModalStore = {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
}

const useDataTasksModalStore = create<DataTasksModalStore>((set) => ({
  isOpen: false,
  onOpen: () => {
    set({ isOpen: true })
    trackAnalyticEvent(ANALYTIC_EVENTS.OPEN_DATA_COLLECTION_TASKS)
  },
  onClose: () => set({ isOpen: false }),
}))

export default useDataTasksModalStore
