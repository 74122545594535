import {
  ChevronRightIcon,
  CloseIcon,
  JoonUIColor,
  SPACING,
  TextButton,
} from "@joonapp/web-shared"
import { useState } from "react"
import { isChrome, isMobile, isAndroid } from "react-device-detect"

import { useAddToHomescreenModal } from "../../components/modals/AddToHomescreenModal"
import { useIOSModal } from "../../components/modals/IOSModal"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { localStorageItems } from "../../util/storage"

const DownloadAppBanner = () => {
  const [forceUpdate, setForceUpdate] = useState(false)
  const { isOpen: isOpenIOSModal } = useIOSModal()
  const { onOpen: openAddToHomescreenModal } = useAddToHomescreenModal()
  if (
    !localStorage.getItem(localStorageItems.showDownloadAppBanner) ||
    isOpenIOSModal
  )
    return <></>

  if (isChrome && isMobile && isAndroid) {
    return (
      <button
        onClick={() => {
          openAddToHomescreenModal()
          trackAnalyticEvent(ANALYTIC_EVENTS.ADD_TO_HOMESCREEN_BANNER_CLICK)
        }}
        className="ios-banner"
        style={{ background: "white" }}
      >
        <TextButton
          style={{
            position: "absolute",
            top: "2px",
            right: "2px",
            padding: SPACING.space1,
            zIndex: 100,
          }}
          // @ts-ignore
          onClick={(e) => {
            e.stopPropagation()
            localStorage.removeItem(localStorageItems.showDownloadAppBanner)
            setForceUpdate(!forceUpdate)
          }}
        >
          <CloseIcon size={12} color={JoonUIColor.background.accent} />
        </TextButton>
        <div>
          <div
            style={{ fontSize: "18px", fontWeight: "600", lineHeight: "22px" }}
          >
            Joon is better as an App!
          </div>
          <div style={{ fontSize: "14px", lineHeight: "18px" }}>
            Add Joon to your homescreen for easy access.
          </div>
        </div>
        <ChevronRightIcon size={24} color={JoonUIColor.text.primary} />
      </button>
    )
  } else
    return (
      <button
        onClick={() => {
          trackAnalyticEvent(ANALYTIC_EVENTS.IOS_BANNER_CLICK)
          window.open(
            "https://apps.apple.com/us/app/joon-behavior-improvement-app/id1482225056",
            "_blank"
          )
        }}
        className="ios-banner"
      >
        <div>
          <TextButton
            style={{
              position: "absolute",
              top: "2px",
              right: "2px",
              padding: SPACING.space1,
              zIndex: 100,
            }}
            // @ts-ignore
            onClick={(e) => {
              e.stopPropagation()
              localStorage.removeItem(localStorageItems.showDownloadAppBanner)
              setForceUpdate(!forceUpdate)
            }}
          >
            <CloseIcon size={12} color={JoonUIColor.background.accent} />
          </TextButton>
          <div
            style={{ fontSize: "18px", fontWeight: "600", lineHeight: "22px" }}
          >
            Joon is better as an App!
          </div>
          <div style={{ fontSize: "14px", lineHeight: "18px" }}>
            Make the most of your subscription and download the iOS app. <br />
            Android coming soon.
          </div>
        </div>
        <img
          src={"/images/download_ios.svg"}
          alt="Download iOS"
          style={{ height: "30px" }}
        />
      </button>
    )
}
export default DownloadAppBanner
