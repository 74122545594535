import { useMutation } from "@tanstack/react-query"

import { useSendMessageModalStore } from "./useSendMessageModalStore"
import { QUERY_KEYS } from "../../../constants"
import { useUserQuery } from "../../../networking/queries"
import { sendMail } from "../../../networking/user"
import { queryClient } from "../../../util/queryClient"

const useSendMailMutation = () => {
  const { user } = useUserQuery()

  const {
    selectedChildId,
    message,
    selectedGift,
    onClose: onCloseSendMessageModal,
  } = useSendMessageModalStore()

  return useMutation({
    mutationFn: () =>
      sendMail(user?.id as number, selectedChildId, message, selectedGift?.id),
    onSuccess: () =>
      queryClient
        .invalidateQueries([QUERY_KEYS.FAMILY_MAIL])
        .then(() => queryClient.invalidateQueries([QUERY_KEYS.INVENTORY]))
        .then(onCloseSendMessageModal),
  })
}

export default useSendMailMutation
