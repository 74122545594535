import {
  Button,
  FlexBox,
  JoonUIColor,
  Modal,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { create } from "zustand"

import { QUERY_KEYS } from "../../constants"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { createJoonAPIClient } from "../../util/joon-api"

interface DeleteRewardModalStore {
  isOpen: boolean
  onOpen: (rewardId: number) => void
  onClose: () => void

  rewardId: number | null
}

export const useDeleteRewardModalStore = create<DeleteRewardModalStore>(
  (set) => ({
    isOpen: false,
    onOpen: (rewardId) => set({ isOpen: true, rewardId }),
    onClose: () => set({ isOpen: false, rewardId: null }),

    rewardId: null,
  })
)

const DeleteRewardModal = () => {
  const { isOpen, onClose, rewardId } = useDeleteRewardModalStore()

  const queryClient = useQueryClient()
  const deleteReward = async () => {
    const API = createJoonAPIClient()
    return API.delete(`api/custom-rewards/${rewardId}/`)
  }

  const deleteRewardMutation = useMutation({
    mutationFn: deleteReward,
    onSuccess: () => {
      onClose()
      trackAnalyticEvent(ANALYTIC_EVENTS.DELETE_REWARD)
      queryClient.invalidateQueries([QUERY_KEYS.CUSTOM_REWARDS])
    },
  })

  return (
    <Modal isOpen={isOpen} onClose={onClose} displayCloseIcon animate>
      <FlexBox
        direction="column"
        gap={SPACING.space4}
        style={{ padding: "30px", width: "min(450px, 95vw)" }}
      >
        <Typography variant="h3">
          Are you sure you want to delete this reward?
        </Typography>
        <Typography variant="body">
          You won't be able to undo this action.
        </Typography>

        <FlexBox
          justify="flex-end"
          wrap={false}
          align="center"
          gap={SPACING.space2}
        >
          <TextButton onClick={onClose}>
            <Typography
              variant="bodyBold"
              color={JoonUIColor.text.primaryAccent}
            >
              Cancel
            </Typography>
          </TextButton>

          <Button
            text="Delete"
            buttonType="redPrimary"
            isLoading={deleteRewardMutation.isLoading}
            onClick={() => deleteRewardMutation.mutate()}
          />
        </FlexBox>
      </FlexBox>
    </Modal>
  )
}

export default DeleteRewardModal
