import { ChildUser, Modal, ParentUser, UserRole } from "@joonapp/web-shared"
import React, { useState } from "react"

import EditChild from "./EditChild"
import UserComponent from "./UserComponent"

interface Props {
  user: ChildUser | ParentUser
  type: UserRole.CHILD | UserRole.PARENT
  name: string
}

const UserItem = ({ user, name, type }: Props) => {
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <Modal
        isOpen={open}
        onClose={handleClose}
        mobileFull
        displayCloseIcon
        animate
      >
        <EditChild
          child={user as ChildUser}
          closeModal={handleClose}
          setLoading={setLoading}
        />
      </Modal>
      <UserComponent
        editChild={handleOpen}
        name={name}
        loading={loading}
        type={type}
        index={2}
      />
    </>
  )
}

export default UserItem
