import {
  Button,
  EllipsisIcon,
  JoonUIColor,
  SPACING,
  TextButton,
} from "@joonapp/web-shared"
import { useEffect, useRef } from "react"
import { useShallow } from "zustand/react/shallow"

import { useAddQuestTemplatesModalStore } from "./addQuestTemplates/useAddQuestTemplatesModalStore"
import AssignedQuests from "./AssignedQuests"
import { useMoreOptionsModalStore } from "./moreOptions/useMoreOptionsModalStore"
import QuestBoardChildToggleHeader from "./QuestBoardChildToggleHeader"
import QuestBoardMultiSelect from "./QuestBoardMultiSelect"
import useQuestBoardMultiSelectStore from "./useQuestBoardMultiSelectStore"
import useQuestBoardStore from "./useQuestBoardStore"
import ChildAuthCode from "../../components/childAuthCode/ChildAuthCode"
import PageContentWrapper from "../../components/pageContentWrapper/PageContentWrapper"
import PageWrapper from "../../components/pageWrapper/PageWrapper"
import { GRADIENTS } from "../../constants"
import useDocumentTitle from "../../hooks/useDocumentTitle"
import useMobile from "../../hooks/useMobile"
import { useReorderQuestsQuery } from "../../networking/queries"
import { requireAuth } from "../../util/auth"

const QuestBoard = () => {
  useDocumentTitle("Joon - Quests")
  const { setQuestBoardRef } = useQuestBoardStore()
  const isMobile = useMobile()
  const { isMultiSelectMode } = useQuestBoardMultiSelectStore(
    useShallow((state) => ({
      isMultiSelectMode: state.isMultiSelectMode,
    }))
  )
  const { isLoading: isLoadingReorderQuests } = useReorderQuestsQuery()
  // Setting a reference for the top of the quest board
  // so I can scroll to it after quests added
  const qbRef = useRef<any>()
  useEffect(() => setQuestBoardRef(qbRef), [setQuestBoardRef])

  return (
    <>
      <PageWrapper ref={qbRef} style={{ background: GRADIENTS.gradient100 }}>
        <PageContentWrapper mobileGutter>
          {!isMultiSelectMode && (
            <>
              <QuestBoardChildToggleHeader />
              {!isMobile && <AddQuestButton />}
              {!isLoadingReorderQuests && <MoreOptionsModalButton />}
              <div style={{ margin: "0 auto" }}>
                <ChildAuthCode />
              </div>
            </>
          )}
          <AssignedQuests />
        </PageContentWrapper>
        <QuestBoardMultiSelect />
      </PageWrapper>
      {isMobile && <AddQuestMobileButton />}
    </>
  )
}

const QuestBoardWithAuth = requireAuth(QuestBoard)
export default QuestBoardWithAuth

const AddQuestButton = () => {
  const { onOpen: addQuest } = useAddQuestTemplatesModalStore()

  return (
    <Button
      text="Add Quest"
      onClick={addQuest}
      style={{
        width: "120px",
        whiteSpace: "nowrap",
        position: "absolute",
        right: "0px",
        top: "18px",
        zIndex: 1,
      }}
    />
  )
}

const MoreOptionsModalButton = () => {
  const { onOpen } = useMoreOptionsModalStore()

  return (
    <TextButton
      onClick={onOpen}
      style={{
        position: "absolute",
        top: SPACING.space2,
        left: SPACING.space4,
        zIndex: 1,
      }}
    >
      <EllipsisIcon size={32} color={JoonUIColor.icon.neutral} />
    </TextButton>
  )
}

export const AddQuestMobileButton = () => {
  const { onOpen: addQuest } = useAddQuestTemplatesModalStore()

  const { isMultiSelectMode } = useQuestBoardMultiSelectStore(
    useShallow((state) => ({
      isMultiSelectMode: state.isMultiSelectMode,
    }))
  )

  if (isMultiSelectMode) return <></>

  return (
    <Button
      onClick={addQuest}
      style={{
        right: "12px",
        position: "absolute",
        bottom: "82px",
        width: "56px",
        height: "56px",
        fontSize: "36px",
        borderRadius: "16px",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.25)",
        paddingBottom: "4px",
      }}
      text="+"
    />
  )
}
