import { QuestSeries } from "@joonapp/web-shared"
import { create } from "zustand"

interface QuestDndListStore {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  userHasMadeChanges: boolean
  setUserHasMadeChanges: (userHasMadeChanges: boolean) => void
  sections: [string, QuestSeries[]][]
  setSections: (sections: [string, QuestSeries[]][]) => void
}

export const useQuestDndListStore = create<QuestDndListStore>((set) => ({
  isOpen: false,
  onOpen: () => set({ isOpen: true }),
  onClose: () => set({ isOpen: false }),
  userHasMadeChanges: false,
  setUserHasMadeChanges: (userHasMadeChanges) => set({ userHasMadeChanges }),
  sections: [],
  setSections: (sections) => set({ sections }),
}))
