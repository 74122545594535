import { JoonUIColor, Modal } from "@joonapp/web-shared"

import TrainingModuleFlow from "./TrainingModuleFlow"
import TrainingTopBar from "./TrainingTopBar"
import useTrainingStore from "./useTrainingStore"

const TrainingModal = () => {
  const { isOpen } = useTrainingStore()

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {}}
      mobileFull
      animate
      animationType="slide-up"
      style={{ background: JoonUIColor.background.lightGray }}
    >
      <TrainingTopBar />
      <TrainingModuleFlow />
    </Modal>
  )
}

export default TrainingModal
