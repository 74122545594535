import { FlexBox, JoonUIColor, Loader } from "@joonapp/web-shared"

interface Props {
  height?: number
  children?: JSX.Element
}

function PageLoader({ height = 350, children }: Props) {
  return (
    <FlexBox
      wrap={false}
      justify="center"
      align="center"
      style={{ height: height }}
    >
      {!children && (
        <Loader color={JoonUIColor.background.accent} size={40} thickness={3} />
      )}

      {children && <>{children}</>}
    </FlexBox>
  )
}

export default PageLoader
