import {
  Button,
  FlexBox,
  JoonUIColor,
  SPACING,
  TextInput,
  Typography,
} from "@joonapp/web-shared"
import { useState } from "react"
import { create } from "zustand"

import useOnboardContext from "../../hooks/useOnboardContext"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { localStorageItems } from "../../util/storage"
import BackButton from "../buttons/BackButton"

interface ParentNameStepStore {
  nameStep: number
  setNameStep: (step: number) => void
}

const useParentNameStepStore = create<ParentNameStepStore>((set) => ({
  nameStep: 0,
  setNameStep: (nameStep) => set({ nameStep }),
}))

function OnboardParentNameSection() {
  const { nameStep } = useParentNameStepStore()

  if (nameStep === 0) return <ParentFirstNameStep />
  else return <ParentNicknameStep />
}

const ParentFirstNameStep = () => {
  const { parentData, setParentData, previousStep } = useOnboardContext()
  const [warningText, setWarningText] = useState("")
  const { setNameStep } = useParentNameStepStore()

  return (
    <>
      <BackButton onBack={previousStep} />
      <div className="onboard-section-centered">
        {!localStorage.getItem(localStorageItems.therapistCode) && (
          <Typography
            variant="h2"
            style={{
              lineHeight: "28px",
              width: "100%",
              textAlign: "center",
              marginBottom: SPACING.space6,
            }}
          >
            Create an account to see <br />
            your custom plan!
          </Typography>
        )}
        {/* First name */}
        <FlexBox direction="column">
          <div className="onboard-question">What's your first name?</div>
          <div className="body-text mb-8">So we know what to call you.</div>
          <TextInput
            fullWidth
            name="firstName"
            placeholder="First Name"
            onChange={(e: any) => setParentData("name", e.target.value)}
            value={parentData.name}
          />
        </FlexBox>
        <FlexBox
          direction="column"
          gap={SPACING.space2}
          align="center"
          style={{ marginTop: SPACING.space4 }}
        >
          {warningText.length > 0 && (
            <Typography variant="caption" color={JoonUIColor.semantic.alert}>
              {warningText}
            </Typography>
          )}
          <Button
            text="Next"
            fullWidth
            onClick={() => {
              if (parentData.name.length === 0) {
                setWarningText("Please enter a name")
              } else {
                trackAnalyticEvent(ANALYTIC_EVENTS.ADD_PARENT_NAME)
                setNameStep(1)
              }
            }}
          />
        </FlexBox>
      </div>
    </>
  )
}

const ParentNicknameStep = () => {
  const { parentData, setParentData, nextStep } = useOnboardContext()
  const [warningText, setWarningText] = useState("")
  const { setNameStep } = useParentNameStepStore()

  return (
    <>
      <BackButton onBack={() => setNameStep(0)} />
      <div className="onboard-section-centered">
        <div className="onboard-question">What do your kids call you?</div>
        <div className="body-text mb-8">
          This is what your children will see your name as in Joon.
        </div>
        <TextInput
          fullWidth
          name="nickName"
          placeholder="Eg. Mom"
          onChange={(e: any) => setParentData("nickname", e.target.value)}
          value={parentData.nickname}
        />

        <FlexBox
          direction="column"
          gap={SPACING.space2}
          align="center"
          style={{ marginTop: SPACING.space4 }}
        >
          {warningText.length > 0 && (
            <Typography variant="caption" color={JoonUIColor.semantic.alert}>
              {warningText}
            </Typography>
          )}

          <Button
            onClick={() => {
              if (parentData.name.length === 0) {
                setWarningText("Please enter a name")
              } else if (parentData.nickname.length === 0) {
                setWarningText("Please enter a nickname")
              } else {
                trackAnalyticEvent(ANALYTIC_EVENTS.ADD_PARENT_NICKNAME)
                nextStep()
              }
            }}
            text="Next"
            fullWidth
          />
        </FlexBox>
      </div>
    </>
  )
}

export default OnboardParentNameSection
