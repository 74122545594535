import {
  DownloadIcon,
  FlexBox,
  JoonUIColor,
  PaperPlaneIcon,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"

import useMobile from "../../hooks/useMobile"
import { useChildAuthCodeQuery } from "../../networking/queries"

import "./shareButton.scss"

const downloadLink = "https://joonapp.io/download-redirect"
const openDownloadLink = () => window.open(downloadLink, "_blank")

export const ShareButton = () => {
  const { data: authCode } = useChildAuthCodeQuery()

  if (!authCode) return <></>

  return (
    <button onClick={openDownloadLink} className="share-button">
      <img src={"/images/logos/joon-pet-game-logo.svg"} alt="" />
      <div className="share-button-text">
        <div className="">Joon Pet Game</div>
        <div className="">Joon App, Inc</div>
      </div>
      <DownloadIcon color={JoonUIColor.icon.neutral} />
    </button>
  )
}

export const ShareLink = () => {
  const { data: authCode } = useChildAuthCodeQuery()
  const isMobile = useMobile()

  if (!authCode) return <></>

  const clickShare = () =>
    isMobile ? shareDownloadLink(authCode) : openDownloadLink()
  return (
    <TextButton
      onClick={clickShare}
      style={{ margin: `${SPACING.space3} auto` }}
    >
      <FlexBox align="center" gap={SPACING.space2} wrap={false}>
        <PaperPlaneIcon size={18} color={JoonUIColor.semantic.info} />
        <Typography variant="body" color={JoonUIColor.semantic.info}>
          Share Download Link
        </Typography>
      </FlexBox>
    </TextButton>
  )
}

const shareDownloadLink = async (code: string) => {
  const subjectNew = "Get Started on Joon!"
  const instructionsNew = `Use this link to download Joon! 
Then use the login code ${code} to start caring for your Doter! 
  
${downloadLink}}`

  try {
    // If the web share API is supported by the browser
    if (navigator.share) {
      await navigator.share({
        title: subjectNew,
        text: instructionsNew,
        url: "https://joonapp.io/download-redirect",
      })
    } else {
      // Else open email to share
      const emailSubject = encodeURIComponent(subjectNew)
      const emailBody = encodeURIComponent(instructionsNew)

      const url = `mailto:?subject=${emailSubject}&body=${emailBody}`
      window.open(url)
    }
  } catch (error) {
    console.error("Error sharing:", error)
  }
}
