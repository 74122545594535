import { JoonColorExpanded } from "@joonapp/web-shared"
import SendbirdProvider from "@sendbird/uikit-react/SendbirdProvider"

import "@sendbird/uikit-react/dist/index.css"
import { useSBCredentialsQuery } from "../../networking/queries"

const SBProvider = (props: { children: React.ReactNode }) => {
  const { data: SBCredentials } = useSBCredentialsQuery()

  const myColorSet = {
    "--sendbird-light-primary-500": JoonColorExpanded.purple600,
    "--sendbird-light-primary-400": JoonColorExpanded.purple600,
    "--sendbird-light-primary-300": JoonColorExpanded.purple600,
    "--sendbird-light-primary-200": JoonColorExpanded.purple200,
    "--sendbird-light-primary-100": JoonColorExpanded.purple100,
  }

  return (
    <SendbirdProvider
      colorSet={myColorSet}
      appId={import.meta.env.VITE_SENDBIRD_APP_ID}
      userId={SBCredentials?.sendbird_user_id || ""}
      accessToken={SBCredentials?.sendbird_access_token}
    >
      {props.children}
    </SendbirdProvider>
  )
}

export default SBProvider
