import {
  CloseIcon,
  FlexBox,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"

import ParentTaskCard from "./ParentTaskCard"
import useParentTasksModalStore from "./useParentTasksModalStore"
import SlideUpModal from "../../../components/slideUpModal/SlideUpModal"
import { GRADIENTS, completeQuestStatuses } from "../../../constants"
import { useParentTaskQuery } from "../../../networking/queries"

const ParentTasksModal = () => {
  const { isOpen, onClose, selectedTherapistId } = useParentTasksModalStore()
  const { data: parentTasks } = useParentTaskQuery({ enabled: isOpen })
  const tasks = parentTasks
    ?.filter(
      (task) => task.series.assigner_profile?.user_id === selectedTherapistId
    )
    .sort((task) => {
      if (completeQuestStatuses.includes(task.status)) return 1
      else return -1
    })

  const therapistName =
    tasks?.[0]?.series?.assigner_profile?.nickname || "your therapist"

  return (
    <SlideUpModal
      isOpen={isOpen}
      onClose={onClose}
      showCard={true}
      size="default"
      backgroundType="overlay"
    >
      <button
        style={{
          position: "absolute",
          right: 0,
          top: 0,
          padding: SPACING.space2,
        }}
        onClick={onClose}
      >
        <CloseIcon color={JoonUIColor.icon.neutral} />
      </button>
      <FlexBox
        direction="column"
        gap={SPACING.space4}
        wrap={false}
        style={{
          padding: SPACING.space6,
          background: GRADIENTS.gradient100,
          height: "100%",
          overflowY: "auto",
        }}
      >
        <FlexBox direction="column" gap={SPACING.space2}>
          <Typography variant="h3">
            Quick to-dos from {therapistName}
          </Typography>
          <Typography variant="bodySmall">
            One time tasks and reminders from Dr Joe for you to do in between
            sessions.
            <br />
            Contact {therapistName} for questions.
          </Typography>
        </FlexBox>
        <FlexBox direction="column" gap={SPACING.space2}>
          {tasks?.map((task) => <ParentTaskCard task={task} />)}
        </FlexBox>
      </FlexBox>
    </SlideUpModal>
  )
}

export default ParentTasksModal
