import { Button, JoonUIColor, SPACING, Typography } from "@joonapp/web-shared"

import { ParentTestimonialCarousel } from "./ParentTestimonialCarousel"
import PageWrapper from "../../components/pageWrapper/PageWrapper"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { useRouter } from "../../util/router"

// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/autoplay"

const WelcomeContent = () => {
  const router = useRouter()

  return (
    <PageWrapper style={{ background: "#f0f1fb" }}>
      <div className="onboard-wrapper" style={{ width: "min(100%, 520px)" }}>
        <div
          className="onboard-section-centered"
          style={{ gap: "30px", minHeight: "80vh" }}
        >
          <img
            src="/images/logos/logo_white.svg"
            alt="Joon"
            style={{ width: "96px" }}
          />
          <div className="welcome-title">
            Turn behavior change into a game your kids love
          </div>
          <Button
            style={{
              width: "min(280px, 95vw)",
              margin: "10px 0",
            }}
            size="large"
            text="GET STARTED"
            onClick={() => {
              trackAnalyticEvent(ANALYTIC_EVENTS.AUTH_NO_ACCOUNT)
              router.push("/invite")
            }}
          />
          <div className="testimonials">
            <div
              className="ratings-box"
              style={{
                background: JoonUIColor.background.primaryNeutral,
                border: `1px solid ${JoonUIColor.border.default}`,
              }}
            >
              <img
                src="/images/icons/review-stars.svg"
                alt="placeholder"
                style={{
                  height: "16px",
                  marginRight: "5px",
                  marginBottom: "2px",
                }}
              />
              4.7{" "}
              <div
                style={{
                  height: "5px",
                  width: "5px",
                  borderRadius: "50%",
                  margin: SPACING.space3,
                  background: JoonUIColor.text.secondary,
                }}
              />{" "}
              5.1K Ratings
            </div>
            <ParentTestimonialCarousel />
          </div>
        </div>
        <button
          onClick={() => {
            trackAnalyticEvent(ANALYTIC_EVENTS.AUTH_HAS_ACCOUNT)
            router.push("/auth/signin")
          }}
          style={{
            width: "100%",
            textAlign: "center",
            marginTop: "20px",
            color: JoonUIColor.text.primaryAccent,
          }}
        >
          <Typography variant="bodyBold" color={JoonUIColor.text.primaryAccent}>
            Sign in to an existing account
          </Typography>
        </button>
      </div>
    </PageWrapper>
  )
}

export default WelcomeContent
