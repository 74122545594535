import { FlexBox, SPACING, Typography } from "@joonapp/web-shared"

import { SKILL_MAPPING } from "../../../constants"

type Props = {
  skill: string | null
}

const CategoryBadge = ({ skill }: Props) => {
  if (!skill) return <></>

  return (
    <FlexBox
      direction="row"
      gap={SPACING.space1}
      align="center"
      style={{ width: "fit-content" }}
    >
      <img
        src={`/images/icons/${skill}-icon.png`}
        alt="Category"
        style={{ height: "14px" }}
      />
      <Typography variant="bodySmall" style={{ fontWeight: "600" }}>
        {SKILL_MAPPING[skill]}
      </Typography>
    </FlexBox>
  )
}

export default CategoryBadge
