import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react"
import {
  ResponsiveContainer,
  StackedCarousel,
} from "react-stacked-center-carousel"

import PlanCarouselCard from "./PlanCarouselCard"
import useMobile from "../../../../hooks/useMobile"
import { useCoachingStore } from "../../useCoachingStore"

const PlanCarousel = memo(() => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [isUpdating, setIsUpdating] = useState(false)
  const { planIndex, setPlanIndex } = useCoachingStore()
  const carouselRef = useRef<any>(null)
  const isMobile = useMobile()

  const carouselData = [{ key: 0 }, { key: 1 }, { key: 2 }, { key: 3 }]

  // updates slider
  const handleActiveSlideChange = (activeSlide: number) => {
    if (!isUpdating) {
      setIsUpdating(true)
      setPlanIndex(activeSlide)
      setCurrentSlide(activeSlide)
      setTimeout(() => {
        setIsUpdating(false)
      }, 32)
    }
  }

  // updates carousel when slider position changes
  useEffect(() => {
    if (!isUpdating && carouselRef.current) {
      setIsUpdating(true)
      carouselRef.current.swipeTo(planIndex - currentSlide)
      setCurrentSlide(planIndex)
      setTimeout(() => {
        setIsUpdating(false)
      }, 32)
    }
  }, [planIndex, currentSlide]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isUpdating && carouselRef.current && currentSlide === 0) {
      setIsUpdating(true)
      setPlanIndex(2)
      setCurrentSlide(2)
      carouselRef.current.swipeTo(2)
      setTimeout(() => {
        setIsUpdating(false)
      }, 32)
    }
  }, [carouselRef.current]) // eslint-disable-line react-hooks/exhaustive-deps

  const slideProps = useMemo(
    () => ({
      style: {
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        pointerEvents: isUpdating ? "none" : "auto",
        userSelect: "none",
      } as React.CSSProperties,
    }),
    [isUpdating]
  )

  const SlideComponent = useCallback(
    ({ dataIndex }: { dataIndex: number }) => (
      <div {...slideProps}>
        <PlanCarouselCard index={dataIndex} />
      </div>
    ),
    [slideProps]
  )

  return (
    <ResponsiveContainer
      render={(parentWidth) => (
        <StackedCarousel
          ref={carouselRef}
          data={carouselData}
          slideComponent={SlideComponent}
          slideWidth={parentWidth * (isMobile ? 0.95 : 0.75)}
          carouselWidth={parentWidth}
          maxVisibleSlide={3}
          onActiveSlideChange={handleActiveSlideChange}
          useGrabCursor={true}
          transitionTime={300}
        />
      )}
    />
  )
})

export default PlanCarousel
