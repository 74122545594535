import { FlexBox, SPACING } from "@joonapp/web-shared"
import { useEffect } from "react"

import CoachQuestionnaire from "./CoachQuestionnaire"
import Matching from "./Matching"
import PlanSelect from "./PlanSelect"
import PostAnswerScreen from "./PostAnswerScreen"
import PostSelectionQuestionnaire from "./PostSelectionQuestionnaire"
import RecommendedCoaches from "./RecommendedCoaches"
import BackButton from "../../../components/buttons/BackButton"
import JoinParentsTextBox from "../../../components/coaching/JoinParentsTextBox"
import TransitionWrapper from "../../../components/coaching/TransitionWrapper"
import { CoachingStep } from "../../../constants"
import useMobile from "../../../hooks/useMobile"
import { useCoachPostSelectionQuestionnaireQuery } from "../../../networking/queries"
import {
  QuestionAnswerMappingInterface,
  QuestionnairesInterface,
} from "../../../types"
import {
  cohorts,
  experimentNames,
  useGetExperimentCohort,
} from "../../../util/experiments"
import { useRouter } from "../../../util/router"
import { localStorageItems } from "../../../util/storage"
import CalendlyPage from "../CalendlyPage"
import CoachLandingPage from "../coachingFlow/CoachLandingPage"
import ScheduledPage from "../ScheduledPage"
import { useCoachingStore } from "../useCoachingStore"

const CoachingFlow = () => {
  const router = useRouter()
  const { step, setStep, questionnaireStep, setQuestionnaireStep } =
    useCoachingStore()
  const { data: postSelectionQuestionnaire } =
    useCoachPostSelectionQuestionnaireQuery()

  const variant = useGetExperimentCohort(
    experimentNames.webParentVirtualCoachOnboarding
  )

  const isMobile = useMobile()

  const showBackButton =
    (variant === cohorts.variantA && step === CoachingStep.CALENDLY) ||
    (step > CoachingStep.LANDING &&
      step < CoachingStep.SELECT_PLAN &&
      step !== CoachingStep.MATCHING &&
      step !== CoachingStep.RECOMMENDED_COACHES)

  const onBackClick = () => {
    if (step === CoachingStep.POST_ANSWER_SCREEN) {
      setQuestionnaireStep(questionnaireStep - 1)
      setStep(CoachingStep.INTAKE_FORM)
    } else if (step === CoachingStep.INTAKE_FORM && questionnaireStep > 0) {
      setQuestionnaireStep(questionnaireStep - 1)
    } else if (variant === cohorts.variantA && step === CoachingStep.CALENDLY) {
      if (
        router.query.from === "academy" ||
        !postSelectionQuestionnaire?.questions?.length
      )
        setStep(CoachingStep.RECOMMENDED_COACHES)
      else setStep(CoachingStep.POST_SELECTION)
    } else {
      setStep(step - 1)
    }
  }

  const onConfirmCoachQuestionnaire = (
    parentId: number,
    coachQuestionnaire: QuestionnairesInterface,
    questionAnswerMapping: QuestionAnswerMappingInterface
  ) => {
    localStorage.setItem(
      localStorageItems.coachQuestionnaire,
      JSON.stringify({ parentId, coachQuestionnaire, questionAnswerMapping })
    )
  }

  const onConfirmPostSelectionQuestionnaire = (
    parentId: number,
    postSelectionQuestionnaire: QuestionnairesInterface,
    questionAnswerMapping: QuestionAnswerMappingInterface
  ) => {
    localStorage.setItem(
      localStorageItems.postSelectionQuestionnaire,
      JSON.stringify({
        parentId,
        postSelectionQuestionnaire,
        questionAnswerMapping,
      })
    )
  }

  useEffect(() => {
    if (router.query.from === "academy")
      setStep(CoachingStep.RECOMMENDED_COACHES)
    else setStep(CoachingStep.LANDING)
  }, [router.query.from, setStep])

  return (
    <FlexBox
      direction="column"
      align="center"
      justify="space-between"
      gap={SPACING.space2}
      wrap={false}
      style={{
        position: "relative",
        width: "min(450px, 100%)",
        ...(isMobile
          ? {
              height: "calc(100dvh - 20px)",
              margin: "20px 20px 0px",
              paddingTop: "20px",
              paddingBottom: "0px",
            }
          : {
              height: "100%",
              margin: "30px 0px 0px",
              paddingTop: "0px",
              paddingBottom: "60px",
            }),
      }}
    >
      <div style={{ width: "100%" }}>
        <div
          style={{
            ...(step === CoachingStep.POST_ANSWER_SCREEN
              ? { opacity: 0.7, pointerEvents: "auto" }
              : showBackButton
                ? { opacity: 1, pointerEvents: "auto" }
                : { opacity: 0, pointerEvents: "none" }),
            transition: "opacity .2s ease",
            position: "absolute",
            top: "0px",
            left: "0px",
            zIndex: 1000,
          }}
        >
          <BackButton onBack={onBackClick} />
        </div>
        {(step === CoachingStep.POST_SELECTION ||
          step === CoachingStep.SCHEDULED ||
          (variant === cohorts.variantA && step === CoachingStep.CALENDLY)) && (
          <img
            src={"/images/logos/joon-health-logo.svg"}
            alt=""
            style={{ height: "24px", width: "auto" }}
          />
        )}
        <TransitionWrapper
          showChildren={step === CoachingStep.POST_ANSWER_SCREEN}
        >
          <PostAnswerScreen />
        </TransitionWrapper>
        <TransitionWrapper showChildren={step === CoachingStep.LANDING}>
          <FlexBox
            justify="flex-start"
            align="center"
            direction="column"
            fullWidth
            gap={SPACING.space4}
            style={{
              minHeight: "70vh",
              padding: `${SPACING.space6} 0 0`,
            }}
          >
            <CoachLandingPage />
          </FlexBox>
        </TransitionWrapper>
        <TransitionWrapper showChildren={step === CoachingStep.INTAKE_FORM}>
          <CoachQuestionnaire onConfirm={onConfirmCoachQuestionnaire} />
        </TransitionWrapper>
        <TransitionWrapper showChildren={step === CoachingStep.MATCHING}>
          <Matching />
        </TransitionWrapper>
        <TransitionWrapper
          showChildren={step === CoachingStep.RECOMMENDED_COACHES}
        >
          <RecommendedCoaches />
        </TransitionWrapper>
        <TransitionWrapper showChildren={step === CoachingStep.POST_SELECTION}>
          <PostSelectionQuestionnaire
            onConfirm={onConfirmPostSelectionQuestionnaire}
          />
        </TransitionWrapper>
        <TransitionWrapper showChildren={step === CoachingStep.SELECT_PLAN}>
          <PlanSelect />
        </TransitionWrapper>
        <TransitionWrapper showChildren={step === CoachingStep.CALENDLY}>
          <CalendlyPage />
        </TransitionWrapper>
        <TransitionWrapper showChildren={step === CoachingStep.SCHEDULED}>
          <ScheduledPage />
        </TransitionWrapper>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          ...(step >= CoachingStep.LANDING && step < CoachingStep.INTAKE_FORM
            ? { opacity: 1, pointerEvents: "auto" }
            : { opacity: 0, pointerEvents: "none" }),
          transition: "opacity .2s ease",
        }}
      >
        <JoinParentsTextBox />
      </div>
    </FlexBox>
  )
}

export default CoachingFlow
