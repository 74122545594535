import { EditIcon, JoonUIColor, Loader, UserRole } from "@joonapp/web-shared"

interface Props {
  editChild: () => void
  name: string
  loading?: boolean
  type: string
  index: number
}

function UserComponent({ name, editChild, loading, type }: Props) {
  return (
    <div className="user-item">
      <div className="user-item-initial">{name.charAt(0)}</div>
      <div className="user-item-name">{name}</div>
      {type === UserRole.CHILD && (
        <button onClick={editChild} className="user-item-icon">
          {loading ? (
            <Loader
              color={JoonUIColor.background.primaryNeutral}
              size={24}
              thickness={2}
            />
          ) : (
            <EditIcon color={JoonUIColor.icon.inverted} />
          )}
        </button>
      )}
    </div>
  )
}

export default UserComponent
