import {
  Button,
  CloseIcon,
  FlexBox,
  JoonUIColor,
  Modal,
  SPACING,
} from "@joonapp/web-shared"
import { create } from "zustand"

import useMobile from "../../hooks/useMobile"

interface ImageModalStore {
  isOpen: boolean
  onOpen: (imageUrl: string) => void
  onClose: () => void
  imageUrl: string | null
}

export const useImageModalStore = create<ImageModalStore>((set) => ({
  isOpen: false,
  onOpen: (imageUrl) => set({ isOpen: true, imageUrl }),
  onClose: () => set({ isOpen: false, imageUrl: "" }),
  imageUrl: null,
}))

export const ImageModal = () => {
  const { isOpen, imageUrl, onClose } = useImageModalStore()
  const isMobile = useMobile()

  return (
    <Modal isOpen={isOpen} onClose={onClose} animate>
      <FlexBox
        style={{
          height: isMobile ? "100dvh" : "auto",
          width: isMobile ? "100vw" : "min(700px, 100vw)",
          background: "black",
        }}
        align="center"
      >
        <div
          style={{
            position: "absolute",
            right: SPACING.space4,
            top: SPACING.space4,
          }}
        >
          <Button
            onClick={onClose}
            text={
              <div style={{ height: "20px" }}>
                <CloseIcon color={JoonUIColor.text.inverted} />
              </div>
            }
            style={{ padding: SPACING.space3 }}
            size="small"
          />
        </div>
        <div style={{ width: "100%" }}>
          <img
            src={imageUrl || ""}
            alt="Magnified view"
            style={{ width: "100%" }}
          />
        </div>
      </FlexBox>
    </Modal>
  )
}
