import { FlexBox, JoonUIColor, SPACING } from "@joonapp/web-shared"
import Skeleton, { SkeletonProps } from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"

const SKELETON_BASE_COLOR = JoonUIColor.background.lightAccent
const SKELETON_HIGHLIGHT_COLOR = JoonUIColor.background.primaryNeutral

export const SkeletonLoader = (props: SkeletonProps) => {
  return (
    <Skeleton
      height={28}
      baseColor={SKELETON_BASE_COLOR}
      highlightColor={SKELETON_HIGHLIGHT_COLOR}
      {...props}
    />
  )
}

export const QuestBoardSkeleton = () => {
  return (
    <div>
      <SkeletonLoader width="100%" height={24} style={{ margin: "12px 0" }} />
      <SkeletonLoader
        width="100%"
        height={56}
        style={{ marginBottom: "24px" }}
      />
      <SkeletonLoader width="100%" height={24} style={{ margin: "12px 0" }} />
      <SkeletonLoader
        width="100%"
        height={56}
        style={{ marginBottom: "12px" }}
      />
      <SkeletonLoader
        width="100%"
        height={56}
        style={{ marginBottom: "12px" }}
      />
    </div>
  )
}

export const LoadingItemsSkeleton = () => {
  return (
    <div>
      <SkeletonLoader
        width="100%"
        height={84}
        style={{ marginBottom: "8px" }}
      />
      <SkeletonLoader
        width="100%"
        height={84}
        style={{ marginBottom: "8px" }}
      />
      <SkeletonLoader
        width="100%"
        height={84}
        style={{ marginBottom: "8px" }}
      />
    </div>
  )
}

export const ProgressCardSkeleton = () => {
  return (
    <FlexBox
      gap={SPACING.space2}
      direction="column"
      align="unset"
      justify="unset"
    >
      <SkeletonLoader width="100%" height={50} />
      <SkeletonLoader width="100%" height={50} />
      <SkeletonLoader width="100%" height={50} />
    </FlexBox>
  )
}

export const TrainingSkeleton = () => {
  return (
    <FlexBox
      direction="column"
      gap={SPACING.space2}
      align="unset"
      justify="unset"
    >
      <SkeletonLoader width="100%" height={72} />
      <SkeletonLoader width="100%" height={72} />
      <SkeletonLoader width="100%" height={72} />
    </FlexBox>
  )
}

export const FamilySkeleton = () => {
  return (
    <FlexBox
      direction="column"
      gap={SPACING.space2}
      align="unset"
      justify="unset"
    >
      <SkeletonLoader
        width="25%"
        height={SPACING.space6}
        style={{ marginTop: SPACING.space4 }}
      />
      <SkeletonLoader width="100%" height={48} />
      <SkeletonLoader width="100%" height={48} />
      <SkeletonLoader
        width="25%"
        height={SPACING.space6}
        style={{ marginTop: SPACING.space4 }}
      />
      <SkeletonLoader width="100%" height={48} />
      <SkeletonLoader width="100%" height={48} />
      <SkeletonLoader
        width="25%"
        height={SPACING.space6}
        style={{ marginTop: SPACING.space4 }}
      />
      <SkeletonLoader width="100%" height={48} />
      <SkeletonLoader width="100%" height={48} />
    </FlexBox>
  )
}

export const FamilyMailboxSkeleton = () => {
  return (
    <FlexBox
      direction="column"
      gap={SPACING.space3}
      align="unset"
      justify="unset"
    >
      <SkeletonLoader width="100%" height={61} style={{ marginTop: "24px" }} />
      <SkeletonLoader width="100%" height={61} />
      <SkeletonLoader width="100%" height={61} />
      <SkeletonLoader width="100%" height={61} />
      <SkeletonLoader width="100%" height={61} />
      <SkeletonLoader width="100%" height={61} />
      <SkeletonLoader width="100%" height={61} />
      <SkeletonLoader width="100%" height={61} />
    </FlexBox>
  )
}
