import { defaultDropAnimationSideEffects, DragOverlay } from "@dnd-kit/core"
import { PropsWithChildren } from "react"

export function SortableOverlay({ children }: PropsWithChildren<{}>) {
  return (
    <DragOverlay
      dropAnimation={{
        sideEffects: defaultDropAnimationSideEffects({
          styles: { active: { opacity: "0.4" } },
        }),
      }}
    >
      {children}
    </DragOverlay>
  )
}
