import {
  FlexBox,
  JoonUIColor,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { useEffect } from "react"
import { isAndroid } from "react-device-detect"
import { isChrome, isMobile } from "react-device-detect"

import PlanCarousel from "./planComponents/PlanCarousel"
import PlanSlider from "./planComponents/PlanSlider"
import TimerBanner from "./planComponents/TimerBanner"
import PageLoader from "../../../components/loading/PageLoader"
import { useAddToHomescreenModal } from "../../../components/modals/AddToHomescreenModal"
import { useIOSModal } from "../../../components/modals/IOSModal"
import { CoachingStep } from "../../../constants"
import useMobile from "../../../hooks/useMobile"
import { ANALYTIC_EVENTS } from "../../../util/analytics"
import { trackAnalyticEvent } from "../../../util/analytics"
import { useRouter } from "../../../util/router"
import ChatBubble from "../ChatBubble"
import SmallCoachCard from "../SmallCoachCard"
import { useCoachingStore } from "../useCoachingStore"

const PlanSelect = () => {
  const { selectedCoach, setTimeLeft } = useCoachingStore()
  const { onOpen: openAddToHomescreenModal } = useAddToHomescreenModal()
  const { onOpen: openIOSModal } = useIOSModal()
  const coachName = selectedCoach?.user.name
  const isMobileMediaQuery = useMobile()
  const router = useRouter()

  const isOnMobileChrome = isChrome && isMobile && isAndroid

  const onSkipClick = () => {
    router.push("/me")
    if (router.query.from === "academy") {
      trackAnalyticEvent(ANALYTIC_EVENTS.MARKETING_PLAN_SKIP_CLICK)
      return
    }
    trackAnalyticEvent(ANALYTIC_EVENTS.PERSONALIZED_COACH_SKIP_CLICK, {
      step: CoachingStep.SELECT_PLAN,
    })
    if (isOnMobileChrome) openAddToHomescreenModal()
    else openIOSModal()
  }

  useEffect(() => {
    setTimeLeft(10 * 60)
  }, [setTimeLeft])

  if (!selectedCoach || !coachName) {
    return <PageLoader />
  }

  return (
    <FlexBox direction="column" align="center" gap={SPACING.space4}>
      <TimerBanner coachName={coachName} />

      <FlexBox
        direction="column"
        align="center"
        gap={SPACING.space4}
        style={{
          position: "relative",
          marginTop: isMobileMediaQuery ? "80px" : "100px",
        }}
      >
        <ChatBubble
          text={`Hi! I'm ${coachName} and I can't wait to get started working with you!`}
        />
        <SmallCoachCard
          coachCardContent={selectedCoach}
          backgroundColor={JoonUIColor.background.primaryNeutral}
        />
      </FlexBox>

      <FlexBox direction="column" align="center" fullWidth>
        <Typography
          variant="bodyBold"
          color={JoonUIColor.text.primary}
          textAlign="center"
        >
          How frequently would you like to meet with {coachName}?
        </Typography>

        <PlanSlider />

        <Typography
          variant="bodyBold"
          color={JoonUIColor.text.primary}
          style={{ fontFamily: "Caveat" }}
        >
          per month
        </Typography>
      </FlexBox>

      <FlexBox direction="column" align="center" gap={SPACING.space0}>
        <PlanCarousel />

        <Typography
          variant={isMobileMediaQuery ? "bodyXSmall" : "bodySmall"}
          color={JoonUIColor.text.secondary}
          style={{
            textAlign: "left",
          }}
        >
          At Joon, we believe in transparent pricing:
          <ul style={{ marginTop: SPACING.space0, listStyleType: "disc" }}>
            <li>
              Monthly subscription cost is based on number of live sessions per
              month
            </li>
            <li>Anytime direct messaging with your coach is always included</li>
          </ul>
        </Typography>
      </FlexBox>

      <TextButton onClick={onSkipClick}>
        <Typography variant="bodyBold" color={JoonUIColor.text.primaryAccent}>
          No thanks, I don't want a coach after all
        </Typography>
      </TextButton>
    </FlexBox>
  )
}

export default PlanSelect
