import { ParentTrainingStatus } from "@joonapp/web-shared"

import AcademyPageHeader from "./AcademyPageHeader"
import Tour from "./tourAcademyTab/Tour"
import TrainingsList from "./TrainingsList"
import PageWrapper from "../../components/pageWrapper/PageWrapper"
import { GRADIENTS } from "../../constants"
import { useTrainingsQuery } from "../../networking/queries"
import { requireAuth } from "../../util/auth"

const AcademyPage = requireAuth(() => {
  const { isLoading: isLoadingIP } = useTrainingsQuery(
    ParentTrainingStatus.IN_PROGRESS
  )
  const { isLoading: isLoadingMastered } = useTrainingsQuery(
    ParentTrainingStatus.MASTERED
  )

  return (
    <PageWrapper
      style={{ background: GRADIENTS.gradient100, paddingBottom: "200px" }}
    >
      <AcademyPageHeader />
      <TrainingsList />
      {!isLoadingIP && !isLoadingMastered ? <Tour /> : <></>}
    </PageWrapper>
  )
})

const AcademyPageWithAuth = requireAuth(AcademyPage)
export default AcademyPageWithAuth
