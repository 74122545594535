import {
  ChevronRightIcon,
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"

import Shine from "../../../components/coaching/Shine"
import useMobile from "../../../hooks/useMobile"
import {
  cohorts,
  experimentNames,
  useGetExperimentCohort,
} from "../../../util/experiments"

interface ReadyButtonProps {
  onClick: () => void
  style?: React.CSSProperties
}

const ReadyButton = ({ onClick, style }: ReadyButtonProps) => {
  const isMobile = useMobile()

  const variant = useGetExperimentCohort(
    experimentNames.webParentVirtualCoachOnboarding
  )

  return (
    <button
      style={{
        position: "fixed",
        bottom: isMobile ? "76px" : "20px",
        width: "min(480px, 96%)",
        padding: 0,
        ...style,
      }}
      onClick={onClick}
    >
      <FlexBox
        direction="row"
        align="center"
        wrap={false}
        style={{
          position: "relative",
          background: JoonColorExpanded.indigo400,
          borderRadius: SPACING.space2,
          padding: `${SPACING.space3} ${SPACING.space2} ${SPACING.space3} ${SPACING.space4}`,
          marginTop: SPACING.space4,
          height: "72px",
          boxShadow: "4px 4px 6px rgba(0, 0, 0, 0.1)",
          overflow: "hidden",
        }}
      >
        <FlexBox direction="column" wrap={false}>
          <Typography
            variant="bodySmall"
            color={JoonUIColor.text.inverted}
            style={{ lineHeight: "1.4" }}
          >
            Ready to try it out?
          </Typography>
          <Typography
            variant="bodyBold"
            color={JoonUIColor.text.inverted}
            textAlign="left"
            style={{ lineHeight: "1.4" }}
          >
            {variant === cohorts.variantA || variant === cohorts.variantD
              ? "See our recommended coaches!"
              : "Book a risk-free 15 minute consultation!"}
          </Typography>
        </FlexBox>

        <div style={{ minWidth: "36px" }}>
          <ChevronRightIcon color={JoonUIColor.icon.inverted} size={28} />
        </div>

        <Shine />
      </FlexBox>
    </button>
  )
}

export default ReadyButton
