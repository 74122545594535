import {
  FamilyChildProfile,
  FlexBox,
  JoonColorExpanded,
  SPACING,
  Typography,
} from "@joonapp/web-shared"

import { useFamilyQuery } from "../../../networking/queries"
import { FamilyQuerySelect } from "../../../types"

type Props = {
  childId: number
  status?: string
}

const ChildBadge = ({ childId }: Props) => {
  const { data: children } = useFamilyQuery(FamilyQuerySelect.CHILDREN)
  const child = children?.find((child: FamilyChildProfile) => {
    return child.user.id === childId
  })?.user
  const childImage = child?.active_doter?.type
    ? `/images/avatars/${child?.active_doter?.type}.png`
    : "/images/avatars/egg.png"

  return (
    <FlexBox
      align="center"
      gap={SPACING.space1}
      style={{
        background: JoonColorExpanded.yellow200,
        width: "fit-content",
        padding: `${SPACING.space0} ${SPACING.space2} ${SPACING.space0} ${SPACING.space05}`,
        borderRadius: SPACING.space4,
      }}
    >
      <img
        style={{
          height: SPACING.space4,
          width: SPACING.space4,
          borderRadius: "50%",
        }}
        src={childImage}
        alt="Child Avatar"
      />
      <Typography variant="bodySmall">{child?.name}</Typography>
    </FlexBox>
  )
}

export default ChildBadge
