import {
  Button,
  ClockIcon,
  JoonUIColor,
  Modal,
  ModalHeader,
  SPACING,
  SelectInput,
} from "@joonapp/web-shared"
import { useState } from "react"

interface Props {
  modalTitle: string
  time: string | null | undefined
  setTime: (time: string | null) => void
  placeholder?: string
  fullWidth?: boolean
  name?: string
  disabled?: boolean
}

const hourOptions = Array.from({ length: 12 }, (_, i) => ({
  value: `${i + 1}`,
  label: `${i + 1}`,
}))
const minuteOptions = [
  {
    value: "00",
    label: "00",
  },
  {
    value: "15",
    label: "15",
  },
  {
    value: "30",
    label: "30",
  },
  {
    value: "45",
    label: "45",
  },
]

const TimeInput = ({
  modalTitle,
  time,
  setTime,
  placeholder,
  name,
  disabled,
  fullWidth,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false)

  const initialHour = time ? time?.split(":")[0] : "9"
  const initialMinute = time ? time?.split(":")[1]?.split(" ")[0] : "00"

  const [hour, setHour] = useState<any>({
    value: initialHour,
    label: initialHour,
  })
  const [minute, setMinute] = useState<any>({
    value: initialMinute,
    label: initialMinute,
  })
  const [amPm, setAmPm] = useState<any>(
    Number(initialHour) < 12
      ? { value: "AM", label: "AM" }
      : { value: "PM", label: "PM" }
  )

  const submit = () => {
    let timeString = `${hour.value}:${minute.value} ${amPm.value}`
    setTime(timeString)
    setIsOpen(false)
  }

  const innerIconColor = disabled
    ? JoonUIColor.icon.inverted
    : JoonUIColor.icon.neutral

  return (
    <>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} animate>
        <ModalHeader title={modalTitle} onClose={() => setIsOpen(false)} />
        <div
          style={{
            width: "min(100vw, 380px)",
            padding: `${SPACING.space2} ${SPACING.space6} ${SPACING.space6} ${SPACING.space6}`,
          }}
        >
          <div style={{ display: "flex", gap: SPACING.space1 }}>
            <SelectInput
              isMulti={false}
              name="hour"
              options={hourOptions}
              selectedOption={hour}
              setSelectedOption={setHour}
              placeholder="Hour"
              fullWidth
            />
            <SelectInput
              isMulti={false}
              name="minute"
              options={minuteOptions}
              selectedOption={minute}
              setSelectedOption={setMinute}
              placeholder="Minute"
              fullWidth
            />
            <SelectInput
              isMulti={false}
              name="amPm"
              options={["AM", "PM"].map((option) => ({
                value: option,
                label: option,
              }))}
              selectedOption={amPm}
              setSelectedOption={setAmPm}
              placeholder="AM/PM"
              fullWidth
            />
          </div>

          <Button
            text="Done"
            onClick={submit}
            style={{ marginTop: "24px" }}
            fullWidth
          />
        </div>
      </Modal>

      <button
        className={`jooniverse__text-input ${fullWidth ? "full-width" : ""} `}
        style={{ cursor: "pointer", minWidth: "unset" }}
        onClick={() => !disabled && setIsOpen(true)}
      >
        <ClockIcon color={innerIconColor} />
        <input
          type="text"
          name={name}
          value={time || undefined}
          placeholder={placeholder}
          disabled={disabled}
          aria-label={name}
          style={{ pointerEvents: "none" }}
        />
      </button>
    </>
  )
}

export default TimeInput
