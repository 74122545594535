import { Button, SPACING, TextInput, Typography } from "@joonapp/web-shared"
import { useQuery } from "@tanstack/react-query"
import dayjs from "dayjs"

import BackButton from "../../components/buttons/BackButton"
import PageLoader from "../../components/loading/PageLoader"
import PageContentWrapper from "../../components/pageContentWrapper/PageContentWrapper"
import PageHeader from "../../components/pageHeader/PageHeader"
import PageWrapper from "../../components/pageWrapper/PageWrapper"
import { GRADIENTS, QUERY_KEYS } from "../../constants"
import useMobile from "../../hooks/useMobile"
import { useFamilyQuery, useUserQuery } from "../../networking/queries"
import { useAuth } from "../../util/auth"
import { useRouter } from "../../util/router"
import { redirectToBilling } from "../../util/stripe"
import { capitalizeFirstLetter } from "../../util/util"
import { useCheckoutRedirect } from "../paywall/useCheckoutRedirect"

const getPlanType = (subscriberData: any) => {
  if (!subscriberData?.subscription?.store) return "None"
  const cleanedStore = subscriberData.subscription.store
    .split("_")
    .map(capitalizeFirstLetter)
    .join(" ")
  const cleanedPeriodType = capitalizeFirstLetter(
    subscriberData.subscription.period_type
  )

  return cleanedStore + " - " + cleanedPeriodType
}

const SettingsSubscription = () => {
  const router = useRouter()
  const isMobile = useMobile()

  const { getSubscriberInfo } = useAuth()
  const { user } = useUserQuery()
  const { data: family } = useFamilyQuery()
  const { isLoadingCheckoutFn, isRedirecting, setIsRedirecting } =
    useCheckoutRedirect()

  const { data: subscriberData, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.SUBSCRIBER_INFO],
    queryFn: getSubscriberInfo,
    select: ({ subscriber, activePlan }) => {
      const subscription = Object.values(subscriber.subscriptions).sort(
        (a, b) => dayjs(b.expires_date).unix() - dayjs(a.expires_date).unix()
      )[0]
      return {
        subscription,
        member_since: subscriber.first_seen,
        renewal: subscription.expires_date,
        activePlan,
        expires_date: subscriber.entitlements.premium.expires_date,
        unsubscribe_detected_at: subscription.unsubscribe_detected_at,
      }
    },
    enabled: !!family,
  })

  const planType = getPlanType(subscriberData)

  const hasExpiredPlan =
    dayjs(subscriberData?.expires_date).unix() < dayjs().unix()
  const hasUnsubscribed = subscriberData?.unsubscribe_detected_at !== null

  if (isLoading) return <PageLoader />
  if (!user) return <></>

  return (
    <PageWrapper style={{ background: GRADIENTS.gradient100 }}>
      <PageHeader
        title="Subscription"
        leftComponent={
          isMobile && <BackButton onBack={() => router.push("/settings")} />
        }
      />
      <PageContentWrapper mobileGutter={true}>
        <Typography variant="caption">Subscription Type</Typography>
        <TextInput
          name="Subscription Type"
          onChange={() => {}}
          disabled
          style={{ height: 50, width: "100%", marginBottom: "10px" }}
          value={
            subscriberData?.activePlan.isActive
              ? planType[0]?.toUpperCase() + planType.slice(1)
              : "None"
          }
        />
        <Typography variant="caption">Member Since</Typography>
        <TextInput
          name="Member Since"
          onChange={() => {}}
          disabled
          value={
            subscriberData?.member_since
              ? dayjs(subscriberData?.member_since)?.format("MMMM D, YYYY")
              : "-"
          }
          style={{ height: 50, width: "100%", marginBottom: "10px" }}
        />
        <Typography variant="caption">
          {hasExpiredPlan
            ? "Expired On"
            : hasUnsubscribed
              ? "Expiring On"
              : "Renewing on"}
        </Typography>
        <TextInput
          name="Renewal Date"
          onChange={() => {}}
          value={
            subscriberData?.renewal
              ? dayjs(subscriberData?.renewal)?.format("MMMM D, YYYY")
              : "-"
          }
          disabled
          style={{ height: 50, width: "100%" }}
        />
        {!subscriberData?.activePlan.isActive ? (
          <Button
            text="Purchase Subscription"
            isLoading={isLoadingCheckoutFn && isRedirecting}
            style={{ marginTop: SPACING.space6, marginBottom: "10px" }}
            onClick={() => setIsRedirecting(true)}
          />
        ) : (
          subscriberData?.subscription?.store === "stripe" && (
            <Button
              style={{ marginTop: SPACING.space6, marginBottom: "10px" }}
              onClick={() => redirectToBilling(user?.email)}
              text="Manage Subscription"
              buttonType="secondary"
            />
          )
        )}
      </PageContentWrapper>
    </PageWrapper>
  )
}

export default SettingsSubscription
