import { JoonColorExpanded } from "@joonapp/web-shared"

import useCoachStore from "./useCoachStore"
import useMobile from "../../hooks/useMobile"

const SBStyles = () => {
  const isMobile = useMobile()
  const { isFocused, isInfoModalOpen } = useCoachStore()
  return (
    <style>{`
      /* page wrapper */
      .jooniverse__page-content-wrapper  {
        position: relative !important;
        padding: 0 !important;
        width: 100% !important;
        height: 100% !important;
        overflow: hidden !important;
      }
      /* input */
      #sendbird-message-input-text-field {
        padding: 12px 48px 12px 16px !important;
        font-size: 16px !important;
        border-radius: 28px !important;
        background-color: #EEEEEE !important;
        border: none !important;
      }
      .sendbird-message-input--placeholder {
        font-size: 16px !important;
        top: 12px !important;
      }
      .sendbird-message-input .sendbird-iconbutton {
        bottom: 9px !important;
      }
      /* reply */
      .sendbird-message-input-wrapper__quote-message-input {
        border: none !important;
      }
      /* button */
      .sendbird-button {
        border-radius: 24px !important;
      }
      /* reaction */
      .sendbird-message-content-reactions {
        min-width: auto !important;
        max-width: 240px !important;
      }
      .sendbird-emoji-reactions__add-reaction-badge {
        position: relative !important;
        z-index: ${isInfoModalOpen ? 0 : 1000} !important;
      }
      /* edit message */
      .sendbird-message-input__edit {
        background-color: #FFFFFF !important;
        padding: 12px !important;
        border-radius: 24px !important;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10) !important;
      }
      .sendbird-message-input__edit .sendbird-message-input .sendbird-message-input-text-field {
        padding: 12px 48px 12px 16px !important;
        font-size: 16px !important;
        border-radius: 28px !important;
        background-color: #EEEEEE !important;
        border: none !important;
      }
      /* conversation */
      .sendbird-conversation {
        border: none !important;
        height: ${isMobile && !isFocused ? "calc(100dvh - 70px)" : "calc(100dvh)"};
        max-width: 100vw;
        background-color: #FFFFFF !important;
        -webkit-overflow-scrolling: touch;
      }
      .sendbird-conversation__messages {
        overflow: auto !important;
        flex: 1 1 auto !important;
        min-height: 0 !important;
      }
      .sendbird-conversation__scroll-container {
        overflow: auto !important;
      }
      .sendbird-conversation__messages-padding {
        padding: 0px 12px !important;
      }
      .sendbird-message-content__middle__message-item-body.sendbird-text-message-item-body.outgoing {
        max-width: 240px !important;
      }
      .sendbird-message-content__middle__message-item-body.sendbird-file-message-item-body.outgoing {
        max-width: 240px !important;
      }
      .sendbird-message-content__middle__message-item-body.sendbird-text-message-item-body.incoming {
        max-width: 240px !important;
        background-color: ${JoonColorExpanded.indigo100} !important;
      }
      .sendbird-message-content__middle__message-item-body.sendbird-file-message-item-body.incoming {
        max-width: 240px !important;
        background-color: ${JoonColorExpanded.indigo100} !important;
      }
      /* images */
      .sendbird-message-content__middle__message-item-body.sendbird-thumbnail-message-item-body.incoming {
        min-width: 240px !important;
        max-width: 240px !important;
        min-height: 240px !important;
        max-height: 240px !important;
        background-color: ${JoonColorExpanded.indigo100} !important;
      }
      .sendbird-message-content__middle__message-item-body.sendbird-thumbnail-message-item-body.outgoing {
        min-width: 240px !important;
        max-width: 240px !important;
        min-height: 240px !important;
        max-height: 240px !important;
        background-color: ${JoonColorExpanded.purple600} !important;
      }
      .sendbird-thumbnail-message-item-body__thumbnail .sendbird-image-renderer {
        min-width: 240px !important;
        max-width: 240px !important;
        min-height: 240px !important;
        max-height: 240px !important;
      }
      .sendbird-thumbnail-message-item-body__thumbnail .sendbird-image-renderer__image {
        min-width: 240px !important;
        max-width: 240px !important;
        min-height: 240px !important;
        max-height: 240px !important;
      }
      /* multi images */
      .sendbird-image-grid-wrap {
        min-width: 240px !important;
        max-width: 240px !important;
        min-height: 240px !important;
        max-height: 240px !important;
      }
      .sendbird-message-content__middle__message-item-body.sendbird-image-grid {
        min-width: 240px !important;
        max-width: 240px !important;
        min-height: 240px !important;
        max-height: 240px !important;
      }
      .sendbird-multiple-files-image-renderer-wrapper {
        min-width: 114px !important;
        max-width: 114px !important;
        min-height: 114px !important;
        max-height: 114px !important;
        border-radius: 12px !important;
      }
      .sendbird-multiple-files-image-renderer-wrapper .sendbird-image-renderer__image {
        min-width: 114px !important;
        max-width: 114px !important;
        min-height: 114px !important;
        max-height: 114px !important;
        border-radius: 12px !important;
      }
      .sendbird-multiple-files-image-renderer__image-cover {
        min-width: 114px !important;
        max-width: 114px !important;
        min-height: 114px !important;
        max-height: 114px !important;
        border-radius: 12px !important;
      }
      /* footer */
      .sendbird-conversation__footer {
        margin-top: 0 !important;
        background-color: #FFFFFF !important;
        padding: 10px 0 !important;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10) !important;
        flex: 0 0 auto !important;
      }
      /* modal */
      .sendbird-modal {
        z-index: 1000;
      }
      .sendbird-modal__content {
        border-radius: 24px !important;
      }
      .sendbird-modal__footer {
        margin-top: 0 !important;
      }
      .sendbird-fileviewer__header__right__actions__download {
        width: 30px;
      }
      .sendbird-fileviewer__header__right__actions__delete {
        width: 30px;
      }
      .sendbird-fileviewer__header__right__actions {
        width: 84px;
      }
      .sendbird-fileviewer__header__left__filename {
        font-size: 14px;
      }
      .sendbird-fileviewer__header__left__sender-name {
        font-size: 12px;
      }
    `}</style>
  )
}

export default SBStyles
