import {
  Button,
  CheckIcon,
  CloseIcon,
  FlexBox,
  InfoIcon,
  JoonUIColor,
  Modal,
  ModalHeader,
  SPACING,
  Shadow,
  SkipIcon,
  TextButton,
  ThumbsDownIcon,
  TrashIcon,
  Typography,
  UserRole,
} from "@joonapp/web-shared"
import { useQueryClient } from "@tanstack/react-query"
import dayjs from "dayjs"
import { useState } from "react"
import toast from "react-hot-toast"
import { create } from "zustand"

import { useQBDatePickerStore } from "./QuestBoardDatePicker"
import useQuestBoardMultiSelect from "./useQuestBoardMultiSelect"
import useQuestBoardMultiSelectStore from "./useQuestBoardMultiSelectStore"
import { QUERY_KEYS } from "../../constants"
import useMobile from "../../hooks/useMobile"
import { useQuestBoardQuery } from "../../networking/queries"
import { deleteQuest } from "../../networking/quests"

const QuestBoardMultiSelect = () => {
  const { selectedQuestIds, isMultiSelectMode } =
    useQuestBoardMultiSelectStore()
  const { data: quests } = useQuestBoardQuery()
  const { onClickCompleteQuests, onClickRejectQuests, onClickSkipQuests } =
    useQuestBoardMultiSelect()
  const { onOpen: openDeleteQuestModal } = useDeleteQuestModalStore()
  const { questBoardDate } = useQBDatePickerStore()

  const isMobile = useMobile()
  const isInFuture = dayjs().isBefore(questBoardDate, "day")

  const hasTherapistModifiedQuest = selectedQuestIds.some((questId) => {
    const quest = quests?.find((quest) => quest.id === questId)
    const isTherapistCreated =
      quest?.series.assigner_profile?.role === UserRole.THERAPIST
    const isTherapistModified = !!quest?.series.current_frequency_target
    return isTherapistCreated || isTherapistModified
  })

  const isDeleteDisabled = hasTherapistModifiedQuest
  const isCompleteDisabled = isInFuture
  const isRejectDisabled = isInFuture

  if (!isMultiSelectMode) return <></>

  return (
    <>
      <FlexBox
        direction="column"
        align="center"
        justify="center"
        gap={SPACING.space2}
        style={{
          position: "fixed",
          background: JoonUIColor.background.accent,
          borderRadius: SPACING.space4,
          borderBottomLeftRadius: isMobile ? 0 : SPACING.space4,
          borderBottomRightRadius: isMobile ? 0 : SPACING.space4,
          bottom: isMobile ? 0 : SPACING.space4,
          left: isMobile ? 0 : "unset",
          padding: SPACING.space3,
          width: isMobile ? "100vw" : "500px",
          zIndex: 900,
        }}
      >
        {isDeleteDisabled && (
          <FlexBox
            direction="row"
            align="center"
            wrap={false}
            gap={SPACING.space1}
            style={{
              padding: SPACING.space2,
              background: JoonUIColor.background.lightBlue,
              borderRadius: SPACING.space2,
            }}
          >
            <InfoIcon size={18} color={JoonUIColor.semantic.info} />
            <Typography variant="caption" color={JoonUIColor.semantic.info}>
              Therapist added or modified Quests can only be deleted by them.
            </Typography>
          </FlexBox>
        )}
        <Typography variant="caption" color={JoonUIColor.text.inverted}>
          {selectedQuestIds.length} Quest
          {selectedQuestIds.length > 1 ? "s" : ""} selected
        </Typography>
        <FlexBox
          direction="row"
          align="center"
          justify="space-around"
          gap={SPACING.space2}
          wrap={false}
          style={{ padding: `${SPACING.space2} 0` }}
        >
          <button
            style={{
              padding: "0",
              cursor: isDeleteDisabled ? "not-allowed" : "pointer",
            }}
            onClick={() => {
              !isDeleteDisabled && openDeleteQuestModal()
            }}
          >
            <FlexBox align="center" justify="center" direction="column">
              <TrashIcon
                size={24}
                color={
                  isDeleteDisabled
                    ? JoonUIColor.text.disabled
                    : JoonUIColor.icon.inverted
                }
              />
              <Typography
                variant="caption"
                color={
                  isDeleteDisabled
                    ? JoonUIColor.text.disabled
                    : JoonUIColor.text.inverted
                }
              >
                Delete
              </Typography>
            </FlexBox>
          </button>
          <button
            style={{
              padding: "0",
              cursor: isRejectDisabled ? "not-allowed" : "pointer",
            }}
            onClick={() => !isRejectDisabled && onClickRejectQuests()}
          >
            <FlexBox align="center" justify="center" direction="column">
              <ThumbsDownIcon
                size={24}
                color={
                  isRejectDisabled
                    ? JoonUIColor.text.disabled
                    : JoonUIColor.icon.inverted
                }
              />
              <Typography
                variant="caption"
                color={
                  isRejectDisabled
                    ? JoonUIColor.text.disabled
                    : JoonUIColor.icon.inverted
                }
              >
                Reject
              </Typography>
            </FlexBox>
          </button>
          <button style={{ padding: "0" }} onClick={onClickSkipQuests}>
            <FlexBox align="center" justify="center" direction="column">
              <SkipIcon size={24} color={JoonUIColor.icon.inverted} />
              <Typography variant="caption" color={JoonUIColor.text.inverted}>
                Skip
              </Typography>
            </FlexBox>
          </button>
          <button
            style={{ padding: "0" }}
            onClick={() => !isCompleteDisabled && onClickCompleteQuests()}
          >
            <FlexBox align="center" justify="center" direction="column">
              <CheckIcon
                size={24}
                color={
                  isCompleteDisabled
                    ? JoonUIColor.text.disabled
                    : JoonUIColor.icon.inverted
                }
              />
              <Typography
                variant="caption"
                color={
                  isCompleteDisabled
                    ? JoonUIColor.text.disabled
                    : JoonUIColor.icon.inverted
                }
              >
                Complete
              </Typography>
            </FlexBox>
          </button>
        </FlexBox>
      </FlexBox>
      <DeleteQuestModal />
    </>
  )
}

export const QuestBoardMultiSelectCounter = () => {
  const [isHovered, setIsHovered] = useState(false)
  const { selectedQuestIds, setMultiSelectMode } =
    useQuestBoardMultiSelectStore()

  return (
    <button style={{ padding: 0 }} onClick={() => setMultiSelectMode(false)}>
      <FlexBox
        direction="row"
        align="center"
        gap={SPACING.space1}
        style={{
          background: JoonUIColor.background.accent,
          borderRadius: SPACING.space4,
          padding: `${SPACING.space1} ${SPACING.space4} ${SPACING.space1} ${SPACING.space2}`,
          width: "fit-content",
          position: "fixed",
          boxShadow: Shadow.high,
          opacity: isHovered ? 0.9 : 1,
          zIndex: 900,
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <CloseIcon size={20} color={JoonUIColor.icon.inverted} />
        <Typography variant="caption" color={JoonUIColor.text.inverted}>
          Selected: {selectedQuestIds.length || 0}
        </Typography>
      </FlexBox>
    </button>
  )
}

interface DeleteQuestModalStore {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

const useDeleteQuestModalStore = create<DeleteQuestModalStore>((set) => ({
  isOpen: false,
  onOpen: () => set({ isOpen: true }),
  onClose: () => set({ isOpen: false }),
}))

const DeleteQuestModal = () => {
  const { isOpen, onClose } = useDeleteQuestModalStore()
  const { data } = useQuestBoardQuery()
  const { selectedQuestIds, setMultiSelectMode } =
    useQuestBoardMultiSelectStore()

  const [isDeleting, setIsDeleting] = useState(false)

  const queryClient = useQueryClient()

  // Writing out a function instead of a mutation
  // Do we want some sort of optimistic update / UI change for this too?
  const onClickDeleteQuests = async () => {
    try {
      if (!data) throw new Error("No data")

      // get list of quest series ids from the selected (instance) ids
      const questSeriesIds = selectedQuestIds.map(
        (questId) => data.find((quest) => quest.id === questId)?.series.id
      )

      const deleteQuestPromises = questSeriesIds.map(
        (questId) => questId && deleteQuest(questId)
      )
      setIsDeleting(true)
      await Promise.all(deleteQuestPromises)
      queryClient.invalidateQueries([QUERY_KEYS.QUEST_BOARD])
      queryClient.invalidateQueries([QUERY_KEYS.QUEST_BOARD_REORDER])
      setMultiSelectMode(false)
      onClose()
      toast.success(
        `${selectedQuestIds.length} Quest${
          selectedQuestIds.length > 1 ? "s" : ""
        } deleted`
      )
    } catch (error) {
      toast.error(
        `Failed to delete quest${selectedQuestIds.length > 1 ? "s" : ""}`
      )
    } finally {
      setIsDeleting(false)
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} animate>
      <ModalHeader title="Delete Quest" onClose={onClose} showBorderBottom />
      <FlexBox
        direction="column"
        align="center"
        justify="center"
        gap={SPACING.space2}
        style={{
          padding: SPACING.space6,
          paddingTop: SPACING.space4,
        }}
      >
        <Typography
          variant="body"
          color={JoonUIColor.text.primary}
          textAlign="left"
        >
          Are you sure you want to delete the selected Quest
          {selectedQuestIds.length > 1 ? "s" : ""}?
        </Typography>
        <Typography variant="body" textAlign="left">
          This action cannot be undone.
        </Typography>
        <FlexBox
          direction="row"
          align="center"
          justify="flex-end"
          gap={SPACING.space4}
          wrap={false}
          style={{ padding: `${SPACING.space2} 0`, marginTop: SPACING.space2 }}
        >
          <TextButton onClick={onClose}>
            <Typography
              variant="bodyBold"
              color={JoonUIColor.text.primaryAccent}
            >
              Cancel
            </Typography>
          </TextButton>
          <Button
            text="Delete Quests"
            onClick={onClickDeleteQuests}
            buttonType="redPrimary"
            isLoading={isDeleting}
          />
        </FlexBox>
      </FlexBox>
    </Modal>
  )
}

export default QuestBoardMultiSelect
