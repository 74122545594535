import {
  ChevronRightIcon,
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { isAndroid, isChrome, isMobile } from "react-device-detect"

import ListCard from "../../../components/coaching/ListCard"
import { ProgramStepIcon } from "../../../components/coaching/ProgramStepIcon"
import Shine from "../../../components/coaching/Shine"
import { useAddToHomescreenModal } from "../../../components/modals/AddToHomescreenModal"
import { useIOSModal } from "../../../components/modals/IOSModal"
import { TrainingStep } from "../../../constants"
import useMobile from "../../../hooks/useMobile"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"
import { useRouter } from "../../../util/router"
import { useCoachingStore } from "../useCoachingStore"

const ProgramDetailsPage = () => {
  const router = useRouter()
  const { setStep } = useCoachingStore()
  const { onOpen: openIOSModal } = useIOSModal()
  const { onOpen: openAddToHomescreenModal } = useAddToHomescreenModal()

  const isOnMobileChrome = isChrome && isMobile && isAndroid
  const isMobileWidth = useMobile()

  const onContinueClick = () => {
    trackAnalyticEvent(ANALYTIC_EVENTS.VIRTUAL_COACH_CONTINUE_CLICK, {
      step: TrainingStep.PROGRAM_DETAILS - TrainingStep.LANDING,
    })
    setStep(TrainingStep.PHONE_NUMBER)
  }

  const onSkipClick = () => {
    trackAnalyticEvent(ANALYTIC_EVENTS.VIRTUAL_COACH_SKIP_CLICK, {
      step: TrainingStep.PROGRAM_DETAILS - TrainingStep.LANDING,
    })
    router.push("/me")
    if (isOnMobileChrome) openAddToHomescreenModal()
    else openIOSModal()
  }

  return (
    <FlexBox
      justify="flex-start"
      align="center"
      direction="column"
      fullWidth
      gap={SPACING.space4}
      style={{ minHeight: "70vh", paddingTop: "20px" }}
    >
      <Typography variant="h2" color={JoonUIColor.text.primary}>
        Virtual Parent Training
      </Typography>
      <FlexBox
        direction="column"
        align="center"
        justify="center"
        gap={SPACING.space3}
        fullWidth
      >
        <Typography variant="bodyBold" color={JoonUIColor.text.primary}>
          Program Details
        </Typography>
        <div
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
            zIndex: 0,
          }}
        >
          <ListCard
            icon={ProgramStepIcon}
            text="Meet with a care navigator for 15-minutes to discuss your goals and preferences, and see if this is the most appropriate option"
            backgroundColor={JoonUIColor.background.lightAccent}
            gap={SPACING.space2}
            step={1}
          />
          <ListCard
            icon={ProgramStepIcon}
            text="Choose a licensed behavior therapist that best fits your needs and insurance coverage"
            backgroundColor={JoonUIColor.background.lightAccent}
            gap={SPACING.space2}
            step={2}
          />
          <ListCard
            icon={ProgramStepIcon}
            text="Join weekly virtual sessions with your behavior therapist with a personalized treatment plan"
            backgroundColor={JoonUIColor.background.lightAccent}
            gap={SPACING.space2}
            step={3}
          />
          <ListCard
            icon={ProgramStepIcon}
            text="Message and receive follow-up materials from your clinician through the Joon app"
            backgroundColor={JoonUIColor.background.lightAccent}
            gap={SPACING.space2}
            step={4}
          />
          <div
            style={{
              position: "absolute",
              height: "90%",
              left: "23px",
              backgroundColor: "transparent",
              borderLeft: `1px dashed ${JoonColorExpanded.indigo200}`,
              zIndex: -1,
            }}
          />
        </div>
      </FlexBox>
      <FlexBox
        direction="row"
        align="center"
        justify="left"
        wrap={false}
        fullWidth
        onClick={onContinueClick}
        style={{
          position: "relative",
          background: JoonColorExpanded.yellow100,
          borderRadius: SPACING.space2,
          padding: `${SPACING.space3} ${SPACING.space2} ${SPACING.space3} ${SPACING.space4}`,
          marginTop: SPACING.space4,
          cursor: "pointer",
          maxHeight: "72px",
          boxShadow: "4px 4px 6px rgba(0, 0, 0, 0.1)",
          overflow: "hidden",
        }}
      >
        <FlexBox direction="column" gap={SPACING.space1} wrap={false}>
          <Typography
            variant="bodyXSmall"
            color={JoonUIColor.text.secondary}
            style={{ lineHeight: "1.1" }}
          >
            Ready to get started?
          </Typography>
          <Typography
            variant="caption"
            color={JoonUIColor.text.primary}
            textAlign="left"
            style={{ lineHeight: "1.1" }}
          >
            Book your risk-free 15 minute consultation!
          </Typography>
        </FlexBox>
        <div
          style={{
            display: "flex",
            justifyContent: "left",
            paddingLeft: SPACING.space3,
            alignItems: "center",
            width: "80px",
            height: "100px",
            borderRadius: "50%",
            transform: isMobileWidth ? "translateX(20px)" : "translateX(24px)",
            backgroundColor: JoonColorExpanded.yellow300,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: SPACING.space05,
              paddingLeft: SPACING.space1,
              backgroundColor: JoonColorExpanded.yellow200,
              borderRadius: "50%",
            }}
          >
            <ChevronRightIcon color={JoonUIColor.icon.neutral} size={28} />
          </div>
        </div>
        <Shine />
      </FlexBox>

      <FlexBox
        direction="column"
        align="center"
        gap={SPACING.space3}
        style={{ marginBottom: "70px" }}
      >
        <TextButton onClick={onSkipClick}>
          <Typography variant="bodyBold" color={JoonUIColor.text.primaryAccent}>
            Skip, Continue to Joon App
          </Typography>
        </TextButton>
      </FlexBox>
    </FlexBox>
  )
}

export default ProgramDetailsPage
