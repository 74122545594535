import {
  CheckIcon,
  FlexBox,
  JoonUIColor,
  LearningModuleAssignment,
  SPACING,
  Typography,
} from "@joonapp/web-shared"

import useTrainingStore from "./training/useTrainingStore"
import { getModuleTheme } from "./training/utils"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { useRouter } from "../../util/router"

const ModuleAssignmentListItem = ({
  assignment,
  isLastItem,
  index,
}: {
  assignment: LearningModuleAssignment
  isLastItem: boolean
  index: number
}) => {
  const { setModuleIndex, onOpen } = useTrainingStore()
  const isCompleted = !!assignment.assignment_completion.date_completed
  const { moduleText } = getModuleTheme(assignment.learning_module.type)

  const router = useRouter()
  const selectedTrainingId = router.params.id

  const onClickAssignment = () => {
    if (!selectedTrainingId) return
    onOpen(+selectedTrainingId)
    setModuleIndex(index)
    trackAnalyticEvent(ANALYTIC_EVENTS.OPEN_LEARNING_MODULE)
  }

  return (
    <button style={{ padding: 0, width: "100%" }} onClick={onClickAssignment}>
      <FlexBox
        direction="row"
        wrap={false}
        align="center"
        justify="space-between"
        gap={SPACING.space3}
        style={{
          padding: `${SPACING.space2} ${SPACING.space4}`,
          background: JoonUIColor.background.primaryNeutral,
          borderBottom: !isLastItem
            ? `1px solid ${JoonUIColor.border.default}`
            : undefined,
        }}
      >
        <FlexBox direction="column" style={{ overflow: "hidden" }}>
          <Typography
            variant="bodySmall"
            color={JoonUIColor.text.secondary}
            textAlign="left"
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {moduleText}
          </Typography>
          <Typography
            variant="bodyBold"
            textAlign="left"
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              width: "100%",
              textOverflow: "ellipsis",
            }}
          >
            {assignment.learning_module.title}
          </Typography>
        </FlexBox>

        <FlexBox
          align="center"
          justify="center"
          style={{
            height: SPACING.space5,
            width: SPACING.space5,
            borderRadius: SPACING.space1,
            border: `2px solid ${JoonUIColor.border.accent}`,
            background: isCompleted ? JoonUIColor.background.accent : "white",
          }}
        >
          <CheckIcon size={16} color={JoonUIColor.background.primaryNeutral} />
        </FlexBox>
      </FlexBox>
    </button>
  )
}

export default ModuleAssignmentListItem
