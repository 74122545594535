import { create } from "zustand"

export enum IncidentReportStep {
  SELECT_CHILD,
  SELECT_BEHAVIOR,
  SELECT_STRATEGY,
  SUCCESS,
}

interface IncidentReportStore {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void

  step: IncidentReportStep
  setStep: (step: IncidentReportStep) => void

  selectedChildId: number | null
  setSelectedChildId: (id: number) => void

  // Incident report data
  selectedBehaviorId: number | null // 0 is "None of the above"
  behaviorDescription: string
  incidentDate: Date
  incidentTime: string | null
  incidentDuration: string | null
  incidentIntensity: number | null
  responseLevel: number | null
  selectedStrategyId: number | null // 0 is "None of the above"
  strategyDescription: string
  antecedent: string | null
  consequence: string | null
  frequency: string | null

  setSelectedBehaviorId: (id: number) => void
  setBehaviorDescription: (description: string) => void
  setIncidentDate: (date: Date) => void
  setIncidentTime: (time: string | null) => void
  setIncidentDuration: (duration: string | null) => void
  setIncidentIntensity: (intensity: number) => void
  setResponseLevel: (level: number) => void
  setSelectedStrategyId: (id: number) => void
  setStrategyDescription: (description: string) => void
  setAntecedent: (antecedence: string) => void
  setConsequence: (consequence: string) => void
  setFrequency: (frequency: string) => void

  resetIncidentReport: () => void
}

const initialData = {
  step: IncidentReportStep.SELECT_CHILD,
  selectedChildId: null,
  selectedBehaviorId: null,
  behaviorDescription: "",
  incidentDate: new Date(),
  incidentTime: null,
  incidentDuration: null,
  incidentIntensity: null,
  responseLevel: null,
  selectedStrategyId: null,
  strategyDescription: "",
  antecedent: null,
  consequence: null,
  frequency: null,
} as const

const useIncidentReportStore = create<IncidentReportStore>((set, get) => ({
  isOpen: false,
  onOpen: () => set({ isOpen: true }),
  onClose: () => set({ isOpen: false, ...initialData }),

  get isFreeFormBehavior() {
    return get().selectedBehaviorId === 0
  },

  // Incident report data
  ...initialData,
  setSelectedChildId: (selectedChildId) => set({ selectedChildId }),
  setStep: (step) => set({ step }),
  setSelectedBehaviorId: (selectedBehaviorId) => set({ selectedBehaviorId }),
  setBehaviorDescription: (behaviorDescription) => set({ behaviorDescription }),
  setIncidentDate: (incidentDate) => set({ incidentDate }),
  setIncidentTime: (incidentTime) => set({ incidentTime }),
  setIncidentDuration: (incidentDuration) => set({ incidentDuration }),
  setIncidentIntensity: (incidentIntensity) => set({ incidentIntensity }),
  setResponseLevel: (responseLevel) => set({ responseLevel }),
  setSelectedStrategyId: (selectedStrategyId) => set({ selectedStrategyId }),
  setStrategyDescription: (strategyDescription) => set({ strategyDescription }),
  setAntecedent: (antecedent) => set({ antecedent }),
  setConsequence: (consequence) => set({ consequence }),
  setFrequency: (frequency) => {
    if (!frequency) set({ frequency: null })
    else if (Number.isNaN(+frequency)) return
    else set({ frequency })
  },

  resetIncidentReport: () => set({ ...initialData }),
}))

export default useIncidentReportStore
