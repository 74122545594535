import { FlexBox, ModuleType } from "@joonapp/web-shared"

import PDFModule from "./modules/PDFModule"
import QuizModule from "./modules/QuizModule"
import ReadingModule from "./modules/ReadingModule"
import ShortAnswer from "./modules/ShortAnswerModule"
import VideoModule from "./modules/VideoModule"
import useTraining from "./useTraining"
import useMobile from "../../../hooks/useMobile"

const TrainingModuleFlow = () => {
  const { currentAssignment } = useTraining()
  const isMobile = useMobile()

  const currentModule = () => {
    switch (currentAssignment?.learning_module.type) {
      case ModuleType.MULTIPLE_CHOICE:
        return <QuizModule />
      case ModuleType.PDF:
        return <PDFModule />
      case ModuleType.READING_TEXTS:
        return <ReadingModule />
      case ModuleType.VIDEO:
        return <VideoModule />
      case ModuleType.SHORT_ANSWERS:
        return <ShortAnswer />
      default:
        return null
    }
  }

  return (
    <FlexBox
      direction="column"
      wrap={false}
      style={{
        width: isMobile ? "100vw" : "min(600px, 100vw)",
        overflowY: "auto",
        height: "100%",
        minHeight: "70vh",
      }}
    >
      {currentModule()}
    </FlexBox>
  )
}

export default TrainingModuleFlow
