import { JoonUIColor, Loader, SPACING, Typography } from "@joonapp/web-shared"
import { useState } from "react"

import { getAfterAuthPath } from "./ForgotPassword"
import { useAccountCreation } from "../../hooks/useOnboardContext"
import {
  signUpWithGoogle,
  signInWithGoogle,
} from "../../networking/authentication"
import { AlertInterface } from "../../types"
import { useRouter } from "../../util/router"

interface Props {
  type: "signin" | "signup"
  setFormAlert?: (alert: AlertInterface) => void
}

function GoogleAuth({ setFormAlert = () => {}, type }: Props) {
  const [pending, setPending] = useState(false)
  const { uploadOnboardingSecondaryData } = useAccountCreation()

  const router = useRouter()

  const onSigninWithProvider = async () => {
    setPending(true)
    try {
      await signInWithGoogle()
      router.push(getAfterAuthPath(router))
    } catch (err) {
      // This is assuming that the error is because the user does not exist
      setFormAlert({
        type: "error",
        message: "User does not exist. Please sign up.",
      })
    } finally {
      setPending(false)
    }
  }

  const onSignUpWithProvider = async () => {
    setPending(true)
    try {
      await signUpWithGoogle()
      uploadOnboardingSecondaryData()
    } catch (error) {
    } finally {
      setPending(false)
    }
  }

  return (
    <button
      type="submit"
      disabled={pending}
      onClick={type === "signin" ? onSigninWithProvider : onSignUpWithProvider}
      style={{
        borderRadius: "40px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        border: `1px solid #ddd`,
        position: "relative",
        textAlign: "center",
        justifyContent: "center",
        padding: `10px ${SPACING.space4}`,
        background: "white",
      }}
    >
      <div
        style={{
          position: "absolute",
          alignItems: "center",
          display: "inline-flex",
          justifyContent: "center",
          width: "1.5em",
          height: "1.5em",
          left: SPACING.space4,
        }}
      >
        <img
          src={`https://uploads.divjoy.com/icon-google.svg`}
          alt={"Google"}
          style={{ display: "block", width: "100%" }}
        />
      </div>
      {pending ? (
        <Loader color={JoonUIColor.background.accent} size={24} thickness={2} />
      ) : (
        <Typography
          variant="body"
          color={JoonUIColor.text.secondary}
          style={{ fontWeight: "600" }}
        >
          {type === "signin" ? "Sign in" : "Sign up"} with Google
        </Typography>
      )}
    </button>
  )
}

export default GoogleAuth
