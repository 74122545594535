import "./inputSectionBox.scss"
import React from "react"

type Props = {
  children: React.ReactNode
  hidden?: boolean
  style?: React.CSSProperties
}

const InputSectionBox = ({ children, hidden = false, style }: Props) => {
  return (
    <div
      className={`input-section-box ${hidden ? "hidden" : ""}`}
      style={style}
    >
      {children}
    </div>
  )
}

export default InputSectionBox
