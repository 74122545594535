import {
  ChevronRightIcon,
  CircleIcon,
  CoachProfile,
  CoachTagCategory,
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  StarIcon,
  Typography,
} from "@joonapp/web-shared"
import { useEffect, useRef, useState } from "react"
import Skeleton from "react-loading-skeleton"

import { useCoachingStore } from "./useCoachingStore"
import { CoachingStep } from "../../constants"
import { useCoachPostSelectionQuestionnaireQuery } from "../../networking/queries"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import {
  cohorts,
  experimentNames,
  useGetExperimentCohort,
} from "../../util/experiments"
import { useRouter } from "../../util/router"
import CareTeamAvatar from "../care/CareTeamAvatar"

interface RecommendedCoachCardProps {
  coachCardContent: CoachProfile
}

const RecommendedCoachCard = ({
  coachCardContent,
}: RecommendedCoachCardProps) => {
  const { setSelectedCoach, setStep } = useCoachingStore()
  const [isExpanded, setIsExpanded] = useState(false)
  const [overflowActive, setOverflowActive] = useState(false)

  const variant = useGetExperimentCohort(
    experimentNames.webParentVirtualCoachOnboarding
  )

  const bioRef = useRef<HTMLDivElement>(null)
  const {
    bio,
    user: { id, name, last_name },
    tags,
    rating,
    num_ratings,
    num_open_slots,
  } = coachCardContent
  const router = useRouter()

  const { data: postSelectionQuestionnaire } =
    useCoachPostSelectionQuestionnaireQuery()

  const specialties = tags
    .filter((tag) => tag.category === CoachTagCategory.SPECIALTIES)
    .map((tag) => tag.name)

  const approaches = tags
    .filter((tag) => tag.category === CoachTagCategory.APPROACHES)
    .map((tag) => tag.name)

  const credentials = tags
    .filter((tag) => tag.category === CoachTagCategory.CREDENTIALS)
    .map((tag) => tag.name)

  const onReadMoreClick = () => {
    if (!isExpanded) {
      if (router.query.from === "academy")
        trackAnalyticEvent(ANALYTIC_EVENTS.MARKETING_COACH_READ_MORE_CLICK, {
          coach_id: id,
        })
      else
        trackAnalyticEvent(ANALYTIC_EVENTS.PERSONALIZED_COACH_READ_MORE_CLICK, {
          coach_id: id,
        })
    }
    setIsExpanded(!isExpanded)
  }

  const onSelectCoachClick = () => {
    setSelectedCoach(coachCardContent)
    if (
      router.query.from === "academy" ||
      !postSelectionQuestionnaire?.questions?.length
    ) {
      trackAnalyticEvent(ANALYTIC_EVENTS.MARKETING_COACH_SELECT, {
        coach_id: id,
      })
      variant === cohorts.variantD
        ? setStep(CoachingStep.SELECT_PLAN)
        : setStep(CoachingStep.CALENDLY)
    } else {
      trackAnalyticEvent(ANALYTIC_EVENTS.PERSONALIZED_COACH_SELECT, {
        coach_id: id,
      })
      trackAnalyticEvent(ANALYTIC_EVENTS.PERSONALIZED_COACH_CONTINUE_CLICK, {
        step: CoachingStep.RECOMMENDED_COACHES,
      })
      setStep(CoachingStep.POST_SELECTION)
    }
  }

  useEffect(() => {
    const checkOverflow = () => {
      if (bioRef.current) {
        const lineHeight = 1.5 * 16
        const maxHeight = lineHeight * 4
        const hasOverflow =
          !isExpanded && bioRef.current.scrollHeight > maxHeight
        setOverflowActive(hasOverflow || isExpanded)
      }
    }
    checkOverflow()
    window.addEventListener("resize", checkOverflow)
    return () => window.removeEventListener("resize", checkOverflow)
  }, [bioRef.current, isExpanded]) // eslint-disable-line react-hooks/exhaustive-deps

  if (!coachCardContent || !bio) return <Skeleton />

  return (
    <FlexBox
      direction="column"
      style={{
        width: "min(450px, 100%)",
        background: "white",
        boxShadow: `${SPACING.space1} ${SPACING.space1} ${SPACING.space1} #F0F3F8`,
        borderRadius: SPACING.space3,
        border: "1px #E3E8EF solid",
      }}
    >
      <FlexBox
        direction="row"
        align="center"
        style={{
          padding: SPACING.space4,
          background: JoonColorExpanded.yellow100,
          borderTopLeftRadius: SPACING.space3,
          borderTopRightRadius: SPACING.space3,
        }}
        gap={SPACING.space4}
        wrap={false}
      >
        <CareTeamAvatar profile={coachCardContent} size="large" />
        <FlexBox
          direction="column"
          gap={SPACING.space2}
          align="center"
          wrap={false}
        >
          <FlexBox
            direction="row"
            justify="space-between"
            align="center"
            fullWidth
            wrap={false}
          >
            <Typography variant="h3" style={{ textAlign: "left" }}>
              {name} {last_name}
            </Typography>
            <FlexBox
              direction="row"
              align="center"
              gap={SPACING.space1}
              wrap={false}
              justify="flex-end"
              style={{
                width: "fit-content",
                textAlign: "left",
                whiteSpace: "nowrap",
              }}
            >
              <div style={{ width: 18, height: 21 }}>
                <StarIcon size={18} color={JoonColorExpanded.yellow400} />
              </div>
              <Typography variant="caption">
                {rating.toFixed(1)} ({num_ratings})
              </Typography>
            </FlexBox>
          </FlexBox>
          <FlexBox direction="column" gap={SPACING.space1}>
            {credentials.map((credential) => (
              <FlexBox
                key={credential}
                direction="row"
                align="center"
                gap={SPACING.space1}
                wrap={false}
              >
                <CircleIcon size={4} color={JoonColorExpanded.yellow400} />
                <Typography
                  variant="bodyXSmall"
                  color={JoonColorExpanded.purple600}
                  textAlign="left"
                >
                  {credential}
                </Typography>
              </FlexBox>
            ))}
          </FlexBox>
        </FlexBox>
      </FlexBox>

      <FlexBox
        direction="column"
        gap={SPACING.space4}
        style={{ padding: SPACING.space4 }}
      >
        <FlexBox direction="column">
          <div
            ref={bioRef}
            style={{
              color: JoonUIColor.text.primary,
              fontSize: "1rem",
              lineHeight: "1.5rem",
              fontWeight: 500,
              textAlign: "left",
              display: "-webkit-box",
              WebkitLineClamp: isExpanded ? "unset" : 4,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {bio}
          </div>
          {overflowActive && (
            <button onClick={onReadMoreClick}>
              <Typography variant="body" color={JoonUIColor.text.primaryAccent}>
                {isExpanded ? "Read less" : "Read more..."}
              </Typography>
            </button>
          )}
        </FlexBox>

        <FlexBox direction="column" gap={SPACING.space2}>
          <Typography variant="bodyBold">Specialties</Typography>
          <FlexBox gap={SPACING.space1} wrap={true}>
            {specialties.map((specialty) => (
              <FlexBox
                key={specialty}
                style={{
                  width: "fit-content",
                  padding: `${SPACING.space1} ${SPACING.space2}`,
                  background: JoonColorExpanded.yellow100,
                  borderRadius: SPACING.space1,
                }}
              >
                <Typography
                  variant="bodySmall"
                  color={JoonUIColor.text.secondary}
                >
                  {specialty}
                </Typography>
              </FlexBox>
            ))}
          </FlexBox>
        </FlexBox>

        <FlexBox direction="column" gap={SPACING.space2}>
          <Typography variant="bodyBold">Approaches</Typography>
          <FlexBox gap={SPACING.space1} wrap={true}>
            {approaches.map((approach) => (
              <FlexBox
                key={approach}
                style={{
                  width: "fit-content",
                  padding: `${SPACING.space1} ${SPACING.space2}`,
                  background: JoonColorExpanded.yellow100,
                  borderRadius: SPACING.space1,
                }}
              >
                <Typography
                  variant="bodySmall"
                  color={JoonUIColor.text.secondary}
                >
                  {approach}
                </Typography>
              </FlexBox>
            ))}
          </FlexBox>
        </FlexBox>

        <FlexBox
          style={{
            width: "fit-content",
            padding: `${SPACING.space1} ${SPACING.space2}`,
            backgroundColor: JoonColorExpanded.orange200,
            borderRadius: SPACING.space1,
          }}
        >
          <Typography variant="bodyBold" color={JoonColorExpanded.orange500}>
            {`${num_open_slots} client slot${num_open_slots === 1 ? "" : "s"} remaining`}
          </Typography>
        </FlexBox>
      </FlexBox>

      <button onClick={onSelectCoachClick} style={{ width: "100%" }}>
        <FlexBox
          fullWidth
          justify="space-between"
          style={{
            background: JoonColorExpanded.indigo400,
            boxShadow: `${SPACING.space1} ${SPACING.space2} ${SPACING.space2} rgba(0, 0, 0, 0.08)`,
            borderBottomLeftRadius: SPACING.space3,
            borderBottomRightRadius: SPACING.space3,
            padding: SPACING.space4,
          }}
        >
          <Typography variant="body" color={JoonUIColor.text.inverted}>
            Continue with {name}
          </Typography>
          <ChevronRightIcon size={24} color={JoonUIColor.text.inverted} />
        </FlexBox>
      </button>
    </FlexBox>
  )
}

export default RecommendedCoachCard
