import { CalloutBox, Typography } from "@joonapp/web-shared"
import { useEffect, useState } from "react"

import PageLoader from "../../components/loading/PageLoader"
import useDocumentTitle from "../../hooks/useDocumentTitle"
import {
  useFamilyQuery,
  useSubscriberInfo,
  useUserQuery,
} from "../../networking/queries"
import { AlertInterface, SubscriptionTrialKey } from "../../types"
import { requireAuth } from "../../util/auth"
import {
  redirectToBilling,
  redirectToCheckoutWithPriceId,
} from "../../util/stripe"

function BillingPage() {
  useDocumentTitle("Joon - Billing")
  const { user } = useUserQuery()
  const { data: family } = useFamilyQuery()
  const { data: subscriberInfo } = useSubscriberInfo()
  const [formAlert, setFormAlert] = useState<AlertInterface>()
  const setRedirectError = () =>
    setFormAlert({ type: "error", message: "Failed to redirect to billing." })

  useEffect(() => {
    if (!subscriberInfo || !user || !family) return
    if (subscriberInfo.planIsActive)
      redirectToBilling(user.email).catch(setRedirectError)
    else
      redirectToCheckoutWithPriceId({
        priceId: "1LRiAHG6yUiW8043aCYR1VjP",
        trialLengthKey: subscriberInfo?.hasPurchasedPlanBefore
          ? SubscriptionTrialKey.NONE
          : undefined,
        subscriberId: family.subscriber_id,
        email: user.email,
      }).catch(setRedirectError)
  }, [user, family, subscriberInfo])

  return (
    <PageLoader>
      {formAlert && (
        <div style={{ maxWidth: "500px" }}>
          <CalloutBox type="error" fullWidth>
            <Typography variant="bodySmall">{formAlert.message}</Typography>
          </CalloutBox>
        </div>
      )}
    </PageLoader>
  )
}

const BillingPageWithAuth = requireAuth(BillingPage)
export default BillingPageWithAuth
