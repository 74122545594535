import {
  ChevronLeftIcon,
  JoonUIColor,
  SPACING,
  TextButton,
} from "@joonapp/web-shared"
import { useState } from "react"

interface Props {
  onBack: () => void
}

function BackButton({ onBack, ...props }: Props) {
  const [isHovered, setIsHovered] = useState(false)
  return (
    <TextButton
      onClick={onBack}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        background: isHovered
          ? "rgba(94, 101, 233, 0.9)"
          : JoonUIColor.background.accent,
        width: "50px",
        borderRadius: "30px",
        padding: `${SPACING.space1} 0`,
      }}
      {...props}
    >
      <ChevronLeftIcon color={JoonUIColor.icon.inverted} size={24} />
    </TextButton>
  )
}

export default BackButton
