import { Button, Modal } from "@joonapp/web-shared"
import { create } from "zustand"

import doterGroup from "../../images/doter-group.svg"
import { useRouter } from "../../util/router"

interface FreeTrialLimitModalStore {
  isOpen: boolean
  onOpen: (remainingQuests?: number) => void
  onClose: () => void
  remainingQuests: number
}

export const useFreeTrialLimitModal = create<FreeTrialLimitModalStore>(
  (set) => ({
    isOpen: false,
    onOpen: (remainingQuests = 0) => set({ isOpen: true, remainingQuests }),
    onClose: () => set({ isOpen: false }),
    remainingQuests: 0,
  })
)

const FreeTrialLimitModal = () => {
  const { isOpen, onClose, remainingQuests } = useFreeTrialLimitModal()
  const router = useRouter()

  return (
    <Modal isOpen={isOpen} onClose={onClose} displayCloseIcon animate>
      <div
        style={{
          width: "min(400px, 95vw)",
          display: "flex",
          flexDirection: "column",
          padding: "30px",
        }}
      >
        <img
          src={doterGroup}
          alt=""
          style={{ width: "100%", height: "120px" }}
        />
        <div className="mt-12 mb-24" style={{ textAlign: "center" }}>
          <div className="purple-text mb-12" style={{ fontSize: "26px" }}>
            Unlock Unlimited Access
          </div>
          {remainingQuests > 0 ? (
            <div style={{ fontSize: "18px" }}>
              You don't have enough verifies left for today. Come back tomorrow
              or start a free trial to verify more Quests.
              <span className="semibold"> start a free trial.</span>
            </div>
          ) : (
            <div style={{ fontSize: "18px" }}>
              You've reached the free limit today. To verify more Quests, come
              back tomorrow or
              <span className="semibold"> start a free trial.</span>
            </div>
          )}
        </div>
        <Button
          text="Start Free Trial"
          onClick={() => {
            onClose()
            router.push("/checkout?tpk=seven")
          }}
        />
      </div>
    </Modal>
  )
}

export default FreeTrialLimitModal
