import { create } from "zustand"

interface QuestBoardMultiSelectStore {
  selectedQuestIds: number[]
  toggleSelectedQuestId: (questId: number) => void
  clearSelectedQuestIds: () => void

  isMultiSelectMode: boolean
  setMultiSelectMode: (isMultiSelectMode: boolean) => void
}

const useQuestBoardMultiSelectStore = create<QuestBoardMultiSelectStore>(
  (set, get) => ({
    selectedQuestIds: [],
    toggleSelectedQuestId: (questId) => {
      // If no quest is selected, enter multi-select mode
      const { selectedQuestIds } = get()
      if (selectedQuestIds.length === 0) {
        set({ isMultiSelectMode: true })
      }
      const isSelected = selectedQuestIds.includes(questId)
      // If the quest is the only one selected, exit multi-select mode
      if (isSelected && selectedQuestIds.length === 1) {
        set({ isMultiSelectMode: false })
      }

      set((state) => ({
        selectedQuestIds: isSelected
          ? state.selectedQuestIds.filter((id) => id !== questId)
          : [...state.selectedQuestIds, questId],
      }))
    },
    clearSelectedQuestIds: () => set({ selectedQuestIds: [] }),

    isMultiSelectMode: false,
    setMultiSelectMode: (isMultiSelectMode) => {
      set({ isMultiSelectMode })
      if (!isMultiSelectMode) set({ selectedQuestIds: [] })
    },
  })
)

export default useQuestBoardMultiSelectStore
