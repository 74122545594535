import {
  CloseIcon,
  FlexBox,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"

import useQuestReviewStore from "./useQuestReviewStore"
import ParentTasksSectionSmall from "../../me/parentTasks/ParentTasksSectionSmall"
import { useDisplayParentTaskSection } from "../../me/parentTasks/useDisplayParentTasksSection"

const QuestReviewEmptyState = () => {
  const { onClose } = useQuestReviewStore()
  const { displayParentTasksSection } = useDisplayParentTaskSection()

  return (
    <>
      <button
        onClick={onClose}
        style={{
          padding: `${SPACING.space4} ${SPACING.space4}`,
          marginLeft: "auto",
        }}
      >
        <CloseIcon color={JoonUIColor.icon.neutral} size={24} />
      </button>
      <FlexBox
        direction="column"
        justify="space-between"
        align="center"
        style={{
          height: "min(600px, calc(100vh - 80px))",
          padding: `${SPACING.space14} ${SPACING.space6} ${SPACING.space6}`,
        }}
      >
        <FlexBox justify="center" gap={SPACING.space1}>
          <Typography
            variant="h1"
            style={{ fontSize: "64px", marginBottom: SPACING.space10 }}
          >
            🎉
          </Typography>
          <Typography variant="bodyBold" textAlign="center">
            You've approved all Quests!
          </Typography>
          <Typography
            variant="bodySmall"
            textAlign="center"
            style={{ width: "245px" }}
          >
            The next time your children play Joon, they can collect their reward
            from the rewards chest!
          </Typography>
        </FlexBox>
        {displayParentTasksSection ? (
          <ParentTasksSectionSmall onClose={onClose} />
        ) : (
          <FlexBox justify="center">
            <Typography
              variant="caption"
              color={JoonUIColor.text.secondary}
              textAlign="center"
            >
              How this helps your family
            </Typography>
            <Typography
              variant="bodySmall"
              textAlign="center"
              color={JoonUIColor.text.secondary}
              style={{ width: "245px" }}
            >
              Consistent feedback loop helps your child build long term and
              sustainable habits! Great job - keep it up!
            </Typography>
          </FlexBox>
        )}
      </FlexBox>
    </>
  )
}

export default QuestReviewEmptyState
