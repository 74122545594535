import { SPACING } from "@joonapp/web-shared"

const Shine = () => {
  return (
    <>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: "-20%",
          width: "140%",
          height: "100%",
          overflow: "hidden",
          borderRadius: SPACING.space2,
          zIndex: 1,
        }}
      >
        <div
          style={{
            content: '""',
            position: "absolute",
            top: 0,
            left: "-50%",
            width: "100%",
            height: "50%",
            background:
              "linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,0.3), rgba(255,255,255,0.2))",
            transform: "rotate(-45deg)",
            animation: "shine 15s ease-in-out infinite",
          }}
        />
      </div>

      <style>{`
          @keyframes shine {
            0% { transform: translateX(-120%) rotate(-35deg); }
            20% { transform: translateX(120%) rotate(-35deg); }
            100% { transform: translateX(120%) rotate(-35deg); }
          }
        `}</style>
    </>
  )
}

export default Shine
