import { QuestInstance } from "@joonapp/web-shared"
import { create } from "zustand"

interface TaskReflectionModalStore {
  isOpen: boolean
  onOpen: (task: QuestInstance) => void
  onClose: () => void
  task: QuestInstance | null
  showCard: boolean
  setShowCard: (showCard: boolean) => void
  view: "details" | "reflection"
  setView: (view: "details" | "reflection") => void
}

const useTaskReflectionModalStore = create<TaskReflectionModalStore>((set) => ({
  isOpen: false,
  onOpen: (task: QuestInstance) => set({ isOpen: true, task }),
  onClose: () => set({ isOpen: false, view: "details", task: null }),
  task: null,
  showCard: true,
  setShowCard: (showCard: boolean) => set({ showCard }),
  view: "details",
  setView: (view) => set({ view }),
}))

export default useTaskReflectionModalStore
