import { Button } from "@joonapp/web-shared"

import { useAuth } from "../../util/auth"

const LogoutButton = () => {
  const { signout } = useAuth()

  return (
    <Button
      text="Logout"
      size="small"
      onClick={signout}
      buttonType="redPrimary"
      style={{
        margin: "auto auto 10vh auto",
        display: "flex",
        width: "160px",
      }}
    >
      Logout
    </Button>
  )
}

export default LogoutButton
