import {
  useIncidentTemplatesQuery,
  useTrainingsQuery,
} from "../../../networking/queries"

const useDataTaskIncident = (dataTaskId: number | null) => {
  const { data: incidentTemplates } = useIncidentTemplatesQuery()
  const { data: parentTrainings } = useTrainingsQuery()

  if (dataTaskId === null) return { dataTask: null }

  const dataTasks = incidentTemplates?.parent_training_templates
  const dataTask = dataTasks?.find((task) => task.id === dataTaskId)

  const dataTaskTraining = parentTrainings?.find(
    (training) => training.id === dataTask?.training_id
  )

  return { dataTask, dataTaskTraining }
}

export default useDataTaskIncident
