import {
  DropdownOption,
  FlexBox,
  JoonUIColor,
  SPACING,
  SegmentedControl2,
} from "@joonapp/web-shared"
import { useEffect, useState } from "react"
import toast from "react-hot-toast"

import ReceivedMail from "./ReceivedMail"
import SendMessageModal from "./SendMessageModal"
import SentMail from "./SentMail"
import { useSendMessageModalStore } from "./useSendMessageModalStore"
import ViewMessageModal from "./ViewMessageModal"
import BackButton from "../../../components/buttons/BackButton"
import PageContentWrapper from "../../../components/pageContentWrapper/PageContentWrapper"
import PageHeader from "../../../components/pageHeader/PageHeader"
import PageWrapper from "../../../components/pageWrapper/PageWrapper"
import { Toasts } from "../../../components/toaster/Toaster"
import { GRADIENTS, QUERY_KEYS } from "../../../constants"
import useDocumentTitle from "../../../hooks/useDocumentTitle"
import { useFamilyQuery, useUserQuery } from "../../../networking/queries"
import { FamilyMailboxTab } from "../../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"
import { queryClient } from "../../../util/queryClient"
import { useRouter } from "../../../util/router"
import { separateFamily, noOnboardedChildren } from "../../../util/util"
import { QuickActionMobileButton } from "../../me/QuickActionsSection"

const familyMailboxPageTabs = [
  {
    label: FamilyMailboxTab.RECEIVED,
    value: FamilyMailboxTab.RECEIVED,
  },
  {
    label: FamilyMailboxTab.SENT,
    value: FamilyMailboxTab.SENT,
  },
]

const FamilyMailboxPage = () => {
  useDocumentTitle("Joon - Family Mailbox")

  const router = useRouter()

  const { user } = useUserQuery()
  const { data: family } = useFamilyQuery()
  const { children } = separateFamily(user, family)

  const [familyMailboxTab, setFamilyMailboxTab] = useState(
    FamilyMailboxTab.RECEIVED
  )

  const onClickFamilyMailboxTab = (tab: FamilyMailboxTab) => {
    setFamilyMailboxTab(tab)
  }

  const { onOpen, setSelectedChildId, setMessage, setSelectedGift } =
    useSendMessageModalStore()

  const onOpenSendMessageModal = () => {
    if (noOnboardedChildren(children)) {
      toast.dismiss()
      toast(Toasts.noOnboardedChildren, { duration: 3000 })
    } else {
      setSelectedChildId(children[0].user.id)
      setMessage("")
      setSelectedGift(null)
      trackAnalyticEvent(ANALYTIC_EVENTS.COMPOSE_FAMILY_MAIL)
      onOpen()
    }
  }

  useEffect(() => {
    queryClient.invalidateQueries([QUERY_KEYS.FAMILY_MAIL])
    queryClient.invalidateQueries([QUERY_KEYS.UNREAD_MAIL])
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PageWrapper
      style={{ background: GRADIENTS.gradient100, overflow: "hidden" }}
    >
      <PageHeader
        title="Family Mailbox"
        leftComponent={<BackButton onBack={() => router.push("/family")} />}
      />
      <PageContentWrapper
        mobileGutter={true}
        style={{ position: "relative", maxHeight: "calc(100vh - 122px)" }}
      >
        <SegmentedControl2
          options={familyMailboxPageTabs}
          value={
            familyMailboxPageTabs.find(
              (tab) => tab.value === familyMailboxTab
            ) as DropdownOption
          }
          setValue={(tab) =>
            onClickFamilyMailboxTab(tab.value as FamilyMailboxTab)
          }
          fullWidth
          style={{
            border: `1px solid ${JoonUIColor.border.default}`,
            marginTop: "18px",
          }}
        />
        {familyMailboxTab === FamilyMailboxTab.RECEIVED ? (
          <ReceivedMail />
        ) : (
          <SentMail />
        )}
        <FlexBox
          direction="row"
          wrap={false}
          gap={SPACING.space2}
          style={{
            position: "absolute",
            zIndex: 500,
            width: "100%",
            left: 0,
            bottom: 0,
            background: "rgba(13, 5, 105, 0.12)",
            padding: SPACING.space2,
            borderRadius: "8px",
          }}
        >
          <QuickActionMobileButton
            text="Send a new mail"
            onClick={onOpenSendMessageModal}
          />
        </FlexBox>
        <ViewMessageModal />
        <SendMessageModal />
      </PageContentWrapper>
    </PageWrapper>
  )
}

export default FamilyMailboxPage
