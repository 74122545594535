import {
  Button,
  FamilyParentProfile,
  FlexBox,
  JoonUIColor,
  Modal,
  SaveIcon,
  SPACING,
  TextInput,
  Typography,
} from "@joonapp/web-shared"
import { useEffect } from "react"

import ParentAvatarButton from "./ParentAvatarButton"
import SelectAvatarModal from "./SelectAvatarModal"
import { useManageProfileModalStore } from "./useManageProfileModalStore"
import { useSelectAvatarModalStore } from "./useSelectAvatarModalStore"
import useEditParentMutation from "../../settings/useEditParentMutation"

type Props = {
  user: FamilyParentProfile
}

const ManageProfileModal = ({ user }: Props) => {
  const { isOpen, onClose, newNickname, setNewNickname } =
    useManageProfileModalStore()
  const { onOpen: onOpenSelectAvatarModal } = useSelectAvatarModalStore()
  const editParentMutation = useEditParentMutation()

  const onSubmit = () => {
    if (newNickname !== user?.nickname) {
      editParentMutation.mutate({
        parentInfo: {
          name: user?.user.name,
          nickname: newNickname,
          avatar: user?.user.avatar,
        },
        userId: user?.user.id,
      })
    }
    onClose()
  }

  useEffect(() => {
    isOpen && setNewNickname(user?.nickname || "")
  }, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

  if (!user) return <></>

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} displayCloseIcon animate>
        <div className="quest-modal">
          <div className="quest-modal-content">
            <FlexBox wrap={false} gap={SPACING.space4} direction="column">
              <FlexBox justify="center">
                <Typography variant="h2">Profile</Typography>
              </FlexBox>
              <FlexBox
                onClick={onOpenSelectAvatarModal}
                className="quest-modal-content"
                style={{ width: "min(200px, 25vw)", borderRadius: "50%" }}
              >
                <div style={{ position: "relative" }}>
                  <ParentAvatarButton
                    avatar={user?.user.avatar || null}
                    isSelected={false}
                    setSelectedParentAvatar={() => {}}
                  />
                  <div
                    style={{
                      width: "min(40px, 5vw)",
                      position: "absolute",
                      bottom: "max(-20px, -2vw)",
                      right: "max(-20px, -2vw)",
                      cursor: "pointer",
                    }}
                  >
                    <SaveIcon color={JoonUIColor.icon.accent} />
                  </div>
                </div>
              </FlexBox>
              <FlexBox justify="space-between" align="center" wrap={false}>
                <FlexBox direction="column" gap={SPACING.space2}>
                  <FlexBox gap={SPACING.space0}>
                    <Typography variant="bodyBold">Your Nickname:</Typography>
                    <Typography variant="body">
                      This is your avatar's name that your kids will see you as
                      in the game.
                    </Typography>
                  </FlexBox>
                  <FlexBox gap={SPACING.space4}>
                    <TextInput
                      name="nickname"
                      value={newNickname}
                      onChange={(e: any) => setNewNickname(e.target.value)}
                      placeholder="Eg. Mom"
                      maxLength={140}
                      fullWidth
                    />
                    <Button
                      onClick={onSubmit}
                      fullWidth
                      text="Save"
                      disabled={newNickname?.length === 0}
                    />
                  </FlexBox>
                </FlexBox>
              </FlexBox>
            </FlexBox>
          </div>
        </div>
      </Modal>
      <SelectAvatarModal user={user} />
    </>
  )
}

export default ManageProfileModal
