import {
  QuestDifficulty,
  QuestRoutine,
  SkillKey,
  QuestInstance,
} from "@joonapp/web-shared"
import { create } from "zustand"

import { QuestRepeatFrequency, REPETITIONS } from "../../../constants"

type AddEditModalErrors = {
  questName?: string
  repetition?: string
}

interface AddEditQuestStore {
  resetQuestDetails: () => void

  questName: string
  description: string | null
  questDifficulty: string
  coinReward: number
  category: SkillKey | undefined | null
  routine: QuestRoutine
  routines: QuestRoutine[]
  repetition: boolean[]
  repeatsFrequency: number | null
  assignedChildren: number[]
  mandatory: boolean
  startDate: string | undefined | null
  reminderTime: string | undefined | null
  timerLength: string | undefined | null
  reqReview: boolean
  reqNotes: boolean
  reqPhoto: boolean
  saveTemplate: boolean
  templateId: number | undefined
  customTemplateId: number | undefined

  setQuestName: (name: string) => void
  setDescription: (description: string) => void
  setQuestDifficulty: (difficulty: string) => void
  setCoinReward: (reward: number) => void
  setCategory: (category: SkillKey | undefined | null) => void
  setRoutine: (routine: QuestRoutine) => void
  toggleRoutine: (routine: QuestRoutine, only?: boolean) => void
  setRepetition: (repetition: boolean[]) => void
  setRepeatsFrequency: (frequency: number | null) => void
  setStartDate: (string: any) => void
  setReminderTime: (time: any) => void
  setTimerLength: (length: any) => void
  toggleChild: (childId: number) => void
  setMandatory: (bool?: boolean) => void
  setReqReview: (bool: boolean) => void
  setReqNotes: (bool?: boolean) => void
  setReqPhoto: (bool?: boolean) => void
  setSaveTemplate: (bool?: boolean) => void
  setTemplateId: (id: number) => void
  setCustomTemplateId: (id: number) => void

  // Modal view
  isOpen: boolean
  onOpen: (selectedQuest?: QuestInstance) => void
  onClose: () => void

  errors: AddEditModalErrors
  setErrors: (errors: AddEditModalErrors) => void

  modalContentRef: any
  setModalContentRef: (qbRef: any) => void

  additionalOptionsRef: any
  setAdditionalOptionsRef: (additionalOptionsRef: any) => void

  isOpenAdditionalOptions: boolean
  setIsOpenAdditionalOptions: (isOpen: boolean) => void

  questRepeatSelection: QuestRepeatFrequency
  setQuestRepeatSelection: (selection: QuestRepeatFrequency) => void

  selectedQuest: QuestInstance | null
  setSelectedQuest: (quest: QuestInstance) => void
}

// for resetting data when modal is closed
const initQuestDataState = {
  questName: "",
  description: "",
  questDifficulty: QuestDifficulty.EASY,
  coinReward: 4,
  category: undefined,
  routine: QuestRoutine.ANYTIME,
  routines: [QuestRoutine.ANYTIME],
  repetition: REPETITIONS.daily,
  repeatsFrequency: 1,
  assignedChildren: [],
  mandatory: true,
  startDate: undefined,
  reminderTime: undefined,
  timerLength: undefined,
  reqReview: true,
  reqNotes: false,
  reqPhoto: false,
  saveTemplate: false,
  templateId: undefined,
  customTemplateId: undefined,
}

export const useAddEditQuestStore = create<AddEditQuestStore>((set, get) => ({
  ...initQuestDataState,

  setQuestName: (name) => set({ questName: name }),
  setDescription: (description) => set({ description }),
  setQuestDifficulty: (difficulty) => set({ questDifficulty: difficulty }),
  setCoinReward: (reward) => set({ coinReward: reward }),
  setCategory: (category) => set({ category }),
  setRoutine: (routine) => set({ routine }),
  toggleRoutine: (routine) => {
    // If anytime is the only one selected and they select a different one,
    // then only select the new one. Otherwise, toggle normally
    const newRoutines = [...get().routines]
    if (newRoutines.length === 1 && newRoutines[0] === QuestRoutine.ANYTIME)
      return set({ routines: [routine] })
    else if (newRoutines.includes(routine))
      newRoutines.splice(newRoutines.indexOf(routine), 1)
    else newRoutines.push(routine)
    set({ routines: newRoutines })
  },
  setRepetition: (repetition) => set({ repetition }),
  setRepeatsFrequency: (frequency) => set({ repeatsFrequency: frequency }),
  toggleChild: (childId) => {
    const newChildren = [...get().assignedChildren]
    if (newChildren.includes(childId))
      newChildren.splice(newChildren.indexOf(childId), 1)
    else newChildren.push(childId)
    if (newChildren.length === 0) set({})
    else set({ assignedChildren: newChildren })
  },
  setStartDate: (startDate) => set({ startDate }),
  setReminderTime: (time) => set({ reminderTime: time }),
  setTimerLength: (length) => set({ timerLength: length }),
  setMandatory: (bool) => set({ mandatory: bool }),
  setReqReview: (bool) => set({ reqReview: bool }),
  setReqNotes: (bool) => set({ reqNotes: bool }),
  setReqPhoto: (bool) => set({ reqPhoto: bool }),
  setSaveTemplate: (bool) => set({ saveTemplate: bool }),
  setTemplateId: (id) => set({ templateId: id }),
  setCustomTemplateId: (id) => set({ customTemplateId: id }),
  resetQuestDetails: () =>
    Object.entries(initQuestDataState).forEach(([key, val]) =>
      set({ [key]: val })
    ),

  // Modal view
  isOpen: false,
  onOpen: (selectedQuest) => set({ isOpen: true, errors: {}, selectedQuest }),
  onClose: () => {
    set({ isOpen: false, selectedQuest: null, isOpenAdditionalOptions: false })
    get().resetQuestDetails()
  },

  errors: {},
  setErrors: (errors: AddEditModalErrors) => set({ errors }),

  modalContentRef: null,
  setModalContentRef: (modalContentRef) => set({ modalContentRef }),

  additionalOptionsRef: null,
  setAdditionalOptionsRef: (additionalOptionsRef) =>
    set({ additionalOptionsRef }),

  isOpenAdditionalOptions: false,
  setIsOpenAdditionalOptions: (isOpen) =>
    set({ isOpenAdditionalOptions: isOpen }),

  questRepeatSelection: QuestRepeatFrequency.REPEAT,
  setQuestRepeatSelection: (selection) =>
    set({ questRepeatSelection: selection }),

  selectedQuest: null,
  setSelectedQuest: (selectedQuest) => set({ selectedQuest }),
}))
