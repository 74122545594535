import useDataTaskCompletionModalStore from "./useDataTaskCompletionModalStore"
import useDataTasksModalStore from "./useDataTasksModalStore"
import Card from "../../../components/card/Card"
import { DataTaskIncidentTemplate } from "../../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"
import { ArrowCircleRightIcon } from "../AcademyActions"

const DataTaskCard = ({ dataTask }: { dataTask: DataTaskIncidentTemplate }) => {
  const { onOpen: openDataTaskCompletionModal } =
    useDataTaskCompletionModalStore()
  const { onClose: closeDataTasksModal } = useDataTasksModalStore()

  const onClick = () => {
    openDataTaskCompletionModal(dataTask.id)
    closeDataTasksModal()
    trackAnalyticEvent(ANALYTIC_EVENTS.INITIATE_DATA_COLLECTION_TASK)
  }

  return (
    <Card
      title={dataTask.title}
      onClick={onClick}
      buttonComponent={<ArrowCircleRightIcon />}
    />
  )
}

export default DataTaskCard
