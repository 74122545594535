import CoachSB from "./CoachSB"
import PageWrapper from "../../components/pageWrapper/PageWrapper"
import { useCareTeamQuery } from "../../networking/queries"

const CoachPage = () => {
  const { isLoading } = useCareTeamQuery()

  if (isLoading) return <></>

  return (
    <PageWrapper
      style={{
        paddingBottom: "0px",
      }}
    >
      <CoachSB />
    </PageWrapper>
  )
}

export default CoachPage
