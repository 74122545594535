import {
  CheckIcon,
  FlexBox,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import React, { useState } from "react"

import ChildBadge from "../badges/childBadge/ChildBadge"

type Props = {
  title: string | React.ReactNode
  subtitle?: string
  coinAmount?: number
  childIds?: number[]
  onClick?: (e: any) => void
  borderColor?: string
  percentage?: number
  isChecked?: boolean
  type?: "primary" | "secondary"
  style?: React.CSSProperties
  buttonComponent?: React.ReactNode
}

const Card = ({
  title,
  subtitle,
  coinAmount,
  childIds,
  onClick,
  borderColor,
  percentage,
  isChecked,
  type = "primary",
  style,
  buttonComponent,
}: Props) => {
  const [isHovered, setIsHovered] = useState(false)
  const [isPressed, setIsPressed] = useState(false)
  return (
    <FlexBox
      justify="space-between"
      direction="row"
      align="center"
      wrap={false}
      onClick={onClick}
      gap={SPACING.space2}
      style={{
        width: "100%",
        padding: `${SPACING.space3} ${SPACING.space4}`,
        border: `1px solid ${borderColor || JoonUIColor.border.default}`,
        borderRadius: SPACING.space2,
        cursor: onClick ? "pointer" : "default",
        boxShadow:
          type === "primary" ? "4px 6px 6px 0px rgba(0, 0, 0, 0.08)" : "none",
        background: isHovered
          ? JoonUIColor.background.xlightGray
          : type === "primary"
            ? JoonUIColor.background.primaryNeutral
            : "rgba(255, 255, 255, 0.6)",
        transition: "background 0.1s ease-in-out",
        transform: isPressed ? "translateY(1px)" : "translateY(0px)",
        ...style,
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => {
        setIsHovered(false)
        setIsPressed(false)
      }}
      onMouseDown={() => setIsPressed(true)}
      onMouseUp={() => setIsPressed(false)}
    >
      <FlexBox
        direction="column"
        align="center"
        style={{ overflowX: "hidden" }}
      >
        {subtitle && (
          <Typography variant="bodyXSmall" textAlign="left">
            {subtitle}
          </Typography>
        )}
        <Typography
          variant="caption"
          textAlign="left"
          style={{
            textOverflow: "ellipsis",
            width: "100%",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {title}
        </Typography>
        {childIds && (
          <FlexBox direction="row" gap={SPACING.space1}>
            {childIds.map((childId, i) => (
              <ChildBadge childId={childId} key={i} />
            ))}
          </FlexBox>
        )}
      </FlexBox>
      <FlexBox
        direction="row"
        wrap={false}
        align="center"
        style={{ width: "fit-content" }}
        gap={SPACING.space2}
      >
        {typeof percentage === "number" && (
          <Typography variant="caption">{percentage}%</Typography>
        )}
        {coinAmount && (
          <FlexBox
            gap={SPACING.space1}
            direction="row"
            wrap={false}
            align="center"
          >
            <img
              style={{ height: SPACING.space3 }}
              src="/images/icons/coin-icon.png"
              alt="coin"
            />
            <Typography variant="bodySmall">{coinAmount}</Typography>
          </FlexBox>
        )}
        {typeof isChecked === "boolean" && (
          <FlexBox
            align="center"
            justify="center"
            style={{
              height: SPACING.space5,
              width: SPACING.space5,
              borderRadius: SPACING.space1,
              border: `2px solid ${JoonUIColor.border.accent}`,
              background: isChecked ? JoonUIColor.background.accent : "white",
            }}
          >
            <CheckIcon
              size={16}
              color={JoonUIColor.background.primaryNeutral}
            />
          </FlexBox>
        )}
      </FlexBox>
      {buttonComponent}
    </FlexBox>
  )
}

export default Card
