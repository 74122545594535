import { create } from "zustand"

interface InviteCodeStore {
  isInvited: boolean
  setIsInvited: (value: boolean) => void
  therapistCode: string
  setTherapistCode: (value: string) => void
}

export const useInviteCodeStore = create<InviteCodeStore>((set) => ({
  isInvited: false,
  setIsInvited: (value: boolean) => set({ isInvited: value }),
  therapistCode: "",
  setTherapistCode: (value: string) => set({ therapistCode: value }),
}))
