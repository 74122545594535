import type { UniqueIdentifier } from "@dnd-kit/core"
import { SortableContext } from "@dnd-kit/sortable"
import { FlexBox, SPACING } from "@joonapp/web-shared"
import type { ReactNode } from "react"
import React from "react"

import { SortableItem } from "./SortableItem"

interface BaseItem {
  id: UniqueIdentifier
}

interface Props<T extends BaseItem> {
  title: string
  items: T[]
  renderItem(item: T): ReactNode
}

export function SortableList<T extends BaseItem>({
  title,
  items,
  renderItem,
}: Props<T>) {
  return (
    <SortableContext id={title} items={items}>
      <FlexBox direction="column" gap={SPACING.space2} role="application">
        {items.map((item) => (
          <React.Fragment key={item.id}>{renderItem(item)}</React.Fragment>
        ))}
      </FlexBox>
    </SortableContext>
  )
}

SortableList.Item = SortableItem
