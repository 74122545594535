import {
  CloseIcon,
  FlexBox,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"

import GiftItem from "./GiftItem"
import { useSelectGiftModalStore } from "./useSelectGiftModalStore"
import { useSendMessageModalStore } from "./useSendMessageModalStore"
import SlideUpModal from "../../../components/slideUpModal/SlideUpModal"
import { GRADIENTS } from "../../../constants"
import { useInventoryGiftsQuery } from "../../../networking/queries"

const SelectGiftModal = () => {
  const { data: gifts } = useInventoryGiftsQuery()

  const { isOpen, onClose } = useSelectGiftModalStore()

  const { setSelectedGift } = useSendMessageModalStore()

  const onCloseModal = () => {
    setSelectedGift(null)
    onClose()
  }

  return (
    <SlideUpModal
      isOpen={isOpen}
      onClose={onClose}
      showCard={true}
      size="fit-content"
      backgroundType="overlay"
    >
      <button
        style={{
          position: "absolute",
          right: 0,
          top: 0,
          padding: SPACING.space2,
        }}
        onClick={onCloseModal}
      >
        <CloseIcon color={JoonUIColor.icon.neutral} />
      </button>
      <FlexBox
        direction="column"
        gap={SPACING.space4}
        wrap={false}
        style={{
          padding: SPACING.space6,
          background: GRADIENTS.gradient100,
          height: "50vh",
        }}
      >
        <Typography variant="h3">Select a gift</Typography>

        <FlexBox
          direction="column"
          gap={SPACING.space2}
          style={{ maxHeight: "40vh", overflowY: "scroll" }}
          wrap={false}
        >
          {gifts?.map((gift) => <GiftItem key={gift.id} gift={gift} />)}
        </FlexBox>
      </FlexBox>
    </SlideUpModal>
  )
}

export default SelectGiftModal
