import { Button, FamilyChildProfile, Modal, SPACING } from "@joonapp/web-shared"
import { create } from "zustand"

import ChildAuthCode from "../../components/childAuthCode/ChildAuthCode"
import {
  ShareButton,
  ShareLink,
} from "../../components/shareButton/ShareButton"
import { QUERY_KEYS } from "../../constants"
import { useFamilyQuery } from "../../networking/queries"
import { FamilyQuerySelect } from "../../types"
import { queryClient } from "../../util/queryClient"

interface GetChildStartedModalStore {
  isOpen: boolean
  onOpen: (childId?: number) => void
  onClose: () => void
  childId?: number | null
  error: string | null
  setError: (error: string | null) => void
}

export const useGetChildStartedModal = create<GetChildStartedModalStore>(
  (set) => ({
    isOpen: false,
    onOpen: (childId?: number) => set({ isOpen: true, childId }),
    onClose: () => set({ isOpen: false, childId: null, error: null }),
    childId: null,
    error: null,
    setError: (error) => set({ error }),
  })
)

const GetChildStartedModal = () => {
  const { isOpen, onClose, childId, error, setError } =
    useGetChildStartedModal()
  const { refetch, isFetching } = useFamilyQuery(FamilyQuerySelect.CHILDREN)

  const checkIfChildHasChosenDoter = () => {
    refetch().then((query) => {
      const child = query?.data?.find(
        (child: FamilyChildProfile) => child.user.id === childId
      )
      if (!!child?.user.active_doter.type) {
        queryClient.invalidateQueries([
          QUERY_KEYS.QUEST_INSTANCE_STATS,
          child.user.id,
        ])
        onClose()
      } else setError(`Your child has not yet chosen a pet.`)
    })
  }

  return (
    <Modal onClose={onClose} isOpen={isOpen} displayCloseIcon animate>
      <div
        style={{
          width: "min(400px, 95vw)",
          display: "flex",
          flexDirection: "column",
          padding: "30px",
          textAlign: "center",
        }}
      >
        <div className="page-title purple-text ">Getting the Kid App</div>

        <ShareButton />
        <div style={{ display: "flex", textAlign: "left", marginTop: "24px" }}>
          <div className="step-number mr-12">1</div>
          <div>
            Search <span className="purple-text semibold">Joon Pet Game </span>
            in the Google Play Store, Apple App Store, Amazon App Store, or
            share the download link below to your child's device.
          </div>
        </div>
        <ShareLink />
        <div
          style={{ display: "flex", textAlign: "left", marginBottom: "12px" }}
        >
          <div className="step-number mr-12">2</div>
          <div>
            Use the code below to log them in once the app is installed.
          </div>
        </div>

        <div style={{ margin: `${SPACING.space3} auto` }}>
          <ChildAuthCode />
        </div>
        {!!childId && (
          <div>
            {error && (
              <div className="error-text" style={{ marginTop: "16px" }}>
                {error}
              </div>
            )}
            <Button
              onClick={checkIfChildHasChosenDoter}
              isLoading={isFetching}
              text="Done"
              fullWidth
              style={{ marginTop: "24px" }}
            />
          </div>
        )}
      </div>
    </Modal>
  )
}

export default GetChildStartedModal
