import {
  Button,
  CalloutBox,
  FlexBox,
  JoonUIColor,
  SPACING,
  TextInput,
  Typography,
} from "@joonapp/web-shared"
import React, { useState } from "react"

import { resetPassword } from "../../networking/authentication"
import { AlertInterface } from "../../types"
import { useRouter } from "../../util/router"
import { localStorageItems } from "../../util/storage"
import { createErrorFromResponse } from "../../util/util"
import BackButton from "../buttons/BackButton"

const ForgotPassword = () => {
  const [email, setEmail] = useState("")
  const [formAlert, setFormAlert] = useState<AlertInterface | null>(null)
  const [pending, setPending] = useState(false)
  const router = useRouter()

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    try {
      setPending(true)
      await resetPassword(email)
      setFormAlert({ type: "success", message: "Password reset email sent" })
    } catch (err) {
      const errorMessage = createErrorFromResponse(err)
      if (errorMessage) setFormAlert({ type: "error", message: errorMessage })
    } finally {
      setPending(false)
    }
  }

  return (
    <div style={{ width: "min(100%, 520px)", textAlign: "center" }}>
      <BackButton onBack={() => router.push("/auth/signin")} />
      <div
        className="onboard-section-centered"
        style={{ gap: "30px", minHeight: "70vh" }}
      >
        <img
          src="/images/logos/logo_white.svg"
          alt="Joon"
          style={{ width: "96px" }}
        />
        <FlexBox justify="center" style={{ marginBottom: SPACING.space2 }}>
          <Typography variant="h1">Get a new password</Typography>
        </FlexBox>
        {formAlert && (
          <div style={{ margin: `${SPACING.space2} 0` }}>
            <CalloutBox type={formAlert.type} fullWidth>
              {formAlert.message}
            </CalloutBox>
          </div>
        )}
        <form onSubmit={onSubmit} style={{ width: "100%" }}>
          <FlexBox direction="column" gap={SPACING.space1}>
            <Typography variant="bodyBold">Email</Typography>
            <TextInput
              type="email"
              icon="email"
              name="email"
              placeholder="user@example.com"
              fullWidth={true}
              onChange={(e: any) => setEmail(e.target.value)}
              value={email}
            />
          </FlexBox>
          <Button
            isLoading={pending}
            type="submit"
            disabled={pending}
            style={{ marginTop: SPACING.space3 }}
            text="Reset Password"
            fullWidth
          />
        </form>
        <hr
          style={{
            backgroundColor: JoonUIColor.border.accent,
            width: "50%",
          }}
        />
        <FlexBox direction="column" align="center" gap={SPACING.space4}>
          <Typography
            variant="body"
            color={JoonUIColor.text.secondary}
            style={{}}
          >
            <span>Remember it after all? </span>
            <button onClick={() => router.push("/auth/signin")}>
              <Typography
                variant="bodyBold"
                color={JoonUIColor.semantic.primary}
              >
                Sign In
              </Typography>
            </button>
          </Typography>
          <Typography variant="body" color={JoonUIColor.text.secondary}>
            <span>Don't have an account? </span>
            <button onClick={() => router.push("/welcome")}>
              <Typography
                variant="bodyBold"
                color={JoonUIColor.semantic.primary}
              >
                Create one here
              </Typography>
            </button>
          </Typography>
        </FlexBox>
      </div>
    </div>
  )
}

export default ForgotPassword

export const getAfterAuthPath = (router: any) => {
  const redirectLink = localStorage.getItem(localStorageItems.redirectLink)
  if (router.query.next) return router.query.next
  else if (redirectLink) {
    localStorage.removeItem(localStorageItems.redirectLink)
    return redirectLink
  } else return "/"
}
