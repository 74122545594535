import {
  SPACING,
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  Typography,
} from "@joonapp/web-shared"
import { useEffect } from "react"

import { useCoachingStore } from "../../useCoachingStore"

interface TimerBannerProps {
  coachName: string
}

const TimerBanner = ({ coachName }: TimerBannerProps) => {
  const { timeLeft, setTimeLeft } = useCoachingStore()

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`
  }

  useEffect(() => {
    if (timeLeft <= 0) return

    const timer = setInterval(() => {
      setTimeLeft(timeLeft - 1)
    }, 1000)

    return () => clearInterval(timer)
  }, [timeLeft, setTimeLeft])

  if (timeLeft <= 0) return null

  return (
    <FlexBox
      direction="row"
      justify="center"
      align="center"
      wrap={false}
      gap={SPACING.space1}
      style={{
        position: "fixed",
        top: 0,
        left: "50%",
        transform: "translateX(-50%)",
        padding: SPACING.space2,
        width: "min(450px, 110vw)",
        background: JoonColorExpanded.yellow300,
        borderBottomLeftRadius: SPACING.space2,
        borderBottomRightRadius: SPACING.space2,
        zIndex: 1000,
      }}
    >
      <Typography
        variant="body"
        color={JoonUIColor.text.primary}
        textAlign="center"
        style={{
          display: "flex",
          width: "fit-content",
          whiteSpace: "nowrap",
        }}
      >
        {`We're holding your spot with ${coachName} for `}
      </Typography>
      <Typography
        variant="bodyBold"
        color={JoonUIColor.text.primary}
        textAlign="center"
        style={{
          width: "32px",
        }}
      >
        {formatTime(timeLeft)}
      </Typography>
    </FlexBox>
  )
}

export default TimerBanner
