import {
  FlexBox,
  JoonUIColor,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { isAndroid, isChrome, isMobile } from "react-device-detect"

import { useRecommendedCoachesQuery } from "./useRecommendedCoachesQuery"
import PageLoader from "../../../components/loading/PageLoader"
import { useAddToHomescreenModal } from "../../../components/modals/AddToHomescreenModal"
import { useIOSModal } from "../../../components/modals/IOSModal"
import { CoachingStep } from "../../../constants"
import { useCoachPostSelectionQuestionnaireQuery } from "../../../networking/queries"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"
import { useRouter } from "../../../util/router"
import RecommendedCoachCard from "../RecommendedCoachCard"

function RecommendedCoaches() {
  const router = useRouter()
  const isOnMobileChrome = isChrome && isMobile && isAndroid
  const { onOpen: openAddToHomescreenModal } = useAddToHomescreenModal()
  const { onOpen: openIOSModal } = useIOSModal()
  const { isLoading: isLoadingQuestionnaire } =
    useCoachPostSelectionQuestionnaireQuery()
  const { data: recommendedCoaches, isLoading: isLoadingCoaches } =
    useRecommendedCoachesQuery()

  const onSkipClick = () => {
    if (router.query.from !== "academy") {
      trackAnalyticEvent(ANALYTIC_EVENTS.PERSONALIZED_COACH_SKIP_CLICK, {
        step: CoachingStep.RECOMMENDED_COACHES,
      })
      if (isOnMobileChrome) openAddToHomescreenModal()
      else openIOSModal()
    } else trackAnalyticEvent(ANALYTIC_EVENTS.MARKETING_COACH_SKIP_CLICK)
    router.push("/me")
  }

  if (isLoadingQuestionnaire || isLoadingCoaches) return <PageLoader />

  return (
    <FlexBox
      direction="column"
      align="center"
      justify="center"
      fullWidth
      gap={SPACING.space6}
      style={{
        marginBottom: SPACING.space10,
      }}
    >
      <Typography variant="h2" color={JoonUIColor.text.primary}>
        Your recommended coaches
      </Typography>
      <Typography variant="bodySmall" color={JoonUIColor.text.primary}>
        Based on your responses, these are the best coaches for you. Select a
        coach to get started, and you can change it at anytime.
      </Typography>
      {recommendedCoaches?.map((coachContent, index) => (
        <RecommendedCoachCard key={index} coachCardContent={coachContent} />
      ))}
      <TextButton onClick={onSkipClick}>
        <Typography variant="bodyBold" color={JoonUIColor.text.primaryAccent}>
          {router.query.from === "academy"
            ? "Nevermind"
            : "No Thanks, Continue to Joon app"}
        </Typography>
      </TextButton>
    </FlexBox>
  )
}

export default RecommendedCoaches
