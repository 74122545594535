import {
  AuthCodeType,
  Button,
  CalloutBox,
  Checkbox,
  Modal,
  TextInput,
} from "@joonapp/web-shared"
import { useQueryClient } from "@tanstack/react-query"
import { useEffect, useState } from "react"
import toast from "react-hot-toast"
import { create } from "zustand"

import therapistDisclaimer from "./therapistDisclaimer"
import { useAddPhoneNumberModalStore } from "../../../components/modals/addPhoneNumberModal/useAddPhoneNumberModalStore"
import { Toasts } from "../../../components/toaster/Toaster"
import { QUERY_KEYS } from "../../../constants"
import { useFamilyQuery, useUserQuery } from "../../../networking/queries"
import { createJoonAPIClient } from "../../../util/joon-api"
import { useRouter } from "../../../util/router"
import { localStorageItems } from "../../../util/storage"
import { separateFamily } from "../../../util/util"

interface EnterTherapistCodeModalStore {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void

  therapistCode: string
  setTherapistCode: (therapistCode: string) => void
}

export const useEnterTherapistCodeModal = create<EnterTherapistCodeModalStore>(
  (set) => ({
    isOpen: false,
    onOpen: () => set({ isOpen: true }),
    onClose: () => set({ isOpen: false }),

    therapistCode: "",
    setTherapistCode: (therapistCode) => set({ therapistCode }),
  })
)

export const EnterTherapistCodeModal = () => {
  const { isOpen, onOpen, onClose, therapistCode, setTherapistCode } =
    useEnterTherapistCodeModal()
  const { onOpen: onOpenAddPhoneNumberModal } = useAddPhoneNumberModalStore()
  const [selectedChildren, setSelectedChildren] = useState<number[]>([])
  const [error, setError] = useState<string | null>(null)
  const [isLinking, setIsLinking] = useState(false)
  const [isValidating, setIsValidating] = useState(false)

  const { data: family } = useFamilyQuery()
  const { user } = useUserQuery()
  const { children } = separateFamily(user, family)
  const queryClient = useQueryClient()
  const router = useRouter()

  useEffect(() => {
    if (router.query.therapist_code) {
      localStorage.setItem(
        localStorageItems.therapistCode,
        router.query.therapist_code
      )
      router.replace(router.pathname)
    }
    if (!!user) {
      const code = localStorage.getItem(localStorageItems.therapistCode)
      if (code) {
        onOpen()
        setTherapistCode(code)
      }
    }
  }, [router, user, onOpen, setTherapistCode])

  const selectChild = (id: number) => {
    if (selectedChildren.includes(id)) {
      setSelectedChildren(selectedChildren.filter((childId) => childId !== id))
    } else {
      setSelectedChildren([...selectedChildren, id])
    }
  }
  const validateCode = async () => {
    if (therapistCode.length !== 8)
      return setError("Code must be 8 characters long")

    try {
      const API = createJoonAPIClient()
      setIsValidating(true)
      const response = await API.get("api/auth-codes/validate/", {
        params: {
          code: therapistCode.toUpperCase(),
          type: AuthCodeType.ADMIT_PATIENT,
        },
      })
      if (!response.data.group.profiles) return setError("An error occurred")
      setError(null)
    } catch (error) {
      setError("Invalid code")
    }
    setIsValidating(false)
  }

  const linkChildren = async () => {
    const API = createJoonAPIClient()

    const linkChildrenPromises = selectedChildren.map((child) =>
      API.post("api/practices/admit-patient/", {
        code: therapistCode,
        user_id: child,
      })
    )
    try {
      setIsLinking(true)
      localStorage.removeItem(localStorageItems.therapistCode)
      const responses = await Promise.all(linkChildrenPromises)
      queryClient.invalidateQueries([QUERY_KEYS.THERAPISTS])
      queryClient.invalidateQueries([QUERY_KEYS.CARE_TEAM])
      queryClient.invalidateQueries([QUERY_KEYS.USER])
      setError(null)
      setTherapistCode("")
      localStorage.setItem(localStorageItems.needsToSeeTherapistTour, "true")
      onClose()
      toast(Toasts.therapistSuccessfullyLinked)
      if (!user?.phone_number || user.phone_number.length < 12) {
        onOpenAddPhoneNumberModal()
      } else if (router.pathname.includes("/paywall")) router.push("/me")
      return responses
    } catch (error) {
      setError("An error occurred")
      throw new Error("Failed to link children")
    } finally {
      setIsLinking(false)
    }
  }

  useEffect(() => {
    if (therapistCode.length === 8) validateCode()
    else setError("")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [therapistCode])

  return (
    <Modal isOpen={isOpen} onClose={onClose} displayCloseIcon animate>
      <div className="link-therapist-modal">
        <div className="modal-title">Link account with therapist</div>
        <div className="modal-body">
          <div>
            <div className="font-body mb-8">Enter therapist code</div>
            <TextInput
              value={therapistCode}
              icon="code"
              name="therapist code"
              placeholder="ABCD1234"
              maxLength={8}
              fullWidth
              onChange={(e: any) => setTherapistCode(e.target.value)}
            />
            {error && <div className="error-text mt-6">{error}</div>}
          </div>
          <div>
            <div className="font-body mb-8">Whose therapist is this?</div>
            <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
              {children.map((child, i) => (
                <Checkbox
                  name="test"
                  key={i}
                  label={child.user.name}
                  onChange={() => selectChild(child.user.id)}
                  selected={selectedChildren.includes(child.user.id)}
                />
              ))}
            </div>
          </div>
          <CalloutBox fullWidth>{therapistDisclaimer}</CalloutBox>
        </div>
        <div className="px-24">
          <Button
            text="Link with therapist"
            onClick={linkChildren}
            isLoading={isLinking}
            fullWidth
            disabled={
              therapistCode.length !== 8 ||
              !!error ||
              isValidating ||
              selectedChildren.length === 0
            }
          />
        </div>
      </div>
    </Modal>
  )
}
