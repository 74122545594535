import {
  Button,
  FamilyParentProfile,
  FlexBox,
  Modal,
  ModalContent,
  ModalHeader,
  SPACING,
  Typography,
  UserRole,
} from "@joonapp/web-shared"
import { useState } from "react"
import { create } from "zustand"

import EditChild from "../../components/EditChild"
import UserItem from "../../components/UserItem"
import { useFamilyQuery, useUserQuery } from "../../networking/queries"
import { separateFamily } from "../../util/util"
import { useAddParentModal } from "../settings/AddParentModal"
import ParentItem from "../settings/ParentItem"

interface EditFamilyModalStore {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

export const useEditFamilyModalStore = create<EditFamilyModalStore>((set) => ({
  isOpen: false,
  onOpen: () => set({ isOpen: true }),
  onClose: () => set({ isOpen: false }),
}))

const EditFamilyModal = () => {
  const { isOpen, onClose } = useEditFamilyModalStore()
  const [showAddChildModal, setShowAddChildModal] = useState(false)
  const handleModalClose = () => setShowAddChildModal(false)
  const { onOpen: openAddParentModal } = useAddParentModal()

  const { user, userStatus } = useUserQuery()
  const { data: family, status: familyStatus } = useFamilyQuery()

  const isLoading = [userStatus, familyStatus].includes("loading")

  if (isLoading) return <></>

  const { parent, coparents, children } = separateFamily(user, family)

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      style={{
        background:
          "linear-gradient(rgba(255, 255, 255, 0.8) 2.83%, rgba(255, 255, 255, 0) 51.53%, rgba(255, 255, 255, 0.8) 99.81%), rgb(233, 234, 250)",
      }}
      animate
    >
      <ModalHeader title="Edit Family" onClose={onClose} />
      <ModalContent style={{ width: "min(500px, 100vw)" }}>
        <FlexBox
          direction="column"
          style={{ padding: SPACING.space6, paddingTop: 0 }}
        >
          <Typography variant="h3">Parents</Typography>
          <FlexBox
            direction="column"
            gap={SPACING.space2}
            style={{ marginTop: SPACING.space4 }}
          >
            <ParentItem parent={parent} />
            {coparents?.map((coparent: FamilyParentProfile, i: number) => (
              <ParentItem key={i} parent={coparent} />
            ))}
          </FlexBox>
          <FlexBox
            justify="center"
            style={{ marginTop: SPACING.space4, marginBottom: SPACING.space6 }}
          >
            <Button
              onClick={openAddParentModal}
              text="+ Add Parent"
              buttonType="secondary"
            />
          </FlexBox>
          <Typography variant="h3">Children</Typography>
          <FlexBox
            direction="column"
            gap={SPACING.space2}
            style={{ marginTop: SPACING.space4 }}
          >
            {children.map((child, i: number) => (
              <UserItem
                key={i}
                name={child.user.name}
                user={child.user}
                type={UserRole.CHILD}
              />
            ))}
          </FlexBox>
          <FlexBox justify="center" style={{ marginTop: SPACING.space4 }}>
            <Button
              onClick={() => setShowAddChildModal(true)}
              text="+ Add Child"
              buttonType="secondary"
            />
          </FlexBox>
        </FlexBox>
      </ModalContent>

      <Modal
        isOpen={showAddChildModal}
        onClose={handleModalClose}
        mobileFull
        displayCloseIcon
        animate
      >
        <EditChild type="new" closeModal={handleModalClose} />
      </Modal>
    </Modal>
  )
}

export default EditFamilyModal
