import { FlexBox, SPACING, Button, ParentAvatar } from "@joonapp/web-shared"
import { useState } from "react"

import useOnboardContext from "../../hooks/useOnboardContext"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { getParentAvatarImage } from "../../util/util"
import BackButton from "../buttons/BackButton"

interface Props {
  avatar: ParentAvatar
}

function ParentAvatarSection({ avatar }: Props) {
  const { parentData, setParentData } = useOnboardContext()
  const isSelected = parentData.avatar === avatar
  const setSelectedParentAvatar = () => {
    setParentData("avatar", avatar)
  }

  return (
    <button
      className={`parent-avatar-section ${isSelected ? "selected" : ""}`}
      onClick={setSelectedParentAvatar}
    >
      <img src={getParentAvatarImage(avatar) || undefined} alt={avatar} />
    </button>
  )
}

function OnboardParentAvatarSection() {
  const [warningText, setWarningText] = useState("")
  const { parentData, nextStep, previousStep } = useOnboardContext()

  return (
    <>
      <BackButton onBack={previousStep} />
      <div className="onboard-section-centered">
        <div className="onboard-question">Select your avatar</div>
        <div className="body-text mb-18">
          This is what your children will see you as in Joon.
        </div>

        <FlexBox
          direction="row"
          gap={SPACING.space0}
          wrap={false}
          justify="space-between"
        >
          <ParentAvatarSection avatar={ParentAvatar.FRUMPULOUS} />
          <ParentAvatarSection avatar={ParentAvatar.JUNK_MUTT} />
          <ParentAvatarSection avatar={ParentAvatar.SCRUFFIT} />
        </FlexBox>

        <FlexBox
          direction="column"
          gap={SPACING.space2}
          align="center"
          style={{ marginTop: SPACING.space4 }}
        >
          {warningText.length > 0 && (
            <div className="error-text">Please select an avatar.</div>
          )}
          <Button
            onClick={() => {
              if (parentData.avatar == null) {
                setWarningText("Please enter a name")
              } else {
                trackAnalyticEvent(ANALYTIC_EVENTS.SELECT_PARENT_AVATAR)
                nextStep()
              }
            }}
            text="Next"
            fullWidth
          />
        </FlexBox>
      </div>
    </>
  )
}

export default OnboardParentAvatarSection
