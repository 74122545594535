import {
  DropdownOption,
  FlexBox,
  JoonUIColor,
  Modal,
  SPACING,
  SelectInput,
  Typography,
} from "@joonapp/web-shared"
import dayjs from "dayjs"

import CustomDateSelectorModal, {
  useCustomDateSelectorModalStore,
} from "./CustomDateSelectorModal"
import TransactionTable from "./TransactionTable"
import { useCoinTransactionsModalStore } from "./useTransactionsModalStore"
import { periodDropdownOptions } from "../../../constants"
import {
  useCoinCulumativeStatsQuery,
  useCoinTransactionsQuery,
} from "../../../networking/queries"

const CoinTransactionsModal = () => {
  const { isOpen, onClose, child, selectedPeriod, setSelectedPeriod } =
    useCoinTransactionsModalStore()
  const { onOpen: openCustomDateModal } = useCustomDateSelectorModalStore()

  const { data: coins } = useCoinTransactionsQuery()
  const { data: stats } = useCoinCulumativeStatsQuery()

  if (!child) return null

  const onClickPeriodOption = (option: DropdownOption) => {
    if (option.value === "custom") openCustomDateModal()
    else setSelectedPeriod(option)
  }

  const onSubmitCustomDateModal = (startDate: Date, endDate: Date) => {
    setSelectedPeriod({
      label: `${dayjs(startDate).format("MMM D, YYYY")} - ${dayjs(
        endDate
      ).format("MMM D, YYYY")}`,
      value: "custom",
      startDate: dayjs(startDate).format("YYYY-MM-DD"),
      endDate: dayjs(endDate).format("YYYY-MM-DD"),
    })
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} displayCloseIcon animate>
      <FlexBox
        style={{ width: "min(700px, 100vw)", padding: SPACING.space6 }}
        gap={SPACING.space4}
      >
        <FlexBox direction="column" gap={SPACING.space4}>
          <FlexBox align="center" justify="center" wrap={false}>
            <img
              src="/images/icons/coin-icon.png"
              alt="coin"
              style={{ width: "20px", marginRight: "12px" }}
            />
            <Typography variant="h2">{child?.name}'s Coins</Typography>
          </FlexBox>
          <FlexBox justify="center" align="center">
            <SelectInput
              name="period"
              options={periodDropdownOptions}
              selectedOption={selectedPeriod}
              setSelectedOption={onClickPeriodOption as any}
              style={{ minWidth: "200px" }}
              isMulti={false}
            />
          </FlexBox>
          <FlexBox justify="space-between" align="center" wrap={false}>
            <div style={{ margin: "0 auto" }}>
              <div>Balance available</div>
              <Typography variant="h2" color={JoonUIColor.semantic.primary}>
                {child.coins.toLocaleString()}
              </Typography>
            </div>
            <div
              style={{
                borderLeft: "1px solid #ccc",
                height: "50px",
                width: "2px",
                margin: `0 ${SPACING.space4}`,
              }}
            />
            <FlexBox
              align="center"
              justify="space-around"
              style={{ maxWidth: "50%" }}
              wrap={false}
            >
              <div>
                <div>Earned</div>
                <Typography variant="h2" color={JoonUIColor.semantic.primary}>
                  {stats?.coins_earned || "-"}
                </Typography>
              </div>
              <div>
                <div>Spent</div>
                <Typography variant="h2" color={JoonUIColor.semantic.primary}>
                  {stats?.coins_spent || "-"}
                </Typography>
              </div>
            </FlexBox>
          </FlexBox>
        </FlexBox>
        <hr />
        <TransactionTable transactions={coins} />
        <CustomDateSelectorModal onSubmit={onSubmitCustomDateModal} />
      </FlexBox>
    </Modal>
  )
}

export default CoinTransactionsModal
