import {
  FamilyChildProfile,
  QuestInstance,
  QuestStatus,
  QuestType,
} from "@joonapp/web-shared"
import dayjs from "dayjs"
import toast from "react-hot-toast"
import { create } from "zustand"
import { useShallow } from "zustand/react/shallow"

import { useSubscription } from "../../hooks/useSubscription"
import {
  useFamilyQuery,
  useQuestBoardQuery,
  useQuestReviewQuery,
} from "../../networking/queries"
import { FamilyQuerySelect } from "../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import useQuestActions from "../questBoard/useQuestActions"

interface CatchUpStore {
  // Modal view
  isOpen: boolean
  onOpen: ({ isReviewing }: { isReviewing: boolean }) => void
  onClose: () => void
  showCard: boolean
  setShowCard: (showCard: boolean) => void
  questStatus:
    | QuestStatus.VERIFIED
    | QuestStatus.RETRY
    | QuestStatus.REJECTED
    | null
  setQuestStatus: (
    status:
      | QuestStatus.VERIFIED
      | QuestStatus.RETRY
      | QuestStatus.REJECTED
      | null
  ) => void
  isReviewing: boolean

  // Card scrolling
  cardScrollRef: any
  setCardScrollRef: (ref: any) => void
  scrollPosition: number
  setScrollPosition: (scrollPosition: number) => void

  // Child toggles
  selectedChild: FamilyChildProfile | null
  childrenArray: FamilyChildProfile[]
  setChildren: (childrenArray: FamilyChildProfile[]) => void
  setSelectedChild: (child: FamilyChildProfile) => void

  // Quests
  selectedQuest: QuestInstance | null
  setSelectedQuest: (quest: QuestInstance | null) => void

  // Review
  questReviewDifficulty: number | null
  setQuestReviewDifficulty: (difficulty: number) => void
  questReviewReward: number
  setQuestReviewReward: (reward: number) => void
  questReviewNotes: string
  setQuestReviewNotes: (notes: string) => void

  // Quests for completion
  selectedQuestIdsForCompletion: number[]
  clearQuestIdsForCompletion: () => void
  toggleQuestIdForCompletion: (questId: number) => void
}

export const useCatchUpStore = create<CatchUpStore>((set, get) => ({
  // Modal view
  isOpen: false,
  onOpen: ({ isReviewing }) => {
    if (isReviewing) {
      trackAnalyticEvent(ANALYTIC_EVENTS.OPEN_QUESTS_REVIEW_CATCHUP)
    } else {
      trackAnalyticEvent(ANALYTIC_EVENTS.OPEN_QUESTS_COMPLETE_CATCHUP)
    }

    set({ isOpen: true, showCard: true, isReviewing })
  },
  onClose: () => {
    get().cardScrollRef?.current?.scrollTo({ top: 0 })
    set({ isOpen: false, showCard: false })
  },
  showCard: false,
  setShowCard: (showCard) => {
    get().cardScrollRef?.current?.scrollTo({ top: 0 })
    set({ showCard: showCard })
  },
  questStatus: null,
  setQuestStatus: (status) => set({ questStatus: status }),
  isReviewing: false,

  // Card scrolling
  cardScrollRef: null,
  setCardScrollRef: (ref) => set({ cardScrollRef: ref }),
  scrollPosition: 0,
  setScrollPosition: (scrollPosition) => set({ scrollPosition }),

  // Child toggles
  selectedChild: null,
  childrenArray: [],
  setChildren: (childrenArray: FamilyChildProfile[]) =>
    set({ childrenArray, selectedChild: childrenArray[0] }),
  setSelectedChild: (child: FamilyChildProfile) =>
    set({ selectedChild: child }),

  // Quests for review
  selectedQuest: null,
  setSelectedQuest: (quest) => {
    set({ selectedQuest: quest })
    if (quest) {
      set({ questReviewReward: quest.series.redeemable_reward })
      set({ questReviewDifficulty: null })
      set({ questReviewNotes: "" })
    }
  },

  // Review
  questReviewDifficulty: null,
  setQuestReviewDifficulty: (difficulty) =>
    set({ questReviewDifficulty: difficulty }),
  questReviewReward: 0,
  setQuestReviewReward: (reward) => set({ questReviewReward: reward }),
  questReviewNotes: "",
  setQuestReviewNotes: (notes) => set({ questReviewNotes: notes }),

  // Quests for completion
  selectedQuestIdsForCompletion: [],
  clearQuestIdsForCompletion: () => set({ selectedQuestIdsForCompletion: [] }),
  toggleQuestIdForCompletion: (questId: number) => {
    set((state) => {
      const newSelectedQuestIds = state.selectedQuestIdsForCompletion.includes(
        questId
      )
        ? state.selectedQuestIdsForCompletion.filter((id) => id !== questId)
        : [...state.selectedQuestIdsForCompletion, questId]
      return { selectedQuestIdsForCompletion: newSelectedQuestIds }
    })
  },
}))

export const useCatchUp = () => {
  const { data: questsToReview } = useQuestReviewQuery()
  const { data: quests } = useQuestBoardQuery()
  const { data: children } = useFamilyQuery(FamilyQuerySelect.CHILDREN)
  const {
    useCompleteQuestsMutation,
    useSkipQuestsMutation,
    useRejectMutation,
    useRetryMutation,
    useVerifyQuestMutation,
    useRejectQuestsMutaton,
  } = useQuestActions()

  const {
    onOpen,
    isReviewing,
    selectedChild,
    setSelectedChild,
    childrenArray,
    selectedQuestIdsForCompletion,
    clearQuestIdsForCompletion,
    selectedQuest,
    setSelectedQuest,
    setShowCard,
    questReviewDifficulty,
    questReviewReward,
    questReviewNotes,
    setQuestStatus,
    setChildren,
  } = useCatchUpStore(
    useShallow((state) => ({
      onOpen: state.onOpen,
      isOpen: state.isOpen,
      isReviewing: state.isReviewing,
      selectedChild: state.selectedChild,
      setSelectedChild: state.setSelectedChild,
      childrenArray: state.childrenArray,
      setChildren: state.setChildren,
      selectedQuestIdsForCompletion: state.selectedQuestIdsForCompletion,
      clearQuestIdsForCompletion: state.clearQuestIdsForCompletion,
      selectedQuest: state.selectedQuest,
      setSelectedQuest: state.setSelectedQuest,
      setShowCard: state.setShowCard,
      questReviewDifficulty: state.questReviewDifficulty,
      questReviewReward: state.questReviewReward,
      questReviewNotes: state.questReviewNotes,
      setQuestStatus: state.setQuestStatus,
    }))
  )
  const { checkRemainingFreeQuests } = useSubscription()

  const questsToComplete = quests?.filter((quest) =>
    [QuestStatus.OPEN, QuestStatus.RETRY].includes(quest.status)
  )

  const openCatchUp = ({ isReviewing }: { isReviewing: boolean }) => {
    if (!children) return
    if (childrenArray.length === 0) setChildren(children)
    if (isReviewing) {
      const firstQuestToReview = questsToReview?.[0] as QuestInstance
      const firstChild = children.find(
        (child) => child.user.id === firstQuestToReview?.user_id
      ) as FamilyChildProfile
      setSelectedQuest(firstQuestToReview)
      setSelectedChild(firstChild)

      trackAnalyticEvent(ANALYTIC_EVENTS.OPEN_QUESTS_REVIEW_CATCHUP)
    } else {
      const firstQuestToComplete = questsToComplete?.[0] as QuestInstance
      const firstChild = children.find(
        (child) => child.user.id === firstQuestToComplete?.user_id
      ) as FamilyChildProfile
      setSelectedQuest(firstQuestToComplete)
      setSelectedChild(firstChild)
      trackAnalyticEvent(ANALYTIC_EVENTS.OPEN_QUESTS_COMPLETE_CATCHUP)
    }
    onOpen({ isReviewing })
  }

  const onClickNextChild = () => {
    if (!selectedChild) return
    const currentIndex = childrenArray.indexOf(selectedChild)
    const nextIndex = (currentIndex + 1) % childrenArray.length

    setShowCard(false)
    setTimeout(() => setShowCard(true), 300)

    const nextChild = childrenArray[nextIndex]

    if (isReviewing) {
      const firstQuestToReview = questsToReview?.find(
        (quest) =>
          quest.user_id === nextChild.user.id && quest.id !== selectedQuest?.id
      )
      if (firstQuestToReview) setSelectedQuest(firstQuestToReview)
      else setSelectedQuest(null)
    }

    setSelectedChild(nextChild)
  }

  const onClickPreviousChild = () => {
    if (!selectedChild) return
    const currentIndex = childrenArray.indexOf(selectedChild)
    const previousIndex =
      (currentIndex - 1 + childrenArray.length) % childrenArray.length

    setShowCard(false)
    setTimeout(() => setShowCard(true), 300)

    const prevChild = childrenArray[previousIndex]

    if (isReviewing) {
      const firstQuestToReview = questsToReview?.find(
        (quest) => quest.user_id === prevChild.user.id
      )
      if (firstQuestToReview) setSelectedQuest(firstQuestToReview)
      else setSelectedQuest(null)
    }

    setSelectedChild(prevChild)
  }

  const goToNextQuest = () => {
    if (!selectedChild || !questsToReview) return null
    const nextQuestWithSameChild = questsToReview.find(
      (quest) =>
        quest.user_id === selectedChild.user.id &&
        quest.id !== selectedQuest?.id
    )
    if (nextQuestWithSameChild) setSelectedQuest(nextQuestWithSameChild)
    else onClickNextChild()
  }

  const onClickApproveQuest = async () => {
    if (!selectedQuest) return
    checkRemainingFreeQuests(1)
    const body = {
      verification_notes: questReviewNotes,
      redeemable_reward: questReviewReward,
      completion_difficulty: questReviewDifficulty || undefined,
      new_difficulty: undefined,
    }

    setQuestStatus(QuestStatus.VERIFIED)
    setTimeout(() => setShowCard(false), 600)
    setTimeout(() => {
      setQuestStatus(null)
      setShowCard(true)
      goToNextQuest()
    }, 900)

    useVerifyQuestMutation.mutate({
      instanceId: selectedQuest.id,
      data: body,
    })

    trackAnalyticEvent(ANALYTIC_EVENTS.VERIFY_QUEST, { source: "me_tab" })
  }

  const onClickRejectQuest = async () => {
    if (!selectedQuest) return
    checkRemainingFreeQuests(1)
    const body = {
      verification_notes: questReviewNotes,
      completion_difficulty: questReviewDifficulty || undefined,
    }

    setQuestStatus(QuestStatus.REJECTED)
    setTimeout(() => setShowCard(false), 600)
    setTimeout(() => {
      setQuestStatus(null)
      setShowCard(true)
      goToNextQuest()
    }, 900)

    useRejectMutation.mutate({
      instanceId: selectedQuest.id,
      data: body,
    })

    trackAnalyticEvent(ANALYTIC_EVENTS.REJECT_QUEST, { source: "me_tab" })
  }

  const onClickRetryQuest = async () => {
    if (!selectedQuest) return
    const body = {
      verification_notes: questReviewNotes,
      completion_difficulty: questReviewDifficulty || undefined,
    }

    setQuestStatus(QuestStatus.RETRY)
    setTimeout(() => setShowCard(false), 600)
    setTimeout(() => {
      setQuestStatus(null)
      setShowCard(true)
      goToNextQuest()
    }, 900)

    useRetryMutation.mutate({
      instanceId: selectedQuest.id,
      data: body,
    })

    trackAnalyticEvent(ANALYTIC_EVENTS.RETRY_QUEST, { source: "me_tab" })
  }

  const onClickCompleteQuests = async () => {
    if (selectedQuestIdsForCompletion.length === 0) return
    checkRemainingFreeQuests(selectedQuestIdsForCompletion.length)
    useCompleteQuestsMutation.mutate({
      questInstanceIds: selectedQuestIdsForCompletion,
      status: QuestStatus.VERIFIED,
      questDate: dayjs().format("YYYY-MM-DD"),
    })
    clearQuestIdsForCompletion()
    trackAnalyticEvent(ANALYTIC_EVENTS.COMPLETE_MULTIPLE_QUESTS, {
      count: selectedQuestIdsForCompletion.length,
      source: "me_tab",
    })
  }

  const onClickSkipQuests = async () => {
    if (selectedQuestIdsForCompletion.length === 0) return
    const filteredQuestIds = removeNonRepeatingQuestsFromIdArray(
      selectedQuestIdsForCompletion
    )
    if (filteredQuestIds.length !== selectedQuestIdsForCompletion.length) {
      toast("Only repeating quests can be skipped.")
    }
    useSkipQuestsMutation.mutate({
      questInstanceIds: filteredQuestIds,
      status: QuestStatus.SKIPPED,
      questDate: dayjs().format("YYYY-MM-DD"),
    })
    clearQuestIdsForCompletion()
    trackAnalyticEvent(ANALYTIC_EVENTS.SKIP_MULTIPLE_QUESTS, {
      count: filteredQuestIds.length,
      source: "me_tab",
    })
  }

  const removeNonRepeatingQuestsFromIdArray = (questInstanceIds: number[]) => {
    return questInstanceIds.filter((questId) =>
      questsToComplete?.find(
        (quest) =>
          quest.id === questId && quest.series.type === QuestType.REPEATING
      )
    )
  }

  const onClickRejectQuests = async () => {
    if (selectedQuestIdsForCompletion.length === 0) return
    checkRemainingFreeQuests(selectedQuestIdsForCompletion.length)
    useRejectQuestsMutaton.mutate({
      questInstanceIds: selectedQuestIdsForCompletion,
      status: QuestStatus.REJECTED,
      questDate: dayjs().format("YYYY-MM-DD"),
    })
    clearQuestIdsForCompletion()
    trackAnalyticEvent(ANALYTIC_EVENTS.REJECT_MULTIPLE_QUESTS, {
      count: selectedQuestIdsForCompletion.length,
      source: "me_tab",
    })
  }

  const goToNextChildWithQuests = () => {
    if (!selectedChild) return
    if (!questsToComplete || questsToComplete.length === 0) return

    const nextQuestToComplete = questsToComplete[0]
    const nextChildWithQuestToComplete = childrenArray.find(
      (child) => child.user.id === nextQuestToComplete.user_id
    )
    if (!nextChildWithQuestToComplete) return

    setSelectedChild(nextChildWithQuestToComplete as FamilyChildProfile)
  }

  return {
    onClickNextChild,
    onClickPreviousChild,

    onClickApproveQuest,
    onClickRetryQuest,
    onClickRejectQuest,

    onClickCompleteQuests,
    onClickSkipQuests,
    onClickRejectQuests,

    goToNextChildWithQuests,

    openCatchUp,
  }
}
