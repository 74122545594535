import {
  Button,
  FlexBox,
  Modal,
  SPACING,
  Typography,
} from "@joonapp/web-shared"

import { useManageGameplay } from "./useManageGameplay"
import { useManageRoutineTimesModalStore } from "./useManageRoutineTimesModalStore"
import TimeInput from "../../../components/timeInput/TimeInput"
import { PREFERENCE_KEY } from "../../../constants"

const ManageRoutineTimesModal = () => {
  const { child, updatePreferenceMutation } = useManageGameplay()
  const {
    isOpen: isOpenManageRoutineTimesModal,
    onClose: onCloseManageRoutineTimesModal,
    tempAfternoonStartTime,
    tempNightStartTime,
    setTempAfternoonStartTime,
    setTempNightStartTime,
    isSaving,
    setIsSaving,
  } = useManageRoutineTimesModalStore()

  return (
    <Modal
      isOpen={isOpenManageRoutineTimesModal}
      onClose={onCloseManageRoutineTimesModal}
      displayCloseIcon
      animate
    >
      <div className="quest-modal">
        <div
          className="quest-modal-content"
          style={{ width: "min(400px, 95vw)" }}
        >
          <FlexBox wrap={false} gap={SPACING.space4} direction="column">
            <FlexBox justify="center">
              <Typography variant="h3">
                Manage Routine Times for {child.name}
              </Typography>
            </FlexBox>
            <FlexBox
              justify="space-between"
              align="center"
              gap={SPACING.space6}
              wrap={false}
            >
              <FlexBox direction="column">
                <Typography variant="bodyBold">
                  Morning ends (and Afternoon starts) at...
                </Typography>
                <TimeInput
                  modalTitle="Select Morning End Time"
                  name="morningEnd"
                  time={tempAfternoonStartTime}
                  setTime={setTempAfternoonStartTime}
                  fullWidth
                />
              </FlexBox>
            </FlexBox>
            <FlexBox
              justify="space-between"
              align="center"
              gap={SPACING.space6}
              wrap={false}
            >
              <FlexBox direction="column">
                <Typography variant="bodyBold">
                  Afternoon ends (and Night starts) at...
                </Typography>
                <TimeInput
                  modalTitle="Select Afternoon End Time"
                  name="afternoonEnd"
                  time={tempNightStartTime}
                  setTime={setTempNightStartTime}
                  fullWidth
                />
              </FlexBox>
            </FlexBox>
            <Button
              text="Save"
              fullWidth
              isLoading={isSaving}
              onClick={() => {
                setIsSaving(true)
                Promise.all([
                  updatePreferenceMutation.mutateAsync({
                    childId: child.id,
                    key: PREFERENCE_KEY.afternoonStartTime,
                    value: tempAfternoonStartTime,
                  }),
                  updatePreferenceMutation.mutateAsync({
                    childId: child.id,
                    key: PREFERENCE_KEY.nightStartTime,
                    value: tempNightStartTime,
                  }),
                ]).then(() => {
                  onCloseManageRoutineTimesModal()
                  setIsSaving(false)
                })
              }}
            />
          </FlexBox>
        </div>
      </div>
    </Modal>
  )
}

export default ManageRoutineTimesModal
