import {
  ChevronRightIcon,
  FlexBox,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"

import { useAddEditRewardModal } from "./AddEditRewardModal"
import { useRewardCardModalStore } from "./RewardCardModal"
import RewardCardModal from "./RewardCardModal"
import { useRewardTemplatesModal } from "./RewardTemplates"
import Card from "../../components/card/Card"
import { HoverCard } from "../../components/hoverCard/HoverCard"
import { LoadingItemsSkeleton } from "../../components/skeleton/Skeleton"
import { TemplateFigure } from "../../components/templateFigure/TemplateFigure"
import { useCustomRewardsQuery } from "../../networking/queries"
import { CustomReward } from "../../types"

const RewardsShop = () => {
  const { data: rewards, status } = useCustomRewardsQuery()
  const { onOpen } = useRewardCardModalStore()

  return (
    <div style={{ paddingTop: SPACING.space3 }}>
      {status === "loading" ? (
        <LoadingItemsSkeleton />
      ) : rewards?.length === 0 ? (
        <RewardsShopEmpty />
      ) : (
        <FlexBox direction="column" gap={SPACING.space2}>
          <Typography variant="bodyXSmall">
            Your children will be able to purchse the following rewards from
            their in-game shop
          </Typography>
          <FlexBox direction="column" gap={SPACING.space1}>
            {rewards?.map((reward: CustomReward, i: number) => (
              <Card
                title={`${reward.emoji} ${reward.title}`}
                coinAmount={reward.cost}
                key={i}
                onClick={() => onOpen(reward.id)}
                childIds={reward.user_ids}
              />
            ))}
          </FlexBox>
          <RewardCardModal />
        </FlexBox>
      )}
    </div>
  )
}

export default RewardsShop

const RewardsShopEmpty = () => {
  const { onOpen: openRewardTemplateModal } = useRewardTemplatesModal()
  const { onOpen: openAddRewardModal } = useAddEditRewardModal()

  return (
    <FlexBox
      align="center"
      justify="center"
      gap={SPACING.space3}
      direction="column"
    >
      <HoverCard fullWidth onClick={() => openAddRewardModal()}>
        <FlexBox
          justify="space-between"
          direction="row"
          align="center"
          wrap={false}
        >
          <Typography variant="bodyBold" color={JoonUIColor.text.primaryAccent}>
            + Create your first Custom Reward
          </Typography>
          <ChevronRightIcon color={JoonUIColor.semantic.primary} size={24} />
        </FlexBox>
      </HoverCard>
      <Typography variant="caption">Or..</Typography>
      <HoverCard fullWidth onClick={() => openRewardTemplateModal()}>
        <FlexBox
          justify="space-between"
          direction="row"
          align="center"
          wrap={false}
        >
          <FlexBox direction="column" gap={SPACING.space2} wrap={false}>
            <Typography
              variant="bodyBold"
              color={JoonUIColor.text.primaryAccent}
            >
              See what other parents are creating:
            </Typography>

            <FlexBox justify="space-around" gap={SPACING.space6}>
              <TemplateFigure
                text="Go out for ice cream"
                image="/images/icons/icecream.png"
              />
              <TemplateFigure
                text="$5 of Robux"
                image="/images/icons/dollar-banknote.png"
              />
              <TemplateFigure
                text="15 minutes of screen-time"
                image="/images/icons/phone-icon.png"
              />
            </FlexBox>
          </FlexBox>
          <ChevronRightIcon color={JoonUIColor.semantic.primary} size={24} />
        </FlexBox>
      </HoverCard>
    </FlexBox>
  )
}
