import { CloseIcon, JoonUIColor, SPACING } from "@joonapp/web-shared"

import useMobile from "../../hooks/useMobile"

type Props = {
  isOpen: boolean
  onClose: () => void
}

const SlideUpModalCloseButton = ({ isOpen, onClose }: Props) => {
  const isMobile = useMobile()

  const backgroundColor = isMobile ? "#E4E8FF" : "transparent"
  const iconColor = isMobile
    ? JoonUIColor.text.primary
    : JoonUIColor.icon.inverted

  return (
    <button
      onClick={onClose}
      style={{
        position: "fixed",
        top: "0",
        right: "0",
        height: "30px",
        width: "30px",
        background: backgroundColor,
        borderBottomLeftRadius: SPACING.space2,
        zIndex: 3000,
        display: isOpen ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
        pointerEvents: isOpen ? "auto" : "none",
      }}
    >
      <CloseIcon color={iconColor} size={20} />
    </button>
  )
}

export default SlideUpModalCloseButton
