import { UserRole, TherapistUser } from "@joonapp/web-shared"

export interface CustomRewardInfo {
  title: string
  cost: number
  emoji: string
  user_ids: number[]
}

export interface CustomReward extends CustomRewardInfo {
  id: number
  template_id: number | null
}

export interface RewardPurchase {
  id: number
  redeem_date: string | null
  reward: CustomReward
  user_id: number
  purchase_date: string
}

// TODO: Need to export these over to web-shared
export interface TherapistProfile {
  nickname: string
  role: UserRole.THERAPIST
  profession: string
  profile_image_url: string | null
  user: TherapistUser
}

export interface TherapistProfileWithPatients extends TherapistProfile {
  patients: number[]
}

// TODO: Move this to web-shared. Note there's an `AuthCode` there already but it's a component.
export interface AuthCode {
  code: string
  id: number
  type: number
}

export enum SubscriptionTrialKey {
  NONE = "none",
  SEVEN = "seven",
  FOURTEEN = "fourteen",
  THIRTY = "thirty",
}
