import { create } from "zustand"

interface ChildQuestionnaireModalStore {
  isOpen: boolean
  onOpen: (childId: number, onSubmit: Function) => void
  onClose: () => void
  childId: number | null
  onSubmit: Function
}

export const useChildQuestionnaireModalStore =
  create<ChildQuestionnaireModalStore>((set) => ({
    isOpen: false,
    onOpen: (childId, onSubmit) => set({ isOpen: true, childId, onSubmit }),
    onClose: () => set({ isOpen: false, onSubmit: () => {} }),
    childId: null,
    onSubmit: () => {},
  }))
