import {
  Button,
  CloseIcon,
  FlexBox,
  JoonUIColor,
  SPACING,
  TextButton,
  TherapistIcon,
  Typography,
} from "@joonapp/web-shared"
import { useEffect, useState } from "react"

import { useTherapistsQuery } from "../../../networking/queries"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"
import { localStorageItems } from "../../../util/storage"
import { useInviteTherapistViaEmailModal } from "../../family/therapists/InviteTherapistViaEmailModal"

const InviteABASection = () => {
  const { onOpen: openInviteModal } = useInviteTherapistViaEmailModal()
  const { data: therapists, isLoading: isLoadingTherapists } =
    useTherapistsQuery()
  const [forceUpdate, setForceUpdate] = useState(false)

  const showABAInviteSection = localStorage.getItem(
    localStorageItems.showABAInviteSection
  )
  const hasLinkedTherapist = therapists && therapists?.length > 0
  const isComponentHidden =
    isLoadingTherapists || hasLinkedTherapist || !showABAInviteSection

  useEffect(() => {
    if (isComponentHidden) return
    trackAnalyticEvent(ANALYTIC_EVENTS.SHOW_ABA_THERAPIST_PROMPT)
  }, [isComponentHidden])

  if (isComponentHidden) return <></>

  const handleClose = () => {
    localStorage.removeItem(localStorageItems.showABAInviteSection)
    setForceUpdate(!forceUpdate)
    trackAnalyticEvent(ANALYTIC_EVENTS.ABA_THERAPIST_FORCE_CLOSE)
  }

  return (
    <FlexBox
      direction="column"
      gap={SPACING.space2}
      align="center"
      style={{
        background:
          "linear-gradient(108deg, rgba(255, 255, 255, 0.60) -6.4%, rgba(255, 255, 255, 0.80) 20.05%, rgba(255, 255, 255, 0.70) 69.45%, rgba(255, 255, 255, 0.60) 111.48%)",
        borderRadius: SPACING.space4,
        padding: SPACING.space4,
        position: "relative",
      }}
    >
      <TextButton
        onClick={handleClose}
        style={{
          padding: SPACING.space1,
          position: "absolute",
          top: SPACING.space2,
          right: SPACING.space2,
        }}
      >
        <CloseIcon color={JoonUIColor.icon.neutral} />
      </TextButton>
      <FlexBox
        direction="column"
        justify="center"
        align="center"
        gap={SPACING.space1}
        style={{ width: "min(450px, 100%)" }}
      >
        <TherapistIcon size={32} color={JoonUIColor.background.accent} />

        <Typography variant="bodyBold" textAlign="center">
          Share your child’s progress with <br />
          their BCBA or RBT
        </Typography>
        <Typography variant="bodySmall" textAlign="center">
          Earn <strong>12-months of free Joon</strong> access when you connect
          your child's ABA Therapist so they can provide better treatment
          recommendations
        </Typography>
        <Button
          text="Invite therapist"
          style={{ width: "min(300px, 100%)", marginTop: SPACING.space2 }}
          onClick={() => {
            trackAnalyticEvent(ANALYTIC_EVENTS.ADD_THERAPIST_PROMPT_INVITE, {
              source: "me_page",
            })
            openInviteModal()
          }}
        />
      </FlexBox>
    </FlexBox>
  )
}

export default InviteABASection
