import "./parentTestimonialCarousel.scss"

// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/autoplay"
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  JoonUIColor,
  Shadow,
  Typography,
} from "@joonapp/web-shared"
import { CSSProperties, useRef } from "react"
import {
  A11y,
  Autoplay,
  Navigation,
  Pagination,
  Scrollbar,
} from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"

export function ParentTestimonialCarousel() {
  const swiperRef = useRef(null)

  return (
    <div style={{ position: "relative" }}>
      <PrevButton swiperRef={swiperRef} />
      <div
        className="parent-testimonial-carousel"
        style={{
          boxShadow: Shadow.high,
          background: JoonUIColor.background.primaryNeutral,
        }}
      >
        <Swiper
          loop
          className="testimonial-box"
          modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
          pagination={true}
          autoplay={{ delay: 5000, disableOnInteraction: false }}
          spaceBetween={30}
          onInit={(swiper: any) => (swiperRef.current = swiper)}
        >
          {parentTestimonials.map((testimonial: any, i: number) => (
            <SwiperSlide>
              <Testimonial testimonial={testimonial} key={i} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <NextButton swiperRef={swiperRef} />
    </div>
  )
}
function Testimonial({
  testimonial,
}: {
  testimonial: (typeof parentTestimonials)[number]
}) {
  const { image, text, quotee } = testimonial

  return (
    <div className="testimonial">
      <img
        src={image}
        style={{ width: "38px", borderRadius: "38px" }}
        alt="placeholder"
      />
      <Typography
        variant="body"
        style={{ lineHeight: "18px", height: "fit-content" }}
      >
        {text}
      </Typography>
      <Typography variant="bodyXSmall" style={{ fontStyle: "italic" }}>
        - {quotee}, {testimonial.quoteeLabel}
      </Typography>{" "}
    </div>
  )
}

const carouselButtonStyles = {
  width: "20px",
  height: "20px",
  position: "absolute" as CSSProperties["position"],
  border: `1px solid ${JoonUIColor.border.accent}`,
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: JoonUIColor.background.accent,
  padding: 0,
  zIndex: 10,
  top: "50%",
}

const PrevButton = ({ swiperRef }: any) => {
  return (
    <button
      style={{ ...carouselButtonStyles, left: "-8px" }}
      onClick={() => swiperRef.current.slidePrev()}
    >
      <ChevronLeftIcon size={20} color={JoonUIColor.text.inverted} />
    </button>
  )
}

const NextButton = ({ swiperRef }: any) => {
  return (
    <button
      style={{ ...carouselButtonStyles, right: "-8px" }}
      onClick={() => swiperRef.current.slideNext()}
    >
      <ChevronRightIcon size={20} color={JoonUIColor.text.inverted} />
    </button>
  )
}

const parentTestimonials = [
  {
    text: (
      <div style={{ display: "block" }}>
        “Seriously try it.{" "}
        <span>
          We have a 7yo with ADHD and we have some pretty big power struggles
          every day. Now that we have Joon,{" "}
          <span className="semibold">she ASKS ME to assign her tasks</span> and
          help her on her behavior.”
        </span>
      </div>
    ),
    quotee: "Lauren",
    quoteeLabel: "Mother of 2 kids with ADHD",
    image: "/images/avatars/testimonial_lauren.svg",
  },
  {
    text: (
      <div style={{ display: "block" }}>
        “All of my kids use Joon.{" "}
        <span className="semibold">
          It helps motivate them to do their homework, chores, and keep
          physically fit!{" "}
        </span>
        These are things I could never convince them to do on my own.”
      </div>
    ),
    quotee: "Rebecca",
    quoteeLabel: "Mother of 3 kids with ADHD, ODD and autism",
    image: "/images/avatars/testimonial_rebecca.svg",
  },
  {
    text: (
      <div style={{ display: "block" }}>
        <span className="semibold">
          "Joon has been a game-changer in my practice as a clinical
          psychologist for children.{" "}
        </span>
        It empowers children to enhance their executive functioning skills, from
        focus to organization and decision-making.”
      </div>
    ),
    quotee: "Dr. Carrie Jackson",
    quoteeLabel: "Licensed ADHD Child Psychologist",
    image: "/images/avatars/testimonial_drjackson.svg",
  },
]
