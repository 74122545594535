import {
  Checkbox,
  CloseIcon,
  DateInput,
  FlexBox,
  JoonUIColor,
  SPACING,
  SegmentedControl,
  SelectInput,
  Typography,
} from "@joonapp/web-shared"
import dayjs from "dayjs"
import { useState } from "react"

import { useAddEditQuestStore } from "./useAddEditQuestStore"
import InputSectionBox from "../../../components/inputSectionBox/InputSectionBox"
import {
  QuestRepeatFrequency,
  REPETITIONS,
  questRepeatSegmentedControlOptions,
} from "../../../constants"
import { getDayAbbreviation, getRepetitionText } from "../../../util/util"

const SchedulePicker = () => {
  const {
    repetition,
    repeatsFrequency,
    startDate,

    setRepetition,
    setRepeatsFrequency,
    setStartDate,

    questRepeatSelection,
    setQuestRepeatSelection,

    selectedQuest,
  } = useAddEditQuestStore()

  const [isCollapsing, setIsCollapsing] = useState(false)
  const isEditing = !!selectedQuest

  const toggleRepeatQuest = (option: (typeof questRepeatSelection)[number]) => {
    if (isCollapsing) return
    if (option === questRepeatSelection) return

    const questDoesNotRepeatSelection = QuestRepeatFrequency.NO_REPEAT
    const questRepeatsSelection = QuestRepeatFrequency.REPEAT
    const isSwitchingToDoesNotRepeat = option === questDoesNotRepeatSelection
    const isSwitchingToRepeats = option === questRepeatsSelection

    setStartDate(null)
    if (isSwitchingToDoesNotRepeat) {
      setQuestRepeatSelection(questDoesNotRepeatSelection)
      setRepeatsFrequency(null)
      setRepetition(REPETITIONS.noDays)
    } else if (isSwitchingToRepeats) {
      setRepeatsFrequency(1)
      setRepetition(REPETITIONS.daily)
      setQuestRepeatSelection(questRepeatsSelection)
    }
    setIsCollapsing(true)
    setTimeout(() => {
      setIsCollapsing(false)
    }, 500) // Delay for the collapse duration
  }

  const toggleDay = (index: number) => {
    const newDays = !repetition ? [...REPETITIONS.noDays] : [...repetition]
    newDays[index] = !newDays[index]
    if (newDays?.every((day) => !day)) return
    setRepetition(newDays)
  }

  const weekOptions = Array.from({ length: 4 }, (_, i) => ({
    value: i + 1,
    label: `${i > 0 ? i + 1 : ""} Week${i > 0 ? "s" : ""}`,
  }))

  return (
    <FlexBox direction="column" gap={SPACING.space1}>
      <Typography variant="bodyBold">Repeat this Quest?</Typography>
      <SegmentedControl
        options={questRepeatSegmentedControlOptions}
        value={questRepeatSelection}
        setValue={(option) => toggleRepeatQuest(option as string)}
        disabled={isEditing}
        fullWidth
      />

      <InputSectionBox
        hidden={
          !(
            questRepeatSelection === QuestRepeatFrequency.NO_REPEAT &&
            !isCollapsing
          )
        }
      >
        <FlexBox gap={SPACING.space1} direction="column">
          <Typography variant="caption">
            Should this Quest appear on a specific date?
          </Typography>
          <FlexBox gap={SPACING.space1} wrap={false} align="center">
            <DateInput
              fullWidth
              modalTitle="Date Quest is Due On"
              name="Date"
              date={startDate ? dayjs(startDate).toDate() : null}
              setDate={(date) => setStartDate(date.toString())}
            />
            {startDate && (
              <button onClick={() => setStartDate(null)}>
                <CloseIcon color={JoonUIColor.icon.accent} />
              </button>
            )}
          </FlexBox>
          <Typography variant="caption" color={JoonUIColor.semantic.primary}>
            {getRepetitionText(repetition, startDate, repeatsFrequency)}
          </Typography>
        </FlexBox>
      </InputSectionBox>

      <InputSectionBox
        hidden={
          !(
            questRepeatSelection === QuestRepeatFrequency.REPEAT &&
            !isCollapsing
          )
        }
      >
        <FlexBox gap={SPACING.space2} direction="column">
          <Typography variant="caption">
            Repeat on the following days...
          </Typography>
          <FlexBox
            gap={SPACING.space1}
            wrap={false}
            style={{ overflowX: "auto" }}
          >
            {repetition &&
              [1, 2, 3, 4, 5, 6, 0].map((dayNumber, i) => (
                <Checkbox
                  name={getDayAbbreviation(dayNumber)}
                  label={getDayAbbreviation(dayNumber)}
                  selected={repetition[dayNumber]}
                  onChange={() => toggleDay(dayNumber)}
                  fullWidth
                  key={i}
                  textOnly
                />
              ))}
          </FlexBox>
          <FlexBox gap={SPACING.space1} wrap={false} align="center">
            <Typography variant="caption" style={{ minWidth: "fit-content" }}>
              And, repeat every
            </Typography>
            <div style={{ width: "200px" }}>
              <SelectInput
                isMulti={false}
                name="repeatsFrequency"
                options={weekOptions}
                // TODO: SelectedOption should allow null type
                selectedOption={
                  repeatsFrequency
                    ? weekOptions.find(
                        (option) => option.value === repeatsFrequency
                      )
                    : (null as any)
                }
                setSelectedOption={(option) =>
                  setRepeatsFrequency(option.value as number)
                }
                placeholder="Select Frequency"
                fullWidth
              />
            </div>
          </FlexBox>
          <Typography variant="caption">Optionally, starting on...</Typography>
          <FlexBox gap={SPACING.space1} wrap={false} align="center">
            <DateInput
              fullWidth
              name="Date"
              modalTitle="Repeating Quest Will Begin On"
              date={startDate ? dayjs(startDate).toDate() : null}
              setDate={(date) => setStartDate(date.toString())}
            />
            {startDate && (
              <button onClick={() => setStartDate(null)}>
                <CloseIcon color={JoonUIColor.icon.accent} />
              </button>
            )}
          </FlexBox>
          <Typography variant="caption" color={JoonUIColor.semantic.primary}>
            {getRepetitionText(repetition, startDate, repeatsFrequency)}
          </Typography>
        </FlexBox>
      </InputSectionBox>
    </FlexBox>
  )
}

export default SchedulePicker
