import { FlexBox, IconProps, JoonColorExpanded } from "@joonapp/web-shared"

export interface ProgramStepIconProps extends IconProps {
  step?: number
}

export const ProgramStepIcon = ({ step = 1 }: ProgramStepIconProps) => {
  return (
    <FlexBox
      align="center"
      justify="center"
      style={{
        fontWeight: "500",
        fontSize: "13px",
        color: JoonColorExpanded.indigo500,
        width: "24px",
        height: "23px",
        backgroundColor: "#FFFFFF",
        border: `1px solid ${JoonColorExpanded.indigo500}`,
        borderRadius: "50%",
        display: "flex",
      }}
    >
      {step}
    </FlexBox>
  )
}
