import { JoonUIColor, SPACING, Typography } from "@joonapp/web-shared"

export const PhoneNumberReminder = () => {
  return (
    <Typography
      variant="bodySmall"
      color={JoonUIColor.text.secondary}
      style={{
        textAlign: "left",
        borderRadius: SPACING.space2,
        padding: SPACING.space4,
        background: JoonUIColor.background.xlightGray,
        lineHeight: 1.25,
      }}
    >
      <strong>Note: </strong>
      We send a daily text message reminder for things your care navigator or
      clinician has shared with you to help keep you on track with your goals.
      Your number will not be shared with any third party. Reply STOP any time
      to cancel, or HELP for more info. Only US numbers are currently supported.
    </Typography>
  )
}
