import { SPACING } from "@joonapp/web-shared"
import React from "react"

type Props = {
  children: React.ReactNode
  style?: React.CSSProperties
}

const TrainingProgressionBar = ({ children, style }: Props) => {
  return (
    <div
      style={{
        position: "sticky",
        bottom: "0",
        padding: SPACING.space4,
        marginTop: "auto",
        background: "white",
        borderTopLeftRadius: SPACING.space4,
        borderTopRightRadius: SPACING.space4,
        boxShadow: `0px -4px 8px 0px rgba(123, 131, 142, 0.20)`,
        width: "100%",
        ...style,
      }}
    >
      {children}
    </div>
  )
}

export default TrainingProgressionBar
