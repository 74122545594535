import CatchUpCardContent from "./CatchUpCardContent"
import ChildToggleHeader from "./CatchUpChildToggle"
import CatchUpCompleteContent from "./CatchUpCompleteContent"
import { useCatchUpStore } from "./useCatchUpStore"
import SlideUpModal from "../../components/slideUpModal/SlideUpModal"
import useMobile from "../../hooks/useMobile"
import { useQuestBoardQuery } from "../../networking/queries"

const CatchUp = () => {
  const { isOpen, onClose, showCard } = useCatchUpStore((state) => ({
    isOpen: state.isOpen,
    onClose: state.onClose,
    showCard: state.showCard,
  }))
  const isReviewing = useCatchUpStore((state) => state.isReviewing)
  const isMobile = useMobile()
  useQuestBoardQuery() // Added query here to get around rerendering quest data issue

  return (
    <SlideUpModal
      isOpen={isOpen}
      onClose={onClose}
      showCard={showCard}
      size="default"
      showCloseButton
      headerComponent={<ChildToggleHeader />}
      backgroundType={isMobile ? "solid" : "overlay"}
    >
      {isReviewing ? <CatchUpCardContent /> : <CatchUpCompleteContent />}
    </SlideUpModal>
  )
}

export default CatchUp
