import { useQueryClient } from "@tanstack/react-query"
import React, { useEffect } from "react"

import { analyticsUserDidLogout } from "./analytics"
import { useExperimentsQuery } from "./experiments"
import { history, useRouter } from "./router"
import { localStorageItems, sessionManager } from "./storage"
import { apiRequest, hasPurchasedPlanBefore } from "./util"
import { hasActivePlanSync, getActivePlan } from "./util"
import PageLoader from "../components/loading/PageLoader"
import { validClinicianAccess } from "../constants"
import { useFamilyQuery, useUserQuery } from "../networking/queries"
import { SubscriberAPIResponse } from "../types"

export const useAuth = () => {
  const queryClient = useQueryClient()
  const { data: family } = useFamilyQuery()

  function getSubscriberInfo() {
    if (!family) throw new Error("No family")

    return apiRequest(
      `subscriber-info?subscriber_id=${family.subscriber_id}`
    ).then(
      // Q: not sure if worth describing the response type here
      // TODO: We should consolidate this with the one in `user.ts`
      (response: SubscriberAPIResponse) => {
        response.planIsActive = hasActivePlanSync(response.subscriber)
        response.activePlan = getActivePlan(response.subscriber)
        response.hasPurchasedPlanBefore = hasPurchasedPlanBefore(
          response.subscriber
        )
        return response
      }
    )
  }

  function signout() {
    analyticsUserDidLogout()
    sessionManager.clearAuthTokens()
    window.location.href = "/welcome"
    queryClient.removeQueries()
  }

  return {
    getSubscriberInfo,
    signout,
  }
}

export const requireAuth = (Component: React.FC) => (props: any) => {
  // Get authenticated user
  const auth = useAuth()
  const { user } = useUserQuery()
  useExperimentsQuery()
  const router = useRouter()
  const hasRefreshToken = sessionManager.getRefreshToken()

  const { priceId, campaign, tpk, clinician_access } = router.query

  useEffect(() => {
    // If the user is not logged in, and they are using a campaign link or
    //   there is a priceId/tpk in the url, store the campaign link in localstorage
    //   so that once they log in, if they have a redirect link, send them
    //   there instead of the paywall, then send them to the welcome page
    if (
      !user &&
      !hasRefreshToken &&
      (campaign || validClinicianAccess.includes(clinician_access))
    ) {
      const path = router.location.pathname + router.location.search
      localStorage.setItem(localStorageItems.redirectLink, path)
      history.replace("/welcome")
    } else if (!user && !hasRefreshToken && (priceId || tpk)) {
      const path = router.location.pathname + router.location.search
      localStorage.setItem(localStorageItems.redirectLink, path)
      history.replace("/auth/signin")
    } else if (!hasRefreshToken) history.replace("/welcome")

    // eslint-disable-next-line
  }, [auth, router])

  if (!user) return <PageLoader />

  // Render component now that we have user
  return <Component {...props} />
}
