import {
  CloseIcon,
  FlexBox,
  JoonUIColor,
  Modal,
  PhotoIcon,
  QuestInstance,
  QuestStatus,
  SPACING,
  StickyNoteIcon,
  Typography,
} from "@joonapp/web-shared"
import dayjs, { Dayjs } from "dayjs"
import { useState } from "react"

import { useViewQuestModalStore } from "./useViewQuestModalStore"
import { completeQuestStatuses } from "../../constants"
import { useQuestLogQuery } from "../../networking/queries"
import { dateIsToday } from "../../util/util"

const QuestLog = () => {
  const { questInstance } = useViewQuestModalStore()
  const { data } = useQuestLogQuery(questInstance ? questInstance.series.id : 0)

  return (
    <table style={{ width: "100%", textAlign: "left" }}>
      <thead>
        <tr>
          <th>
            <Typography variant="bodySmall" color={JoonUIColor.text.secondary}>
              Date
            </Typography>
          </th>
          <th>
            <Typography variant="bodySmall" color={JoonUIColor.text.secondary}>
              Status
            </Typography>
          </th>
          <th>
            <Typography variant="bodySmall" color={JoonUIColor.text.secondary}>
              Coins
            </Typography>
          </th>
          <th>
            <Typography variant="bodySmall" color={JoonUIColor.text.secondary}>
              Details
            </Typography>
          </th>
        </tr>
      </thead>
      <tbody>
        {data?.map((questInstance: QuestInstance, i: number) => {
          const questIsComplete =
            questInstance?.status &&
            completeQuestStatuses.includes(questInstance.status)

          return (
            <tr key={i}>
              <td>
                <Typography variant="bodySmall">
                  {dayjs(questInstance.date, "YYYY-MM-DD").format("MM/DD")}
                </Typography>
              </td>
              <td>
                {getQuestPanelStatusLabel(
                  questInstance.status,
                  dayjs(questInstance.date)
                )}
              </td>
              <td data-tooltip-id={`instance-reward-${i}`}>
                <Typography
                  variant={"bodySmall"}
                  color={JoonUIColor.text.primary}
                >
                  {questIsComplete ? questInstance.redeemable_reward : "-"}
                </Typography>
              </td>
              <td>
                <FlexBox align="center" gap={SPACING.space1} wrap={false}>
                  <QuestNote questCompletion={questInstance} />
                  <QuestPic questCompletion={questInstance} />
                </FlexBox>
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default QuestLog

const QuestNote = ({ questCompletion }: { questCompletion: QuestInstance }) => {
  const [showModal, setShowModal] = useState(false)

  if (!questCompletion.completion_notes && !questCompletion.verification_notes)
    return null

  return (
    <>
      <button onClick={() => setShowModal(true)}>
        <FlexBox
          style={{
            padding: `${SPACING.space05} ${SPACING.space1}`,
            background: JoonUIColor.background.lightGray,
            borderRadius: SPACING.space1,
            width: "fit-content",
          }}
          wrap={false}
          align="center"
          justify="center"
          gap={SPACING.space1}
        >
          <StickyNoteIcon size={16} color={JoonUIColor.icon.neutral} />
          <Typography variant="bodySmall">note</Typography>
        </FlexBox>
      </button>
      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        displayCloseIcon={false}
        animate
      >
        <FlexBox
          style={{
            padding: `${SPACING.space4} ${SPACING.space6}`,
            width: "min(95vw, 400px)",
          }}
          justify="space-between"
          align="center"
          wrap={false}
        >
          <Typography variant="h3">Completion notes</Typography>
          <button onClick={() => setShowModal(false)} style={{ padding: "0" }}>
            <CloseIcon color={JoonUIColor.text.primary} />
          </button>
        </FlexBox>
        <FlexBox
          style={{
            padding: `${SPACING.space0} ${SPACING.space6} ${SPACING.space6} ${SPACING.space6}`,
            width: "min(95vw, 400px)",
          }}
          gap={SPACING.space2}
          direction="column"
        >
          {questCompletion.completion_notes && (
            <FlexBox
              style={{
                padding: SPACING.space2,
                borderRadius: SPACING.space2,
                background: JoonUIColor.background.lightBlue,
              }}
            >
              <Typography
                variant="bodyBold"
                color={JoonUIColor.text.primary}
                textAlign="left"
              >
                Submitted completion notes
              </Typography>
              <Typography variant="body">
                {questCompletion.completion_notes}
              </Typography>
            </FlexBox>
          )}
          {questCompletion.verification_notes && (
            <FlexBox
              style={{
                padding: SPACING.space2,
                borderRadius: SPACING.space2,
                background: JoonUIColor.background.lightBlue,
              }}
            >
              <Typography
                variant="bodyBold"
                color={JoonUIColor.text.primary}
                textAlign="left"
              >
                Response from parents
              </Typography>
              <Typography variant="body">
                {questCompletion.verification_notes}
              </Typography>
            </FlexBox>
          )}
        </FlexBox>
      </Modal>
    </>
  )
}

const QuestPic = ({ questCompletion }: { questCompletion: QuestInstance }) => {
  const [showModal, setShowModal] = useState(false)

  if (!questCompletion.completion_photo_url) return null

  return (
    <>
      <button onClick={() => setShowModal(true)}>
        <FlexBox
          style={{
            padding: `${SPACING.space05} ${SPACING.space1}`,
            background: JoonUIColor.background.lightGray,
            borderRadius: SPACING.space1,
            width: "fit-content",
          }}
          wrap={false}
          align="center"
          justify="center"
          gap={SPACING.space1}
        >
          <PhotoIcon size={16} color={JoonUIColor.icon.neutral} />
          <Typography variant="bodySmall">pic</Typography>
        </FlexBox>
      </button>
      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        displayCloseIcon={false}
        animate
      >
        <FlexBox
          style={{
            padding: `${SPACING.space4} ${SPACING.space6}`,
            width: "min(95vw, 600px)",
          }}
          justify="space-between"
          align="center"
          wrap={false}
        >
          <Typography variant="h3">Completion Photo</Typography>
          <button onClick={() => setShowModal(false)} style={{ padding: "0" }}>
            <CloseIcon color={JoonUIColor.text.primary} />
          </button>
        </FlexBox>
        <FlexBox
          style={{
            padding: `${SPACING.space0} ${SPACING.space6} ${SPACING.space6} ${SPACING.space6}`,
            width: "min(95vw, 600px)",
          }}
        >
          <img
            src={questCompletion.completion_photo_url}
            alt="completion"
            style={{ width: "100%" }}
          />
        </FlexBox>
      </Modal>
    </>
  )
}

const getQuestPanelStatusLabel = (status: QuestStatus, date: Dayjs) => {
  switch (status) {
    case QuestStatus.COMPLETED:
      return <Typography variant="bodySmall">Not yet verified</Typography>
    case QuestStatus.REDEEMED:
    case QuestStatus.VERIFIED:
      return <Typography variant="bodySmall">Completed</Typography>
    case QuestStatus.OPEN:
      if (dateIsToday(date)) {
        return <Typography variant="bodySmall">Incomplete</Typography>
      } else return <Typography variant="bodySmall">Missed</Typography>
    case QuestStatus.REJECTED:
      return (
        <Typography variant="bodySmall" color={JoonUIColor.background.alert}>
          Rejected
        </Typography>
      )
    case QuestStatus.SKIPPED:
      return (
        <Typography variant="bodySmall" color={JoonUIColor.text.secondary}>
          Skipped
        </Typography>
      )
    default:
      return (
        <Typography variant="bodySmall" color={JoonUIColor.text.secondary}>
          -
        </Typography>
      )
  }
}
