import { create } from "zustand"

interface ParentTasksModalStore {
  isOpen: boolean
  onOpen: (selectedTherapistId: number) => void
  onClose: () => void
  selectedTherapistId: number | null
}

const useParentTasksModalStore = create<ParentTasksModalStore>((set) => ({
  isOpen: false,
  onOpen: (selectedTherapistId) => set({ isOpen: true, selectedTherapistId }),
  onClose: () => set({ isOpen: false }),
  selectedTherapistId: null,
}))

export default useParentTasksModalStore
