import { create } from "zustand"

interface ManageRoutineTimesModalStore {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  tempAfternoonStartTime: string | null
  tempNightStartTime: string | null
  setTempAfternoonStartTime: (time: string | null) => void
  setTempNightStartTime: (time: string | null) => void
  isSaving: boolean
  setIsSaving: (isSaving: boolean) => void
}

export const useManageRoutineTimesModalStore =
  create<ManageRoutineTimesModalStore>((set) => ({
    isOpen: false,
    onOpen: () => set({ isOpen: true }),
    onClose: () => set({ isOpen: false }),
    tempAfternoonStartTime: null,
    tempNightStartTime: null,
    setTempAfternoonStartTime: (time) => set({ tempAfternoonStartTime: time }),
    setTempNightStartTime: (time) => set({ tempNightStartTime: time }),
    isSaving: false,
    setIsSaving: (isSaving) => set({ isSaving }),
  }))
