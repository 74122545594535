import {
  Button,
  CalloutBox,
  Checkbox,
  Modal,
  TextInput,
  Typography,
} from "@joonapp/web-shared"
import { useQueryClient } from "@tanstack/react-query"
import { useState } from "react"
import toast from "react-hot-toast"
import { create } from "zustand"

import therapistDisclaimer from "./therapistDisclaimer"
import { QUERY_KEYS } from "../../../constants"
import { useFamilyQuery, useUserQuery } from "../../../networking/queries"
import { sendTherapistInvitation } from "../../../networking/user"
import { localStorageItems } from "../../../util/storage"
import { createErrorFromResponse, separateFamily } from "../../../util/util"

interface InviteTherapistViaEmailModalStore {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

export const useInviteTherapistViaEmailModal =
  create<InviteTherapistViaEmailModalStore>((set) => ({
    isOpen: false,
    onOpen: () => set({ isOpen: true }),
    onClose: () => set({ isOpen: false }),
  }))

export const InviteTherapistViaEmailModal = () => {
  const { isOpen, onClose } = useInviteTherapistViaEmailModal()
  const [email, setEmail] = useState("")
  const [error, setError] = useState<string | null>(null)
  const [selectedChildren, setSelectedChildren] = useState<number[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const { data: family } = useFamilyQuery()
  const { user } = useUserQuery()
  const { children } = separateFamily(user, family)
  const queryClient = useQueryClient()

  const selectChild = (id: number) => {
    if (selectedChildren.includes(id)) {
      setSelectedChildren(selectedChildren.filter((childId) => childId !== id))
    } else {
      setSelectedChildren([...selectedChildren, id])
    }
  }

  const sendInvite = async () => {
    setIsLoading(true)
    try {
      await sendTherapistInvitation(email, selectedChildren)
      onClose()
      toast.success("Invite sent successfully!")
      localStorage.removeItem(localStorageItems.showABAInviteSection)
      queryClient.invalidateQueries([QUERY_KEYS.THERAPIST_INVITES])
      setEmail("")
      setError(null)
    } catch (error) {
      const errorMessage = createErrorFromResponse(error)
      setError(errorMessage)
    }
    setIsLoading(false)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} displayCloseIcon animate>
      <div className="link-therapist-modal">
        <div className="modal-title">Invite Therapist</div>
        <div className="modal-body">
          <div>
            <div className="font-body mb-8">Therapist email address</div>
            <TextInput
              value={email}
              icon="email"
              type="email"
              name="therapist code"
              placeholder="Enter email"
              onChange={(e: any) => setEmail(e.target.value)}
              fullWidth
            />
          </div>
          <div>
            <div className="font-body mb-8">Whose therapist is this?</div>
            <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
              {children.map((child, i) => (
                <Checkbox
                  name="test"
                  key={i}
                  label={child.user.name}
                  onChange={() => selectChild(child.user.id)}
                  selected={selectedChildren.includes(child.user.id)}
                />
              ))}
            </div>
          </div>
          <CalloutBox fullWidth>{therapistDisclaimer}</CalloutBox>
        </div>
        {error && (
          <CalloutBox fullWidth>
            <Typography variant="bodySmall">{error}</Typography>
          </CalloutBox>
        )}
        <div className="px-24">
          <Button
            text="Invite therapist"
            size="large"
            fullWidth
            isLoading={isLoading}
            onClick={sendInvite}
            disabled={email === "" || selectedChildren.length === 0}
          />
        </div>
      </div>
    </Modal>
  )
}
