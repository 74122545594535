import { JoonColorExpanded } from "@joonapp/web-shared"

const NavigationDots = ({ activeIndex }: { activeIndex: number }) => {
  return (
    <div
      style={{
        position: "absolute",
        bottom: "24px",
        left: "50%",
        transform: "translateX(-50%)",
        display: "flex",
        gap: "10px",
      }}
    >
      {[...Array(3)].map((_, index) => (
        <span
          style={{
            width: "10px",
            height: "10px",
            borderRadius: "50%",
            backgroundColor:
              index === activeIndex ? "#553B75" : JoonColorExpanded.purple200,
          }}
        />
      ))}
    </div>
  )
}

export default NavigationDots
