import { create } from "zustand"

interface AddPhoneNumberModalStore {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  phoneNumber: string
  setPhoneNumber: (phoneNumber: string) => void
  isChecked: boolean
  setIsChecked: (isChecked: boolean) => void
}

export const useAddPhoneNumberModalStore = create<AddPhoneNumberModalStore>(
  (set) => ({
    isOpen: false,
    onOpen: () => set({ isOpen: true }),
    onClose: () => set({ isOpen: false }),
    phoneNumber: "",
    setPhoneNumber: (phoneNumber) => set({ phoneNumber }),
    isChecked: false,
    setIsChecked: (isChecked) => set({ isChecked }),
  })
)
