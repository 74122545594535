import { Button, FlexBox, SPACING, Typography } from "@joonapp/web-shared"
import * as Sentry from "@sentry/react"
import { ReactNode } from "react"

const ErrorBoundary = ({ children }: { children: ReactNode }) => {
  return (
    <Sentry.ErrorBoundary fallback={ErrorFallback}>
      {/* ------- Uncomment these to test error handling------- */}
      {/* <ErrorTrigger /> */}
      {/* <RenderErrorComponent /> */}
      {children}
    </Sentry.ErrorBoundary>
  )
}

const ErrorFallback = ({ error }: { error: Error }) => {
  if (import.meta.env.DEV) {
    return (
      <FlexBox
        align="center"
        direction="column"
        wrap={false}
        style={{
          height: "100vh",
          width: "min(100vw, 1200px)",
          margin: "0 auto",
          padding: SPACING.space4,
          paddingTop: "10dvh",
          backgroundColor: "#ffffff",
          color: "#24292f",
        }}
        gap={SPACING.space4}
      >
        <Typography
          variant="h2"
          textAlign="center"
          style={{ color: "#cf222e" }}
        >
          Runtime Error
        </Typography>

        <FlexBox
          direction="column"
          gap={SPACING.space2}
          style={{
            width: "100%",
            backgroundColor: "#f6f8fa",
            padding: SPACING.space4,
            borderRadius: "6px",
            border: "1px solid #d0d7de",
            boxShadow: "0 1px 2px rgba(31, 35, 40, 0.1)",
          }}
        >
          <Typography variant="h3" style={{ color: "#cf222e" }}>
            {error.message}
          </Typography>

          <Typography
            variant="bodySmall"
            style={{
              fontFamily:
                "ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, monospace",
              whiteSpace: "pre-wrap",
              backgroundColor: "#ffffff",
              padding: SPACING.space3,
              borderRadius: "6px",
              overflowX: "auto",
              lineHeight: "1.5",
              border: "1px solid #d0d7de",
            }}
          >
            {error.stack}
          </Typography>
        </FlexBox>

        <FlexBox
          direction="column"
          gap={SPACING.space2}
          style={{
            width: "100%",
            backgroundColor: "#f6f8fa",
            padding: SPACING.space4,
            borderRadius: "6px",
            border: "1px solid #d0d7de",
            boxShadow: "0 1px 2px rgba(31, 35, 40, 0.1)",
          }}
        >
          <Typography variant="h3" style={{ color: "#0550ae" }}>
            Error Details
          </Typography>
          <Typography
            variant="bodySmall"
            style={{
              fontFamily:
                "ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, monospace",
              whiteSpace: "pre-wrap",
              backgroundColor: "#ffffff",
              padding: SPACING.space3,
              borderRadius: "6px",
              overflowX: "auto",
              lineHeight: "1.5",
              border: "1px solid #d0d7de",
            }}
          >
            {JSON.stringify(error, null, 2)}
          </Typography>
        </FlexBox>
      </FlexBox>
    )
  }
  return (
    <FlexBox
      align="center"
      justify="center"
      direction="column"
      style={{ height: "100vh", width: "100vw" }}
      gap={SPACING.space4}
    >
      <img
        src="/images/avatars/squelchee_thinking.png"
        alt="Sad Squelchee"
        style={{ width: "min(80vw, 250px)" }}
      />
      <FlexBox direction="column">
        <Typography variant="h3" textAlign="center">
          Uh oh, something went wrong.
        </Typography>
        <Typography variant="body" textAlign="center">
          Our team has been notified.
        </Typography>
      </FlexBox>
      <Button text="Reload" onClick={() => window.location.reload()} />
    </FlexBox>
  )
}

export default ErrorBoundary

// ------- Uncomment these to test error handling-------
// const ErrorTrigger: React.FC = () => {
//   const [shouldThrow, setShouldThrow] = useState(false)

//   useEffect(() => {
//     if (shouldThrow) {
//       throw new Error("Triggered error")
//     }
//   }, [shouldThrow])

//   return <button onClick={() => setShouldThrow(true)}>Trigger Error</button>
// }

// const RenderErrorComponent: React.FC = () => {
//   const badFunction = null
//   // @ts-ignore
//   return <div>{badFunction()}</div> // This will cause a TypeError
// }
