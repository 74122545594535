import {
  Button,
  Checkbox,
  DownloadIcon,
  FlexBox,
  JoonUIColor,
  PDFIcon,
  PdfModule,
  Shadow,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useEffect, useState } from "react"

import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../../util/analytics"
import TrainingProgressionBar from "../TrainingProgressionBar"
import useAssignmentCompletionMutation from "../useAssignmentCompletionMutation"
import useTraining from "../useTraining"
import useTrainingStore from "../useTrainingStore"
import { getModuleTheme } from "../utils"

const PDFModule = () => {
  const { currentCompletion, currentModule, getButtonText } = useTraining()
  const { incrementModuleIndex } = useTrainingStore()
  const [hasVerifiedCompletion, setHasVerifiedCompletion] = useState(
    !!currentCompletion && !!currentCompletion.date_completed
  )

  const assignmentCompletionMutation = useAssignmentCompletionMutation()

  useEffect(() => {
    setHasVerifiedCompletion(
      !!currentCompletion && !!currentCompletion.date_completed
    )
  }, [currentCompletion])

  if (!currentModule) return null

  const buttonText = getButtonText({ isLastPageInModule: true })

  const { completionVerificationText } = getModuleTheme(currentModule?.type)

  const onClickNext = () => {
    if (!currentCompletion) return
    incrementModuleIndex()
    assignmentCompletionMutation.mutate({
      assignmentCompletionId: currentCompletion.id,
    })
    trackAnalyticEvent(ANALYTIC_EVENTS.COMPLETE_BASIC_LEARNING_ASSIGNMENT)
  }

  return (
    <>
      <FlexBox
        direction="column"
        wrap={false}
        gap={SPACING.space8}
        style={{ padding: SPACING.space6 }}
      >
        <Typography
          variant="h2"
          textAlign="center"
          style={{ padding: SPACING.space4 }}
        >
          {currentModule?.title}
        </Typography>
        <PDFButton currentModule={currentModule as PdfModule} />
      </FlexBox>
      <TrainingProgressionBar>
        <FlexBox direction="column" gap={SPACING.space2}>
          {!!completionVerificationText && (
            <Checkbox
              name="Completion verification"
              hideBorder
              label={completionVerificationText}
              style={{ background: "transparent" }}
              selected={hasVerifiedCompletion}
              onChange={() => setHasVerifiedCompletion(!hasVerifiedCompletion)}
            />
          )}
          <Button
            text={buttonText}
            onClick={onClickNext}
            fullWidth
            disabled={!!completionVerificationText && !hasVerifiedCompletion}
            style={{ whiteSpace: "nowrap" }}
          />
        </FlexBox>
      </TrainingProgressionBar>
    </>
  )
}

export default PDFModule

const PDFButton = ({ currentModule }: { currentModule: PdfModule }) => {
  const [isHovered, setIsHovered] = useState(false)
  const [isPressed, setIsPressed] = useState(false)
  return (
    <button
      style={{ padding: 0, width: "100%" }}
      onClick={() => window.open(currentModule.pdf_url, "_blank")}
    >
      <FlexBox
        direction="row"
        align="center"
        wrap={false}
        gap={SPACING.space4}
        style={{
          width: "fit-content",
          background: isHovered
            ? JoonUIColor.background.lightBlue
            : JoonUIColor.background.primaryNeutral,
          padding: SPACING.space4,
          borderRadius: SPACING.space2,
          margin: "0 auto",
          boxShadow: Shadow.high,
          border: `1px solid ${JoonUIColor.border.default}`,
          transform: isPressed ? "translateY(2px)" : "translateY(0)",
          transition: "transform 0.1s",
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => {
          setIsPressed(false)
          setIsHovered(false)
        }}
        onMouseDown={() => setIsPressed(true)}
        onMouseUp={() => setIsPressed(false)}
      >
        <PDFIcon />
        <Typography variant="bodyBold" textAlign="left">
          {currentModule?.title}
        </Typography>
        <DownloadIcon />
      </FlexBox>
    </button>
  )
}
