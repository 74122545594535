import {
  Button,
  CustomReward,
  FlexBox,
  JoonUIColor,
  Modal,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { create } from "zustand"

import { useAddEditRewardModal } from "./AddEditRewardModal"
import { useDeleteRewardModalStore } from "./DeleteRewardModal"
import { usePurchaseRewardModal } from "./PurchaseReward"
import { useCustomRewardsQuery } from "../../networking/queries"

interface RewardCardModalStore {
  isOpen: boolean
  onOpen: (rewarId: number) => void
  onClose: () => void

  rewardId: number | null
}

export const useRewardCardModalStore = create<RewardCardModalStore>((set) => ({
  isOpen: false,
  onOpen: (rewardId) => set({ isOpen: true, rewardId }),
  onClose: () => set({ isOpen: false }),

  rewardId: null,
}))

const RewardCardModal = () => {
  const { isOpen, onClose, rewardId } = useRewardCardModalStore()
  const { onOpen: openAddEditRewardModal } = useAddEditRewardModal()
  const { onOpen: openDeleteRewardModal } = useDeleteRewardModalStore()
  const { onOpen: openPurchaseRewardModal } = usePurchaseRewardModal()
  const { data: rewards } = useCustomRewardsQuery()

  const reward = rewards?.find(
    (reward) => reward.id === rewardId
  ) as CustomReward

  if (!reward) return <></>

  return (
    <Modal isOpen={isOpen} onClose={onClose} displayCloseIcon animate>
      <FlexBox
        style={{ padding: SPACING.space6, maxWidth: "250px" }}
        direction="column"
        wrap={false}
        gap={SPACING.space1}
      >
        <Typography
          variant="h3"
          textAlign="center"
          style={{ marginTop: SPACING.space2, marginBottom: SPACING.space2 }}
        >
          Manage Reward
        </Typography>
        <Typography
          variant="body"
          textAlign="center"
          style={{ marginBottom: SPACING.space4 }}
        >
          {reward.title}
        </Typography>
        <Button
          onClick={() => {
            openPurchaseRewardModal(reward)
            onClose()
          }}
          text="Purchase"
          fullWidth
        >
          Purchase
        </Button>
        <TextButton
          onClick={() => {
            openAddEditRewardModal(reward)
            onClose()
          }}
          style={{ width: "100%" }}
        >
          <Typography variant="bodyBold" color={JoonUIColor.text.primaryAccent}>
            Edit
          </Typography>
        </TextButton>
        <TextButton
          onClick={() => {
            openDeleteRewardModal(reward.id)
            onClose()
          }}
          style={{ width: "100%" }}
        >
          <Typography
            variant="bodyBold"
            color={JoonUIColor.semantic.destructive}
          >
            Delete
          </Typography>
        </TextButton>
      </FlexBox>
    </Modal>
  )
}

export default RewardCardModal
