import "./pageWrapper.scss"
import React from "react"

// WILL BE MOVING THESE TO LIBRARY ONCE STABLE

interface PageWrapperProps extends React.HTMLProps<HTMLDivElement> {
  children: React.ReactNode
  style?: React.CSSProperties
}

const PageWrapper = ({ children, style }: PageWrapperProps) => {
  return (
    <div className="jooniverse__page-wrapper" style={style}>
      {children}
    </div>
  )
}

export default PageWrapper
