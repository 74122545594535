import { JoonColorExpanded, JoonUIColor, SPACING } from "@joonapp/web-shared"
import { useEffect } from "react"
import Joyride, {
  ACTIONS,
  CallBackProps,
  EVENTS,
  Status,
  STATUS,
} from "react-joyride"

import { steps } from "./TourSteps"
import useTourStore from "./useTourStore"
import { localStorageItems } from "../../../util/storage"

const Tour = () => {
  const { run, setRun, showTour, setShowTour, stepIndex, setStepIndex } =
    useTourStore()

  useEffect(() => {
    if (
      localStorage.getItem(localStorageItems.needsToSeeTherapistTour) === "true"
    ) {
      setShowTour(true)
      localStorage.removeItem(localStorageItems.needsToSeeTherapistTour)
    }
  }, [setShowTour])

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, type, action, index } = data
    const finishedStatuses: Status[] = [STATUS.FINISHED, STATUS.SKIPPED]

    if (finishedStatuses.includes(status)) {
      setShowTour(false)
      setRun(false)
    }

    if (
      index === stepIndex &&
      (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND)
    ) {
      setStepIndex(stepIndex + 1)
    }

    if (
      type === EVENTS.STEP_AFTER &&
      action === ACTIONS.PREV &&
      stepIndex - 1 >= 0
    ) {
      setStepIndex(stepIndex - 1)
    }
  }

  return (
    <Joyride
      continuous
      disableScrolling
      callback={handleJoyrideCallback}
      run={showTour && run}
      steps={steps}
      stepIndex={stepIndex}
      disableOverlayClose={true}
      spotlightPadding={8}
      locale={{
        back: "Prev",
        last: "Finish",
        next: "Next",
      }}
      styles={{
        tooltip: {
          position: "relative",
          padding: SPACING.space2,
          paddingBottom: SPACING.space0,
          borderRadius: SPACING.space4,
          background:
            "linear-gradient(90deg, rgba(227,201,253,0.2) 0%, rgba(255,209,154,0.2) 100%), #fff",
        },
        tooltipContent: {
          padding: `${SPACING.space2} ${SPACING.space2} ${SPACING.space4} ${SPACING.space2}`,
        },
        tooltipFooter: {
          justifyContent: "space-between",
          marginTop: SPACING.space0,
          padding: SPACING.space3,
          marginLeft: "-8px",
          marginRight: "-8px",
          borderImage: `linear-gradient(to right, ${JoonColorExpanded.purple200} 0%, #FFD19A 100%) 1`,
          borderWidth: "2px",
          borderStyle: "solid",
          borderLeft: "none",
          borderRight: "none",
          borderBottom: "none",
          borderBottomLeftRadius: SPACING.space4,
          borderBottomRightRadius: SPACING.space4,
          backgroundColor: JoonUIColor.background.xlightGray,
        },
        tooltipFooterSpacer: {
          position: "absolute",
        },
        options: {
          arrowColor: "#F9F4FF",
          primaryColor: JoonUIColor.semantic.primary,
          zIndex: 10000,
        },
        spotlight: {
          borderRadius: SPACING.space2,
        },
        buttonBack: {
          marginLeft: 0,
          fontWeight: 600,
        },
        buttonNext: {
          color: JoonUIColor.border.accent,
          backgroundColor: "transparent",
          fontWeight: 600,
        },
      }}
    />
  )
}
export default Tour
