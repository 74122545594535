import {
  ArrowRightIcon,
  FlexBox,
  JoonUIColor,
  QuestStatus,
  SPACING,
  Typography,
} from "@joonapp/web-shared"

import { useCatchUp } from "./useCatchUpStore"
import {
  useFamilyQuery,
  useQuestBoardQuery,
  useQuestReviewQuery,
} from "../../networking/queries"

const CatchUpCompleteButton = () => {
  const { data: quests } = useQuestBoardQuery()
  const { data: questsToReview } = useQuestReviewQuery()
  const { isLoading: isLoadingFamily } = useFamilyQuery()
  const questsToComplete = quests?.filter((quest) =>
    [QuestStatus.OPEN, QuestStatus.RETRY].includes(quest.status)
  )
  const { openCatchUp } = useCatchUp()

  const hasQuestsToComplete = questsToComplete && questsToComplete?.length > 0
  const hasQuestsToReview = questsToReview && questsToReview?.length > 0
  if (!hasQuestsToComplete || hasQuestsToReview || isLoadingFamily) return <></>

  return (
    <button
      style={{ padding: "0" }}
      onClick={() => openCatchUp({ isReviewing: false })}
    >
      <FlexBox
        direction="row"
        wrap={false}
        justify="space-around"
        align="center"
        style={{
          background:
            "linear-gradient(255deg, #E7F4A1 1.18%, #2EA384 48.22%, #249BEE 94.33%)",
          padding: `${SPACING.space4}`,
          borderRadius: SPACING.space4,
          boxSizing: "border-box",
          position: "relative",
          boxShadow: "4px 6px 6px 0px rgba(47, 30, 53, 0.24)",
        }}
      >
        <span
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            right: "0px",
            bottom: "0px",
            borderRadius: SPACING.space4,
            border: "3px solid rgba(255, 255, 255, 0.5)",
            pointerEvents: "none",
          }}
        />
        <FlexBox direction="column">
          <Typography variant="bodyXSmall" color={JoonUIColor.text.inverted}>
            Catch up
          </Typography>
          <div>
            <Typography variant="caption" color={JoonUIColor.text.inverted}>
              {questsToComplete.length} Incomplete{" "}
            </Typography>
            <Typography variant="bodySmall" color={JoonUIColor.text.inverted}>
              Quests to review
            </Typography>
          </div>
        </FlexBox>
        <ArrowRightIcon color={JoonUIColor.text.inverted} size={15} />
      </FlexBox>
    </button>
  )
}

export default CatchUpCompleteButton
