import {
  FlexBox,
  JoonUIColor,
  QuestStatus,
  QuestType,
  SPACING,
  Typography,
  capitalizeFirstLetter,
} from "@joonapp/web-shared"
import { useMemo } from "react"
import { useShallow } from "zustand/react/shallow"

import CatchUpCompleteEmptyState from "./CatchUpCompleteEmptyState"
import CatchUpTabBar from "./CatchUpTabBar"
import CatchUpCompleteActionButtons from "./CatchUpTabBarCompleteButtons"
import { useCatchUpStore } from "./useCatchUpStore"
import Card from "../../components/card/Card"
import { useQuestBoardQuery } from "../../networking/queries"

const CatchUpCompleteContent = () => {
  const {
    selectedChild,
    selectedQuestIdsForCompletion,
    toggleQuestIdForCompletion,
  } = useCatchUpStore(
    useShallow((state) => ({
      selectedChild: state.selectedChild,
      selectedQuestIdsForCompletion: state.selectedQuestIdsForCompletion,
      toggleQuestIdForCompletion: state.toggleQuestIdForCompletion,
    }))
  )

  const { data: quests } = useQuestBoardQuery()

  const questsToComplete = quests?.filter(
    (quest) =>
      [QuestStatus.OPEN, QuestStatus.RETRY].includes(quest.status) &&
      quest.user_id === selectedChild?.user.id
  )

  if (!questsToComplete || questsToComplete.length === 0)
    return <CatchUpCompleteEmptyState />

  return (
    <>
      <FlexBox
        style={{
          padding: `${SPACING.space4} ${SPACING.space6}`,
          background:
            "linear-gradient(180deg, rgba(255, 255, 255, 0.80) 2.83%, rgba(255, 255, 255, 0.00) 51.53%, rgba(255, 255, 255, 0.80) 99.81%), var(--surface-background-light-accent, #E9EAFA)",
          borderTopLeftRadius: SPACING.space4,
          borderTopRightRadius: SPACING.space4,
        }}
        gap={SPACING.space4}
      >
        <Typography variant="bodySmall">
          {selectedChild?.user.name} has not checked off the following quests.
          Mark them as completed if they’re actually done so they can receive
          their reward!
        </Typography>
        <FlexBox direction="column" gap={SPACING.space1}>
          {questsToComplete?.map((quest) => (
            <div style={{ position: "relative", width: "100%" }} key={quest.id}>
              {/* @ts-ignore */}
              {quest.temp_status && (
                // @ts-ignore
                <QuestCardStatusOverlay questStatus={quest.temp_status} />
              )}
              <Card
                title={quest.series.title}
                subtitle={`${capitalizeFirstLetter(quest.series.routine)}${quest.series.reminder_time ? `, ${quest.series.reminder_time}` : ""}${quest.series.type === QuestType.REPEATING ? " (Repeating)" : ""}`}
                style={{ borderRadius: SPACING.space4 }}
                isChecked={selectedQuestIdsForCompletion.includes(quest.id)}
                coinAmount={quest.series.redeemable_reward}
                onClick={() => toggleQuestIdForCompletion(quest.id)}
              />
            </div>
          ))}
        </FlexBox>
      </FlexBox>
      <CatchUpTabBar>
        <CatchUpCompleteActionButtons />
      </CatchUpTabBar>
    </>
  )
}

export const QuestCardStatusOverlay = ({
  questStatus,
}: {
  questStatus: QuestStatus
}) => {
  const overlayStyles = useMemo(() => {
    switch (questStatus) {
      case QuestStatus.REJECTED:
        return {
          background: "rgba(255, 233, 233, 0.80)",
          primaryColor: JoonUIColor.semantic.alert,
          text: "Rejected",
        }
      case QuestStatus.VERIFIED:
        return {
          background: "rgba(220, 252, 244, 0.80)",
          primaryColor: JoonUIColor.semantic.success,
          text: "Completed",
        }
      case QuestStatus.SKIPPED:
        return {
          background: "rgba(233, 233, 255, 0.80)",
          primaryColor: JoonUIColor.text.secondary,
          text: "Skipped",
        }
      case QuestStatus.RETRY:
        return {
          background: "rgba(233, 233, 255, 0.80)",
          primaryColor: JoonUIColor.text.secondary,
          text: "Retry",
        }
      default:
        return {}
    }
  }, [questStatus])

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        top: "0",
        left: "0",
        bottom: "0",
        zIndex: 100,
        right: "0",
        border: `2px solid ${overlayStyles.primaryColor}`,
        borderRadius: SPACING.space2,
        background: overlayStyles.background,
      }}
    >
      <Typography variant="h3" color={overlayStyles.primaryColor}>
        {overlayStyles.text}
      </Typography>
    </div>
  )
}

export default CatchUpCompleteContent
