import { Badge, Button, JoonUIColor, SPACING } from "@joonapp/web-shared"
import { useState } from "react"

import { InvitationGroup } from "../../../types"
import { createJoonAPIClient } from "../../../util/joon-api"

export interface TherapistInvite {
  id: number
  therapist: {
    email: string
  }
  children: {
    id: number
    name: string
  }[]
}

const TherapistInviteItem = ({
  invitationGroup,
}: {
  invitationGroup: InvitationGroup
}) => {
  const [reminderSent, setReminderSent] = useState(false)
  const [sendingReminder, setSendingReminder] = useState(false)

  const sendReminder = async () => {
    const API = createJoonAPIClient()
    try {
      setSendingReminder(true)
      API.post(`api/invitations/${invitationGroup[0].id}/send-reminder/`)
      setReminderSent(true)
    } catch (error) {
      console.log(error)
    }
    setSendingReminder(false)
    setReminderSent(true)
  }

  const inviteeEmail = invitationGroup[0].invitee_email
  const subjectData = invitationGroup.flatMap(
    (invitation) => invitation.subject_user
  )

  const canSendReminder = invitationGroup[0].can_send_reminder

  return (
    <div className="therapist-item">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="font-body">{inviteeEmail}</div>
        <div>
          <div
            className="font-footnote"
            style={{
              background: JoonUIColor.background.lightBlue,
              padding: SPACING.space1,
              borderRadius: SPACING.space1,
            }}
          >
            Pending
          </div>
        </div>
      </div>
      <div style={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
        {subjectData.map((subject) => (
          <Badge key={subject.id}>
            <div className="font-footnote">{subject.name}</div>
          </Badge>
        ))}
      </div>{" "}
      {canSendReminder && (
        <Button
          text={reminderSent ? "Reminder Sent" : "Send Reminder"}
          size="small"
          isLoading={sendingReminder}
          onClick={sendReminder}
          disabled={reminderSent}
        />
      )}
    </div>
  )
}
export default TherapistInviteItem
