import {
  FlexBox,
  Typography,
  TextInput,
  Button,
  SPACING,
  JoonUIColor,
  ChevronRightIcon,
  Modal,
} from "@joonapp/web-shared"
import { useEffect } from "react"
import toast from "react-hot-toast"
import { create } from "zustand"

import { Toasts } from "../../components/toaster/Toaster"
import { createJoonAPIClient } from "../../util/joon-api"
import { useEnterTherapistCodeModal } from "../family/therapists/EnterTherapistCodeModal"

interface PaywallReferralModalStore {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  code: string
  setCode: (code: string) => void
  isApplyingCode: boolean
  setIsApplyingCode: (isApplyingCode: boolean) => void
  error: string | null
  setError: (error: string | null) => void
  hasValidCode: boolean
  setHasValidCode: (hasAppliedCode: boolean) => void
  modalScreen: "referralChoice" | "friendReferralCode"
  setModalScreen: (modalScreen: "referralChoice" | "friendReferralCode") => void
}

export const applyReferralCode = (code: string) => {
  const API = createJoonAPIClient()
  return API.post("api/referral-codes/apply/", { code })
}

export const usePaywallReferralModalStore = create<PaywallReferralModalStore>(
  (set) => ({
    isOpen: false,
    onOpen: () => set({ isOpen: true, modalScreen: "referralChoice" }),
    onClose: () => set({ isOpen: false, error: null, code: "" }),
    code: "",
    setCode: (code) => set({ code }),
    isApplyingCode: false,
    setIsApplyingCode: (isApplyingCode) => set({ isApplyingCode }),
    error: null,
    setError: (error) => set({ error }),
    hasValidCode: false,
    setHasValidCode: (hasValidCode) => set({ hasValidCode }),
    modalScreen: "referralChoice",
    setModalScreen: (modalScreen) => set({ modalScreen }),
  })
)

const PaywallReferralModal = () => {
  const { isOpen, onClose, modalScreen, setModalScreen } =
    usePaywallReferralModalStore()
  const { onOpen: openTherapistCodeModal } = useEnterTherapistCodeModal()

  return (
    <Modal isOpen={isOpen} onClose={onClose} displayCloseIcon animate>
      {modalScreen === "referralChoice" ? (
        <FlexBox
          direction="column"
          justify="center"
          align="center"
          style={{ width: "min(350px, 95vw)", padding: SPACING.space6 }}
          gap={SPACING.space6}
        >
          <Typography variant="h3">Who referred you to Joon?</Typography>
          <FlexBox direction="column" gap={SPACING.space4} align="center">
            <button
              style={buttonStyle}
              onClick={() => {
                onClose()
                openTherapistCodeModal()
              }}
            >
              <Typography variant="bodyBold">Therapist</Typography>
              <ChevronRightIcon color={JoonUIColor.text.primary} />
            </button>
            <button
              style={buttonStyle}
              onClick={() => setModalScreen("friendReferralCode")}
            >
              <Typography variant="bodyBold">Another parent</Typography>
              <ChevronRightIcon color={JoonUIColor.text.primary} />
            </button>
          </FlexBox>
        </FlexBox>
      ) : (
        <FriendReferralCodeScreen />
      )}
    </Modal>
  )
}

const buttonStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  borderRadius: SPACING.space2,
  border: `1px solid ${JoonUIColor.border.default}`,
  boxShadow: "2px 2px 2px 0px #F0F3F8",
  padding: `${SPACING.space3} ${SPACING.space4}`,
}

const FriendReferralCodeScreen = () => {
  const {
    onClose,
    code,
    setCode,
    isApplyingCode,
    setIsApplyingCode,
    error,
    setError,
    hasValidCode,
    setHasValidCode,
  } = usePaywallReferralModalStore()

  const validateCode = async () => {
    setIsApplyingCode(true)
    try {
      await applyReferralCode(code.toUpperCase())
      setError(null)
      setHasValidCode(true)
    } catch (error) {
      setError("Invalid code. Please try again.")
      setHasValidCode(false)
    }
    setIsApplyingCode(false)
  }

  useEffect(() => {
    if (code.length === 6) validateCode()
    else {
      setHasValidCode(false)
      setError("")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code])

  return (
    <FlexBox
      direction="column"
      justify="center"
      align="center"
      style={{ width: "min(350px, 95vw)", padding: SPACING.space6 }}
      gap={SPACING.space6}
    >
      <Typography variant="h3">Enter friend referral code</Typography>
      <TextInput
        value={code}
        icon="code"
        name="referralCode"
        maxLength={6}
        onChange={(e: any) => setCode(e.target.value)}
        placeholder={"ABC123"}
        fullWidth
      />
      <FlexBox direction="column" gap={SPACING.space1} align="center">
        {error && (
          <Typography variant="caption" color={JoonUIColor.semantic.alert}>
            {error}
          </Typography>
        )}
        <Button
          text="Apply Code"
          onClick={() => {
            onClose()
            toast(Toasts.parentPaywallDiscount)
          }}
          fullWidth
          isLoading={isApplyingCode}
          disabled={!hasValidCode || isApplyingCode}
        />
      </FlexBox>
    </FlexBox>
  )
}

export default PaywallReferralModal
