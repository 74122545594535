import { CoinTransaction, Typography } from "@joonapp/web-shared"
import dayjs from "dayjs"

const TransactionTable = ({
  transactions,
}: {
  transactions: CoinTransaction[]
}) => {
  return (
    <table
      style={{
        display: "block",
        maxHeight: "60vh",
        minHeight: "400px",
        overflowY: "auto",
        width: "100%",
        tableLayout: "fixed",
        borderCollapse: "separate",
        borderSpacing: "4px 0",
      }}
    >
      <thead>
        <tr>
          <td style={{ minWidth: "50px" }}>
            <Typography variant="caption">Date</Typography>
          </td>
          <td style={{ width: "100%", maxWidth: "50vw !important" }}>
            <Typography variant="caption">Description</Typography>
          </td>
          <td style={{ minWidth: "60px" }}>
            <Typography variant="caption">Amount</Typography>
          </td>
          <td style={{ minWidth: "60px" }}>
            <Typography variant="caption">Balance </Typography>
          </td>
        </tr>
      </thead>

      <tbody>
        {transactions?.map((transaction) => (
          <tr style={{ width: "100%" }}>
            <td>
              <Typography variant="bodySmall">
                {dayjs(transaction.date_created).format("MMM D")}
              </Typography>
            </td>
            <td style={{ maxWidth: "40vw" }}>
              <Typography
                variant="bodySmall"
                style={{ whiteSpace: "normal", wordWrap: "break-word" }}
              >
                {transaction.display_string}
              </Typography>
            </td>
            <td>
              <Typography variant="bodySmall">
                {transaction.amount > 0 && "+"}
                {transaction.amount.toLocaleString()}
              </Typography>
            </td>
            <td>
              <Typography variant="bodySmall">
                {transaction.ending_balance.toLocaleString()}
              </Typography>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default TransactionTable
