import { useMutation, useQueryClient } from "@tanstack/react-query"
import toast from "react-hot-toast"

import { Toasts } from "../../../../components/toaster/Toaster"
import { QUERY_KEYS } from "../../../../constants"
import { reorderQuests } from "../../../../networking/quests"

export const useReorderQuestsMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: reorderQuests,
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.QUEST_BOARD_REORDER])
    },
    onError: (error) => {
      console.error("Error reordering quests:", error)
      toast.error(Toasts.errorReorderingQuests, { duration: 1500 })
    },
  })
}
