import { QuestInstance, QuestStatus } from "@joonapp/web-shared"
import { useMutation } from "@tanstack/react-query"

import useQuestReviewStore from "./useQuestReviewStore"
import { QUERY_KEYS } from "../../../constants"
import {
  rejectQuests,
  retryQuests,
  verifyAllQuests,
} from "../../../networking/quests"
import { queryClient } from "../../../util/queryClient"

const useQuestReview = () => {
  const { setSelectedQuestIds } = useQuestReviewStore()

  const questReviewQueryKey = [QUERY_KEYS.QUEST_REVIEW]

  const onReviewMutate = async (body: {
    questInstanceIds: number[]
    status: QuestStatus.VERIFIED | QuestStatus.RETRY | QuestStatus.REJECTED
  }) => {
    await queryClient.cancelQueries(questReviewQueryKey)

    // Snapshot the previous value
    const previousQuestsToReview =
      queryClient.getQueryData<QuestInstance[]>(questReviewQueryKey)

    const newQuestsToReview = [...(previousQuestsToReview || [])]
    newQuestsToReview.forEach((questInstance) => {
      if (body.questInstanceIds.includes(questInstance.id)) {
        // @ts-ignore
        questInstance.temp_status = body.status
      }
    })

    // Optimistically update to the new value
    queryClient.setQueryData<QuestInstance[]>(
      questReviewQueryKey,
      newQuestsToReview
    )

    // Clear the selected quest ids
    // If this isn't called here after the query data is updated,
    // for some reason even though the query data is updated,
    // the component will not re-render with the new data
    setSelectedQuestIds([])

    return { previousQuestsToReview }
  }

  const onReviewError = (
    _err: any,
    _variables: any,
    context: { previousQuestsToReview: QuestInstance[] | undefined } | undefined
  ) => {
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    if (context?.previousQuestsToReview) {
      queryClient.setQueryData<QuestInstance[]>(
        questReviewQueryKey,
        context.previousQuestsToReview
      )
    }
  }

  const onReviewSettled = () => {
    // Always refetch after error or success:
    queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.QUEST_REVIEW] })
    queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.QUEST_BOARD] })
    queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.USER] })
  }

  const verifyAllMutation = useMutation({
    mutationFn: verifyAllQuests,
    onMutate: (data) =>
      onReviewMutate({ ...data, status: QuestStatus.VERIFIED }),
    onError: onReviewError,
    onSettled: onReviewSettled,
  })

  const retryQuestsMutation = useMutation({
    mutationFn: retryQuests,
    onMutate: (data) => onReviewMutate({ ...data, status: QuestStatus.RETRY }),
    onError: onReviewError,
    onSettled: onReviewSettled,
  })

  const rejectQuestsMutation = useMutation({
    mutationFn: rejectQuests,
    onMutate: (data) =>
      onReviewMutate({ ...data, status: QuestStatus.REJECTED }),
    onError: onReviewError,
    onSettled: onReviewSettled,
  })

  return {
    verifyAllMutation,
    retryQuestsMutation,
    rejectQuestsMutation,
  }
}

export default useQuestReview
