import * as Sentry from "@sentry/react"
import axios, { AxiosError, AxiosHeaders } from "axios"

import { sessionManager } from "./storage"

// const baseUrl = "http://127.0.0.1:8000/"
const baseUrl = "https://app.joonapp.io/"

export const createJoonAPIClient = () => {
  const getHeaders = () => {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions()
    let headers = {
      Timezone: timeZone,
      // We set the version to be the max integer since web will always be up-to-date.
      Version: Number.MAX_SAFE_INTEGER,
    } as Headers
    if (sessionManager.getRefreshToken()) {
      headers.Authorization = sessionManager.getRefreshToken()
    }
    if (sessionManager.getIDToken()) {
      headers.Token = sessionManager.getIDToken()
    }
    return headers
  }

  const instance = axios.create({
    baseURL: baseUrl,
    headers: getHeaders(),
  })

  // Add a response interceptor to log errors to Sentry
  instance.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      // Log the error to Sentry
      Sentry.withScope((scope) => {
        scope.setExtra("request", {
          method: error.config?.method,
          url: error.config?.url,
          data: error.config?.data,
        })
        scope.setExtra("response", {
          status: error.response?.status,
          data: error.response?.data,
        })
        Sentry.captureException(error)
      })

      // Rethrow the error so it can be caught and handled by the calling code
      return Promise.reject(error)
    }
  )

  return instance
}

interface Headers extends AxiosHeaders {
  Timezone: string
  Version: number
  Authorization?: string | null
  Token?: string | null
}
