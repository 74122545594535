import {
  CheckIcon,
  FlexBox,
  JoonUIColor,
  QuestInstance,
  QuestSeries,
  QuestType,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import dayjs from "dayjs"

import { useParentTaskInstancesQuery } from "../../networking/queries"
import useTaskReflectionModalStore from "../me/parentTasks/useTaskReflectionModalStore"

const TaskListItem = ({
  questSeries,
  isLastItem,
}: {
  questSeries: QuestSeries
  isLastItem: boolean
}) => {
  const { onOpen: openTaskReflection } = useTaskReflectionModalStore()

  const { data: taskInstances } = useParentTaskInstancesQuery({
    seriesId: questSeries.id,
    ...(questSeries.type === QuestType.REPEATING && {
      minDate: dayjs().subtract(1, "week").format("YYYY-MM-DD"),
      maxDate: dayjs().format("YYYY-MM-DD"),
    }),
  })

  const last7DayTaskInstances = taskInstances?.filter((task: QuestInstance) => {
    if (!task.completion_date) return false
    const minDate = dayjs().subtract(1, "week")
    const maxDate = dayjs()
    const taskCompletionDate = dayjs.unix(+task.completion_date)
    return (
      taskCompletionDate.isAfter(minDate) &&
      taskCompletionDate.isBefore(maxDate)
    )
  })

  if (!taskInstances) return null

  const isCompleted = taskInstances[0]?.completion_date ? true : false

  const subtitle =
    questSeries.type === QuestType.REPEATING
      ? `Completed ${last7DayTaskInstances ? `${last7DayTaskInstances.length}x` : "--"} in past 7 days`
      : "One-time task"

  return (
    <button
      style={{ padding: 0, width: "100%" }}
      onClick={(e) => {
        if (!taskInstances) return
        e.stopPropagation()
        openTaskReflection(taskInstances[0])
      }}
    >
      <FlexBox
        direction="row"
        wrap={false}
        align="center"
        justify="space-between"
        gap={SPACING.space4}
        style={{
          padding: `${SPACING.space2} ${SPACING.space4}`,
          borderBottom: isLastItem
            ? "none"
            : `1px solid ${JoonUIColor.border.default}`,
        }}
      >
        <FlexBox direction="column" style={{ overflow: "hidden" }}>
          <Typography
            variant="bodySmall"
            color={JoonUIColor.text.secondary}
            textAlign="left"
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {subtitle}
          </Typography>
          <Typography variant="bodyBold">{questSeries.title}</Typography>
        </FlexBox>
        <FlexBox
          align="center"
          justify="center"
          style={{
            height: SPACING.space5,
            width: SPACING.space5,
            borderRadius: SPACING.space1,
            border: `2px solid ${JoonUIColor.border.accent}`,
            background: isCompleted ? JoonUIColor.background.accent : "white",
          }}
        >
          <CheckIcon size={16} color={JoonUIColor.background.primaryNeutral} />
        </FlexBox>
      </FlexBox>
    </button>
  )
}

export default TaskListItem
