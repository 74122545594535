import {
  Button,
  FamilyChildProfile,
  FlexBox,
  JoonColorExpanded,
  SPACING,
  Typography,
  toPercentage,
} from "@joonapp/web-shared"
import dayjs from "dayjs"
import { useMemo } from "react"
import { useShallow } from "zustand/react/shallow"

import Card from "../../components/card/Card"
import { ProgressCardSkeleton } from "../../components/skeleton/Skeleton"
import {
  useFamilyQuery,
  useQuestInstanceStatsQuery,
} from "../../networking/queries"
import { FamilyQuerySelect } from "../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { useRouter } from "../../util/router"
import { getChildImage } from "../../util/util"
import { useGetChildStartedModal } from "../family/GetChildStartedModal"
import { useAddQuestTemplatesModalStore } from "../questBoard/addQuestTemplates/useAddQuestTemplatesModalStore"
import { useQuestTemplatesStore } from "../questBoard/addQuestTemplates/useQuestTemplatesStore"
import { useQBDatePickerStore } from "../questBoard/QuestBoardDatePicker"
import useQuestBoardStore from "../questBoard/useQuestBoardStore"

const TodaysProgressSection = () => {
  const { data: children, isLoading } = useFamilyQuery(
    FamilyQuerySelect.CHILDREN
  )

  return (
    <FlexBox direction="column" gap={SPACING.space2}>
      <Typography variant="bodyBold">Today's Progress</Typography>
      <FlexBox direction="column" gap={SPACING.space1}>
        {isLoading ? (
          <ProgressCardSkeleton />
        ) : (
          children?.map((child) => (
            <ChildProgressCard key={child.user.id} child={child} />
          ))
        )}
      </FlexBox>
    </FlexBox>
  )
}

const ChildProgressCard = ({ child }: { child: FamilyChildProfile }) => {
  const { data } = useQuestInstanceStatsQuery(child.user.id)
  const router = useRouter()
  const { onOpen: openGetChildStartedModal } = useGetChildStartedModal()
  const setSelectedChild = useQuestTemplatesStore(
    useShallow((state) => state.setSelectedChild)
  )
  const openQuestTemplates = useAddQuestTemplatesModalStore(
    useShallow((state) => state.onOpen)
  )
  const { setSelectedChildId } = useQuestBoardStore(
    useShallow((state) => ({
      setSelectedChildId: state.setSelectedChildId,
    }))
  )
  const { setQuestBoardDate } = useQBDatePickerStore(
    useShallow((state) => ({
      setQuestBoardDate: state.setQuestBoardDate,
    }))
  )

  const noQuestsToday = data?.num_total_today === 0
  const routineNotSetUp = data?.num_user_created === 0
  // @ts-ignore
  const notYetOnboarded = child?.user.active_doter.date_hatched === null

  const progress = useMemo(() => {
    if (!data || !data.num_total_today || !data?.num_user_created)
      return undefined
    return +toPercentage(
      (data.num_total_today - data.num_to_complete) / data.num_total_today
    )
  }, [data])

  const subtitle = useMemo(() => {
    if (notYetOnboarded) return "Not yet onboarded"
    if (routineNotSetUp) return "Routine not set up"
    if (noQuestsToday) return "No Quests for today"
    return undefined
  }, [notYetOnboarded, routineNotSetUp, noQuestsToday])

  const buttonComponent = useMemo(() => {
    if (notYetOnboarded)
      return (
        <Button
          size="small"
          style={{
            background: JoonColorExpanded.orange300,
            borderColor: "transparent",
          }}
          text="Onboard"
          onClick={() => {
            trackAnalyticEvent(ANALYTIC_EVENTS.CLICK_TODAYS_PROGRESS, {
              type: "onboard",
            })
            openGetChildStartedModal(child.user.id)
          }}
        />
      )
    if (routineNotSetUp)
      return (
        <Button
          size="small"
          text="Set Up"
          style={{ whiteSpace: "nowrap" }}
          onClick={() => {
            openQuestTemplates()
            setSelectedChild({ label: child.user.name, value: child.user.id })
            trackAnalyticEvent(ANALYTIC_EVENTS.CLICK_TODAYS_PROGRESS, {
              type: "setup_routine",
            })
            router.push("/quests")
            setSelectedChildId(child.user.id)
          }}
        />
      )
    return undefined
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notYetOnboarded, routineNotSetUp])

  return (
    <Card
      title={
        <FlexBox direction="row" gap={SPACING.space1}>
          <img
            src={getChildImage(child.user)}
            alt="Child avatar"
            style={{
              width: SPACING.space6,
              height: SPACING.space6,
              borderRadius: SPACING.space6,
            }}
          />
          <Typography variant="caption">{child.user.name}</Typography>
        </FlexBox>
      }
      subtitle={subtitle}
      onClick={() => {
        if (buttonComponent) return
        trackAnalyticEvent(ANALYTIC_EVENTS.CLICK_TODAYS_PROGRESS, {
          type: "show_progress",
        })
        router.push("/quests")
        setSelectedChildId(child.user.id)
        setQuestBoardDate(dayjs())
      }}
      buttonComponent={buttonComponent}
      type="secondary"
      percentage={progress}
    />
  )
}

export default TodaysProgressSection
