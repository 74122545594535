import { Button, Checkbox, ChildUser, Family, Modal } from "@joonapp/web-shared"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useState } from "react"
import toast from "react-hot-toast"
import { create } from "zustand"

import { QUERY_KEYS } from "../../constants"
import { useFamilyQuery } from "../../networking/queries"
import { CustomReward } from "../../types"
import { createJoonAPIClient } from "../../util/joon-api"
import { getUserFromFamily } from "../../util/util"

interface PurchaseRewardModalStore {
  isOpen: boolean
  onOpen: (reward: CustomReward) => void
  onClose: () => void

  reward: CustomReward | null
}

export const usePurchaseRewardModal = create<PurchaseRewardModalStore>(
  (set) => ({
    isOpen: false,
    onOpen: (reward) => set({ isOpen: true, reward }),
    onClose: () => set({ isOpen: false, reward: null }),

    reward: null,
  })
)

const PurchaseRewardModal = () => {
  const queryClient = useQueryClient()
  const [selectedChildren, setSelectedChildren] = useState<number[]>([])
  const { isOpen, onClose, reward } = usePurchaseRewardModal()

  const { data: family } = useFamilyQuery()
  const children = reward?.user_ids.map((userId) =>
    getUserFromFamily(family as Family, userId)
  ) as ChildUser[]

  const toggleChild = (childId: number) => {
    if (selectedChildren.includes(childId))
      setSelectedChildren(selectedChildren.filter((id) => id !== childId))
    else setSelectedChildren([...selectedChildren, childId])
  }

  const purchaseReward = async (childId: number) => {
    const API = createJoonAPIClient()
    return API.post(`api/custom-rewards/${reward?.id}/purchase/`, {
      user_id: childId,
    })
  }
  const purchaseRewards = async () => {
    if (children?.length === 1) return purchaseReward(children[0].id)
    else {
      const purchasePromises = selectedChildren.map((childId) =>
        purchaseReward(childId)
      )
      return Promise.all(purchasePromises)
    }
  }

  const purchaseRewardMutation = useMutation({
    mutationFn: purchaseRewards,
    onSuccess: () => {
      onClose()
      queryClient.invalidateQueries([QUERY_KEYS.PURCHASED_REWARDS, false])
      queryClient.invalidateQueries([QUERY_KEYS.CUSTOM_REWARDS])
      queryClient.invalidateQueries([QUERY_KEYS.FAMILY])
      toast.success("Reward purchased successfully!")
    },
  })

  if (!family || !reward) return <></>

  const childrenWithEnoughCoins = children.filter(
    (child) => child.coins >= reward.cost
  )

  return (
    <Modal onClose={onClose} isOpen={isOpen} displayCloseIcon animate>
      <div style={{ width: "min(400px, 95vw", padding: "30px" }}>
        {childrenWithEnoughCoins.length === 0 ? (
          <div style={{ textAlign: "center" }}>
            <div className="section-title">
              Looks like your kids don't have enough coins to purchase this
              reward!
            </div>
            <div className="body-text mt-6 mb-24">
              Assign them more Quests so they can start saving up.
            </div>
            <Button text="OK" onClick={onClose} fullWidth />
          </div>
        ) : (
          <>
            <div className="quest-modal-header section-title">
              Purchase Reward
            </div>
            <div className="body-text2 semibold mb-18">
              Select the children you want to purchase this reward for. Note
              that the kids without enough coins will not show up here.
            </div>
            <div
              style={{
                display: "flex",
                gap: "8px",
                flexWrap: "wrap",
                marginBottom: "24px",
              }}
            >
              {childrenWithEnoughCoins.map((child) => (
                <Checkbox
                  name={child.name}
                  label={child.name}
                  onChange={() => toggleChild(child.id)}
                  selected={selectedChildren.includes(child.id)}
                />
              ))}
            </div>
            <Button
              text="Confirm"
              isLoading={purchaseRewardMutation.isLoading}
              onClick={() => purchaseRewardMutation.mutate()}
              disabled={selectedChildren.length === 0}
              fullWidth
            />
          </>
        )}
      </div>
    </Modal>
  )
}

export default PurchaseRewardModal
