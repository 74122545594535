import { useMutation } from "@tanstack/react-query"

import { QUERY_KEYS } from "../../../constants"
import { useUserQuery } from "../../../networking/queries"
import { markMailAsRead } from "../../../networking/user"
import { queryClient } from "../../../util/queryClient"

const useMarkAsReadMutation = () => {
  const { user } = useUserQuery()

  return useMutation({
    mutationFn: (familyMailId: number) =>
      markMailAsRead(familyMailId, user?.id as number),
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.FAMILY_MAIL])
      queryClient.invalidateQueries([QUERY_KEYS.UNREAD_MAIL])
    },
  })
}

export default useMarkAsReadMutation
