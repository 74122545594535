import {
  ModuleType,
  MultipleChoiceLearningModuleAssignmentCompletion,
  ParentTraining,
  ParentTrainingStatus,
  ShortAnswerLearningModuleAssignmentCompletion,
} from "@joonapp/web-shared"
import { useMutation } from "@tanstack/react-query"

import useTraining from "./useTraining"
import { QUERY_KEYS } from "../../../constants"
import { completeAssignment } from "../../../networking/trainings"
import { queryClient } from "../../../util/queryClient"

const useAssignmentCompletionMutation = () => {
  const { currentTraining, currentCompletion } = useTraining()

  return useMutation({
    mutationFn: completeAssignment,
    onMutate: ({
      assignmentCompletionId,
      shortAnswerAnswers,
      multipleChoiceAnswers,
    }) => {
      queryClient.cancelQueries([QUERY_KEYS.TRAININGS])
      const cacheKey = [QUERY_KEYS.TRAININGS, ParentTrainingStatus.IN_PROGRESS]

      const previousTrainings =
        queryClient.getQueryData<ParentTraining[]>(cacheKey)

      const newTrainings = previousTrainings?.map((training) => {
        if (training.id !== currentTraining?.id) return training

        const newAssignments = training.assignments.map((assignment) => {
          if (assignment.assignment_completion.id !== assignmentCompletionId)
            return assignment

          const newAssignment = {
            ...assignment,
            assignment_completion: {
              ...assignment.assignment_completion,
              date_completed: new Date().toISOString(),
              ...(assignment.learning_module.type ===
                ModuleType.MULTIPLE_CHOICE && {
                multiple_choice_answers: [
                  ...(
                    currentCompletion as MultipleChoiceLearningModuleAssignmentCompletion
                  )?.multiple_choice_answers,
                  ...(multipleChoiceAnswers || []),
                ],
              }),
              ...(assignment.learning_module.type ===
                ModuleType.SHORT_ANSWERS && {
                short_answer_answers: [
                  ...(
                    currentCompletion as ShortAnswerLearningModuleAssignmentCompletion
                  ).short_answer_answers,
                  ...(shortAnswerAnswers || []),
                ],
              }),
            },
          }

          return newAssignment
        })

        return { ...training, assignments: newAssignments }
      })

      queryClient.setQueryData(cacheKey, newTrainings)

      return { previousTrainings }
    },
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.TRAININGS])
    },
    onError: (_, __, context: any) => {
      queryClient.setQueryData(
        [QUERY_KEYS.TRAININGS],
        context.previousTrainings
      )
    },
  })
}

export default useAssignmentCompletionMutation
