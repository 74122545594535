import {
  Checkbox,
  FlexBox,
  JoonUIColor,
  QuestInstance,
  QuestStatus,
  SPACING,
  TherapistIcon,
  Typography,
} from "@joonapp/web-shared"
import { useState } from "react"

import { useReviewQuestModal } from "./ReviewQuestModal"
import useQuestBoardMultiSelectStore from "./useQuestBoardMultiSelectStore"
import useQuestBoardStore from "./useQuestBoardStore"
import { useViewQuestModalStore } from "./useViewQuestModalStore"
import Card from "../../components/card/Card"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"

export enum QuestSectionType {
  COMPLETED = "Completed",
  REVIEW = "In Review",
  SKIPPED = "Skipped",
  REJECTED = "Rejected",
  ROUTINE = "Routine",
}

type Props = {
  questInstance: QuestInstance
  type: QuestSectionType
  index: number
}

const QuestCard = ({ questInstance, type, index }: Props) => {
  const { onOpen } = useViewQuestModalStore()
  const { onOpen: openReviewQuestModal, setQuest: setQuestToReview } =
    useReviewQuestModal()
  const selectedChildId = useQuestBoardStore((state) => state.selectedChildId)

  const { selectedQuestIds, toggleSelectedQuestId } =
    useQuestBoardMultiSelectStore()

  const [isHovered, setIsHovered] = useState(false)
  const [isHoveredOnCheckbox, setIsHoveredOnCheckbox] = useState(false)

  const tabName = getQuestCardTabName(type, index)
  const isMandatory = questInstance?.series?.mandatory
  const borderColor = getQuestCardBorderColor(type, isMandatory)
  const isSelected = selectedQuestIds.includes(questInstance.id)

  const onClickCard = () => {
    if (questInstance.status === QuestStatus.COMPLETED) {
      setQuestToReview(questInstance)
      openReviewQuestModal()
      return
    } else {
      trackAnalyticEvent(ANALYTIC_EVENTS.VIEW_QUEST_DETAILS)
      onOpen(questInstance)
    }
  }

  return (
    <div
      style={{
        width: "100%",
        transform: isHovered ? "scale(1.015)" : "scale(1)",
        transition: "transform 0.2s",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {tabName && (
        <div
          style={{
            padding: `${SPACING.space0} ${SPACING.space2}`,
            background: borderColor,
            borderRadius: `${SPACING.space2} ${SPACING.space2} 0 0`,
            marginLeft: SPACING.space3,
            width: "fit-content",
            display: "flex",
            alignItems: "center",
            height: SPACING.space5,
          }}
        >
          <Typography
            variant="bodyXSmall"
            color={JoonUIColor.text.inverted}
            style={{ lineHeight: SPACING.space5 }}
          >
            {tabName}
          </Typography>
        </div>
      )}
      <FlexBox
        wrap={false}
        style={{
          background: "rgba(255, 255, 255, 0.6)",
          borderRadius: SPACING.space2,
          width: "100%",
          height: "fit-content",
        }}
        justify="unset"
        align="center"
      >
        <Card
          title={<QuestCardTitle questInstance={questInstance} />}
          subtitle={questInstance?.series?.reminder_time || ""}
          childIds={!selectedChildId ? [questInstance.user_id] : undefined}
          coinAmount={questInstance?.series?.redeemable_reward}
          borderColor={borderColor}
          onClick={onClickCard}
          style={{
            maxWidth:
              type === QuestSectionType.ROUTINE ? "calc(100% - 54px)" : "100%",
          }}
        />
        {type === QuestSectionType.ROUTINE && (
          <div
            onMouseEnter={() => setIsHoveredOnCheckbox(true)}
            onMouseLeave={() => setIsHoveredOnCheckbox(false)}
            style={{
              transform: isHoveredOnCheckbox ? "scale(1.1)" : "scale(1)",
              transition: "transform 0.2s",
            }}
          >
            <Checkbox
              name={questInstance.id.toString()}
              selected={isSelected}
              onChange={() => toggleSelectedQuestId(questInstance.id)}
              hideBorder
              style={{
                padding: SPACING.space4,
                background: "transparent",
              }}
            />
          </div>
        )}
      </FlexBox>
    </div>
  )
}

export default QuestCard

const getQuestCardBorderColor = (
  type: QuestSectionType,
  mandatory: boolean
) => {
  switch (type) {
    case QuestSectionType.COMPLETED:
      return JoonUIColor.semantic.success
    case QuestSectionType.REVIEW:
      return JoonUIColor.semantic.info
    case QuestSectionType.SKIPPED:
      return JoonUIColor.semantic.warning
    case QuestSectionType.REJECTED:
      return JoonUIColor.semantic.alert
    case QuestSectionType.ROUTINE:
      return mandatory ? JoonUIColor.border.accent : ""
  }
}

const getQuestCardTabName = (type: QuestSectionType, index: number) => {
  if (index !== 0) return undefined
  if (type === QuestSectionType.ROUTINE) return undefined
  switch (type) {
    case QuestSectionType.COMPLETED:
      return "Completed"
    case QuestSectionType.REVIEW:
      return "In Review"
    case QuestSectionType.SKIPPED:
      return "Skipped"
    case QuestSectionType.REJECTED:
      return "Rejected"
  }
}

export const QuestCardTitle = ({
  questInstance,
}: {
  questInstance: QuestInstance
}) => {
  return (
    <FlexBox align="center" gap={SPACING.space1} wrap={false}>
      {questInstance?.series?.current_frequency_target && (
        <TherapistIcon size={16} color={JoonUIColor.background.accent} />
      )}
      <Typography
        variant="caption"
        style={{
          width: "100%",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {questInstance?.series?.title}
      </Typography>
    </FlexBox>
  )
}
