import { JoonUIColor, SPACING, Typography } from "@joonapp/web-shared"
import React from "react"

type Props = {
  children: React.ReactNode
}

// Will add this to shared if we decide to move to this pattern
// we already have a Badge in shared with different styles
const Badge = ({ children }: Props) => {
  return (
    <div
      style={{
        background: JoonUIColor.background.lightAccent,
        color: JoonUIColor.text.primaryAccent,
        borderRadius: SPACING.space2,
        padding: `${SPACING.space1} ${SPACING.space2}`,
      }}
    >
      <Typography variant="bodySmall" color={JoonUIColor.text.primaryAccent}>
        {children}
      </Typography>
    </div>
  )
}

export default Badge
