import {
  useIncidentTemplatesQuery,
  useParentTaskQuery,
} from "../../../networking/queries"

export const useDisplayParentTaskSection = () => {
  const { data: parentTasks } = useParentTaskQuery()
  const { data: incidentTemplates } = useIncidentTemplatesQuery()

  const hasIncidentLogTemplates =
    incidentTemplates && incidentTemplates.child_action_templates.length > 0

  const hasDataTasks =
    incidentTemplates && incidentTemplates.parent_training_templates.length > 0

  const displayParentTasksSection =
    hasIncidentLogTemplates ||
    (parentTasks && parentTasks.length > 0) ||
    hasDataTasks

  return { displayParentTasksSection }
}
