import { DropdownOption } from "@joonapp/web-shared"
import { create } from "zustand"

import { ageGroups, templateCategoryDropdownOptions } from "../../../constants"
import { QuestTemplate, QuestTemplateCategory } from "../../../types/quests"

interface QuestTemplatesStore {
  ageGroup: DropdownOption | null
  setAgeGroup: (ageGroup: DropdownOption) => void
  questCategory: DropdownOption
  setQuestCategory: (questCategory: DropdownOption) => void
  selectedChild: DropdownOption | null
  setSelectedChild: (selectedChild: DropdownOption | null) => void
  searchValue: string
  setSearchValue: (searchValue: string) => void
  filteredTemplates: QuestTemplate[]
  setFilteredTemplates: (filteredTemplates: QuestTemplate[]) => void
}

export const useQuestTemplatesStore = create<QuestTemplatesStore>(
  (set, get) => ({
    ageGroup: ageGroups.six_under,
    setAgeGroup: (ageGroup) => set({ ageGroup: ageGroup }),
    questCategory: templateCategoryDropdownOptions[0],
    setQuestCategory: (questCategory) => {
      if (questCategory.value === QuestTemplateCategory.RECOMMENDED) {
        set({ questCategory: questCategory, ageGroup: null })
      } else {
        set({
          questCategory: questCategory,
          ageGroup: get().ageGroup ?? ageGroups.seven_nine,
        })
      }
    },
    selectedChild: null,
    setSelectedChild: (selectedChild) => set({ selectedChild, ageGroup: null }),
    searchValue: "",
    setSearchValue: (searchValue) => set({ searchValue }),
    filteredTemplates: [],
    setFilteredTemplates: (filteredTemplates) => set({ filteredTemplates }),
  })
)
