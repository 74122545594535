import {
  CaretLeftIcon,
  CaretRightIcon,
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"

import { useCatchUp, useCatchUpStore } from "./useCatchUpStore"
import useMobile from "../../hooks/useMobile"
import { getChildImage } from "../../util/util"

// TODO: Refactor this component to use the new ToggleHeader component
const ChildToggleHeader = () => {
  const { isOpen, childrenArray, selectedChild, scrollPosition } =
    useCatchUpStore()
  const isMobile = useMobile()

  const { onClickNextChild, onClickPreviousChild } = useCatchUp()

  const fontColor = isMobile
    ? JoonColorExpanded.indigo700
    : JoonUIColor.text.inverted

  const scaleFactor = Math.max(0, 1 - scrollPosition / 80)
  const translateYOffset = Math.pow(scrollPosition, 0.03 * scrollPosition)

  return (
    <div
      style={{
        zIndex: "2000",
        opacity: isOpen ? 1 : 0,
        width: "100%",
        position: "absolute",
        margin: "0 auto",
        top: 0,
        transition: "transform 0.3s",
        transform: `scale(${scaleFactor}) translateY(-${translateYOffset}px)`, // Scale down linearly from 1 to 0.8
      }}
    >
      <FlexBox
        direction="row"
        justify="center"
        align="center"
        wrap={false}
        style={{
          margin: "0 auto",
          height: "80px",
          minWidth: "250px",
          width: "fit-content",
        }}
        gap={SPACING.space6}
      >
        {childrenArray.length > 1 && (
          <button
            onClick={onClickPreviousChild}
            style={{ padding: SPACING.space4 }}
          >
            <CaretLeftIcon color={JoonUIColor.text.inverted} size={15} />
          </button>
        )}
        <FlexBox
          align="center"
          justify="center"
          gap={SPACING.space2}
          wrap={false}
        >
          <img
            src={selectedChild ? getChildImage(selectedChild?.user) : undefined}
            style={{ width: "30px", height: "30px", borderRadius: "50%" }}
            alt="avatar"
          />
          <Typography variant="h3" color={fontColor}>
            {selectedChild?.user.name}
          </Typography>
        </FlexBox>
        {childrenArray.length > 1 && (
          <button
            onClick={onClickNextChild}
            style={{ padding: SPACING.space4 }}
          >
            <CaretRightIcon color={JoonUIColor.text.inverted} size={15} />
          </button>
        )}
      </FlexBox>
    </div>
  )
}

export default ChildToggleHeader
