import { CalloutBox, Typography } from "@joonapp/web-shared"
import { useEffect, useState } from "react"

import PageLoader from "../components/loading/PageLoader"
import {
  CAMPAIGNS,
  priceIds,
  validCampaigns,
  validClinicianAccess,
} from "../constants"
import useDocumentTitle from "../hooks/useDocumentTitle"
import {
  useFamilyQuery,
  useSubscriberInfo,
  useUserQuery,
} from "../networking/queries"
import { AlertInterface, SubscriptionTrialKey } from "../types"
import { requireAuth } from "../util/auth"
import { useRouter } from "../util/router"
import { redirectToCheckoutWithPriceId } from "../util/stripe"

function CheckoutPage() {
  useDocumentTitle("Joon - Subscription")
  const router = useRouter()
  const { user } = useUserQuery()
  const { data: family } = useFamilyQuery()
  const { data: subscriber } = useSubscriberInfo()
  const [formAlert, setFormAlert] = useState<AlertInterface>()
  const setRedirectError = () =>
    setFormAlert({ type: "error", message: "Failed to redirect to checkout." })

  useEffect(() => {
    if (!family || !user || !subscriber) return

    const { priceId, tpk, campaign, clinician_access, trialPriceId } =
      router.query
    const subscriberId = family.subscriber_id
    const email = user.email

    const isValidCampaign = Object.values(validCampaigns).includes(campaign)
    const isValidClinicAccess = validClinicianAccess.includes(clinician_access)

    const redirectParams = {
      subscriberId,
      email,
      campaign,
      trialPriceId,
      clinician_access,
      priceId,
      trialLengthKey: tpk,
    }

    if (isValidCampaign) {
      redirectParams.priceId = CAMPAIGNS[campaign].priceId
      redirectParams.trialLengthKey = subscriber?.hasPurchasedPlanBefore
        ? SubscriptionTrialKey.NONE
        : CAMPAIGNS[campaign].trialLengthKey
    } else if (isValidClinicAccess) {
      redirectParams.priceId = priceIds.comp
      redirectParams.trialLengthKey = SubscriptionTrialKey.NONE
    } else if (campaign) {
      redirectParams.priceId = priceIds.discount
      redirectParams.trialLengthKey = subscriber?.hasPurchasedPlanBefore
        ? SubscriptionTrialKey.NONE
        : SubscriptionTrialKey.SEVEN
    }

    redirectToCheckoutWithPriceId(redirectParams).catch(setRedirectError)
  }, [family, user, subscriber, router.query])

  return (
    <PageLoader>
      {formAlert && (
        <div style={{ maxWidth: "500px" }}>
          <CalloutBox type="error" fullWidth>
            <Typography variant="bodySmall">{formAlert.message}</Typography>
          </CalloutBox>
        </div>
      )}
    </PageLoader>
  )
}

const CheckoutPageWithAuth = requireAuth(CheckoutPage)
export default CheckoutPageWithAuth
