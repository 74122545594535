import { Modal } from "@joonapp/web-shared"
import { create } from "zustand"

import { useAddEditRewardModal } from "./AddEditRewardModal"
import { useRewardTemplatesQuery } from "../../networking/queries"
import { CustomReward } from "../../types"

interface RewardTemplatesModalStore {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

export const useRewardTemplatesModal = create<RewardTemplatesModalStore>(
  (set) => ({
    isOpen: false,
    onOpen: () => set({ isOpen: true }),
    onClose: () => set({ isOpen: false }),
  })
)

const RewardTemplates = () => {
  const { isOpen, onClose } = useRewardTemplatesModal()
  const { data: templates } = useRewardTemplatesQuery()

  return (
    <Modal isOpen={isOpen} onClose={onClose} displayCloseIcon animate>
      <div className="quest-modal">
        <div className="quest-modal-content">
          <div className="quest-modal-header section-title">Templates</div>
          <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            {templates?.map((template: CustomReward, i: number) => (
              <RewardTemplate template={template} key={i} />
            ))}
          </div>
        </div>
      </div>
    </Modal>
  )
}

const RewardTemplate = ({ template }: { template: CustomReward }) => {
  const { onOpen } = useAddEditRewardModal()
  const { onClose } = useRewardTemplatesModal()

  const onClickUseTemplate = () => {
    setTimeout(() => onOpen(template), 100)
    onClose()
  }

  return (
    <div className="reward-template">
      <div className="reward-template-left">
        <div style={{ display: "flex", gap: "8px" }}>
          <div className="reward-template-emoji">{template.emoji}</div>
          <div className="reward-template-title">{template.title}</div>
        </div>
        <div className="reward-template-cost">
          <div>{template.cost} </div>
          <img
            src="/images/icons/coin-icon.png"
            className="reward-template-coin"
            alt="coin"
          />
        </div>
      </div>
      <div className="reward-template-right">
        <button className="reward-template-button" onClick={onClickUseTemplate}>
          Use
        </button>
      </div>
    </div>
  )
}

export default RewardTemplates
