import { useEffect } from "react"

import WelcomeContent from "./WelcomeContent"
import useDocumentTitle from "../../hooks/useDocumentTitle"
import { usePixelEvent } from "../../hooks/usePixelEvent"
import { useQuestionnairesQuery } from "../../networking/queries"
import {
  ANALYTIC_EVENTS,
  FacebookPixelEvent,
  GAEvent,
  PinterestPixelEvent,
  TiktokPixelEvent,
  trackAnalyticEvent,
  trackGAEvent,
} from "../../util/analytics"
import { useRouter } from "../../util/router"
import { sessionManager } from "../../util/storage"

export default function WelcomePage() {
  useDocumentTitle("Joon - Welcome")
  const router = useRouter()
  const { trackFBPixelEvent, trackTikTokPixelEvent, trackPinterestEvent } =
    usePixelEvent()
  // This is called here to preload/cache questionnaires for survey step
  useQuestionnairesQuery()

  useEffect(() => {
    if (sessionManager.getRefreshToken()) router.push("/quests")
    trackFBPixelEvent(FacebookPixelEvent.CONTACT)
    trackTikTokPixelEvent(TiktokPixelEvent.CONTACT)
    trackPinterestEvent(PinterestPixelEvent.CONTACT)
    trackAnalyticEvent(ANALYTIC_EVENTS.SHOW_WELCOME, {
      utm_source: router.query.utm_source,
      utm_campaign: router.query.utm_campaign,
      utm_content: router.query.utm_content,
      utm_term: router.query.utm_term,
      ad_set_name: router.query.ad_set_name,
      ad_name: router.query.ad_name,
    })
    trackGAEvent(GAEvent.START_QUIZ)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <WelcomeContent />
}
