import {
  Button,
  ChatIcon,
  FlexBox,
  HandHoldingHeartIcon,
  InfoIcon,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  StarIcon,
  Typography,
  VideoIcon,
} from "@joonapp/web-shared"
import { isAndroid, isChrome, isMobile } from "react-device-detect"

import { useCoachingStore } from "./useCoachingStore"
import ListCard from "../../components/coaching/ListCard"
import { ProgramStepIcon } from "../../components/coaching/ProgramStepIcon"
import { useAddToHomescreenModal } from "../../components/modals/AddToHomescreenModal"
import { useIOSModal } from "../../components/modals/IOSModal"
import { COACH_PLANS } from "../../constants"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import {
  cohorts,
  experimentNames,
  useGetExperimentCohort,
} from "../../util/experiments"
import { useRouter } from "../../util/router"

const ScheduledPage = () => {
  const { planIndex, selectedCoach } = useCoachingStore()
  const router = useRouter()
  const { onOpen: openIOSModal } = useIOSModal()
  const { onOpen: openAddToHomescreenModal } = useAddToHomescreenModal()
  const { plan, frequency } = COACH_PLANS[planIndex]

  const isOnMobileChrome = isChrome && isMobile && isAndroid

  const variant = useGetExperimentCohort(
    experimentNames.webParentVirtualCoachOnboarding
  )

  const onFinishClick = () => {
    router.push("/me")
    if (router.query.from === "academy") return
    trackAnalyticEvent(ANALYTIC_EVENTS.VIRTUAL_COACH_ONBOARDING_COMPLETE)
    if (isOnMobileChrome) openAddToHomescreenModal()
    else openIOSModal()
  }

  return (
    <FlexBox
      justify="flex-start"
      align="center"
      direction="column"
      fullWidth
      gap={SPACING.space4}
      style={{ minHeight: "70vh", paddingTop: "20px" }}
    >
      <FlexBox
        direction="column"
        align="center"
        justify="center"
        gap={SPACING.space4}
        style={{
          ...(variant === cohorts.variantD
            ? { marginTop: SPACING.space6 }
            : { margin: `${SPACING.space6} 0` }),
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: SPACING.space4,
            backgroundColor: JoonColorExpanded.viridian100,
            width: "62px",
            height: "62px",
            transform: "rotate(15deg)",
            paddingBottom: "5px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              transform: "rotate(-15deg)",
            }}
          >
            <HandHoldingHeartIcon
              size={48}
              color={JoonUIColor.semantic.success}
            />
          </div>
        </div>
        {variant === cohorts.variantD ? (
          <Typography variant="h2" color={JoonUIColor.text.primary}>
            Coaching membership started!
          </Typography>
        ) : (
          <Typography variant="h2" color={JoonUIColor.text.primary}>
            Care Navigator <br />
            call scheduled!
          </Typography>
        )}
      </FlexBox>

      {variant === cohorts.variantD && (
        <>
          <Typography variant="body" color={JoonUIColor.text.primary}>
            Membership summary:
          </Typography>
          <FlexBox
            direction="column"
            justify="center"
            align="center"
            style={{
              position: "relative",
              width: "min(450px, 100%)",
              background: "#F9FAFC",
              border: `2px solid ${JoonUIColor.text.primaryAccent}`,
              borderRadius: SPACING.space2,
              marginBottom: SPACING.space2,
              boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.10)",
              textAlign: "left",
              padding: SPACING.space2,
            }}
          >
            <FlexBox
              direction="row"
              justify="center"
              align="center"
              gap={SPACING.space2}
              style={{
                position: "absolute",
                padding: `${SPACING.space1} ${SPACING.space2}`,
                top: "-1px",
                left: "-1px",
                backgroundColor: JoonUIColor.background.accent,
                borderTopLeftRadius: SPACING.space2,
                borderBottomRightRadius: SPACING.space2,
                width: "fit-content",
              }}
            >
              <StarIcon size={16} color={JoonUIColor.icon.inverted} />
              <Typography variant="bodySmall" color={JoonUIColor.text.inverted}>
                Most popular
              </Typography>
            </FlexBox>
            <FlexBox
              justify="left"
              align="center"
              gap={SPACING.space1}
              style={{
                marginTop: SPACING.space6,
              }}
            >
              <Typography variant="h3" color={JoonUIColor.text.primary}>
                JoonCoach
              </Typography>
              <Typography
                variant="h3"
                color={JoonUIColor.text.primaryAccent}
                style={{ fontFamily: "Caveat" }}
              >
                {plan}
              </Typography>
            </FlexBox>

            <FlexBox justify="center" align="center" gap={SPACING.space6}>
              <FlexBox
                direction="column"
                align="flex-start"
                gap={SPACING.space0}
              >
                <FlexBox
                  direction="row"
                  justify="flex-start"
                  align="center"
                  gap={SPACING.space2}
                  wrap={false}
                  style={{ padding: SPACING.space2 }}
                >
                  <div
                    style={{
                      minWidth: "20px",
                      maxWidth: "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <HandHoldingHeartIcon
                      size={20}
                      color={JoonUIColor.icon.accent}
                    />
                  </div>
                  <Typography
                    variant="bodyXSmall"
                    color={JoonUIColor.text.primary}
                    style={{ fontWeight: "700" }}
                  >
                    A personally matched Coach to help you set up your Joon app
                    properly for your family
                  </Typography>
                </FlexBox>

                <FlexBox
                  direction="row"
                  justify="flex-start"
                  align="center"
                  gap={SPACING.space2}
                  wrap={false}
                  style={{ padding: SPACING.space2 }}
                >
                  <div
                    style={{
                      minWidth: "20px",
                      maxWidth: "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <ChatIcon size={20} color={JoonUIColor.icon.accent} />
                  </div>
                  <Typography
                    variant="bodyXSmall"
                    color={JoonUIColor.text.primary}
                    style={{ fontWeight: "700" }}
                  >
                    Anytime direct messaging with your coach
                  </Typography>
                </FlexBox>

                <FlexBox
                  direction="row"
                  justify="flex-start"
                  align="center"
                  gap={SPACING.space2}
                  wrap={false}
                  style={{
                    padding: SPACING.space2,
                    paddingRight: SPACING.space0,
                    borderRadius: SPACING.space2,
                  }}
                >
                  <div
                    style={{
                      minWidth: "20px",
                      maxWidth: "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <VideoIcon size={20} color={JoonUIColor.icon.accent} />
                  </div>
                  <Typography
                    variant="bodyXSmall"
                    color={JoonUIColor.text.primary}
                    style={{ fontWeight: "700" }}
                  >
                    {frequency}
                  </Typography>
                </FlexBox>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </>
      )}

      {variant === cohorts.variantD ? (
        <ListCard
          icon={InfoIcon}
          text={`You're all set - ${selectedCoach?.user.name || "your coach"} will reach out in 24-48 hours to chat about your first meeting!`}
          backgroundColor={JoonUIColor.background.lightAccent}
          gap={SPACING.space2}
          iconColor={JoonUIColor.border.accent}
        />
      ) : router.query.from === "academy" || variant === cohorts.variantA ? (
        <>
          <ListCard
            icon={InfoIcon}
            text="Before your call, make a note of anything you'd like to review with our Care Navigator!"
            backgroundColor={JoonUIColor.background.lightAccent}
            gap={SPACING.space2}
            iconColor={JoonUIColor.border.accent}
          />
          <Typography
            variant="bodySmall"
            color={JoonUIColor.text.primary}
            style={{ fontStyle: "italic" }}
          >
            If you have any questions beforehand, feel <br />
            free to email us at contact@joonapp.io
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="body" color={JoonUIColor.text.primary}>
            While we wait for your scheduled call, <br />
            let's start building a routine for your child!
          </Typography>

          <div
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "10px",
              zIndex: 0,
            }}
          >
            <ListCard
              icon={ProgramStepIcon}
              text="Start by creating a few daily routine quests for your child"
              backgroundColor={JoonUIColor.background.lightAccent}
              gap={SPACING.space2}
              step={1}
            />
            <ListCard
              icon={ProgramStepIcon}
              text="Set up custom rewards in the rewards tab to help with child motivation and buy-in."
              backgroundColor={JoonUIColor.background.lightAccent}
              gap={SPACING.space2}
              step={2}
            />
            <ListCard
              icon={ProgramStepIcon}
              text="Make a note on anything you'd like to review with our Care Navigator."
              backgroundColor={JoonUIColor.background.lightAccent}
              gap={SPACING.space2}
              step={3}
            />
            <div
              style={{
                position: "absolute",
                height: "90%",
                left: "23px",
                backgroundColor: "transparent",
                borderLeft: `1px dashed ${JoonColorExpanded.indigo200}`,
                zIndex: -1,
              }}
            />
          </div>
        </>
      )}

      <Button
        fullWidth
        onClick={onFinishClick}
        text={
          router.query.from === "academy"
            ? "Return home"
            : variant === cohorts.variantA || variant === cohorts.variantD
              ? "Done"
              : "Let's go!"
        }
      />
    </FlexBox>
  )
}

export default ScheduledPage
