import {
  Button,
  CircleCheckboxChecked,
  CloseCircleIcon,
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  MultipleChoiceLearningModuleAssignmentCompletion,
  MultipleChoiceModule,
  MultipleChoiceOption,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { useEffect, useMemo, useState } from "react"

import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../../util/analytics"
import TrainingProgressionBar from "../TrainingProgressionBar"
import useAssignmentCompletionMutation from "../useAssignmentCompletionMutation"
import useTraining from "../useTraining"
import useTrainingStore from "../useTrainingStore"

const QuizModule = () => {
  const { currentModule } = useTraining()
  const learningModule = currentModule as MultipleChoiceModule
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(-1)
  const startingPageIndex = -1
  const summaryPageIndex = learningModule.multiple_choice_questions.length

  // whenever we change current module, reset the current question index
  useEffect(() => {
    setCurrentQuestionIndex(startingPageIndex)
  }, [currentModule, startingPageIndex])

  switch (currentQuestionIndex) {
    case startingPageIndex:
      return (
        <QuizModuleStartingPage
          setCurrentQuestionIndex={setCurrentQuestionIndex}
        />
      )
    case summaryPageIndex:
      return (
        <QuizSummaryPage
          currentQuestionIndex={currentQuestionIndex}
          setCurrentQuestionIndex={setCurrentQuestionIndex}
        />
      )
    default:
      return (
        <QuizQuestion
          currentQuestionIndex={currentQuestionIndex}
          setCurrentQuestionIndex={setCurrentQuestionIndex}
        />
      )
  }
}

export default QuizModule

const AnswerItem = ({
  answer,
  isSelected,
  isSubmitted,
  setSelectedAnswer,
}: {
  answer: MultipleChoiceOption
  isSelected: boolean
  isSubmitted: boolean
  setSelectedAnswer: (answerId: number) => void
}) => {
  const isSystemCorrect = answer.is_correct
  const isCorrectSelected = isSystemCorrect && isSelected
  const isCorrectNotSelected = isSystemCorrect && !isSelected
  const isIncorrectSelected = !isSystemCorrect && isSelected

  const colors = useMemo(() => {
    if (!isSubmitted) {
      return {
        primary: JoonUIColor.text.primary,
        secondary: JoonUIColor.background.primaryNeutral,
      }
    }
    if (isCorrectSelected) {
      return {
        primary: JoonUIColor.semantic.success,
        secondary: JoonColorExpanded.viridian100,
      }
    }
    if (isCorrectNotSelected) {
      return {
        primary: JoonColorExpanded.blue400,
        secondary: JoonColorExpanded.blue100,
      }
    }
    if (isIncorrectSelected) {
      return {
        primary: JoonColorExpanded.red400,
        secondary: JoonColorExpanded.red100,
      }
    }
    return {
      secondary: JoonUIColor.background.primaryNeutral,
      primary: JoonUIColor.text.primary,
    }
  }, [
    isCorrectSelected,
    isCorrectNotSelected,
    isIncorrectSelected,
    isSubmitted,
  ])

  const onClick = () => {
    if (!isSubmitted) {
      setSelectedAnswer(answer.id)
    }
  }

  return (
    <button
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between",
        borderRadius: SPACING.space2,
        padding: SPACING.space3,
        background: colors.secondary,
        border:
          isSelected && !isSubmitted
            ? `1px solid ${JoonUIColor.border.accent}`
            : `1px solid ${JoonUIColor.border.default}`,
      }}
      onClick={onClick}
    >
      <Typography variant="body" textAlign="left">
        {answer.text}
      </Typography>
      {isSubmitted &&
        (isSystemCorrect ? (
          <CircleCheckboxChecked color={colors.primary} size={16} />
        ) : (
          isSelected && <CloseCircleIcon color={colors.primary} />
        ))}
    </button>
  )
}

const QuizModuleStartingPage = ({
  setCurrentQuestionIndex,
}: {
  setCurrentQuestionIndex: (index: number) => void
}) => {
  const { currentModule } = useTraining()
  const learningModule = currentModule as MultipleChoiceModule
  const totalQuestions = learningModule.multiple_choice_questions.length

  const onClickStart = () => {
    setCurrentQuestionIndex(0)
  }

  return (
    <>
      <FlexBox
        direction="column"
        gap={SPACING.space4}
        style={{ padding: SPACING.space6 }}
      >
        <FlexBox
          direction="column"
          align="center"
          justify="center"
          gap={SPACING.space6}
          style={{
            background: JoonUIColor.background.primaryNeutral,
            borderRadius: SPACING.space2,
            padding: SPACING.space4,
          }}
        >
          <Typography variant="h2" textAlign="center">
            {learningModule.title}
          </Typography>
          <Typography variant="body" textAlign="center">
            {totalQuestions} questions
          </Typography>
        </FlexBox>
      </FlexBox>
      <TrainingProgressionBar>
        <Button text="Start" onClick={onClickStart} fullWidth />
      </TrainingProgressionBar>
    </>
  )
}

const QuizSummaryPage = ({
  setCurrentQuestionIndex,
  currentQuestionIndex,
}: {
  currentQuestionIndex: number
  setCurrentQuestionIndex: (index: number) => void
}) => {
  const { incrementModuleIndex } = useTrainingStore()
  const { currentModule, getButtonText } = useTraining()
  const learningModule = currentModule as MultipleChoiceModule
  const totalQuestions = learningModule.multiple_choice_questions.length

  const buttonText = getButtonText({ isLastPageInModule: true })

  const onClickNext = () => {
    incrementModuleIndex()
    trackAnalyticEvent(
      ANALYTIC_EVENTS.COMPLETE_MULTIPLE_CHOICE_LEARNING_ASSIGNMENT
    )
  }

  return (
    <>
      <FlexBox
        direction="column"
        gap={SPACING.space4}
        style={{ padding: SPACING.space6 }}
      >
        <FlexBox
          direction="column"
          align="center"
          justify="center"
          gap={SPACING.space6}
          style={{
            background: JoonUIColor.background.primaryNeutral,
            borderRadius: SPACING.space2,
            padding: SPACING.space4,
          }}
        >
          <Typography variant="h2" textAlign="center">
            {learningModule.title}
          </Typography>
          <Typography variant="body" textAlign="center">
            {totalQuestions} questions answered!
          </Typography>
        </FlexBox>

        <QuizGrade />
      </FlexBox>
      <TrainingProgressionBar>
        <FlexBox direction="row" gap={SPACING.space3} wrap={false}>
          <TextButton
            onClick={() => setCurrentQuestionIndex(currentQuestionIndex - 1)}
          >
            <Typography
              variant="bodyBold"
              color={JoonUIColor.text.primaryAccent}
              style={{ padding: `0 ${SPACING.space8}` }}
            >
              Previous
            </Typography>
          </TextButton>
          <Button
            text={buttonText}
            onClick={onClickNext}
            fullWidth
            style={{ whiteSpace: "nowrap" }}
          />
        </FlexBox>
      </TrainingProgressionBar>
    </>
  )
}

const QuizGrade = () => {
  const { currentCompletion, currentModule } = useTraining()
  const completion =
    currentCompletion as MultipleChoiceLearningModuleAssignmentCompletion
  const learningModule = currentModule as MultipleChoiceModule

  const { percentCorrect, completionText } = (() => {
    const totalQuestions = learningModule.multiple_choice_questions.length
    const correctAnswers = completion.multiple_choice_answers.filter(
      (answer) => answer.is_correct
    ).length

    const percentCorrect = Math.round((correctAnswers / totalQuestions) * 100)

    const completionText =
      percentCorrect === 100
        ? "Great job! You got all the answers correct!"
        : `You got ${correctAnswers} out of ${totalQuestions} correct!`

    return {
      percentCorrect,
      completionText,
    }
  })()

  return (
    <FlexBox
      direction="column"
      gap={SPACING.space2}
      wrap={false}
      style={{
        background: JoonUIColor.background.primaryNeutral,
        borderRadius: SPACING.space2,
        padding: SPACING.space4,
      }}
    >
      <FlexBox
        align="center"
        wrap={false}
        gap={SPACING.space2}
        justify="center"
      >
        {percentCorrect === 100 && (
          <Typography variant="expressive">🙌</Typography>
        )}
        <Typography variant="expressive">{percentCorrect}%</Typography>
      </FlexBox>
      <Typography variant="bodyBold" textAlign="center">
        {completionText}
      </Typography>
      {percentCorrect <= 60 && (
        <Typography
          variant="body"
          textAlign="center"
          style={{ maxWidth: "300px", margin: "0 auto" }}
        >
          We recommend reviewing this topic with your clinician during your next
          session.
        </Typography>
      )}
    </FlexBox>
  )
}

const QuizQuestion = ({
  currentQuestionIndex,
  setCurrentQuestionIndex,
}: {
  currentQuestionIndex: number
  setCurrentQuestionIndex: (index: number) => void
}) => {
  const { currentModule, currentCompletion } = useTraining()
  const learningModule = currentModule as MultipleChoiceModule
  const completion =
    currentCompletion as MultipleChoiceLearningModuleAssignmentCompletion
  const currentQuestion =
    learningModule.multiple_choice_questions[currentQuestionIndex]
  const totalQuestions = learningModule.multiple_choice_questions.length

  const currentQuestionUserAnswerId =
    completion.multiple_choice_answers[currentQuestionIndex]?.answer_id

  const [selectedAnswer, setSelectedAnswer] = useState<number | null>(
    currentQuestionUserAnswerId ?? null
  )
  const [isSubmitted, setIsSubmitted] = useState(!!currentQuestionUserAnswerId)

  const assignmentCompletionMutation = useAssignmentCompletionMutation()

  const isAnswerCorrect = useMemo(() => {
    return currentQuestion.options.every((option) => {
      const isCorrect = option.is_correct
      const isSelected = option.id === selectedAnswer
      return isCorrect === isSelected
    })
  }, [currentQuestion, selectedAnswer])

  useEffect(() => {
    setSelectedAnswer(currentQuestionUserAnswerId ?? null)
    setIsSubmitted(!!currentQuestionUserAnswerId)
  }, [currentQuestionUserAnswerId])

  const onClickNext = () => {
    if (selectedAnswer === null) return

    if (!isSubmitted) {
      setIsSubmitted(true)
      assignmentCompletionMutation.mutate({
        assignmentCompletionId: completion.id,
        multipleChoiceAnswers: [
          {
            question_id: currentQuestion.id,
            answer_id: selectedAnswer,
            is_correct: isAnswerCorrect,
          },
        ],
      })
    } else {
      setIsSubmitted(false)
      setCurrentQuestionIndex(currentQuestionIndex + 1)
    }
  }

  return (
    <>
      <FlexBox
        direction="column"
        gap={SPACING.space4}
        style={{
          padding: SPACING.space6,
          background: JoonUIColor.background.primaryNeutral,
          flex: 1,
        }}
      >
        <Typography variant="h2" textAlign="center">
          {currentQuestion.text}
        </Typography>
        <FlexBox direction="column" gap={SPACING.space2}>
          {currentQuestion.options.map((answer, index) => (
            <AnswerItem
              key={index}
              answer={answer}
              isSelected={selectedAnswer === answer.id}
              isSubmitted={isSubmitted}
              setSelectedAnswer={setSelectedAnswer}
            />
          ))}
        </FlexBox>
      </FlexBox>
      <TrainingProgressionBar
        style={{
          boxShadow: isSubmitted
            ? `0px 8px 24px 0px ${isAnswerCorrect ? JoonUIColor.semantic.success : JoonUIColor.semantic.destructive}`
            : undefined,
        }}
      >
        <FlexBox direction="column" gap={SPACING.space3}>
          {isSubmitted &&
            (isAnswerCorrect ? (
              <FlexBox direction="row" gap={SPACING.space2} align="center">
                <CircleCheckboxChecked
                  color={JoonUIColor.semantic.success}
                  size={24}
                />
                <Typography variant="h3" color={JoonUIColor.semantic.success}>
                  Correct!
                </Typography>
              </FlexBox>
            ) : (
              <FlexBox direction="row" gap={SPACING.space2} align="center">
                <CloseCircleIcon color={JoonColorExpanded.red400} />
                <Typography variant="h3" color={JoonColorExpanded.red400}>
                  Incorrect
                </Typography>
              </FlexBox>
            ))}

          <FlexBox direction="row" gap={SPACING.space3} wrap={false}>
            {isSubmitted && (
              <TextButton
                onClick={() =>
                  setCurrentQuestionIndex(currentQuestionIndex - 1)
                }
              >
                <Typography
                  variant="bodyBold"
                  color={JoonUIColor.text.primaryAccent}
                  style={{ padding: `0 ${SPACING.space8}` }}
                >
                  Previous
                </Typography>
              </TextButton>
            )}
            <Button
              text={
                isSubmitted
                  ? currentQuestionIndex === totalQuestions - 1
                    ? "Finish"
                    : "Next question"
                  : "Check answer"
              }
              onClick={onClickNext}
              fullWidth
              disabled={!isSubmitted && selectedAnswer === null}
            />
          </FlexBox>
        </FlexBox>
      </TrainingProgressionBar>
    </>
  )
}
