import {
  ChildUser,
  Family,
  FamilyChildProfile,
  FamilyParentProfile,
  ParentAvatar,
  UserRole,
  QuestInstance,
  QuestSeries,
} from "@joonapp/web-shared"
import dayjs, { Dayjs } from "dayjs"
import { getAuth, signOut } from "firebase/auth"
import { RRule, Weekday } from "rrule"

import { ANALYTIC_EVENTS, trackAnalyticEvent } from "./analytics"
import { firebaseApp } from "./firebase"
import {
  CustomErrorInterface,
  TherapistProfileWithPatients,
  Subscriber,
  Invitation,
  InvitationGroup,
  ShopItem,
  ShopItemType,
} from "../types"

const auth = getAuth(firebaseApp)

export async function apiRequestOld(path: string, method = "GET", data: any) {
  const accessToken = auth.currentUser
    ? await auth.currentUser.getIdToken()
    : undefined

  return fetch(`/api/${path}`, {
    method: method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: data ? JSON.stringify(data) : undefined,
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.status === "error") {
        // Automatically signout user if accessToken is no longer valid
        if (response.code === "auth/invalid-user-token") {
          signOut(auth)
        }

        throw CustomError(response.code, response.message)
      } else {
        return response.data
      }
    })
}

export async function apiRequest(
  path: string,
  method: string = "GET",
  data?: any
) {
  return fetch(`/api/${path}`, {
    method: method,
    headers: { "Content-Type": "application/json" },
    body: data ? JSON.stringify(data) : undefined,
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.status === "error") {
        // Automatically signout user if accessToken is no longer valid
        if (response.code === "auth/invalid-user-token") {
          signOut(auth)
        }

        throw CustomError(response.code, response.message)
      } else {
        return response.data
      }
    })
}

// Create an Error with custom message and code
export function CustomError(
  code: string,
  message: string
): CustomErrorInterface {
  return { ...new Error(message), code }
}

export function hasActivePlanSync(subscriber: Subscriber): boolean {
  const entitlements = subscriber.entitlements
  if (entitlements.premium) {
    const expire_date = entitlements.premium.expires_date
    const expire_date1 = new Date(expire_date)

    const today = new Date()

    return expire_date1 > today
  }
  // Q: Should return boolean false at end?
  return false
}

function getPlanType(subscriber: Subscriber) {
  if (subscriber.management_url) {
    if (subscriber.management_url.includes("apple")) {
      return "apple"
    } else {
      return "stripe"
    }
  } else {
    return "promotional"
  }
}

export function hasPurchasedPlanBefore(subscriber: Subscriber) {
  // Just check if there is anything for the premium entitlements
  return !!subscriber.entitlements.premium
}

export function getActivePlan(subscriber: Subscriber) {
  const entitlements = subscriber.entitlements
  if (entitlements.premium) {
    const expire_date = entitlements.premium.expires_date
    const expire_date1 = new Date(expire_date)

    const today = new Date()

    return {
      isActive: expire_date1 > today,
      planType: getPlanType(subscriber),
    }
  }

  // Q: Should have a return type in all cases. This ok?
  return { isActive: false, planType: "none" }
}

// Q: Doesn't look like this is being used anywhere?
export async function hasActivePlan(subscriberId: string) {
  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "X-Platform": "ios",
      "Content-Type": "application/json",
      // Authorization: `Bearer ${import.meta.env.VITE_REVCAT_KEY}`
      Authorization: "Bearer strp_bSiokSQhnXUFPUgkNSxpfctchCr",
    },
  }

  return fetch(
    `https://api.revenuecat.com/v1/subscribers/${subscriberId}`,
    options
  )
    .then((response) => response.json())
    .then((response) => {
      const subscriber = response.subscriber
      const entitlements = subscriber.entitlements

      if (entitlements.premium) {
        const expire_date = entitlements.premium.expires_date
        const expire_date1 = new Date(expire_date)

        const today = new Date()

        return expire_date1 > today
      }
    })
    .catch((err) => console.error(err))
}

export const sortByRoutine = (data: QuestInstance[]) => {
  const routineOrder = {
    morning: 0,
    afternoon: 1,
    night: 2,
    anytime: 3,
  } as { [key: string]: number }
  return data.sort((a, b) => {
    return routineOrder[a.series.routine] - routineOrder[b.series.routine]
  })
}

export const getDayAbbreviations = (repetitionArray: boolean[]) => {
  if (repetitionArray.every((val) => !!val)) return "Daily"
  if (repetitionArray.filter((val) => !!val).length === 1)
    return dayjs().day(repetitionArray.indexOf(true)).format("dddd")
  return repetitionArray
    .map((day: boolean, i: number) => (day ? dayjs().day(i).format("dd") : ""))
    .join("")
}

export const capitalizeFirstLetter = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1)

export const separateFamily = (user: any, family: Family | undefined) => {
  const separateFamily: {
    parent: FamilyParentProfile | null
    coparents: FamilyParentProfile[]
    children: FamilyChildProfile[]
  } = {
    parent: null,
    coparents: [],
    children: [],
  }

  if (user && family)
    family.profiles.forEach((profile) => {
      if (profile.user.id === user.id)
        separateFamily.parent = profile as FamilyParentProfile
      if (profile.role === UserRole.PARENT && profile.user.id !== user.id)
        separateFamily.coparents.push(profile)
      if (profile.role === UserRole.CHILD) separateFamily.children.push(profile)
    })

  return separateFamily
}

export const getChildrenFromFamily = (family: Family) =>
  family?.profiles.filter(
    (profile) => profile.role === UserRole.CHILD
  ) as FamilyChildProfile[]

export const noOnboardedChildren = (children: FamilyChildProfile[]) => {
  return (
    !children ||
    children.length === 0 ||
    children.every((child) => !child.user.active_doter.name)
  )
}

export const getShopItemText = (shopItem: ShopItem) => {
  switch (shopItem.type) {
    case ShopItemType.ACCESSORY:
      return "Used to accessorize doters."
    case ShopItemType.DECORATION:
      return "Used to decorate backyard."
    case ShopItemType.FOOD:
      return "Used to feed doters."
    default:
      return ""
  }
}

export const getRelativeDay = (date: string) => {
  const day = dayjs(date)
  const today = dayjs()
  const diff = day.diff(today, "day")

  return diff === 1
    ? "Tomorrow"
    : diff === 0
      ? "Today"
      : diff === -1
        ? "Yesterday"
        : dayjs(date).format("MMM DD, YYYY")
}

export const getRepetitionText = (
  repetition: boolean[] | null | undefined,
  startDate: string | null | undefined,
  repeatsFrequency: number | null | undefined
) => {
  let text = "This Quest will"

  if (!repetition?.some((day) => !!day)) {
    if (startDate) text += ` only show up ${getRelativeDay(startDate)}.`
    else text += ` show up everyday until completed.`
  } else if (repetition?.some((day) => !!day) && repeatsFrequency) {
    if (startDate) text += ` start ${getRelativeDay(startDate)} and `
    text += ` repeat ${getDayAbbreviations(repetition)}`
    text += ` every ${repeatsFrequency} week${repeatsFrequency > 1 ? "s" : ""}`
  }
  return text
}

export const getCheckoutRedirectUrl = (router: any) => {
  const trialLengthParams = router.query.tpk ? `&tpk=${router.query.tpk}` : ""
  const campaignParams = router.query.campaign
    ? `&campaign=${router.query.campaign}`
    : ""
  return `/checkout?priceId=${router.query.priceId}${trialLengthParams}${campaignParams}`
}

export const getChildImage = (child: ChildUser | null) => {
  return child?.active_doter?.type
    ? `/images/avatars/${child?.active_doter?.type}.png`
    : "/images/avatars/egg.png"
}

export const getUserFromFamily = (family: Family, user_id: number) => {
  return family?.profiles.find((profile) => profile?.user?.id === user_id)?.user
}

export const sortQuestSection = (questGroups: QuestInstance[]) => {
  const getTimeValue = (timeString: string) => {
    const [time, period] = timeString.split(" ")
    const [hours, minutes] = time.split(":")
    let totalMinutes = parseInt(hours) * 60 + parseInt(minutes)
    if (period === "PM" && parseInt(hours) !== 12) {
      totalMinutes += 12 * 60
    }
    return totalMinutes
  }
  const getOrderValue = (order: number | null) => {
    return order === null ? Infinity : order
  }

  return questGroups.sort((a, b) => {
    const seriesA = a.series
    const seriesB = b.series

    if (seriesA.order !== null || seriesB.order !== null) {
      return getOrderValue(seriesA.order) - getOrderValue(seriesB.order)
    } else if (seriesA.mandatory !== seriesB.mandatory) {
      return seriesA.mandatory ? -1 : 1
    } else if (!!seriesA.reminder_time && !!seriesB.reminder_time) {
      const timeA = getTimeValue(seriesA.reminder_time)
      const timeB = getTimeValue(seriesB.reminder_time)
      return timeA - timeB
    } else if (!!seriesA.reminder_time && !seriesB.reminder_time) {
      return -1
    } else if (!seriesA.reminder_time && !!seriesB.reminder_time) {
      return 1
    } else return seriesA.date_created - seriesB.date_created
  })
}

export const sortQuestSeriesSection = (questGroups: QuestSeries[]) => {
  const getTimeValue = (timeString: string) => {
    const [time, period] = timeString.split(" ")
    const [hours, minutes] = time.split(":")
    let totalMinutes = parseInt(hours) * 60 + parseInt(minutes)
    if (period === "PM" && parseInt(hours) !== 12) {
      totalMinutes += 12 * 60
    }
    return totalMinutes
  }
  const getOrderValue = (order: number | null) => {
    return order === null ? Infinity : order
  }

  return questGroups.sort((seriesA, seriesB) => {
    if (seriesA.order !== null || seriesB.order !== null) {
      return getOrderValue(seriesA.order) - getOrderValue(seriesB.order)
    } else if (seriesA.mandatory !== seriesB.mandatory) {
      return seriesA.mandatory ? -1 : 1
    } else if (!!seriesA.reminder_time && !!seriesB.reminder_time) {
      const timeA = getTimeValue(seriesA.reminder_time)
      const timeB = getTimeValue(seriesB.reminder_time)
      return timeA - timeB
    } else if (!!seriesA.reminder_time && !seriesB.reminder_time) {
      return -1
    } else if (!seriesA.reminder_time && !!seriesB.reminder_time) {
      return 1
    } else return seriesA.date_created - seriesB.date_created
  })
}

// Creates a list of dates for the calendar
const getDates = () => {
  const dateList = []
  const numDays = 120 // ~2 months each direction. probably don't even need that much
  const startingDate = dayjs().subtract(numDays / 2, "day")
  for (let i = 0; i < numDays; i++) {
    dateList.push(startingDate.add(i, "day"))
  }
  return dateList
}
export const calendarDates = getDates()

// Takes in a list of children and their therapists, and returns
// a list of therapists with their patients
export const getTherapistsWithPatients = (
  response: { [childId: string]: any }[]
): TherapistProfileWithPatients[] => {
  const therapistsWithPatients = {} as {
    [childId: string]: TherapistProfileWithPatients
  }

  response.forEach((childTherapistMap) => {
    Object.entries(childTherapistMap).forEach(([childId, therapists]) => {
      therapists.forEach((therapist: any) => {
        const therapistId = therapist.user.id
        if (therapistsWithPatients[therapistId]) {
          therapistsWithPatients[therapistId].patients.push(Number(childId))
        } else {
          therapistsWithPatients[therapistId] = {
            ...therapist,
            patients: [childId],
          }
        }
      })
    })
  })

  return Object.values(therapistsWithPatients)
}

const therapistInstructionsSubject =
  "See my child's executive functioning improvements"
const therapistInstructionsBody = `A new reward system we've been using called Joon has improved my child's executive functioning more than visual charts. Joon is a video game my child is very motivated to use which holds them accountable without me having to nag, yell, or beg.

If you create a free therapist account on Joon, you'll be able to see their routine and track our routine progress outside of therapy, seeing which tasks they still struggle with daily.

To see my child's executive functioning progress, you can sign up at: https://joonapp.io/therapists-invite-2

Joon has helped over 500k families improve their executive functioning and is recommended by ADDitude Magazine and over 300 clinics in the US!
  `
export const emailInstructions = () => {
  const emailSubject = encodeURIComponent(therapistInstructionsSubject)
  const emailBody = encodeURIComponent(therapistInstructionsBody)
  const url = `mailto:?subject=${emailSubject}&body=${emailBody}`
  window.open(url)
  trackAnalyticEvent(ANALYTIC_EVENTS.SEND_THERAPIST_INVITE)
}
export const shareInstructions = () => {
  if (navigator.share) {
    navigator.share({
      title: therapistInstructionsSubject,
      text: therapistInstructionsBody,
    })
  }
  trackAnalyticEvent(ANALYTIC_EVENTS.SEND_THERAPIST_INVITE)
}

export const createErrorFromResponse = (err: any) => {
  let error = err.response.data
  if (typeof error == "string") return error
  if (Object.values(error).length > 0) {
    error = Object.values(error)[0]
    if (error.constructor === Array) return error[0]
    else return error
  }
}
export const createErrorFromSignupResponse = (err: any): string => {
  const error = err.response.data
  if (typeof error == "string") return error
  if (Object.entries(error).length > 0) {
    const errorKey = Object.entries(error)[0][0]
    const errorMessage = Object.entries(error)[0][1] as string | string[]
    if (errorMessage?.constructor === Array) {
      if (["email", "password"].includes(errorKey))
        return `${errorKey}: ${errorMessage[0]}`
      else return `${errorMessage[0]}`
    } else return errorMessage as string
  }
  return "Unknown error occurred. Please try again."
}

// These next two functions convert repetition array to rrule representation and back
export const convertToByWeekday = (booleanArray: boolean[]) => {
  if (!booleanArray) return []
  const weekdays: Weekday[] = [
    RRule.SU,
    RRule.MO,
    RRule.TU,
    RRule.WE,
    RRule.TH,
    RRule.FR,
    RRule.SA,
  ]

  return booleanArray
    .map((value, index) => (value ? weekdays[index] : null))
    .filter((day): day is Weekday => day !== null)
}

export const getRepetitionArrayFromRrule = (rruleString: string): boolean[] => {
  const rrule = RRule.fromString(rruleString)
  const weekdays = [6, 0, 1, 2, 3, 4, 5] // Mapping to RRule's internal representation

  return weekdays.map((day) => rrule.options.byweekday?.includes(day) || false)
}

export const getReptitionIntervalFromRrule = (rruleString: string): number => {
  return RRule.fromString(rruleString).options.interval
}

export const isTestEnv =
  import.meta.env.MODE === "development" ||
  window.location.hostname.endsWith(".vercel.app")

export const groupInvites = (invites: Invitation[]): InvitationGroup[] => {
  const therapistInvites: { [key: string]: InvitationGroup } = {}
  for (const invite of invites) {
    if (!therapistInvites[invite.invitee_email])
      therapistInvites[invite.invitee_email] = []
    therapistInvites[invite.invitee_email].push(invite)
  }
  return Object.values(therapistInvites)
}

export const getParentAvatarImage = (avatar: ParentAvatar) => {
  switch (avatar) {
    case ParentAvatar.FRUMPULOUS:
      return "/images/avatars/frumpulous-portrait.png"
    case ParentAvatar.JUNK_MUTT:
      return "/images/avatars/junk-mutt-portrait.png"
    case ParentAvatar.SCRUFFIT:
      return "/images/avatars/scruffit-portrait.png"
    default:
      return null
  }
}
export const isUsingInAppBrowser = (() => {
  // @ts-ignore
  var ua = navigator.userAgent || navigator.vendor || window.opera || ""
  return (
    /FBAN|FBAV|FBBV|FB|FBDV|FBMD|FBSV|FBSS|FBCR|FBID|FBLC|FBOP|FBCA/i.test(
      ua
    ) ||
    /Instagram/i.test(ua) ||
    /Tiktok|TT/i.test(ua)
  )
})()

export const getDayAbbreviation = (day: number) => {
  const dayAbbrevationMap = {
    0: "Su",
    1: "M",
    2: "T",
    3: "W",
    4: "Th",
    5: "F",
    6: "Sa",
  } as { [key: number]: string }
  return dayAbbrevationMap[day]
}

export const dateIsToday = (date: Dayjs) => {
  return dayjs().isSame(date, "day")
}

export const groupQuestsByTherapist = (
  parentTasks: QuestInstance[] | undefined
) => {
  if (!parentTasks) return []
  const therapists = {} as Record<number, QuestInstance[]>
  parentTasks.forEach((task) => {
    const therapist = task.series.assigner_profile?.user_id
    if (therapist && !therapists[therapist]) {
      therapists[therapist] = [task]
    } else if (therapist) {
      therapists[therapist].push(task)
    }
  })
  return Object.values(therapists)
}

export const groupQuestsByDate = (quests: QuestInstance[]) => {
  const groupedQuests: Record<string, QuestInstance[]> = {}
  quests.forEach((quest) => {
    const date = dayjs(quest.date).format("YYYY-MM-DD")
    if (!groupedQuests[date]) groupedQuests[date] = []
    groupedQuests[date].push(quest)
  })
  return Object.entries(groupedQuests).sort(
    ([dateA], [dateB]) => dayjs(dateB).unix() - dayjs(dateA).unix()
  )
}

export const getReadableFileSize = (size: number | undefined) => {
  if (!size) return ""
  var i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024))
  return (
    +(size / Math.pow(1024, i)).toFixed(2) * 1 +
    " " +
    ["B", "kB", "MB", "GB", "TB"][i]
  )
}
