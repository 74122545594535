import QuestReviewEmptyState from "./QuestReviewEmptyState"
import QuestReviewModalContent from "./QuestReviewModalContent"
import useQuestReviewStore from "./useQuestReviewStore"
import SlideUpModal from "../../../components/slideUpModal/SlideUpModal"
import { useQuestReviewQuery } from "../../../networking/queries"

const QuestReviewModal = () => {
  const { isOpen, onClose } = useQuestReviewStore()
  const { data: questsToReview } = useQuestReviewQuery()

  return (
    <SlideUpModal
      isOpen={isOpen}
      onClose={onClose}
      size="default"
      backgroundType="overlay"
    >
      {questsToReview && questsToReview?.length > 0 ? (
        <QuestReviewModalContent />
      ) : (
        <QuestReviewEmptyState />
      )}
    </SlideUpModal>
  )
}

export default QuestReviewModal
