import { JoonColorExpanded, ModuleType } from "@joonapp/web-shared"

export const getModuleTheme = (type: ModuleType) => {
  switch (type) {
    case ModuleType.PDF:
      return {
        primaryColor: JoonColorExpanded.viridian400,
        secondaryColor: JoonColorExpanded.viridian100,
        moduleText: "PDF",
        completionVerificationText: "I finished reviewing this PDF",
      }
    case ModuleType.READING_TEXTS:
      return {
        primaryColor: JoonColorExpanded.green300,
        secondaryColor: JoonColorExpanded.green100,
        moduleText: "Reading",
        completionVerificationText: "I finished reading this article",
      }
    case ModuleType.VIDEO:
      return {
        primaryColor: JoonColorExpanded.pink400,
        secondaryColor: JoonColorExpanded.pink100,
        moduleText: "Video",
        completionVerificationText: "I finished watching this video",
      }
    case ModuleType.SHORT_ANSWERS:
      return {
        primaryColor: JoonColorExpanded.yellow400,
        secondaryColor: JoonColorExpanded.yellow100,
        moduleText: "Short answer",
        completionVerificationText: null,
      }
    case ModuleType.MULTIPLE_CHOICE:
      return {
        primaryColor: JoonColorExpanded.orange300,
        secondaryColor: JoonColorExpanded.orange100,
        moduleText: "Multiple choice",
        completionVerificationText: null,
      }
  }
}
