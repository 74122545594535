import { FlexBox } from "@joonapp/web-shared"
import { useEffect, useRef } from "react"
import ReactDOM from "react-dom"

import SlideUpModalBackground from "./SlideUpModalBackground"
import SlideUpModalCard from "./SlideUpModalCard"
import SlideUpModalCloseButton from "./SlideUpModalCloseButton"

type Props = {
  isOpen: boolean
  onClose: () => void
  showCard?: boolean
  showCloseButton?: boolean
  size: "fit-content" | "default"
  backgroundType: "overlay" | "solid"
  headerComponent?: React.ReactNode
  zIndex?: number
  children: React.ReactNode | React.ReactNode[]
}

const SlideUpModal = ({
  isOpen,
  onClose,
  showCard = true,
  showCloseButton = false,
  size = "default",
  backgroundType = "overlay",
  headerComponent,
  zIndex,
  children,
}: Props) => {
  const modalRef = useRef<HTMLDivElement>(null)

  // Temporary fix for scrolling issue. Ideally we want to remove the component from
  // the DOM when it's not open, but that causes issues with the slide up animation.
  // For now, we'll just scroll to the top of the modal when it's closed.
  useEffect(() => {
    if (modalRef.current && !isOpen) {
      // Select all scrollable elements inside the parent component
      const scrollContainers = modalRef.current.querySelectorAll(
        '[style*="overflow"]'
      )
      // Scroll each container to the top
      scrollContainers.forEach((container) => {
        container.scrollTop = 0
      })
    }
  }, [isOpen])

  return ReactDOM.createPortal(
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        position: "relative",
        overflow: "hidden",
        zIndex: zIndex ?? 900,
      }}
      ref={modalRef}
    >
      <SlideUpModalBackground
        isOpen={isOpen}
        onClose={onClose}
        type={backgroundType}
      />
      {showCloseButton && (
        <SlideUpModalCloseButton isOpen={isOpen} onClose={onClose} />
      )}
      <FlexBox
        direction="column"
        wrap={false}
        style={{
          height: "100dvh",
          width: "min(450px, 100vw)",
          position: "fixed",
          top: 0,
          pointerEvents: isOpen ? "auto" : "none",
          zIndex: "1000",
        }}
      >
        {headerComponent}
        <SlideUpModalCard
          isOpen={isOpen}
          showCard={showCard}
          onClose={onClose}
          size={size}
          hasHeaderComponent={!!headerComponent}
        >
          {isOpen ? children : <></>}
        </SlideUpModalCard>
      </FlexBox>
    </div>,
    document.body
  )
}

export default SlideUpModal
