import {
  ChildUser,
  FlexBox,
  JoonUIColor,
  SPACING,
  Slider,
  TextInput,
  Typography,
} from "@joonapp/web-shared"
import dayjs from "dayjs"
import { useShallow } from "zustand/react/shallow"

import CatchUpCardEmptyState from "./CatchUpCardEmptyState"
import CatchUpCardStatusOverlay from "./CatchUpCardStatusOverlay"
import CatchUpTabBar from "./CatchUpTabBar"
import CatchUpTabBarReviewButtons from "./CatchUpTabBarReviewButtons"
import TimeOfDayCard from "./TimeOfDayCard"
import { useCatchUpStore } from "./useCatchUpStore"
import Counter from "../../components/counter/Counter"
import { useImageModalStore } from "../../components/modals/ImageModal"
import { getChildImage } from "../../util/util"

const CatchUpCardContent = () => {
  const {
    selectedQuest,
    questReviewDifficulty,
    setQuestReviewDifficulty,
    questReviewReward,
    setQuestReviewReward,
    questReviewNotes,
    setQuestReviewNotes,
    selectedChild,
  } = useCatchUpStore(
    useShallow((state) => ({
      selectedQuest: state.selectedQuest,
      questReviewDifficulty: state.questReviewDifficulty,
      setQuestReviewDifficulty: state.setQuestReviewDifficulty,
      questReviewReward: state.questReviewReward,
      setQuestReviewReward: state.setQuestReviewReward,
      questReviewNotes: state.questReviewNotes,
      setQuestReviewNotes: state.setQuestReviewNotes,
      selectedChild: state.selectedChild,
    }))
  )

  if (!selectedQuest) return <CatchUpCardEmptyState />

  return (
    <>
      <div>
        <div
          style={{
            padding: `${SPACING.space2} ${SPACING.space3}`,
            borderBottom: `1px solid ${JoonUIColor.border.default}`,
          }}
        >
          <TimeOfDayCard
            date={selectedQuest.date}
            time={selectedQuest.series.reminder_time}
            title={selectedQuest.series.title}
            routine={selectedQuest.series.routine}
          />
        </div>
        <CompletionNotes />
        <div
          style={{
            zIndex: 1001,
            borderTopLeftRadius: SPACING.space4,
            borderTopRightRadius: SPACING.space4,
            background: "white",
            padding: `${SPACING.space4} ${SPACING.space6}`,
          }}
        >
          <Typography
            variant="caption"
            textAlign="center"
            style={{ marginBottom: SPACING.space1 }}
          >
            How difficult was it to motivate {selectedChild?.user.name}?
          </Typography>
          <Slider
            value={questReviewDifficulty}
            options={[0, 1, 2, 3, 4, 5, 6]}
            setValue={setQuestReviewDifficulty}
            minLabel="Very easy"
            maxLabel="Very difficult"
          />
        </div>
        <div style={{ padding: `${SPACING.space4} ${SPACING.space6}` }}>
          <Typography variant="caption" textAlign="center">
            Reward Adjustment
          </Typography>
          <Typography
            variant="bodySmall"
            textAlign="center"
            color={JoonUIColor.text.secondary}
            style={{ marginBottom: SPACING.space1 }}
          >
            Modify rewards based on completion quality
          </Typography>
          <Counter value={questReviewReward} setValue={setQuestReviewReward} />
        </div>
        <div style={{ padding: `${SPACING.space4} ${SPACING.space6}` }}>
          <Typography variant="caption" textAlign="center">
            Add a note for {selectedChild?.user.name}
          </Typography>
          <TextInput
            name="Note"
            placeholder="Type a message..."
            value={questReviewNotes}
            onChange={(e) => setQuestReviewNotes(e.target.value)}
            fullWidth
          />
        </div>
      </div>
      <CatchUpCardStatusOverlay />
      <CatchUpTabBar>
        <CatchUpTabBarReviewButtons />
      </CatchUpTabBar>
    </>
  )
}

const CompletionNotes = () => {
  const { onOpen } = useImageModalStore()
  const { selectedQuest, selectedChild } = useCatchUpStore(
    useShallow((state) => ({
      selectedQuest: state.selectedQuest,
      selectedChild: state.selectedChild,
    }))
  )
  if (!selectedQuest) return null

  const completionDate = dayjs.unix(Number(selectedQuest.completion_date))

  return (
    <>
      {/* eslint-disable-next-line */}
      <div
        onClick={() =>
          selectedQuest.completion_photo_url &&
          onOpen(selectedQuest.completion_photo_url)
        }
        style={{
          maxHeight: selectedQuest.completion_photo_url ? "250px" : "70px",
          height: selectedQuest.completion_photo_url ? "250px" : "70px",
          overflow: "hidden",
          marginBottom: "-16px",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "0%",
            right: "0%",
            bottom: "0%",
            left: "0%",
            background: `${
              selectedQuest.completion_photo_url
                ? `url(${selectedQuest.completion_photo_url})`
                : "linear-gradient(48deg, rgba(105,77,154,1) 0%, rgba(90,108,148,1) 35%, rgba(129,111,117,1) 68%, rgba(142,96,120,1) 100%)"
            } no-repeat center/cover`, // Adjust as needed
            filter: selectedQuest.completion_photo_url ? "blur(8px)" : "unset",
            zIndex: "-2",
          }}
        />
        <img
          src={selectedQuest.completion_photo_url || ""}
          alt=""
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "100%",
            maxHeight: "100%",
            zIndex: "-1", // Ensures the image is above the blurred background
          }}
        />
      </div>
      <FlexBox
        justify="space-between"
        align="center"
        wrap={false}
        style={{
          zIndex: 1001,
          background: "rgba(27, 31, 37, 0.8)",
          position: "absolute",
          width: `calc(100% - ${SPACING.space8})`,
          margin: `0 ${SPACING.space4}`,
          transform: "translateY(-100%)",
          borderTopLeftRadius: SPACING.space2,
          borderTopRightRadius: SPACING.space2,
          padding: `${SPACING.space1} ${SPACING.space3}`,
        }}
      >
        <div>
          <Typography
            textAlign="left"
            variant="bodySmall"
            color={JoonUIColor.text.inverted}
          >
            Completed {completionDate?.format("MMMM D, YYYY")} at{" "}
            {completionDate?.format("h:mm A")}
          </Typography>
          <Typography
            variant="caption"
            color={JoonUIColor.text.inverted}
            textAlign="left"
          >
            {selectedQuest.completion_notes}
          </Typography>
        </div>
        <img
          src={getChildImage(selectedChild?.user as ChildUser)}
          alt="avatar"
          style={{
            width: "24px",
            height: "24px",
            borderRadius: SPACING.space6,
          }}
        />
      </FlexBox>
    </>
  )
}

export default CatchUpCardContent
